import React, { useEffect, useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { useLocation, useNavigate } from "react-router-dom"
import { Button, Divider, List, ListItem, ListItemText, SwipeableDrawer, IconButton } from "@mui/material"
import "./MobileMenu.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import LanguageSwicher from "../LanguageSwicher/LanguageSwicher"
import { T } from "@transifex/react"
import Twitter from "../../assets/Icons/Twitter"
import Facebook from "../../assets/Icons/Facebook"
import Instagram from "../../assets/Icons/Instagram"
import LinkedIn from "../../assets/Icons/LinkedIn"
import { handleGoSocial } from "../../utils"

export default function MobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [showTariff, setShowTariff] = useState(true)

  function historyListen() {
    if (location.pathname === "/personal-info" || location.pathname === "/tariff-plan") {
      setShowTariff(false)
    } else {
      setShowTariff(true)
    }
  }

  useEffect(() => {
    historyListen()
    // eslint-disable-next-line
  }, [location])

  const links = [
    { label: "Home", link: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}` },
    {
      label: "Our Team",
      link: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-team`,
    },
    {
      label: "Our Generators",
      link: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-generators`,
    },
    { label: "FAQs", link: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/faq` },
    { label: "Blog", link: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/blog` },
  ]

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setAnchorEl(open)
  }

  const getActive = link => {
    let pathname = location.pathname
    if (pathname === link) return "active"
  }
  const handleGoTariff = () => {
    navigate("/tariff-plan")
  }
  return (
    <div className={"mobile-menu-container"}>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)} color="primary">
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer anchor={"right"} open={anchorEl} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
          <div role="presentation" style={{ width: 300 }}>
            <IconButton
              onClick={toggleDrawer(false)}
              color="primary"
              style={{
                position: "fixed",
                left: 0,
              }}
            >
              <SvgImage name={"clarity_window-close-line"} />
            </IconButton>
            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItem>
                <T _str={"Menu"} />
              </ListItem>
            </List>
            <Divider />
            <List className={"mobile-menu-links-container"}>
              <ListItem>
                <SvgImage name={"logo-green-dark"} className={"mobile-menu-logo"} />
              </ListItem>
              {links.map((item, idx) => (
                <ListItem
                  button
                  key={idx}
                  onClick={() => {
                    window.open(item.link)
                    toggleDrawer(false)
                  }}
                  className={getActive(item.link)}
                >
                  <ListItemText>
                    {" "}
                    <T _str={item.label} />
                  </ListItemText>
                </ListItem>
              ))}
              <ListItem>
                <ListItemText>
                  <LanguageSwicher button={false} />
                </ListItemText>
              </ListItem>
              {showTariff === true && (
                <ListItem>
                  <ListItemText>
                    <Button color="inherit" className={"mobile-tariff-btn"}>
                      <div
                        className={"label"}
                        onClick={() => {
                          handleGoTariff()
                        }}
                      >
                        <T _str="Calculate Tariff" />
                      </div>
                    </Button>
                  </ListItemText>
                </ListItem>
              )}
            </List>
            <Divider />
            <List>
              <ListItem>
                <div className="mobile-social-links">
                  <div
                    className={"social-item"}
                    onClick={() => {
                      handleGoSocial("twitter")
                    }}
                  >
                    <Twitter color={"#00C1B1"} />
                  </div>
                  <div
                    className={"social-item"}
                    onClick={() => {
                      handleGoSocial("facebook")
                    }}
                  >
                    <Facebook color={"#00C1B1"} />
                  </div>
                  <div
                    className={"social-item"}
                    onClick={() => {
                      handleGoSocial("instagram")
                    }}
                  >
                    <Instagram color={"#00C1B1"} />
                  </div>
                  <div
                    className={"social-item"}
                    onClick={() => {
                      handleGoSocial("linkedin")
                    }}
                  >
                    <LinkedIn color={"#00C1B1"} />
                  </div>
                </div>
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  )
}
