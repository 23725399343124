import { T, UT } from "@transifex/react"
import React from "react"

const NewDynamicTooltipBody = () => {
  return (
    <div>
      <div className={"dynamic-item-container"} style={{ border: "none", paddingBottom: 0 }}>
        <div className="dynamic-item-content">
          <p className="title">
            <strong>
              <T _str={"Looking for our Dynamic Tariff?"} />
            </strong>
          </p>

          <p style={{ paddingTop: 5, textAlign: "left" }}>
            <T _str={"We've simplified the process to make it even easier for you:"} />
          </p>

          <ol>
            <li>
              <T _str={"Click “Join Ostrom” and sign up with our SimplyFair Tariff."} />
            </li>
            <li>
              <UT
                _str={
                  "The grid operator will inform us if you have a smart meter and will begin sending us live data. <em>Please note: this step could take up to 8 weeks, depending on your grid operator.<em>"
                }
              />
            </li>
            <li>
              <T _str={"We will contact you to switch to our SimplyDynamic tariff."} />
            </li>
          </ol>

          <p style={{ paddingTop: 5 }}>
            <T _str={"Learn more about SimplyDynamic on our website."} />
          </p>
        </div>
      </div>
    </div>
  )
}

export default NewDynamicTooltipBody
