import React, { useEffect, useState } from "react"
import { SvgImage } from "../../assets/svgs/SvgImage"

import { T } from "@transifex/react"
import { t } from "@transifex/native"
import "./AutoComplete.scss"

interface AutoCompleteProps {
  style?: React.CSSProperties
  required?: boolean
  className?: string
  defaultValue?: string
  onChange?: (e: any) => void
  showError?: boolean
  helpText?: string
  placeholder?: string
  description?: string
  hideDetail?: boolean
  leadIcon?: string
  trailingIcon?: string
  active?: boolean
  label?: string
  disabled?: boolean
  list: any[]
  indexKey?: string
  labelKey?: string
  useDefaultValue?: boolean
  id?: string
}

export default function AutoComplete({
  style,
  required = false,
  className = "",
  defaultValue = "",
  onChange = () => {},
  showError = false,
  helpText = t("Invalid format"),
  placeholder = "",
  description = "",
  hideDetail = false,
  leadIcon = "",
  trailingIcon = "",
  active = false,
  label = "",
  disabled = false,
  list = [],
  indexKey = "",
  labelKey = "",
  useDefaultValue = true,
  ...props
}: Readonly<AutoCompleteProps>) {
  const [value, setValue] = useState("")
  const [anchorEl, setAnchorEl] = React.useState(false)
  const [validate, setValidate] = useState(true)
  const [filterList, setFilterList] = useState(list)
  const [errorText, setErrorText] = useState(helpText)
  const [selectedKey, setSelectKey] = useState(defaultValue)

  useEffect(() => {
    setErrorText(helpText)
  }, [helpText])
  useEffect(() => {
    if (!useDefaultValue) {
      if (typeof defaultValue === "number") {
        let idx = list.findIndex(item => getItemKeyValue(item) === defaultValue)
        setValue(getItemLabelValue(list[idx]))
      } else {
        setValue(defaultValue)
      }
    }
  }, [defaultValue, list, useDefaultValue])

  useEffect(() => {
    if (useDefaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue, useDefaultValue])
  let inputClass = "text-field-input"
  inputClass += validate ? "" : " error"
  inputClass += showError ? " error" : ""
  inputClass += disabled ? " disabled" : ""
  inputClass += leadIcon === "" ? "" : " leadIcon"
  inputClass += trailingIcon === "" ? "" : " trailingIcon"
  inputClass += active ? " active-border" : ""
  const handleChange = e => {
    setAnchorEl(e.currentTarget)
    setValue(e.currentTarget.value)
    let valid = true
    setValidate(valid)
    setFilterList(
      list.filter(item => getItemLabelValue(item).toUpperCase().includes(e.currentTarget.value.toUpperCase()) === true)
    )
  }
  const handleSelect = itemKey => {
    handleClose()
    let idx = list.findIndex(item => getItemKeyValue(item) === itemKey)
    if (idx > -1) {
      setValue(getItemLabelValue(list[idx]))
      setSelectKey(getItemKeyValue(list[idx]))
      onChange({
        value: getItemKeyValue(list[idx]),
        valid: true,
        error: "",
        supplierCodeNumber: list[idx].codeNumber,
        cityName: list[idx].name,
      })
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = event => {
    setFilterList(list)
    setAnchorEl(event.currentTarget)
  }
  function getItemKeyValue(item) {
    try {
      if (indexKey !== "") return item[indexKey]
      return item.toString()
    } catch (e) {
      return ""
    }
  }
  function getItemLabelValue(item) {
    try {
      if (labelKey !== "") return item[labelKey]
      return item.toString()
    } catch (e) {
      return ""
    }
  }
  return (
    <div className={"text-field-container " + className} style={style}>
      {label !== "" && (
        <div className={"label"}>
          <T _str={label} />
        </div>
      )}
      <div className={"text-field"} onMouseLeave={handleClose}>
        {leadIcon !== "" && <SvgImage name={leadIcon} className={"leadIcon"} />}
        <div className={"input-controller"}>
          <input
            disabled={disabled}
            required={required}
            className={inputClass}
            value={value}
            placeholder={placeholder}
            pattern="\d{4}-\d{2}-\d{2}"
            onClick={handleOpen}
            onChange={handleChange}
            {...props}
          />
        </div>
        <div
          style={{ display: anchorEl ? "block" : "none" }}
          className={"autocomplete-drop"}
          data-test-id={"autocomplete-dropdown"}
        >
          {filterList.map((item, index) => (
            <div
              key={index}
              data-test-id={"autocomplete-dropdown-item" + index}
              className={"item " + (getItemKeyValue(item) === selectedKey ? "selected" : "")}
              title={getItemLabelValue(item)}
              onClick={() => {
                handleSelect(getItemKeyValue(item))
              }}
            >
              {getItemLabelValue(item)}
            </div>
          ))}
        </div>
        {trailingIcon !== "" && (
          <div onClick={handleOpen}>
            <SvgImage name={trailingIcon} className={"trailingIcon"} />
          </div>
        )}
      </div>
      {description !== "" && !((!validate && !hideDetail) || showError) && (
        <div className={"description"}>{description}</div>
      )}
      {((!validate && !hideDetail) || showError) && (
        <div className={"error-description"}>
          <T _str={errorText} />{" "}
        </div>
      )}
    </div>
  )
}
