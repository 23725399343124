import React, { useEffect } from "react"
import "./CheckBox.scss"
import { T } from "@transifex/react"
import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material"

const BpIcon = styled("span")(() => ({
  "borderRadius": 4.96796,
  "width": 18,
  "height": 18,
  "backgroundColor": "white",
  "color": "inherit",
  "border": "1px solid",
  ".Mui-focusVisible &": {
    outline: "1px auto #00C1B1",
    outlineOffset: 2,
  },
}))

const BpCheckedIcon = styled(BpIcon)(() => ({
  "backgroundImage":
    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
  "backgroundColor": "#00C1B1",
  "color": "#00C1B1",
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
  },
}))

const GreenCheckbox = styled(Checkbox)(() => ({
  root: {
    "color": "#00c1b1",
    "&.Mui-checked": {
      color: "#00c1b1",
    },
  },
  checked: {},
}))

const RedCheckbox = styled(Checkbox)(() => ({
  root: {
    "color": "#f00",
    "&.Mui-checked": {
      color: "#f00",
    },
  },
  checked: {},
}))

interface CheckBoxProps {
  label?: string
  className?: string
  value: boolean
  onChange: (value: boolean) => void
  showError?: boolean
  errorText?: string
  labelType?: string
}

export default function CheckBox({
  label = "",
  className = "",
  value,
  onChange,
  showError,
  errorText,
  labelType = "",
  ...props
}: CheckBoxProps) {
  const [state, setState] = React.useState(false)
  const handleChange = event => {
    setState(event.target.checked)
    onChange(event.target.checked)
  }
  useEffect(() => {
    setState(value)
  }, [value])

  const CheckBoxControl = () => {
    if (showError === true && errorText !== "") {
      if (labelType === "component") {
        return (
          <FormControlLabel
            control={
              <Checkbox
                icon={<BpIcon />}
                checked={state}
                onChange={handleChange}
                sx={{
                  "color": "#f00",
                  "&.Mui-checked": {
                    color: "#00c1b1",
                  },
                }}
                {...props}
              />
            }
            label={label}
            className={className}
          />
        )
      } else {
        return (
          <FormControlLabel
            control={
              <RedCheckbox
                icon={<BpIcon />}
                checked={state}
                onChange={handleChange}
                sx={{
                  "color": "#f00",
                  "&.Mui-checked": {
                    color: "#00c1b1",
                  },
                }}
                {...props}
              />
            }
            label={<T _str={label} />}
            className={className}
          />
        )
      }
    } else if (labelType === "component") {
      return (
        <FormControlLabel
          control={
            <GreenCheckbox
              {...props}
              icon={<BpIcon />}
              checkedIcon={<BpCheckedIcon />}
              checked={state}
              onChange={handleChange}
              sx={{
                color: "#00c1b1",
              }}
              {...props}
            />
          }
          label={label}
          className={className}
        />
      )
    } else {
      return (
        <FormControlLabel
          control={
            <GreenCheckbox
              icon={<BpIcon />}
              checkedIcon={<BpCheckedIcon />}
              checked={state}
              onChange={handleChange}
              sx={{
                color: "#00c1b1",
              }}
              {...props}
            />
          }
          label={<T _str={label} />}
          className={className}
        />
      )
    }
  }
  return (
    <FormGroup row className={"check-box"}>
      {CheckBoxControl()}
    </FormGroup>
  )
}
