import React, { useEffect, useState } from "react"
import { RouterProvider } from "react-router-dom"
import { tx } from "@transifex/native"
import PageLoader from "./components/PageLoader/PageLoader"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { GoogleTagManager } from "./googletag"
import { router } from "./router/main"
import { isInjectedCode } from "./utils/helpers"
import { SKIP_SENTRY_MESSAGES } from "./constants/common"

if (process.env.REACT_APP_ENV === "production") {
  GoogleTagManager()
}

tx.init({
  token: process.env.REACT_APP_TRANSIFEX,
  currentLocale: "en",
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay({ maskAllInputs: true })],
  tracesSampleRate: 0.1,
  ignoreErrors: SKIP_SENTRY_MESSAGES,
  beforeSend(event) {
    if (isInjectedCode(event)) {
      return null
    }
    return event
  },
})

export default function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    // eslint-disable-next-line
  }, [!loading])

  return (
    <div>
      <PageLoader loading={loading} />
      <RouterProvider router={router} />
    </div>
  )
}
