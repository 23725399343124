import React from "react"
import { T, useLocale } from "@transifex/react"
import Container from "@mui/material/Container"
import Link from "@mui/material/Link"
import { SvgImage } from "../assets/svgs/SvgImage"

import "./Footer.scss"
import { handleGoSocial } from "../utils"
import { ENGLISH } from "../constants/common"
import { t } from "@transifex/native"
import useTextConstants from "../hooks/useTextConstants"

export default function Footer() {
  const locale = useLocale()

  const { links, aboutUs, infomation } = useTextConstants()

  const infoLinks = [
    {
      name: t("Imprint"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/imprint`,
      testID: "",
    },
    {
      name: locale == ENGLISH ? "Privacy Policy" : "Datenschutz",
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/privacy-policy`,
      testID: "",
    },
    ...infomation,
  ]

  const siteData = [
    {
      icon: "email",
      value: "Contact Form",
      bold: false,
      link: { type: "", value: "https://ostrom.typeform.com/to/Pchunu31?typeform-source=ostrom.webflow.io" },
    },
  ]
  const socials = [
    { icon: "instagram", value: "/", bold: false },
    { icon: "facebook", value: "/", bold: true },
    { icon: "twitter", value: "/", bold: false },
  ]

  function Copyright() {
    return (
      <p className="footer-copy">
        <span style={{ marginRight: 8 }}>{"©"}</span>
        <span>{new Date().getFullYear() + " ostrom"}</span>
      </p>
    )
  }
  return (
    <div className={"footer-container"}>
      <Container>
        <div className="footer-wrapper">
          <div className="footer-up">
            <div className="footer-item-1">
              <SvgImage name="logo-green-dark" className="footer-logo w-inline-block" />
              <div className="footer-item-list" style={{ fontWeight: 400, width: 200 }}>
                <div className="footer-list-item mb-12">
                  <SvgImage name={"timer"} className="sm-icon" />
                  <span className="footer-text bold">
                    <T _str={"Mon-Fri 9:00-17:00"} />
                    <br />
                    <T _str={"Straßburger Straße 55"} />
                    <br />
                    <T _str={"10405 Berlin, Germany"} />
                  </span>
                </div>
              </div>
              {siteData.map(item => (
                <div
                  key={item.value}
                  className="footer-item-list"
                  style={{ fontWeight: item.icon === "timer" ? 400 : 600, width: 200 }}
                >
                  {item.link ? (
                    <a className="footer-list-item mb-12" href={`${item.link.type}${item.link.value}`} target="_blank">
                      <SvgImage name={item.icon} className="sm-icon" />
                      <span className="footer-text bold">
                        <T _str={item.value} />
                      </span>
                    </a>
                  ) : (
                    <div className="footer-list-item mb-12">
                      <SvgImage name={item.icon} className="sm-icon" />
                      <span className="footer-text bold">
                        <T _str={item.value} />
                      </span>
                    </div>
                  )}
                </div>
              ))}
              <SvgImage name={"b_corp"} className="mobile-hidden" styles={{ width: 120, marginTop: "2rem" }} />
            </div>
            <div className="footer-item-2">
              <div className="footer-info">
                <div className="green-link">
                  <T _str={"Links"} />
                </div>
                {links.map(link => (
                  <div key={link.path} className="footer-link">
                    <Link
                      href={link.path}
                      target="_blank"
                      color={"inherit"}
                      rel="noopener noreferrer"
                      data-test-id={link.testID}
                    >
                      <T _str={link.name} />
                    </Link>
                  </div>
                ))}
              </div>
              <div className="footer-info">
                <div className="green-link">
                  <T _str="Ostrom" />
                </div>
                {aboutUs.map(link => (
                  <div key={link.path} className="footer-link">
                    <Link
                      href={link.path}
                      target="_blank"
                      color={"inherit"}
                      rel="noopener noreferrer"
                      data-test-id={link.testID}
                    >
                      <T _str={link.name} />
                    </Link>
                  </div>
                ))}
              </div>
              <div className="footer-info">
                <div className="green-link">
                  <T _str={"Information"} />
                </div>
                {infoLinks.map(link => (
                  <div key={link.path} className="footer-link">
                    <Link
                      href={link.path}
                      target="_blank"
                      color={"inherit"}
                      rel="noopener noreferrer"
                      data-test-id={link.testID}
                    >
                      <T _str={link.name} />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="footer-item-3">
              <div className="green-link">
                <T _str={"Partners"} />
              </div>
              <div className="footer-link">
                <Link href="https://www.ostrom.de/partners" target="_blank" color={"inherit"} rel="noopener noreferrer">
                  <T _str={"Become A Partner"} />
                </Link>
              </div>

              <div className={"partner-desktop"} style={{ textAlign: "center" }}>
                <SvgImage name={"green_tech"} styles={{ marginBottom: 10 }} />
                <a
                  href="https://ecologi.com/ostrom"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="View our Ecologi profile"
                >
                  <SvgImage name={"ecology"} />
                </a>
              </div>
              <div className={"partner-mobile"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <a
                      href="https://ecologi.com/ostrom"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="View our Ecologi profile"
                    >
                      <SvgImage name={"ecology"} styles={{ maxWidth: "50%", display: "block" }} />
                    </a>
                    <SvgImage name={"green_tech"} styles={{ maxWidth: "50%", display: "block", marginTop: 10 }} />
                  </div>
                  <div>
                    <SvgImage name={"b_corp"} styles={{ maxWidth: "50%", display: "block" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-down">
          <Copyright />
          <div className="footer-links">
            {socials.map(item => (
              <div
                onClick={() => {
                  handleGoSocial(item.icon)
                }}
                key={item.icon}
              >
                <SvgImage key={item.icon} name={item.icon} className={"social-link"} />
              </div>
            ))}
            <div
              onClick={() => {
                handleGoSocial("pinterest")
              }}
            >
              <img
                src="https://assets.website-files.com/60ec127477c1e52acb31ae8c/619e6988b3de7b11c810253c_Pinterest.png"
                className={"social-link"}
                width={24}
                height={24}
                alt="pinterest"
              />
            </div>
            <div
              onClick={() => {
                handleGoSocial("snapchat")
              }}
            >
              <img
                src="https://assets.website-files.com/60ec127477c1e52acb31ae8c/619e699fc81ca22ec25566b9_Snapchat.png"
                className={"social-link"}
                width={24}
                height={24}
                alt="snapchat"
              />
            </div>
            <div
              onClick={() => {
                handleGoSocial("youtube")
              }}
            >
              <img
                src="https://assets.website-files.com/60ec127477c1e52acb31ae8c/619e69ad3a080603a0443fa9_YouTube.png"
                className={"social-link"}
                width={24}
                height={24}
                alt="youtube"
              />
            </div>
            <div
              onClick={() => {
                handleGoSocial("tiktok")
              }}
            >
              <img
                src="https://assets.website-files.com/60ec127477c1e52acb31ae8c/619e69be1fed16fdf0df27e8_TikTok.png"
                className={"social-link"}
                width={24}
                height={24}
                alt="tiktok"
              />
            </div>
            <div
              onClick={() => {
                handleGoSocial("linkedin")
              }}
            >
              <img
                src="https://assets.website-files.com/60ec127477c1e52acb31ae8c/619e69d6cb2c9b7aa77b8dc4_LinkedIn.png"
                className={"social-link"}
                width={24}
                height={24}
                alt="linkedin"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
