import React from "react"
import { useShallow } from "zustand/react/shallow"

import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useUserInfoModel } from "../../models/userInfoModel"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useGeneralModel } from "../../models/useGeneralModel"
import { T } from "@transifex/react"
import { useNavigate } from "react-router-dom"
import { PERSONAL_INFO_PAGE_PATH } from "../../constants/common"
import { scrollToSection } from "../../utils"

export default function SummaryUserInfoCard() {
  const planForm = usePlanFormModel(useShallow(state => state))
  const { companyName, firstName, surname, email, phone, houseNumber, street, dob } = useUserInfoModel(
    useShallow(state => state)
  )
  const personalTab = useGeneralModel(useShallow(state => state.personalTab))
  const navigate = useNavigate()

  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Personal Info"} />
          </div>
        </div>
        <div
          className={"btn-edit"}
          onClick={() => {
            scrollToSection("stepper-container-section")
            navigate(`${PERSONAL_INFO_PAGE_PATH}${window.location.search}`)
          }}
        >
          <SvgImage name={"edit"} />
          <p>
            <T _str={"edit"} />
          </p>
        </div>
      </div>
      <div className={"info-card-content"}>
        {personalTab === "business" && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"COMPANY NAME"} />
            </div>
            <div className={"item-value"}>{companyName.value}</div>
          </div>
        )}
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"FIRST NAME"} />
          </div>
          <div className={"item-value"}>{firstName.value}</div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"SURNAME"} />
          </div>
          <div className={"item-value"}>{surname.value}</div>
        </div>
        {personalTab === "home" && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"DATE OF BIRTH"} />
            </div>
            <div className={"item-value"}>{dob.value}</div>
          </div>
        )}
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"EMAIL"} />
          </div>
          <div className={"item-value"}>{email.value}</div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"PHONE NUMBER"} />
          </div>
          <div className={"item-value"}>{phone.value}</div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"Address"} />
          </div>
          <div className={"item-value"}>
            {street.value} {houseNumber.value}, {planForm.postalCode} {planForm?.cityName}
          </div>
        </div>
      </div>
    </div>
  )
}
