import React, { useEffect, useMemo } from "react"

import { Container, Divider } from "@mui/material"
import { Box } from "@mui/system"
import "./style.scss"
import ContractPicker from "./component/ContractPicker"
import PartnerPermission from "./component/PartnerPermissions"
import InfoCard from "./component/InfoCard"
import { usePartnerModel } from "../../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { useNavigate } from "react-router-dom"
import PartnerContainer from "./component/PartnerContainer"
import HeaderTitle from "./component/HeaderTitle"
import { T, UT, useLocale } from "@transifex/react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import usePartnerContractSelection from "../../hooks/usePartnerContractSelection"

const ChooseContractPage = () => {
  const { linkData, user } = usePartnerModel(
    useShallow(state => ({
      linkData: state.linkData,
      user: state.user,
    }))
  )

  const navigate = useNavigate()

  useEffect(() => {
    if (!linkData || !user) {
      navigate("/link/connection-error")
      return
    }
    if (user?.contracts?.length === 0) {
      navigate("/link/connection-error")
    }
  }, [linkData, user])

  const locale = useLocale()

  const { isAlreadyConnected, isSimplyDynamic } = usePartnerContractSelection()

  const renderSection = useMemo(() => {
    if (!linkData || !user) return null
    switch (true) {
      case isAlreadyConnected: {
        const hasMultipleContracts = user?.contracts?.length > 1
        return (
          <InfoCard
            renderDescription={
              hasMultipleContracts ? (
                <T
                  _str="This contract is already associated with {partnerName}. Please select a different contract for connection."
                  partnerName={linkData.partnerName}
                />
              ) : (
                <T _str="This contract is already associated with {partnerName}." partnerName={linkData.partnerName} />
              )
            }
            title={<T _str="Your contract is already connected" />}
            renderImg={<SvgImage name="check-circle" styles={{ height: 36 }} />}
            link={{
              url: linkData.redirectUrl,
              text: <T _str="Back to {partnerName}" partnerName={linkData.partnerName} />,
            }}
          />
        )
      }
      case !isSimplyDynamic:
        return (
          <InfoCard
            renderDescription={
              <UT
                _str="You're currently on the <strong>SimplyFair tariff</strong> but you must switch to the <strong>SimplyDynamic tariff</strong> to connect to {partnerName}."
                partnerName={linkData.partnerName}
              />
            }
            title={<T _str="Tariff change needed" />}
            renderImg={<img alt="" src={require("../../assets/images/warning.png")} height={36} />}
            link={{
              url: `https://www.ostrom.de/dynamic-pricing?lang=${locale.includes("de") ? "de" : "en"}`,
              text: <T _str="Learn More" />,
            }}
            showLogin
          />
        )
      case isSimplyDynamic:
        return <PartnerPermission />
      default:
        return null
    }
  }, [isAlreadyConnected, isSimplyDynamic, user?.contracts, linkData, locale])

  return (
    <PartnerContainer>
      <Box data-test-id="loginForm" className={"column-centered"}>
        <Container maxWidth="sm">
          <HeaderTitle title={<T _str="Connect To Ostrom" />} showBackBtn />
        </Container>
        <Container maxWidth="sm" className={"card shadowProp"}>
          <ContractPicker />
          <div style={{ height: "30px" }} />
          <Divider sx={{ mb: 3 }} />
          {renderSection}
        </Container>
      </Box>
    </PartnerContainer>
  )
}

export default ChooseContractPage
