import React, { useEffect, useState } from "react"
import "./SignupContainer.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { Container, Step, StepConnector, StepLabel, Stepper, stepConnectorClasses } from "@mui/material"

import { T } from "@transifex/react"
import { ErrorBoundary } from "react-error-boundary"

import getRate, { getCitiesByPostalCode, validateReferralCode } from "../../service/api"
import { useLocation, useNavigate } from "react-router-dom"
import { useShallow } from "zustand/react/shallow"

import PlanForm from "../PlanForm/PlanForm"
import TrustPilot from "../TrustPilot"
import { ENGLISH, MAX_USAGE, MIN_USAGE, TARIFF_RESPONSE_STATUS } from "../../constants/common"
import { sendErrorToSentry } from "../../utils/helpers"
import { PlanFormType } from "../../types/form"
import { FormPageStep, PartnerParams } from "../../types/generic"
import { getReferralInfo } from "../../utils"
import { useResponsive } from "../../hooks/useResponsive"
import { t } from "@transifex/native"
import { styled } from "@mui/system"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useGeneralModel } from "../../models/useGeneralModel"
import useCurrentStep from "../../hooks/useCurrentStep"
import { useDeliveryFormModel } from "../../models/useDeliveryFormModel"
import { usePaymentModel } from "../../models/usePaymentFormModel"
import { useUserInfoModel } from "../../models/userInfoModel"
import useInitialParams from "../../hooks/useInitialParams"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 4,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00C1B1",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    [theme.breakpoints.down("lg")]: {
      borderTopWidth: 0,
      display: "none",
    },
  },
}))

const QontoStepIconRoot = styled("div")(props => {
  const { theme } = props
  return {
    "color": theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    "background": theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    "display": "flex",
    "height": 8,
    "width": 8,
    "borderRadius": "50%",
    "alignItems": "center",
    // @ts-ignore
    ...(props?.completed && {
      color: "#00C1B1",
      background: "#00C1B1",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#00C1B1",
      background: "#00C1B1",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      borderRadius: 24,
    },
  }
})

function ErrorFallback({ error }) {
  return (
    <div role="alert" style={{ textAlign: "center" }}>
      <p>
        <T _str="Something went wrong:" />
      </p>
      <pre>{error.message}</pre>
    </div>
  )
}

function SignupContainer({
  handleNext,
  children,
}: Readonly<{
  handleNext?: (step?: FormPageStep, isValidationOnly?: boolean) => Promise<number | void>
  children?: React.ReactNode
}>) {
  const location = useLocation()
  const navigate = useNavigate()
  const [referralAmount, setReferralAmount] = useState(0)
  const [errorRefCode, setErrorRefCode] = useState("")
  const [initialUrlReady, setInitialUrlReady] = useState(false)

  const planForm = usePlanFormModel(
    useShallow(state => ({
      usage: state.usage,
      cityId: state.cityId,
      postalCode: state.postalCode,
      cityName: state.cityName,
      referralCode: state.referralCode,
      product: state.product,
      clientApplicationId: state.clientApplicationId,
      externalUserId: state.externalUserId,
      externalTransactionId: state.externalTransactionId,
    }))
  )

  const { locale } = useGeneralModel(useShallow(state => state))

  const { steps, stepLabels, currentStep } = useCurrentStep()

  const { getInitialUrlParams } = useInitialParams()

  useEffect(() => {
    // @ts-ignore
    if (window?.Cypress) {
      //@ts-ignore
      window.usePlanFormModel = usePlanFormModel
      //@ts-ignore
      window.useGeneralModel = useGeneralModel
      //@ts-ignore
      window.useUserInfoModel = useUserInfoModel
      //@ts-ignore
      window.useDeliveryFormModel = useDeliveryFormModel
      //@ts-ignore
      window.usePaymentModel = usePaymentModel
    }

    getInitialUrlParams()
    setInitialUrlReady(true)
  }, [])

  useEffect(() => {
    const handlePlanFormChanges = async () => {
      const newPlanForm = Object.entries({ ...planForm, language: locale !== ENGLISH ? locale : "" }).reduce(
        (prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev),
        {} as Partial<PlanFormType>
      )

      let url = location.pathname

      const allParams = { ...newPlanForm } as Partial<PlanFormType> | Partial<PartnerParams>

      if (Object.keys(allParams).length > 0) {
        // convert object to string and remove undefined values
        // @ts-ignore
        url += "?" + new URLSearchParams(allParams).toString()
      }
      navigate(url)
    }

    if (!initialUrlReady) {
      return () => {}
    }

    if (!planForm.usage) {
      return () => {}
    }
    if (planForm.usage === undefined) {
      return () => {}
    }
    if (+planForm?.usage < MIN_USAGE || +planForm?.usage > MAX_USAGE) {
      return () => {}
    }

    handlePlanFormChanges()
    // eslint-disable-next-line
  }, [planForm, locale, initialUrlReady])

  useEffect(() => {
    const handleCityChange = async () => {
      if (planForm.postalCode && planForm?.postalCode?.length === 5) {
        const { cityId, cityName } = usePlanFormModel.getState()
        await getCitiesByPostalCode(planForm.postalCode)
          .then(res => {
            if (res?.data?.length > 0) {
              // check if cityId and cityName is valid
              const isValidCityDetails = res.data.find(
                city => city?.id === parseInt(`${cityId}`) && city?.name === cityName
              )
              if (!isValidCityDetails) {
                usePlanFormModel.setState({ cityId: null, cityName: null, postalCode: null })
                useGeneralModel.setState({ cities: [], rate: null })
                return
              }
              useGeneralModel.setState({ cities: res.data })
            }
          })
          .catch(() => {
            usePlanFormModel.setState({ cityId: null, cityName: null, postalCode: null })
            useGeneralModel.setState({ cities: [], rate: null })
          })
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    handleCityChange()
  }, [planForm.postalCode, initialUrlReady])

  useEffect(() => {
    const handleChange = async () => {
      if (planForm.referralCode) {
        await validateReferralCode({ referralCode: planForm.referralCode })
          .then(({ data }) => {
            const [isValid, errMsg] = getReferralInfo(data.status)
            if (!isValid) {
              usePlanFormModel.setState({ referralCode: "" })
              setReferralAmount(0)
              setErrorRefCode(t(errMsg))
              return
            }
            setReferralAmount(data.amount)
            setErrorRefCode("")
          })
          .catch(() => {
            setReferralAmount(0)
            setErrorRefCode(t("Invalid Referral Code"))
          })
      } else {
        setReferralAmount(0)
        // setErrorRefCode("")
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    handleChange()
  }, [planForm.referralCode, initialUrlReady])

  useEffect(() => {
    const handleChange = async () => {
      if (+planForm.usage >= MIN_USAGE && +planForm.usage <= MAX_USAGE) {
        await getRate({
          cityId: planForm.cityId,
          usage: +planForm.usage,
          clientApplicationId: planForm?.clientApplicationId,
        }).then(res => {
          if (res.data) {
            if (res.data.status === TARIFF_RESPONSE_STATUS.MAINTENANCE) {
              navigate("/maintenance")
            } else if (res.data.status === TARIFF_RESPONSE_STATUS.HIGH_DEMAND) {
              navigate("/high-demand")
            } else {
              useGeneralModel.setState({
                rate: res?.data,
                isPendingApplicationAllowed: res?.data?.isPendingApplicationAllowed,
              })
            }
          }
        })
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    if (planForm.cityId && planForm.usage) {
      handleChange()
    } else {
      if (!planForm.cityId) {
        useGeneralModel.setState({ rate: null })
      }
    }
  }, [planForm.cityId, planForm.usage, initialUrlReady, planForm?.clientApplicationId])

  const { deviceType } = useResponsive()

  return (
    <div className="personal-info-container">
      <div>
        {deviceType.mobile && (
          <div className={"tariff-header tariff-summary-header"}>
            <div
              className="header-background"
              style={location.pathname !== "/tariff-plan" ? { height: "475px" } : { height: "422px" }}
            ></div>
            <div>
              <div className="header-title">
                <T _str={currentStep?.name} />
              </div>
            </div>
          </div>
        )}
        {deviceType.deskTop && (
          <div className="tariff-header">
            <div className="header-background"></div>
            <div>
              <div className="header-title">
                <T _str={currentStep?.name} />
              </div>
            </div>
          </div>
        )}

        <PlanForm referralAmount={referralAmount} errorRefCode={errorRefCode} setErrorRefCode={setErrorRefCode} />
        <div className={"stepper-container"} id="stepper-container-section">
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, info) => {
              sendErrorToSentry(error, info)
            }}
          >
            <Container>
              <div className={"stepper-from-container"}>
                <Stepper alternativeLabel activeStep={currentStep?.index + 1} connector={<QontoConnector />}>
                  {stepLabels.map((label, index) => (
                    <Step
                      key={label}
                      onClick={() => {
                        if (handleNext) {
                          handleNext(steps[label])
                          return
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      data-test-id={`step-${index}`}
                    >
                      {/* @ts-ignore */}
                      <StepLabel StepIconComponent={QontoStepIconRoot}>
                        <span className="stepper-label">
                          <T _str={steps[label].name} />
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {children}
              </div>
            </Container>
          </ErrorBoundary>
        </div>
        <div className="reviews-container">
          <div className={"reviews-background"}>
            <Container>
              <div className="back-container">
                <SvgImage name="quote-icon" />
              </div>
            </Container>
          </div>
          <div className="title">
            <T _str={"What our customers are saying"} />
          </div>
          <div className="description">
            <T
              _str={
                "We take pride in providing excellent customer service. But don’t just take our word for it: Hear it from customers who’ve already made the jump to Ostrom."
              }
            />
          </div>
          <TrustPilot />
        </div>
      </div>
    </div>
  )
}

export default SignupContainer
