import { Box } from "@mui/material"
import React from "react"
import usePartnerLanguage from "../../../hooks/usePartnerLanguage"

function PartnerContainer({ children }: Readonly<{ children: React.ReactNode }>) {
  usePartnerLanguage()
  return (
    <Box
      sx={{
        backgroundImage: `url(${require("../../../assets/images/bkround.png")})`,
        backgroundSize: "cover",
        pt: 3,
        height: "100vh",
      }}
    >
      {children}
    </Box>
  )
}

export default PartnerContainer
