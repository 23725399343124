import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import Cookie from "js-cookie"
import { ENGLISH } from "../../constants/common"

const TrustPilot = () => {
  const [lang, setLang] = useState(ENGLISH)
  const ref = useRef(null)

  const location = useLocation()

  useEffect(() => {
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true)
    }
    const locale = Cookie.get("locale")
    if (locale) setLang(locale)
  }, [])

  useEffect(() => {
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [lang])

  const handleLangChange = () => {
    let params = new URLSearchParams(location.search).get("language")
    if (params) {
      setLang(params)
    }
  }

  useEffect(() => {
    handleLangChange()
  }, [location])

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="607f2b7694941100013939e4"
        data-style-height="280px"
        data-style-width="100%"
        data-theme="light"
        data-stars="5"
        data-review-languages={!lang || lang === ENGLISH ? ENGLISH : "de"}
        data-locale={!lang || lang === ENGLISH ? "en-US" : "de-DE"}
      >
        <a href="https://www.trustpilot.com/review/ostrom.de" target="_blank" rel="noreferrer">
          Trustpilot
        </a>
      </div>
    </div>
  )
}

export default TrustPilot
