import React, { useEffect } from "react"
import { T, useLocale } from "@transifex/react"
import { t } from "@transifex/native"
import { useShallow } from "zustand/react/shallow"

import { usePaymentModel } from "../../../models/usePaymentFormModel"
import {
  DEFAULT_VALUE_0,
  DEFAULT_VALUE_1,
  DIRECT_DEBIT,
  ENGLISH,
  IBAN,
  IBAN_LENGTH,
  PAYMENT_WITH_BANNER_PATH,
  TRANSFER,
} from "../../../constants/common"
import { getCitiesByPostalCode, getStreetsByCityId } from "../../../service/api"
import Dropdown from "../../../components/Dropdown/Dropdown"
import TextField from "../../../components/TextField/TextField"
import CheckBox from "../../../components/CheckBox/CheckBox"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import AutoComplete from "../../../components/SupplierInput/AutoComplete"
import { useResponsive } from "../../../hooks/useResponsive"
import { useGeneralModel } from "../../../models/useGeneralModel"
import { Box } from "@mui/material"
import useGetRate from "../../../hooks/useGetRate"
import { STR_RATE_OSTROM, STR_RATE_TARIFF } from "../../../constants/global"
import useValidateIban from "../../../hooks/useValidateIban"

const PaymentFormBanner = () => {
  const { localRate } = useGeneralModel(useShallow(state => state))

  const { getRateData, selectedTariff } = useGetRate()

  const rateData = getRateData(STR_RATE_TARIFF, STR_RATE_OSTROM, "monthly")

  // @ts-ignore
  const regularCost = parseInt(rateData) || localRate

  const amount = selectedTariff ?? regularCost

  return (
    <div className="payment-banner">
      <div className="banner-title">
        <T
          _str="⚡️ You’re almost there! Just enter your payment details to secure your great price of {amount} €. ⚡️"
          amount={amount}
        />
      </div>
      <div className="banner-subtitle">
        <T _str="You won’t be charged until your contract is confirmed. We also offer a 14-day cancellation period in case you change your mind." />
      </div>
    </div>
  )
}

export default function PaymentForm() {
  const paymentMethods = [t("Direct Debit (SEPA)"), t("Transfer")]

  const onChangePaymentType = (index: number) => {
    usePaymentModel.setState({
      paymentMethod: index === DEFAULT_VALUE_0 ? DIRECT_DEBIT : TRANSFER,
    })
  }

  const locale = useLocale()

  const {
    billingCity,
    billingZip,
    billingFirstName,
    billingHouseNumber,
    billingStreet,
    billingSurname,
    accountHolderName,
    companyName,
    iban,
    isDifBillingAdr,
    paymentMethod,
  } = usePaymentModel(useShallow(state => state))

  const { personalTab, cities, cityStreets } = useGeneralModel(useShallow(state => state))

  const { validateIbanValue } = useValidateIban()

  const handlePostalCode = postalCodeValue => {
    const isValidPostalcode = [...postalCodeValue.value].every(c => "0123456789".includes(c))
    if (isValidPostalcode) {
      usePaymentModel.setState({
        billingZip: { value: postalCodeValue.value, error: "", valid: postalCodeValue.valid },
        billingCity: { ...billingCity, error: "" },
      })
    }
  }

  useEffect(() => {
    if (cities.length && (billingZip?.value?.length !== 5 || billingZip?.error || !billingZip.valid)) {
      useGeneralModel.setState({ cities: [] })
    }
  }, [billingZip, cities])

  useEffect(() => {
    if (billingZip?.value?.length === 5) {
      getCitiesByPostalCode(billingZip.value).then(({ data }) => {
        if (data[0] === null) {
          usePaymentModel.setState({
            billingZip: { ...billingZip, error: "Invalid" },
          })
          useGeneralModel.setState({ cities: [] })
          return () => {}
        }
        usePaymentModel.setState({
          billingZip: { ...billingZip, error: "" },
        })
        useGeneralModel.setState({ cities: data })
      })
    } else {
      usePaymentModel.setState({
        billingCity: { value: "", valid: false, error: "" },
        billingStreet: { value: "", valid: false, error: "" },
        billingHouseNumber: { value: "", valid: false, error: "" },
      })
      useGeneralModel.setState({ cities: [], cityStreets: [] })
    }
  }, [billingZip.value])

  useEffect(() => {
    // @ts-ignore
    if (billingCity?.code && parseInt(billingCity?.code, 10)) {
      // @ts-ignore
      getStreetsByCityId(billingCity.code).then(({ data }) => {
        if (data) {
          useGeneralModel.setState({ cityStreets: data })
        } else {
          useGeneralModel.setState({ cityStreets: [] })
        }
      })
    }
    // @ts-ignore
  }, [billingCity.code])

  useEffect(() => {
    if (isDifBillingAdr) {
      usePaymentModel.setState({
        billingCity: { ...billingCity },
      })
    }
  }, [isDifBillingAdr])

  useEffect(() => {
    validateIbanValue(iban.value, iban)
  }, [iban.value])

  const { deviceType } = useResponsive()

  const isPaymentBanner = location.pathname === PAYMENT_WITH_BANNER_PATH

  return (
    <>
      <div className={"form-container bg-white-color" + `${isPaymentBanner ? " banner-container" : ""}`}>
        {isPaymentBanner && <PaymentFormBanner />}
        <div className={`${isPaymentBanner ? " main-payment-container" : ""}`}>
          <div className={"form-title"}>
            <div className={"title bg-white-color"}>
              <T _str="Payment Details" />
            </div>
            <div className={"line-container"}>
              <div className={"line"} />
            </div>
          </div>
          <div className={"form-content pb-0"}>
            <div className={"payment-dropdown"}>
              <Dropdown
                list={paymentMethods}
                label={t("Select Method")}
                dataTestId={"payment-selector"}
                defaultValue={paymentMethod === DIRECT_DEBIT ? DEFAULT_VALUE_0 : DEFAULT_VALUE_1}
                onChange={onChangePaymentType}
              />
            </div>
            {paymentMethod === DIRECT_DEBIT ? (
              <>
                <div className={"form-tab-container"}>
                  <div className={"personal-input-controller"} style={{ width: "100%" }}>
                    <div className={"personal-text-field"} style={{ minHeight: iban.error !== "" ? 123 : 103 }}>
                      <TextField
                        active={true}
                        defaultValue={iban.value}
                        helpText={iban.error}
                        showError={iban.error !== ""}
                        onChange={e => {
                          usePaymentModel.setState({
                            iban: { ...iban, value: e.value, error: "" },
                          })
                        }}
                        placeholder={t("DE00 0000 0000 0000 0000 00")}
                        label={t("IBAN")}
                        maxLength={IBAN_LENGTH}
                        type={IBAN}
                        data-test-id={"iban-input"}
                      />
                    </div>
                    <div className={"personal-text-field"}>
                      {personalTab === "home" ? (
                        <TextField
                          active={true}
                          defaultValue={accountHolderName.value}
                          helpText={accountHolderName.error}
                          showError={accountHolderName.error !== ""}
                          onChange={e => {
                            usePaymentModel.setState({
                              accountHolderName: { value: e.value, valid: e.valid, error: "" },
                            })
                          }}
                          label={"ACCOUNT HOLDER"}
                          data-test-id={"account-holder-input"}
                        />
                      ) : (
                        <TextField
                          active={true}
                          defaultValue={companyName.value}
                          helpText={companyName.error}
                          showError={companyName.error !== ""}
                          onChange={e => {
                            usePaymentModel.setState({
                              companyName: { value: e.value, valid: e.valid, error: "" },
                            })
                          }}
                          label={"ACCOUNT HOLDER"}
                          data-test-id={"account-holder-input"}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={"form-tab-container sepa-label"}>
                  <p>
                    <T _str={"SEPA MANDATE"} />
                  </p>
                  <T
                    _str={
                      "The named account holder authorizes Ostrom (Aplus Energy GmbH) to collect payments from their account by means of direct debit. At the same time, the named account holder instructs their credit institution to honor the direct debits drawn on their account by the supplier. Payee: Aplus Energy GmbH, Straßburger Str. 55, 10405 Berlin, Creditors-ID DE16ZZZ00002388888. The mandate reference can be found on the account statement in the reason for debit. Note: Within eight weeks, beginning with the debit date, the refund of the debited amount can be demanded. The conditions agreed with the credit institution apply."
                    }
                  />
                </div>
                <div className={"form-tab-container mt-20"}>
                  <div className={"personal-input-controller check-box-header"} style={{ width: "100%" }}>
                    <CheckBox
                      errorText={""}
                      value={isDifBillingAdr}
                      onChange={check => usePaymentModel.setState({ isDifBillingAdr: check })}
                      showError={false}
                      className={"text-upper"}
                      label={
                        locale == ENGLISH
                          ? "Use Different billing address (optional)"
                          : "EINE ANDERE RECHNUNGSADRESSE VERWENDEN (OPTIONAL)"
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={"form-tab-container"} style={{ marginTop: 28 }}>
                  <div className={"alert-outline-primary alert-payment"}>
                    <SvgImage name={"warning"} className={"mr-15"} />
                    <div>
                      <T
                        _str={
                          "All good for now! You will receive an email about your payment details with your order confirmation."
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={"form-tab-container mt-20"}>
                  <div className={"personal-input-controller check-box-header"} style={{ width: "100%" }}>
                    <CheckBox
                      errorText={""}
                      value={isDifBillingAdr}
                      onChange={check => usePaymentModel.setState({ isDifBillingAdr: check })}
                      showError={false}
                      className={"text-upper"}
                      label={
                        locale === ENGLISH
                          ? "Use Different billing address (optional)"
                          : "EINE ANDERE RECHNUNGSADRESSE VERWENDEN (OPTIONAL)"
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isDifBillingAdr && (
        <div className={"form-container bg-white-color mt-40"}>
          <div className={"form-title"}>
            <div className={"title bg-white-color"}>
              <T _str="Billing Address" />
            </div>
            <div className={"line-container"}>
              <div className={"line"} />
            </div>
          </div>
          <div className={"form-content billing-address-form-content"} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div className={"personal-input-controller"}>
              <Box
                className={"personal-text-field billing-address-input"}
                sx={{
                  height: 64,
                  marginTop: deviceType.deskTop ? 0 : "32px",
                }}
              >
                <TextField
                  active={true}
                  defaultValue={billingFirstName.value}
                  showError={billingFirstName.error !== ""}
                  helpText={billingFirstName.error}
                  onChange={e => {
                    usePaymentModel.setState({
                      billingFirstName: { value: e.value, valid: e.valid, error: "" },
                    })
                  }}
                  label={"FIRST NAME"}
                />
              </Box>
              <Box
                className={"personal-text-field billing-address-input"}
                sx={{
                  height: 64,
                  marginTop: deviceType.deskTop ? 0 : "32px",
                }}
              >
                <TextField
                  active={true}
                  defaultValue={billingSurname.value}
                  showError={billingSurname.error !== ""}
                  helpText={billingSurname.error}
                  onChange={e => {
                    usePaymentModel.setState({
                      billingSurname: { value: e.value, valid: e.valid, error: "" },
                    })
                  }}
                  label={"SURNAME"}
                />
              </Box>
            </div>
            <div
              className={"personal-input-controller personal-input-controller-mobile"}
              style={{ marginTop: 28, height: 64 }}
            >
              <div
                className={"personal-text-field billing-address-input"}
                style={{ flex: deviceType?.deskTop ? 0.2 : 0.3 }}
              >
                <TextField
                  active={true}
                  defaultValue={billingZip.value}
                  showError={billingZip.error !== ""}
                  helpText={billingZip.error}
                  onChange={e => {
                    handlePostalCode(e)
                  }}
                  label={locale === ENGLISH ? "POSTCODE" : "PLZ"}
                  maxLength={5}
                  className={"mt-0"}
                />
              </div>
              <div
                className={"personal-text-field select-trailing-icon billing-address-input"}
                style={{ flex: deviceType?.deskTop ? 0.8 : 0.7 }}
              >
                <AutoComplete
                  active={true}
                  defaultValue={billingCity.value}
                  useDefaultValue={false}
                  showError={billingCity.error !== ""}
                  helpText={billingCity?.error}
                  label={"CITY"}
                  className={"mt-0"}
                  labelKey={"name"}
                  indexKey={"id"}
                  disabled={!cities.length}
                  onChange={e => {
                    usePaymentModel.setState({
                      // @ts-ignore
                      billingCity: { ...billingCity, value: e.cityName, valid: e.valid, code: e.value },
                      ...(e.value !== billingCity.value
                        ? { billingStreet: { value: "", valid: false, error: "" } }
                        : {}),
                    })
                  }}
                  placeholder={t("Select")}
                  list={cities}
                  trailingIcon={"arrow-down"}
                  id={"search-billing-city"}
                />
              </div>
            </div>
            <div
              className={"personal-input-controller personal-input-controller-mobile"}
              style={{ height: 64, marginBottom: 32, marginTop: 32 }}
            >
              <div
                className={"personal-text-field billing-address-input select-trailing-icon"}
                style={{ flex: deviceType?.deskTop ? 0.8 : 0.7 }}
              >
                <AutoComplete
                  active={true}
                  defaultValue={billingStreet.value}
                  showError={billingStreet.error !== ""}
                  helpText={billingStreet.error}
                  label={"STREET NAME"}
                  className={"mt-0"}
                  labelKey={"name"}
                  indexKey={"name"}
                  disabled={!cityStreets.length}
                  onChange={e => {
                    usePaymentModel.setState({
                      billingStreet: { ...billingStreet, value: e.value, valid: e.valid, error: "" },
                    })
                  }}
                  placeholder={t("Select")}
                  list={cityStreets}
                  trailingIcon={"arrow-down"}
                  id={"search-billing-street"}
                />
              </div>

              <div
                className={"personal-text-field billing-address-input personal-billing-number"}
                style={{ flex: deviceType?.deskTop ? 0.2 : 0.3 }}
              >
                <TextField
                  active={true}
                  defaultValue={billingHouseNumber.value}
                  showError={billingHouseNumber.error !== ""}
                  helpText={billingHouseNumber.error}
                  disabled={!billingStreet?.value}
                  onChange={e => {
                    usePaymentModel.setState({
                      billingHouseNumber: { value: e.value, valid: e.valid, error: "" },
                    })
                  }}
                  label={"NUMBER"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
