import { useCallback, useMemo } from "react"
import { PRODUCT_BASIS_DYNAMIC } from "../constants/common"
import { PartnerParams, ProductCode } from "../types/generic"
import { useGeneralModel } from "../models/useGeneralModel"

export const useFromPartner = (): { isFromPartner: boolean; partnerParams: Partial<PartnerParams> } => {
  const getPartnerParams = useCallback(() => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    const product = params.get("product")
    const clientApplicationId = params.get("clientApplicationId")
    const externalUserId = params.get("externalUserId")
    const externalTransactionId = params.get("externalTransactionId")

    // create an object with all values defined with productParams, clientApplicationIdParams, externalUserIdParams and externalTransactionIdParams
    let data = {
      product,
      clientApplicationId,
      externalUserId,
      externalTransactionId,
    } as Partial<PartnerParams>
    // remove values that are not defined
    data = Object.entries(data).reduce((prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev), {})

    if (product === PRODUCT_BASIS_DYNAMIC) {
      useGeneralModel.setState({ productCode: ProductCode.basisDynamic })
    }

    // check if clientApplicationId is defined
    if (clientApplicationId) {
      return { isFromPartner: true, partnerParams: data }
    } else {
      return { isFromPartner: false, partnerParams: {} }
    }
  }, [])

  const data = useMemo(() => {
    return getPartnerParams()
  }, [])

  return data
}
