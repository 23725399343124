import React from "react"
import { T } from "@transifex/react"

function SmartMeterTooltip() {
  return (
    <div style={{ maxWidth: 300, position: "relative", marginLeft: 18 }}>
      <div style={{ position: "absolute", right: -12, top: 15, transform: "rotate(180deg)" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none">
          <path d="M0.097168 11L12.1348 0.5V21.5L0.097168 11Z" fill="#16295A" />
        </svg>
      </div>
      <div className="dynamic" style={{ paddingTop: 20 }}>
        <div>
          <div className={"dynamic-item-container"} style={{ border: "none", paddingBottom: 0 }}>
            <div className="dynamic-item-content">
              <p>
                <strong>
                  <T _str={"Do you have a smart meter installed?"} />
                </strong>
              </p>

              <p style={{ paddingTop: 5 }}>
                <T _str={"You could save money with our SimplyDynamic tariff."} />
              </p>

              <p style={{ marginTop: 16 }}>
                <strong>
                  <T _str={"Interested in purchasing a smart meter?"} />
                </strong>
              </p>

              <p>
                <T _str={"We also sell them! Order yours on our website."} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartMeterTooltip
