import { ENGLISH } from "../constants/common"
import { T, useLocale } from "@transifex/react"
import React from "react"

export const PendingApplicationDescription = () => {
  const langValue = useLocale()
  return (
    <>
      {langValue === ENGLISH ? (
        <div className={"description-no-meter-id"}>
          <T _str={"You can send either one via the Ostrom app, download it here for "} />

          <span className={"mobile-app-link"}>
            <a href={"https://app.eu.adjust.com/t9ghjhe"} rel="noreferrer">
              <T _str={"iOS"} />
            </a>
          </span>

          <br />
          <span className={"mobile-app-link"}>
            <T _str={"or for "} />
            <a href={"https://app.eu.adjust.com/t9ghjhe"} target={"_blank"} rel="noreferrer">
              {" "}
              <T _str={"Android"} />
            </a>
          </span>
          <T _str={". Feel free to contact us via the live chat if you have any questions. "} />
        </div>
      ) : (
        <div className={"description-no-meter-id"}>
          Du kannst einfach eins von beiden via Ostrom-App übermitteln, die du hier für <br />
          <span className={"mobile-app-link"}>
            <a href={"https://app.eu.adjust.com/t9ghjhe"} rel="noreferrer">
              <T _str={"iOS"} />
            </a>
          </span>
          {" oder für "}
          <span className={"mobile-app-link"}>
            <a href={"https://app.eu.adjust.com/t9ghjhe"} target={"_blank"} rel="noreferrer">
              <T _str={"Android"} />
            </a>
          </span>
          {" herunterladen kannst."}
          <br />
          Melde dich bei Fragen gerne in unserem Live Chat.
        </div>
      )}
    </>
  )
}
