import React, { useMemo } from "react"
import { useGeneralModel } from "../models/useGeneralModel"
import { useShallow } from "zustand/react/shallow"
import useIsPriceCapAvailable from "./useIsPriceCapAvailable"
import {
  PAYMENT_WITH_BANNER_PATH,
  signupStepsWithPriceCap,
  signupStepsWithoutPriceCap,
  stepsLabelWithNoPriceCap,
  stepsLabelWithPriceCap,
} from "../constants/common"
import { useLocation } from "react-router-dom"

export default function useCurrentStep() {
  const { isDynamic, smartMeterOfferAvailable } = useGeneralModel(useShallow(state => state))

  const isPriceCapAvailable = useIsPriceCapAvailable()

  const location = useLocation()

  const showPriceCap = useMemo(() => !isDynamic && isPriceCapAvailable, [isDynamic, isPriceCapAvailable])

  let pathName = location.pathname.split("/").pop() as string
  // for AB test we have two payment pages, so we need to show the same step for both
  if (PAYMENT_WITH_BANNER_PATH.includes(pathName)) {
    const PAYMENT = "payment"
    pathName = PAYMENT
  }
  const stepLabels = showPriceCap ? stepsLabelWithPriceCap : stepsLabelWithNoPriceCap
  const steps = showPriceCap || smartMeterOfferAvailable ? signupStepsWithPriceCap : signupStepsWithoutPriceCap
  const currentStep = showPriceCap ? steps[pathName] : steps[pathName]

  return { steps, stepLabels, currentStep }
}
