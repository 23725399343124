import React from "react"

interface SvgImageProps {
  name: string
  className?: string
  onClick?: () => void
  styles?: object
  dataLabel?: string
  dataTestId?: string
}

export const SvgImage = ({ name, className, onClick, styles = {}, dataLabel = "", dataTestId = "" }: SvgImageProps) => {
  return (
    <img
      alt={name}
      onClick={onClick}
      className={className}
      src={require(`./${name}.svg`)}
      style={styles}
      data-label={dataLabel}
      data-test-id={dataTestId}
    />
  )
}
