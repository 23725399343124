import { useState } from "react"
import { T } from "@transifex/react"
import "./ConsumptionInput.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { t } from "@transifex/native"
import {
  DEFAULT_ONE_PERSON_VALUE,
  DEFAULT_MORE_PERSONS_VALUE,
  DEFAULT_KWH_CONSUMPTION,
  MAX_USAGE,
  MIN_USAGE,
} from "../../constants/common"

const ConsumptionInput = ({ usageError, usageValue }) => {
  const [personCount, setPersonCount] = useState(1)
  const [usage, setUsage] = useState(DEFAULT_KWH_CONSUMPTION)
  const [showCounter, setShowCounter] = useState(true)

  const onSubtract = () => {
    setShowCounter(true)
    if (personCount > 1) {
      usageError("")
      setPersonCount(prevState => {
        const counterValue = prevState - 1
        if (counterValue === 2) {
          const newUsageValue = DEFAULT_KWH_CONSUMPTION + DEFAULT_ONE_PERSON_VALUE
          setUsage(newUsageValue)
          usageValue(newUsageValue)
        } else if (counterValue === 1) {
          setUsage(DEFAULT_KWH_CONSUMPTION)
          usageValue(DEFAULT_KWH_CONSUMPTION)
        } else {
          const value =
            (counterValue - 2) * DEFAULT_MORE_PERSONS_VALUE + DEFAULT_ONE_PERSON_VALUE + DEFAULT_KWH_CONSUMPTION
          setUsage(value)
          usageValue(value)
        }
        return counterValue
      })
    }
  }

  const onAdd = () => {
    setShowCounter(true)
    usageError("")

    setPersonCount(prevState => {
      const counterValue = prevState + 1
      if (counterValue === 2) {
        const value = DEFAULT_KWH_CONSUMPTION + DEFAULT_ONE_PERSON_VALUE
        setUsage(value)
        usageValue(value)
      } else {
        const newCounterValue = counterValue - 2
        const value = newCounterValue * DEFAULT_MORE_PERSONS_VALUE + DEFAULT_ONE_PERSON_VALUE + DEFAULT_KWH_CONSUMPTION
        setUsage(value)
        usageValue(value)
      }
      return counterValue
    })
  }

  const onChangeUsage = e => {
    setShowCounter(false)
    const usage = e.target.value
    if (usage > MAX_USAGE || usage < MIN_USAGE) {
      usageError(t("Must be between 600 and 15000 kWh"))
    } else {
      usageError("")
    }
    setUsage(e.target.value)
    usageValue(e.target.value)
  }

  return (
    <div className={"consumption-input"}>
      <div className={"person-calculator"}>
        <div className={"consumption-icon"}>
          <button onClick={onSubtract}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23ZM5 11C5 11.8284 5.67157 12.5 6.5 12.5H16.5C17.3284 12.5 18 11.8284 18 11C18 10.1716 17.3284 9.5 16.5 9.5H6.5C5.67157 9.5 5 10.1716 5 11Z"
                fill="#E0E0E0"
              />
            </svg>
          </button>
        </div>
        <div className={"person-container"}>
          <SvgImage name={"person"} />
          {showCounter && (
            <p className={"person-text"} style={{ visibility: `${!showCounter ? "hidden" : "visible"}` }}>
              {personCount} <T _str={"Person"} />
            </p>
          )}
        </div>
        <div className={"consumption-icon"}>
          <button onClick={onAdd}>
            <SvgImage name={"plus"} className={"consumption-icon"} />
          </button>
        </div>
      </div>
      <div className={"ref-divider"}>
        <SvgImage name={"ref-input-divider"} />
      </div>
      <div className={"consumption"}>
        <SvgImage name={"ref-electricity"} />
        <input
          autoComplete={"new-password"}
          id={"search-postcode"}
          type="number"
          value={usage}
          onChange={onChangeUsage}
        />
        <p>kWh</p>
      </div>
    </div>
  )
}

export default ConsumptionInput
