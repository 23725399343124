import React from "react"
import { T } from "@transifex/react"

import { Container } from "@mui/material"
import { Box } from "@mui/system"
import "./style.scss"
import InfoCard from "./component/InfoCard"
import PartnerContainer from "./component/PartnerContainer"
import HeaderTitle from "./component/HeaderTitle"
import { usePartnerModel } from "../../models/usePartnerModel"
import { Navigate } from "react-router-dom"

const ConnectionSuccessPage = () => {
  const linkData = usePartnerModel(state => state?.linkData)

  if (!linkData) {
    return <Navigate to="/link/connection-error" />
  }

  return (
    <PartnerContainer>
      <Box data-test-id="loginForm" className={"column-centered"}>
        <Container maxWidth="sm">
          <HeaderTitle title={<T _str="Connect To Ostrom" />} />
        </Container>
        <Container maxWidth="sm" className={"card shadowProp"}>
          <InfoCard
            renderDescription={
              <T
                _str={"All set! Your contract has been connected to {partnerName}."}
                partnerName={linkData?.partnerName}
              />
            }
            title={<T _str="Connection successful!" />}
            renderImg={<img alt="" src={require("../../assets/images/Plugs.png")} height={60} />}
            link={{
              url: linkData.redirectUrl,
              text: <T _str={"Back to {partnerName}"} partnerName={linkData.partnerName} />,
            }}
          />
        </Container>
      </Box>
    </PartnerContainer>
  )
}

export default ConnectionSuccessPage
