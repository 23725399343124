import React, { useCallback, useEffect, useState } from "react"
import "./PlanForm.scss"
import { T } from "@transifex/react"
import TextField from "../TextField/TextField"
import {
  Alert,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material"
import { SvgImage } from "../../assets/svgs/SvgImage"

import { debounce } from "lodash"
import { useResponsive } from "../../hooks/useResponsive"
import { t } from "@transifex/native"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useShallow } from "zustand/react/shallow"
import Transition from "../Transition/Transition"
import { REFERRAL_BONUS } from "../../constants/common"

const getTooltipcomponentProps = theme => ({
  tooltip: {
    sx: {
      background: "#16295A",
      minWidth: "480px !important",
      borderRadius: "6px",
      [theme.breakpoints.down("md")]: {
        minWidth: "600px !important",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px !important",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "300px !important",
      },
    },
  },
})

interface ReferralCodeSectionProps {
  isTariffPage?: boolean
  locale?: string
  errorRefCode?: string
  referralAmount?: number
  setErrorRefCode?: (x: string) => void
  styled?: React.CSSProperties
}

const ReferralCodeTxt = () => {
  return (
    <p style={{ fontSize: "14px", lineHeight: "21px", padding: "3px" }}>
      <T
        _str={`Join Ostrom with a referral code for a bonus! Deduct 35€ from your invoice or shop with ${REFERRAL_BONUS} € at the Ostrom Store.`}
      />
    </p>
  )
}

const ReferralCodeSection = (props: ReferralCodeSectionProps) => {
  const [referralCodeValidate, setReferralCodeValidate] = useState(null)
  const [openReferralDlg, setOpenReferralDlg] = useState(false)
  const [referralCodeLocal, setReferralCodeLocal] = useState("")
  const [openReferralTooltip, setOpenReferralTooltip] = useState(false)

  const { referralCode } = usePlanFormModel(
    useShallow(state => ({
      referralCode: state.referralCode,
    }))
  )

  const debounceReferralChange = useCallback(
    debounce(({ value }) => {
      handleValidateReferralCode(value)
    }, 500),
    []
  )

  const { deviceType } = useResponsive()

  const handleValidateReferralCode = useCallback(async (refCode = "") => {
    usePlanFormModel.setState({ referralCode: refCode })
    if (!refCode) {
      props.setErrorRefCode("")
      return
    }
  }, [])

  useEffect(() => {
    if (referralCode && props.referralAmount) {
      setReferralCodeValidate(true)
      setReferralCodeLocal(referralCode)
    } else {
      setReferralCodeValidate(false)
    }
  }, [referralCode, props.referralAmount])

  const theme = useTheme()

  const componentTooltipProps = getTooltipcomponentProps(theme)

  return (
    <>
      <div className="input-controller" style={props.styled}>
        <div className="header-card-footer">
          {referralCodeValidate}

          <span className="label ref-code-icon">
            <div>{deviceType.mobile && props.locale === "de_DE" ? "CODE" : <T _str="REFERRAL CODE" />}</div>
            {!deviceType.mobile ? (
              <Tooltip
                disableInteractive={false}
                placement={window.innerWidth > 1200 ? "top" : "top-end"}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                title={<ReferralCodeTxt />}
                componentsProps={componentTooltipProps}
              >
                <IconButton>
                  <SvgImage name={"info"} className={"info-icon"} />
                </IconButton>
              </Tooltip>
            ) : (
              <ClickAwayListener onClickAway={() => setOpenReferralTooltip(false)}>
                <Tooltip
                  disableInteractive={false}
                  placement="top-end"
                  open={openReferralTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<ReferralCodeTxt />}
                  componentsProps={componentTooltipProps}
                >
                  <IconButton
                    onClick={() => {
                      setOpenReferralTooltip(true)
                    }}
                  >
                    <SvgImage name={"info"} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            )}
          </span>

          <div className="referral-code" style={{ marginBottom: 0 }}>
            <TextField
              defaultValue={referralCodeLocal}
              onChange={debounceReferralChange}
              maxLength={10}
              minLength={10}
              className="refer-input"
              data-test-id={deviceType.mobile ? "referral-input-mobile" : "referral-input"}
            />
            {referralCodeValidate && (
              <div className="ref-status">
                <SvgImage name="white-circle-check" />
              </div>
            )}

            {!deviceType.mobile && (
              <>
                <div>
                  <span className={"error-description"} data-test-id={"referral-error-message"}>
                    <T _str={props.errorRefCode} />
                  </span>
                </div>

                {props.referralAmount > 0 && (
                  <div className="cost-description discount-text web">
                    <T _str={`-up to ${REFERRAL_BONUS} € one-time bonus`} />
                  </div>
                )}
              </>
            )}
          </div>
          {deviceType.mobile && (
            <>
              <div>
                <span
                  className={"error-description"}
                  style={{ textAlign: "right" }}
                  data-test-id={"referral-error-message-mobile"}
                >
                  <T _str={props.errorRefCode} />
                </span>
              </div>

              {props.isTariffPage && props.referralAmount > 0 && (
                <>
                  <div className="cost-description discount-text mobile" style={{ position: "absolute" }}>
                    <T _str={`-up to ${REFERRAL_BONUS} € one-time bonus`} />
                  </div>
                  <div style={{ height: 20 }}></div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        open={openReferralDlg}
        onClose={() => {
          setOpenReferralDlg(false)
          setReferralCodeLocal(referralCode)
        }}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="referral-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            className="dialog-close-icon"
            size="small"
            onClick={() => {
              setOpenReferralDlg(false)
              setReferralCodeLocal(referralCode)
            }}
          >
            <SvgImage name="clarity_window-close-line" />
          </IconButton>
          <div className="dialog-icon-container">
            <SvgImage name="friend-referral" className="dialog-icon" />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={"dialog-content"}>
            <DialogContentText id="alert-dialog-description">
              {t(
                "Know an existing Ostrom client? Enter a referral code and you both get a 35€ bonus for switching to green energy!"
              )}
            </DialogContentText>
            <TextField
              placeholder={t("Referral Code")}
              defaultValue={referralCodeLocal}
              onChange={e => {
                setReferralCodeLocal(e.value)
              }}
              helpText={t("Mini of 10 characters")}
              maxLength={10}
              minLength={10}
            />
            {props.errorRefCode !== "" && (
              <Alert className={"mt-20"} severity="warning">
                <T _str={props.errorRefCode} />
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions className={"dialog-action"}>
          <Button
            onClick={() => {
              setOpenReferralDlg(false)
              setReferralCodeLocal(referralCode)
            }}
            className={"btn-primary round-50"}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleValidateReferralCode()
            }}
            className={"btn-primary secondary-color round-50"}
            autoFocus
          >
            {t("Apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(ReferralCodeSection)
