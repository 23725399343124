import * as Sentry from "@sentry/react"
import isEmail from "validator/es/lib/isEmail"

export const sendErrorToSentry = (e, data) => {
  Sentry.captureException(e, {
    extra: data,
  })
}

export function ValidateEmail(email) {
  return isEmail(email)
}

export function ValidatePassword(password) {
  return password.length >= 8
}
export function ValidateMeterNumber(value) {
  let chars = ["!", ",", "|", "%", "@"]
  for (let i = 0; i < chars.length; i++) {
    const char = chars[i]
    if (value.includes(char)) return false
  }
  return true
}
export function getCommaSeparatedTwoDecimalsNumber(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2)
  let temp = String(fixedNumber).replace(".", ",")
  return temp
}

/* disable errors originating from injected scripts such as Google Tag Manager */
export function isInjectedCode(event) {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames

  if (!frames || frames.length === 0) return false

  const firstFrame = frames[0]
  if (firstFrame.filename === "<anonymous>") {
    return true
  }

  const lastFrame = frames[frames.length - 1]
  if (
    typeof lastFrame.filename === "string" &&
    (lastFrame.filename === window.location.pathname ||
      (lastFrame.filename.startsWith(window.location.origin) &&
        // static file should not be considered as injected code. We use react-script currently, and all js-generated files are in this "static" directory.
        !lastFrame.filename.includes("/static/")))
  ) {
    return true
  }

  return !!frames.some(
    frame =>
      typeof frame.filename === "string" &&
      (frame.filename.startsWith("https://metrics.ostrom.de/gtag/js") ||
        frame.filename.startsWith("https://www.googletagmanager.com") ||
        frame.filename.startsWith("https://googleads."))
  )
}
