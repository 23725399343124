import React, { useCallback, useEffect, useState } from "react"
import "./PlanForm.scss"
import TextField from "../TextField/TextField"
import { t } from "@transifex/native"

import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useShallow } from "zustand/react/shallow"
import { debounce } from "lodash"
import { MAX_USAGE, MIN_DYNAMIC_USAGE, MIN_USAGE, PRODUCT_BASIS_DYNAMIC } from "../../constants/common"
import { useFromPartner } from "../../hooks/useFromPartner"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useResponsive } from "../../hooks/useResponsive"

interface ConsumptionSectionProps {
  style?: React.CSSProperties
}

const ConsumptionSection = (props: ConsumptionSectionProps) => {
  const [usageError, setUsageError] = useState("")

  const usage = usePlanFormModel(useShallow(state => state.usage))

  const { partnerParams } = useFromPartner()

  const { deviceType } = useResponsive()

  const debouncePlanFormChanges = useCallback(
    debounce(g => {
      usePlanFormModel.setState({ usage: g })
    }, 500),
    []
  )

  const debounceUsageChange = useCallback(
    debounce(g => checkValidUsage(g), 500),
    []
  )

  const checkValidUsage = useCallback(
    (val: string) => {
      const rate = useGeneralModel.getState().rate
      // @ts-ignore
      const newRate = { ...rate, ostrom: { ...rate?.ostrom, monthly: { ...rate?.ostrom?.monthly, tariff: 0 } } }
      if (partnerParams?.product === PRODUCT_BASIS_DYNAMIC && +val < MIN_DYNAMIC_USAGE) {
        setUsageError(t("Must be between 6000 and 15000 kWh"))
        if (+val < MIN_USAGE) {
          useGeneralModel.setState({ rate: newRate })
        }
      } else if (+val > MAX_USAGE || +val < MIN_USAGE) {
        setUsageError(t("Must be between 600 and 15000 kWh"))
        useGeneralModel.setState({ rate: newRate })
      } else {
        setUsageError("")
        usePlanFormModel.setState({ usage: val })
      }
      debouncePlanFormChanges(val)
    },
    [partnerParams?.product]
  )

  useEffect(() => {
    if (partnerParams?.product === PRODUCT_BASIS_DYNAMIC && +usage < MIN_DYNAMIC_USAGE) {
      setUsageError(t("Must be between 6000 and 15000 kWh"))
    } else if (+usage > MAX_USAGE || +usage < MIN_USAGE) {
      setUsageError(t("Must be between 600 and 15000 kWh"))
    } else {
      setUsageError("")
    }
  }, [usage])

  return (
    <div className="input-controller" style={props.style ? props.style : {}}>
      <div className="input-text-box prepend" data-test-id={"consumption-content"}>
        <TextField
          onChange={e => debounceUsageChange(e.value)}
          defaultValue={`${usage}`}
          label={t("YEARLY CONSUMPTION")}
          type={"tel"} // type tel to show numeric keyboard on mobile
          showError={usageError !== ""}
          helpText={usageError}
          leadIcon={"Vector"}
          trailingText={"kWh"}
          maxLength={5}
          data-test-id={deviceType.mobile ? "consumption-input-mobile" : "consumption-input"}
        />
      </div>
    </div>
  )
}

export default React.memo(ConsumptionSection)
