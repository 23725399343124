import { Button, Dialog, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import { T, useLocale } from "@transifex/react"
import React, { useEffect, useMemo, useState } from "react"
import "./MeterNumber.scss"
import TextField from "../../TextField/TextField"
import { t } from "@transifex/native"
import { validateMeterNumberMininumRequirements } from "../../../utils"
import { METER_NUMBER_TYPE } from "../../../constants/common"
import Transition from "../../Transition/Transition"

const meterImg1 = require("../../../assets/images/meterImage-3.png")
const meterImg2 = require("../../../assets/images/meterImage-5.png")
const meterImg3 = require("../../../assets/images/meterImage-2.png")
const meterImg4 = require("../../../assets/images/meterImage-1.png")

const meterImgs = [meterImg1, meterImg2, meterImg3, meterImg4]

interface MeterNumberErrorProps {
  openStatus: boolean
  onClose: () => void
  meterValue: string
  onContinue: (meterNo: string) => void
}

export function MeterNumberError({ openStatus, onClose, meterValue, onContinue }: MeterNumberErrorProps) {
  const [open, setOpen] = useState(false)
  const [currentMeterExample, setCurrentMeterExample] = useState(0)
  const [meterNo, setMeterNo] = useState(meterValue)
  const [meterNoHasError, setMeterNoHasError] = useState(true)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const lang = useLocale()

  useEffect(() => {
    if (meterValue) {
      setMeterNo(meterValue)
    }
    if (openStatus) {
      setMeterNoHasError(true)
    }
  }, [meterValue, openStatus])

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const MIN_SWIPE_DISTANCE = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e?.targetTouches?.[0]?.clientX)
  }

  const onTouchMove = e => {
    setTouchEnd(e.targetTouches[0]?.clientX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const leftSwipe = distance > MIN_SWIPE_DISTANCE
    const rightSwipe = distance < -MIN_SWIPE_DISTANCE
    if (leftSwipe || rightSwipe) {
      rightSwipe ? handleBack() : handleNext()
    }
  }

  useEffect(() => {
    setOpen(openStatus)
  }, [openStatus])
  useEffect(() => {
    if (open === false) {
      onClose()
    }
    // eslint-disable-next-line
  }, [open])

  const handleNext = () => {
    if (currentMeterExample === 3) {
      setCurrentMeterExample(0)
      return
    }
    setCurrentMeterExample(prev => prev + 1)
  }

  const handleBack = () => {
    if (currentMeterExample < 1) {
      setCurrentMeterExample(3)
      return
    }
    setCurrentMeterExample(prev => prev - 1)
  }

  const minumumRequirements = useMemo(() => {
    return validateMeterNumberMininumRequirements(meterNo)
  }, [meterNo])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="meter-number-dialog"
      maxWidth={"md"}
      data-test-id="meter-number-dialog-error"
    >
      <DialogTitle id="alert-dialog-title">
        <div
          className={"dialog-close-icon"}
          style={{ width: 20, height: 40 }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <IconButton size="small" data-test-id="dialog-close-button-error">
            <SvgImage name="dialog-close" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={"meter-number-dialog-content"}>
          <div className={"dialog-content-title"}>
            <T _str={"Invalid"} />
            <span style={{ fontStyle: "italic" }}> Zählernummer </span>
            <T _str={"(Meter ID) "} />
          </div>
          <div className={"dialog-content-title-info"}>
            {lang === "en" ? (
              <strong>
                <T _str={"The"} />
                <span style={{ fontStyle: "italic" }}> Zählernummer </span>
                <T _str={"you entered is not the right format."} />
              </strong>
            ) : (
              <strong>
                <T _str={"Die von dir eingegebene Zählernummer hat nicht das richtige Format."} />
              </strong>
            )}
            <T _str={" "} />
            <T _str={"Please check the images, adjust your"} />
            <span style={{ fontStyle: "italic" }}> Zählernummer </span>
            <T _str={"in the input field below, then click continue."} />
          </div>
          <div
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            className={"dialog-content-bg meterno-error-bg"}
          >
            <img src={meterImgs[currentMeterExample]} alt="meter-number" />
            <div className={"dialog-carousel-button meterno-error"}>
              <IconButton onClick={handleBack} data-test-id="dialog-back-button-error">
                <SvgImage name={"dialog-back-btn"} />
              </IconButton>
              <p className={"desktop-mobile-text"}>
                <T _str={"Click to see other meter examples "} /> <span>{`(${currentMeterExample + 1}/4)`}</span>
              </p>
              <p className={"mobile-text"}>
                <T _str={"Swipe to see other meter examples "} /> <span>{`(${currentMeterExample + 1}/4)`}</span>
              </p>
              <IconButton onClick={handleNext} data-test-id="dialog-next-button-error">
                <SvgImage name={"dialog-forward-btn"} />
              </IconButton>
            </div>
          </div>
          <div className={"meterno-info-input-card"}>
            <p className={"title"}>
              <T _str={"Check your Zählernummer"} />
            </p>
            <TextField
              active={true}
              defaultValue={meterNo}
              type={METER_NUMBER_TYPE}
              onChange={e => {
                setMeterNo(e?.value)
                setMeterNoHasError(!validateMeterNumberMininumRequirements(e?.value))
              }}
              showError={meterNoHasError}
              helpText={
                !minumumRequirements ? t("Doesn't meet minimum requirements") : t("Doesn't meet format requirements")
              }
              className={"mt-0"}
              placeholder={t("Up to 20 characters found on your meter")}
              maxLength={40}
              data-test-id={"meter-number-input"}
            />
            <p className={"information"}>
              <T _str={"If you checked the images and are sure you have the correct"} />
              {lang === "en" ? <em> Zählernummer, </em> : <span> Zählernummer hast, </span>}
              <T
                _str={
                  "click “Continue” and we'll be in touch if there are any issues. Alternately, close this window and add your"
                }
              />
              <em> Marktlokations-ID </em>
              <T _str={"(Market Location ID) instead."} />
            </p>

            <Button
              onClick={() => {
                onContinue(meterNo)
              }}
              disabled={!minumumRequirements}
              fullWidth
              variant="contained"
              color="primary"
              className={"btn-primary round-50"}
              autoFocus
              data-test-id={"meter-number-submit-error"}
              style={{
                fontWeight: 700,
                fontSize: 18,
                backgroundColor: !minumumRequirements ? "#e0e0e0" : "#16295a",
              }}
            >
              <T _str={"Continue"} />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
