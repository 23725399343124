import { InitFormValueTypeBoolean } from "../types/form"
import { City, ProductCode, Rate, Street } from "../types/generic"
import { initFormValue2 } from "../utils"
import {
  CHANGE_OF_SUPPLIER,
  DEFAULT_VALUE_0,
  DEFAULT_VALUE_1,
  ENGLISH,
  LETTER_M,
  MARKET_LOCATION_ID,
} from "../constants/common"
import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

interface GeneralState {
  personalTab: "home" | "business"
  electricityIdentifierType: "MARKET_LOCATION_ID" | "METER_NUMBER"
  electricityContractType: string
  isPendingApplicationAllowed?: boolean
  oldSupplier: any[]
  electricityContract: string
  deliveryTab1: number
  deliveryTab2: number
  previousContractTerminated: boolean
  hasSmartMeter: boolean
  hasElectricVehicle: boolean
  hasHeatPump: boolean
  interestedInThgQuota: boolean
  priceCap?: number
  acceptPartnership?: InitFormValueTypeBoolean
  rate?: Rate
  productCode?: ProductCode
  isDynamic?: boolean
  currentPlan?: string
  cities?: City[]
  localRate?: number
  isEmailInUse?: boolean
  priceCap35?: number
  termsAndConditions?: InitFormValueTypeBoolean
  termsAndConditionsPriceCap?: InitFormValueTypeBoolean
  cityStreets?: Street[]
  language: string
  locale: string
  smartMeterOfferAvailable: boolean
  smartMeterOrder: boolean
  smartMeterOfferSeen: boolean
  smartMeterPostalCodeId: number
  smartMeterPrice: number
}

const initialState = {
  personalTab: "home",
  electricityIdentifierType: MARKET_LOCATION_ID,
  electricityContractType: CHANGE_OF_SUPPLIER,
  isPendingApplicationAllowed: null,
  oldSupplier: [],
  electricityContract: "no",
  deliveryTab1: DEFAULT_VALUE_0,
  deliveryTab2: DEFAULT_VALUE_1,
  previousContractTerminated: true,
  hasSmartMeter: false,
  hasElectricVehicle: false,
  hasHeatPump: false,
  interestedInThgQuota: false,
  priceCap: null,
  acceptPartnership: initFormValue2,
  rate: null,
  productCode: ProductCode.basisProdukt,
  isDynamic: false,
  currentPlan: LETTER_M,
  cities: [],
  localRate: 0,
  isEmailInUse: false,
  priceCap35: 0,
  termsAndConditions: initFormValue2,
  termsAndConditionsPriceCap: initFormValue2,
  cityStreets: [],
  locale: ENGLISH,
  language: "ENGLISH",
  smartMeterOfferAvailable: false,
  smartMeterOrder: null,
  smartMeterOfferSeen: false,
  smartMeterPostalCodeId: null,
  smartMeterPrice: null,
} as GeneralState

type Actions = {
  reset: () => void
}

export const useGeneralModel = create(
  persist<GeneralState & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: "general-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
