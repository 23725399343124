import React from "react"
import propTypes from "prop-types"
import { T } from "@transifex/react"

import { SvgImage } from "../../assets/svgs/SvgImage"

import "./style.scss"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import ReviewComponent from "../../components/Reviews"

const HighDemandPage = () => {
  return (
    <NavigationWrapper>
      <div className="maintenance-container">
        <div className="wrapper">
          <div className="text-error">
            <SvgImage name="time" className="footer-logo w-inline-block" />
          </div>
          <br />
          <br />
          <div className="title-text">
            <T _str={`Sorry,   signup  temporarily paused`} />
          </div>
          <br />
          <br />
          <div className={"main-text-container"}>
            <div className="maintenance-text">
              <T
                _str={
                  "Due to high demand, signups are temporarily paused so that each customer can continue to receive the same outstanding service"
                }
              />
            </div>
            <br />
            <br />
            <div className="maintenance-text">
              <T
                _str={
                  "If you’d like to be notified once we’re live again, leave your email address in the chat or send a short email to"
                }
              />{" "}
              <a className="mail-link" href={"mailto:hallo@ostrom.de"}>
                <span>
                  <T _str={"hallo@ostrom.de"} />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ReviewComponent />
    </NavigationWrapper>
  )
}

export default HighDemandPage

HighDemandPage.propTypes = {
  deviceType: propTypes.object,
}
