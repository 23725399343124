import React from "react"
import NewDynamicTooltipBody from "../DynamicTooltipBody/NewDynamicTooltipBody"

const NewDynamicPricingTooltip = () => {
  return (
    <div style={{ maxWidth: 300, position: "relative", marginLeft: 18 }}>
      <div style={{ position: "absolute", left: -12, top: 85 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none">
          <path d="M0.097168 11L12.1348 0.5V21.5L0.097168 11Z" fill="#16295A" />
        </svg>
      </div>
      <div className="dynamic" style={{ paddingTop: 20 }}>
        <NewDynamicTooltipBody />
      </div>
    </div>
  )
}

export default NewDynamicPricingTooltip
