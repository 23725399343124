import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { T } from "@transifex/react"
import "./ReferralHeader.scss"
import { getReferralType } from "../../service/api"
import { REFERRAL_BONUS, REFERRAL_CODE_LENGTH, REFERRAL_CODE_TYPE } from "../../constants/common"
import { scrollToSection } from "../../utils"
import React from "react"

const ReferralHeader = ({ getRefCode, getRefData, refHeader }) => {
  const [refCode, setRefCode] = useState(null)
  const [refType, setRefType] = useState(null)

  const location = useLocation()
  const navigate = useNavigate()

  const getRefType = async code => {
    const { data } = await getReferralType(code)
    if (data?.status === REFERRAL_CODE_TYPE.UNKNOWN) {
      getRefCode("")
    }
    setRefType(data)
    getRefData(data)
    return data
  }

  useEffect(() => {
    const referral = new URLSearchParams(location.search).get("referralCode")
    if (referral) {
      setRefCode(referral)
    }
  }, [location.search])

  useEffect(() => {
    getRefCode(refCode)
    if (refCode) {
      getRefType(refCode)
      scrollToSection()
    }
  }, [refCode])

  useEffect(() => {
    if (refType !== null && refType?.status !== REFERRAL_CODE_TYPE.ACTIVE) {
      navigate(`/tariff-plan?referralCode=${refCode}`)
      return () => {}
    }
    getRefCode(refCode)
  }, [refType])

  useEffect(() => {
    const init = async () => {
      if (refHeader?.length === REFERRAL_CODE_LENGTH) {
        const { data } = await getReferralType(refHeader)
        if (data?.status === REFERRAL_CODE_TYPE.EXPIRED || data?.status === REFERRAL_CODE_TYPE.INACTIVE) {
          setRefCode("")
        } else {
          setRefCode(refHeader)
        }
      }
    }
    init()
  }, [refHeader])

  return (
    <div className={`referral-header ${refType?.type !== "USER" && "partner-header"}`}>
      {refType?.type === "PARTNER" && (
        <div className={"heading-text"}>
          <h3 className={"referral-header-type"}>Ostrom x {refType?.firstName}</h3>
          <h2 className={"sub-header"}>
            <T _str={`Sign up to Ostrom and get up to ${REFERRAL_BONUS} €`} />
          </h2>
        </div>
      )}
      {refType?.type === "USER" && (
        <div className={"heading-text"}>
          <h2 className={"sub-header"}>
            <T _str={"Sign up to Ostrom and "} />
            <span className={"sub-header"}>
              <T _str={"you and "} /> {refType?.firstName}
            </span>
            <T _str={` both get up to ${REFERRAL_BONUS} €`} />
          </h2>
        </div>
      )}
      {refType?.type !== "USER" && refType?.type !== "PARTNER" && (
        <div className={"heading-text"}>
          <span className={"sub-header-ostrom"}>
            <T _str={"Energy. Just Better."} />
          </span>
          <h2 className={"sub-header"}>
            <T _str={`Sign up to Ostrom and get up to ${REFERRAL_BONUS} €`} />
          </h2>
        </div>
      )}
      <div className={"list"}>
        <div>
          ✓ <T _str={"One transparent plan, cancel anytime"} />
        </div>
        <div>
          ✓ <T _str={"Everything in English and German"} />
        </div>
        <div>
          ✓ <T _str={"100% green energy"} />
        </div>
        <div>
          ✓ <T _str={"Available anywhere in Germany"} />
        </div>
        <div className={"list-bold"}>
          ✓ <T _str={`Get up to ${REFERRAL_BONUS} € for every friend who signs up!`} />
        </div>
      </div>
    </div>
  )
}

export default ReferralHeader
