import Axios from "axios"
import { setupCache, buildWebStorage } from "axios-cache-interceptor"

const instance = Axios.create({
  baseURL: process.env.REACT_APP_OSTROM_API_URL,
})
const axiosClient = setupCache(instance, {
  // As sessionStorage is a public storage, you can add a prefix
  // to all keys to avoid collisions with other code.
  storage: buildWebStorage(sessionStorage, "ostrom-axios-cache:"),
  cacheTakeover: false,
})

export default axiosClient
