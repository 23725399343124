import React, { useCallback } from "react"
import { checkIfEmailExists } from "../service/api"
import { useUserInfoModel } from "../models/userInfoModel"
import { useGeneralModel } from "../models/useGeneralModel"
import { t } from "@transifex/native"

export default function useCheckIfEmailExists() {
  const { email } = useUserInfoModel.getState()

  const checkEmail = useCallback(
    async (emailString: string) => {
      return checkIfEmailExists({ email: emailString }).then(res => {
        if (res.data === true) {
          useGeneralModel.setState({ isEmailInUse: true })
          useUserInfoModel.setState({
            email: {
              ...email,
              error: t(
                "This email is already in use. Create more contracts easily in the Ostrom app: “More” -> “Contract” -> “Create Another Contract”"
              ),
            },
          })
          return true
        }
        return false
      })
    },
    [email]
  )

  return { checkEmail }
}
