export default function LinkedIn({ width = 24, height = 25, color = "#16295A" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.82773 24.517H5.27934V7.62215H0.82773V24.517ZM3.05354 0.164062C1.56966 0.164062 0.382568 1.38171 0.382568 2.90377C0.382568 4.42583 1.56966 5.64347 3.05354 5.64347C4.53741 5.64347 5.7245 4.42583 5.7245 2.90377C5.7245 1.38171 4.53741 0.164062 3.05354 0.164062ZM12.8471 10.2097V7.62215H8.39547V24.517H12.8471V15.8413C12.8471 10.9707 18.931 10.6663 18.931 15.8413V24.517H23.3826V14.167C23.3826 5.94789 14.9245 6.2523 12.8471 10.2097Z"
        fill={color}
      />
    </svg>
  )
}
