import { useCallback, useState } from "react"
import { useDeliveryFormModel } from "../models/useDeliveryFormModel"
import { useGeneralModel } from "../models/useGeneralModel"
import { usePlanFormModel } from "../models/usePlanFormModel"
import { useUserInfoModel } from "../models/userInfoModel"
import {
  CAMPAIGN_STANDARD,
  CHANGE_OF_SUPPLIER,
  CREDIT_SCORE_ERROR_MESSAGE,
  PENDING_PAGE_PATH,
  THANK_YOU_PAGE_PATH,
  FAILED_CHECK_PAGE_PATH,
} from "../constants/common"
import { formatIban, formatPhoneNumber, getDateBackendFormat, scrollToSection } from "../utils"
import { useFromPartner } from "./useFromPartner"
import { usePaymentModel } from "../models/usePaymentFormModel"
import { signUp, validateMeterNumber } from "../service/api"
import { useNavigate } from "react-router-dom"
import { SignUpFormType } from "../types/signup"
import { ProductCode } from "../types/generic"
import useCheckIfEmailExists from "./useCheckIfEmailExists"
import { isArray, isObject } from "lodash"
import { t } from "@transifex/native"
import useValidatePlanForm from "./useValidatePlanForm"
import useUserInfoValidation from "./useUserInfoValidation"
import useDeliveryInfoValidation from "./useDeliveryInfoValidation"
import usePaymentInfoValidate from "./usePaymentInfoValidate"

export default function useSubmitForm() {
  const navigate = useNavigate()

  const { isFromPartner, partnerParams } = useFromPartner()

  const { checkEmail } = useCheckIfEmailExists()

  const checkMeterNumber = async () => {
    const { meterNumber, noMeterId } = useDeliveryFormModel.getState()
    const res = await validateMeterNumber(meterNumber.value)
    if (res.data === false && !noMeterId.value) {
      useDeliveryFormModel.setState({
        meterNumber: {
          ...meterNumber,
          valid: false,
          error: t("Does not meet format requirements"),
        },
      })
      return 0
    } else if (res.data === true && !noMeterId.value) {
      useDeliveryFormModel.setState({
        meterNumber: {
          ...meterNumber,
          error: "",
          valid: true,
        },
      })
      return 1
    }
  }

  const { validatePlanForm } = useValidatePlanForm()
  const { handleNext: validateUserInfo } = useUserInfoValidation()
  const { handleNext: validateDeliveryInfo } = useDeliveryInfoValidation({
    setOpenMeterIdErrorDlg: () => {},
    checkMeterNumber: checkMeterNumber,
  })
  const { handleNext: validatePaymentInfo } = usePaymentInfoValidate()

  const [submitting, setSubmitting] = useState(false)
  const [signupErrors, setSignupErrors] = useState<string[]>([])

  const prepareDataAndSubmit = async () => {
    const {
      previousSupplierId,
      terminatedFromDate,
      cancelAsSoonAsPossible,
      marketLocationId,
      noMarketLocationId,
      movingDate,
      meterNumber,
      noMeterId,
    } = useDeliveryFormModel.getState()

    const {
      personalTab,
      termsAndConditions,
      hasHeatPump,
      hasSmartMeter,
      hasElectricVehicle,
      interestedInThgQuota,
      acceptPartnership,
      electricityContractType,
      electricityIdentifierType,
      priceCap,
      productCode,
      previousContractTerminated,
      language,
      smartMeterOrder,
      smartMeterPostalCodeId,
      smartMeterPrice,
      smartMeterOfferSeen,
    } = useGeneralModel.getState()

    const planForm = usePlanFormModel.getState()

    const { firstName, surname, dob, phone, email, companyName, street, houseNumber } = useUserInfoModel.getState()

    const {
      billingCity,
      billingFirstName,
      billingHouseNumber,
      billingStreet,
      billingSurname,
      billingZip,
      iban,
      isDifBillingAdr,
      paymentMethod,
      accountHolderName,
    } = usePaymentModel.getState()

    const planFormIsValid = validatePlanForm()

    if (!planFormIsValid) {
      setSignupErrors([t("Please fill all required fields")])
      scrollToSection("signup-error-div")
      return
    }

    // check if the different forms are valid

    const userInfoHasErrors = await validateUserInfo(null, true)

    const deliveryInfoHasErrors = await validateDeliveryInfo(null, true)

    const paymentInfoHasErrors = await validatePaymentInfo(null, true)

    if (userInfoHasErrors === 0 || deliveryInfoHasErrors === 0 || paymentInfoHasErrors === 0) {
      setSignupErrors([t("Please fill all required fields")])
      scrollToSection("signup-error-div")
      return
    }

    const data = {
      customer: {
        referralCode: planForm?.referralCode || "",
        business: personalTab !== "home",
        companyName: companyName.value,
        firstName: firstName.value,
        lastName: surname.value,
        dateOfBirth: getDateBackendFormat(dob.value),
        // @ts-ignore
        phone: formatPhoneNumber(phone.value, phone?.phoneAreaCode),
        // @ts-ignore
        phoneAreaCode: `+${phone?.phoneAreaCode}`,
        email: email.value,
        language,
        address: {
          zip: planForm.postalCode,
          city: planForm.cityName,
          street: street.value,
          houseNumber: houseNumber.value,
        },
      },
      agreement: {
        termsAndConditions: termsAndConditions.value,
        newsletter: false,
        hasHeatPump: hasHeatPump,
        hasSmartMeter: hasSmartMeter,
        hasElectricVehicle: hasElectricVehicle,
        interestedInThgQuota: interestedInThgQuota,
        ...(isFromPartner
          ? {
              isPartnerAllowedToAccessUserData: acceptPartnership.value,
            }
          : {}),
      },
      campaign: CAMPAIGN_STANDARD,
      productCode: priceCap ? ProductCode.basisPriceBrake35 : productCode,
      ...(isFromPartner
        ? {
            ...partnerParams,
          }
        : {}),
      contract: {
        usage: +planForm.usage,
        electricityContractType: electricityContractType,
        electricityIdentifierType: electricityIdentifierType,
        movingDate: getDateBackendFormat(movingDate.value),
        // @ts-ignore
        previousSupplierId: previousSupplierId?.codeNumber,
        previousContractTerminated: electricityContractType === CHANGE_OF_SUPPLIER ? previousContractTerminated : "",
        terminatedFromDate: getDateBackendFormat(terminatedFromDate.value),
        cancelAsSoonAsPossible: cancelAsSoonAsPossible.value,
        pendingApplication: noMeterId.value || noMarketLocationId.value,
        marketLocationId: noMeterId.value || noMarketLocationId.value ? "" : marketLocationId.value,
        meterNumber: noMeterId.value || noMarketLocationId.value ? "" : meterNumber.value,
      },
      ...(priceCap ? { addOn: { priceCap35: priceCap } } : {}),
      payment: {
        paymentMethod: paymentMethod,
        iban: formatIban(iban.value).replace(/\s+/g, ""),
        accountHolderName: accountHolderName.value,
        sepaMandate: true,
        differentBillingAddress: isDifBillingAdr,
        billingAddress: {
          zip: billingZip.value,
          city: billingCity.value,
          street: billingStreet.value,
          houseNumber: billingHouseNumber.value,
          firstName: billingFirstName.value,
          lastName: billingSurname.value,
        },
      },
      addOn: smartMeterOfferSeen && {
        smartMeterOfferSeen,
        smartMeterOrder,
        smartMeterPostalCodeId,
        smartMeterPrice,
      },
    } as SignUpFormType

    await submitForm(data)
  }

  const submitForm = async (data: SignUpFormType) => {
    await signUp(data)
      .then(res => {
        scrollToSection()
        localStorage.setItem("customer-info", JSON.stringify(res))
        if (res?.data?.error === CREDIT_SCORE_ERROR_MESSAGE) {
          navigate(FAILED_CHECK_PAGE_PATH)
          return
        }
        if (res?.data?.pendingApplication) {
          navigate(PENDING_PAGE_PATH)
        } else {
          navigate(THANK_YOU_PAGE_PATH)
        }
      })
      .catch(e => {
        if (isArray(e.error)) {
          setSignupErrors(e?.error)
        } else if (isObject(e?.error)) {
          setSignupErrors(Object.values(e.error))
        } else if (typeof e.error === "string") {
          setSignupErrors([e.error])
        }
        scrollToSection("signup-error-div")
      })
  }

  const handleSubmit = useCallback(async () => {
    const { email } = useUserInfoModel.getState()
    setSubmitting(true)
    setSignupErrors([])
    if (email.value && email.valid) {
      await checkEmail(email.value).then(async res => {
        if (res) {
          setSignupErrors([t("Email already exists")])
          scrollToSection("signup-error-div")
        } else {
          await prepareDataAndSubmit()
        }
      })
    } else {
      setSignupErrors([t("Valid email is required")])
      scrollToSection("signup-error-div")
    }
    setSubmitting(false)
  }, [])

  return { handleSubmit, submitting, signupErrors }
}
