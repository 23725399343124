import { T } from "@transifex/react"
import "./ServiceCards.scss"
import Flexible from "../../assets/svgs/flexible.svg"
import Green from "../../assets/svgs/green.svg"
import Easy from "../../assets/svgs/easy.svg"
import React from "react"

const ServiceCards = () => {
  return (
    <div className={"service-wrapper"}>
      <div className={"service-cards"}>
        <div className={"service-card"}>
          <div className={"service-card-image"}>
            <img src={Flexible} alt={"Flexible"} />
          </div>
          <div className={"service-card-heading"}>
            <h2>
              <T _str={"Flexible & Fair"} />
            </h2>
          </div>
          <div className={"service-text"}>
            <p>
              <T _str={"We sell our energy at cost because we'd rather our customers consume less, instead of more."} />
            </p>
          </div>
        </div>
        <div className={"service-card"}>
          <div className={"service-card-image"}>
            <img src={Green} alt={"Green"} />
          </div>
          <div className={"service-card-heading"}>
            <h2>
              <T _str={"Clean & Green"} />
            </h2>
          </div>
          <div className={"service-text"}>
            <p>
              <T _str={"We sign with 100% renewable energy sources, meaning your power comes from the best places."} />
            </p>
          </div>
        </div>
        <div className={"service-card"}>
          <div className={"service-card-image"}>
            <img src={Easy} alt={"Easy"} />
          </div>
          <div className={"service-card-heading"}>
            <h2>
              <T _str={"Easy & Digital"} />
            </h2>
          </div>
          <div className={"service-text"}>
            <p>
              <T
                _str={"Read your meter, manage your payments, or chat with us live—all in one app. No letters, no fax!"}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCards
