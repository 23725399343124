import React from "react"
import SignupContainer from "../../components/SignupContainer/SignupContainer"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import { Button } from "@mui/material"
import { T } from "@transifex/react"
import { useNavigate } from "react-router-dom"
import { SvgImage } from "../../assets/svgs/SvgImage"

import "./forms.scss"
import SummaryForm from "./forms/summary-form"
import { useGeneralModel } from "../../models/useGeneralModel"
import { t } from "@transifex/native"
import useIsPriceCapAvailable from "../../hooks/useIsPriceCapAvailable"
import { FormPageStep } from "../../types/generic"
import useCurrentStep from "../../hooks/useCurrentStep"
import useSubmitForm from "../../hooks/useSubmitForm"
import PageLoader from "../../components/PageLoader/PageLoader"
import useValidatePlanForm from "../../hooks/useValidatePlanForm"
import { usePlanFormModel } from "../../models/usePlanFormModel"

function SignupSummaryPage() {
  const navigate = useNavigate()

  const { currentStep } = useCurrentStep()

  const ispPiceCapAvailable = useIsPriceCapAvailable()

  const errorMessage = "Required field"

  const { signupErrors, handleSubmit, submitting } = useSubmitForm()

  const { validatePlanForm } = useValidatePlanForm()

  const handleNext = async (step?: FormPageStep) => {
    const { termsAndConditions, termsAndConditionsPriceCap, priceCap, acceptPartnership } = useGeneralModel.getState()

    if (step) {
      const diff = step.index - currentStep.index
      if (diff > 1) return // This condition prevents the navigation to move more than one step ahead
    }

    if (step && currentStep.name.match(/summary/gi)) {
      // don't validate the form if the user is navigating to a different step from summary
      navigate(`${step.url}${window.location.search}`)
      return
    }

    const planFormIsValid = validatePlanForm()

    if (!planFormIsValid) {
      return
    }

    let p = 1
    useGeneralModel.setState({
      termsAndConditions: { ...termsAndConditions, error: termsAndConditions.value ? "" : t(errorMessage) },
      acceptPartnership: { ...acceptPartnership, error: acceptPartnership.value ? "" : t(errorMessage) },
    })
    if (!termsAndConditions.value) {
      p *= 0
    }
    const clientApplicationId = usePlanFormModel.getState().clientApplicationId
    if (clientApplicationId && !acceptPartnership.value) {
      p *= 0
    }
    if (ispPiceCapAvailable && priceCap) {
      useGeneralModel.setState({
        termsAndConditionsPriceCap: {
          ...termsAndConditionsPriceCap,
          error: termsAndConditionsPriceCap.value ? "" : t(errorMessage),
        },
      })
      if (!termsAndConditions.value || !termsAndConditionsPriceCap.value) {
        p *= 0
      }
    }
    if (p === 0) return

    if (step) {
      navigate(`${step.url}${window.location.search}`)
    } else {
      handleSubmit()
    }
  }

  return (
    <NavigationWrapper>
      <PageLoader loading={submitting} />
      <SignupContainer handleNext={handleNext}>
        <SummaryForm isSubmitting={submitting} signupErrors={signupErrors} />
        <div className={"form-action"}>
          <div>
            <Button
              disabled={submitting}
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={"btn-further"}
              style={{
                marginRight: 0,
              }}
              data-test-id={"stepper-next-btn"}
            >
              <>
                <T _str={"Place final order"} />
                <SvgImage className={"next-icon"} name={"icon-arrow-right"} />{" "}
              </>
            </Button>
          </div>
        </div>
      </SignupContainer>
    </NavigationWrapper>
  )
}

export default React.memo(SignupSummaryPage)
