import React, { useEffect, useState, useMemo } from "react"
import { T, UT, useLocale } from "@transifex/react"
import { t } from "@transifex/native"
import "./NewDynamicPricingGraph.scss"
import CustomToggleButton from "../CustomToggleButton/CustomToggleButton"
import DynamicGraph from "./DynamicGraph"
import { getSpotPrices } from "../../service/api"
import moment from "moment"
import { useLocation } from "react-router-dom"
import {
  DEFAULT_VALUE_0,
  DEFAULT_VALUE_1,
  DEFAULT_POST_CODE,
  DEFAULT_CITY_ID,
  ENGLISH,
  GraphUrlPath,
  monthNames,
  monthNamesShort,
} from "../../constants/common"
import { DynamicGraphData } from "../../types/generic"

const STROMPREIS = "/strompreis"

const NewDynamicPricingGraph = () => {
  const [toggleValue, setToggleValue] = useState(DEFAULT_VALUE_0)
  const [data, setData] = useState<DynamicGraphData | null>(null)
  const [yearData, setYearData] = useState([])
  const [dayData, setDayData] = useState([])

  let locale = useLocale()

  const onToggleChange = (e, val) => {
    if (!isNaN(val)) {
      setToggleValue(val)
    }
  }

  const toggleButtonStyle = {
    fontFamily: "Inter",
    color: "#16295A",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    width: 226,
    textTransform: "capitalize",
  }
  const location = useLocation()

  const getPrices = async () => {
    const { cityId, postalCode } = cityAndPostalCode
    if (cityId && postalCode) {
      const data = await getSpotPrices(cityId, postalCode)
      return data
    }
  }

  useEffect(() => {
    if (locale === "en") {
      moment.updateLocale("en", {
        monthsShort: monthNamesShort.en,
        months: monthNames.en,
      })
    } else {
      moment.updateLocale("de", {
        monthsShort: monthNamesShort.de,
        months: monthNames.de,
      })
    }
  }, [locale])

  const cityAndPostalCode = useMemo(() => {
    const cityId = new URLSearchParams(location.search).get("cityId") || null
    const postalCode = new URLSearchParams(location.search).get("postalCode") || null
    if (!(cityId || postalCode) && location.pathname === GraphUrlPath) {
      return { cityId: DEFAULT_CITY_ID, postalCode: DEFAULT_POST_CODE }
    }
    return { cityId, postalCode }
  }, [location.search])

  useEffect(() => {
    getPrices().then(res => {
      if (res?.data) {
        setData(res.data)
      }
    })
  }, [cityAndPostalCode])

  useEffect(() => {
    if (data?.day?.prices.length > 0) {
      const transFormed = data.day.prices.map(reading => {
        const { taxesAndLevies, price, priceWithTaxesAndLevies } = reading
        const date = moment.utc(reading.date)
        return {
          x: date.format("H:mm"),
          y: priceWithTaxesAndLevies ?? 0,
          labelData: {
            date: date.format("DD.MM.YYYY").toString(),
            time: date.format("H:mm"),
            taxesAndLevies: parseFloat(taxesAndLevies).toFixed(2) ?? 0,
            spotPrice: price.toFixed(2) ?? 0,
            totalPrice: priceWithTaxesAndLevies?.toFixed(2),
          },
          label: "",
        }
      })
      setDayData([...transFormed])
    }
    if (data?.year?.prices.length > 0) {
      const transFormed = data.year.prices.map(reading => {
        // @ts-ignore
        moment.updateLocale(locale === ENGLISH ? "en" : "de")
        const { taxesAndLevies, price, priceWithTaxesAndLevies, month } = reading
        const date = moment.utc(month, "YYYY-MM")

        return {
          x: date.format("MMM"),
          y: priceWithTaxesAndLevies || 0,
          labelData: {
            date: date.format("MMMM YYYY"),
            taxesAndLevies,
            spotPrice: price?.toFixed(2) || 0,
            totalPrice: priceWithTaxesAndLevies?.toFixed(2) || 0,
          },
          label: "",
        }
      })
      setYearData([...transFormed])
    }
  }, [data, toggleValue, locale])

  const avgConsumption = useMemo(() => {
    if (toggleValue === DEFAULT_VALUE_0) {
      return dayData.map(g => ({
        y: data?.day?.averagePrice || 0,
        x: g.x,
      }))
    } else {
      return yearData.map(g => ({
        y: data?.year?.averagePrice || 0,
        x: g.x,
      }))
    }
  }, [toggleValue, dayData, yearData])

  return (
    <div className={"dynamicGraphSection"} data-test-id={"dynamic-graph-section"}>
      <div className={"header"}>
        <h2 className={"headerText"}>
          <T _str={"Do You Already Have a Smart Meter?"} />{" "}
        </h2>
        <span className="headerSubText">
          <T _str="You Could Save Money With Our SimplyDynamic Tariff!" />
        </span>
        <p className={"subText"}>
          {location.pathname !== STROMPREIS ? (
            <T
              _str={
                "The graph below shows today's unit prices for your postcode, and averages from the last 12 months."
              }
            />
          ) : (
            <T
              _str={
                "The graph below shows today's unit prices for the postcode 10405 in Berlin, and averages from the last 12 months."
              }
            />
          )}
        </p>
        <p className={"subText"}>
          {location.pathname !== STROMPREIS ? (
            <UT
              _str={
                "<strong>Interested?</strong> Just click “Join Ostrom” above to sign up to our SimplyFair tariff. As soon as the grid operator validates your smart meter, we'll be in touch to switch you to SimplyDynamic."
              }
            />
          ) : (
            <UT
              _str={
                "<strong>Interested?</strong> Visit <a href='/'>ostrom.de</a> to sign up to our SimplyFair tariff. As soon as the grid operator validates your smart meter, we'll be in touch to switch you to SimplyDynamic."
              }
            />
          )}
        </p>
        <p className={"subText"}>
          <T
            _str="{purchase} We're selling them! Get yours {link}."
            purchase={
              <strong>
                <T _str="Want to purchase a smart meter?" />
              </strong>
            }
            link={
              <a target="_blank" href={`https://www.ostrom.de/smart-meter`}>
                <strong>
                  <T _str="here" />
                </strong>
              </a>
            }
          />
        </p>
      </div>
      <div className={"toggle-button-container"}>
        <CustomToggleButton
          toggleButtonStyle={toggleButtonStyle}
          buttonData={[t("Today"), t("Last 12 Months")]}
          onChange={onToggleChange}
          value={toggleValue}
        />
      </div>
      {toggleValue === DEFAULT_VALUE_0 && (
        <DynamicGraph
          graphData={dayData}
          avgConsumption={avgConsumption}
          toggleValue={DEFAULT_VALUE_0}
          fillColor={"#16295A"}
          strokeColor1={"#00C1B1"}
          strokeColor2={"#16295A"}
        />
      )}
      {toggleValue === DEFAULT_VALUE_1 && (
        <DynamicGraph
          graphData={yearData}
          avgConsumption={avgConsumption}
          toggleValue={DEFAULT_VALUE_1}
          fillColor={"#00C1B1"}
          strokeColor1={"#00C1B1"}
          strokeColor2={"#16295A"}
        />
      )}
      <div className={"graph-bottom-text"}>
        <p>
          <T
            _str={
              "The Unit Price shown here is based on the average market price + taxes, levies, and VAT for the postcode you entered."
            }
          />
        </p>
      </div>
    </div>
  )
}
export default NewDynamicPricingGraph
