import React, { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import "./NewPlanForm.scss"
import { T, useLocale } from "@transifex/react"

import { REFERRAL_BONUS, TARIFF_PAGE_PATH } from "../../constants/common"
import { useResponsive } from "../../hooks/useResponsive"
import { useGeneralModel } from "../../models/useGeneralModel"
import ConsumptionSection from "./ConsumptionSection"
import PostcodeSection from "./PostcodeSection"
import ReferralCodeSection from "./ReferralCodeSection"
import NewAmountDisplaySection from "./NewAmountDisplaySection"

interface PlanFormProps {
  setErrorRefCode?: (x: string) => void
  referralAmount?: number
  errorRefCode?: string
}

function NewPlanForm({ referralAmount, errorRefCode, setErrorRefCode }: Readonly<PlanFormProps>) {
  const location = useLocation()

  useEffect(() => {
    useGeneralModel.setState({ isDynamic: false })
  }, [])

  const locale = useLocale()

  const { deviceType } = useResponsive()

  const isTariffPage = useMemo(() => location.pathname.includes(TARIFF_PAGE_PATH), [location.pathname])

  return (
    <div className={`${isTariffPage ? "header-card header-card-landing-page" : "header-card"}`}>
      {/* DESKTOP */}
      {deviceType.deskTop ? (
        <div className="header-card-content desktop">
          <ConsumptionSection style={isTariffPage ? { flex: 0.3 } : { flex: 0.2 }} />
          <PostcodeSection style={{ flex: 0.5, position: "relative" }} locale={locale} />

          <ReferralCodeSection
            isTariffPage={isTariffPage}
            locale={locale}
            errorRefCode={errorRefCode}
            referralAmount={referralAmount}
            setErrorRefCode={setErrorRefCode}
            styled={isTariffPage ? { flex: 0.3 } : { flex: 0.2 }}
          />

          {!isTariffPage && <NewAmountDisplaySection extraClass={"hide"} />}
        </div>
      ) : (
        <>
          {/* MOBILE */}
          <div className="header-card-content mobile">
            <PostcodeSection style={{ position: "relative" }} locale={locale} />

            <div className="input-flex">
              <ConsumptionSection />

              <ReferralCodeSection
                isTariffPage={isTariffPage}
                locale={locale}
                errorRefCode={errorRefCode}
                referralAmount={referralAmount}
                setErrorRefCode={setErrorRefCode}
              />
            </div>
          </div>

          {!isTariffPage && (
            <>
              <NewAmountDisplaySection extraClass={"mobile"} />
              {referralAmount > 0 && (
                <div className="cost-description discount-text" style={{ textAlign: "center" }}>
                  <T _str={`-up to ${REFERRAL_BONUS} € one-time bonus`} />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(NewPlanForm)
