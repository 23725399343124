import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import { t } from "@transifex/native"
import { T } from "@transifex/react"
import React, { useEffect, useState } from "react"
import "./SentQuote.scss"
import Transition from "../../Transition/Transition"

export function SentQuote({ openStatus, onClose }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(openStatus)
  }, [openStatus])
  useEffect(() => {
    if (open === false) {
      onClose()
    }
    // eslint-disable-next-line
  }, [open])
  // eslint-disable-next-line
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="sent-quote-dialog"
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton
          className="dialog-close-icon"
          size="small"
          onClick={() => {
            setOpen(false)
          }}
        >
          <SvgImage name="clarity_window-close-line" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={"dialog-content"}>
          <SvgImage name={"quote-mail"} />
          <div className={"title"}>
            <T _str={"We sent the quote to you!"} />
          </div>
          <div className={"description"}>
            <T
              _str={"We hope to see you back to become a customer and help us to revolutionize the energy industry."}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={"dialog-action"}>
        <Button
          onClick={() => {
            onClose(false)
          }}
          className={"btn-primary secondary-color round-50"}
          autoFocus
        >
          {t("Okay")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
