import { Button } from "@mui/material"
import { T } from "@transifex/react"
import React, { ReactNode } from "react"
import { usePartnerModel } from "../../../models/usePartnerModel"

export default function InfoCard({
  renderImg,
  title,
  renderDescription,
  link,
  showLogin,
}: Readonly<{
  renderImg: any
  title: string | ReactNode
  renderDescription: ReactNode | string
  link?: { url: string; text: string | ReactNode }
  showLogin?: boolean
}>) {
  const linkData = usePartnerModel(state => state?.linkData)

  return (
    <div className="infoCard">
      {renderImg}
      <h3>{title}</h3>
      <div className="desc">{renderDescription}</div>
      {link && (
        <Button href={link.url} variant="contained" fullWidth id="main-btn" sx={{ mt: 3 }}>
          {link.text}
        </Button>
      )}
      {showLogin && (
        <Button
          id="sub-btn"
          href={`/link?id=${linkData.id}`}
          variant="text"
          fullWidth
          sx={{
            "mt": 1,
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <T _str="Login to Another Account" />
        </Button>
      )}
    </div>
  )
}
