import { initFormValue, initFormValue2 } from "./../utils/index"
import { create } from "zustand"
import { InitFormValueTypeBoolean, InitFormValueTypeString } from "../types/form"
import { persist, createJSONStorage } from "zustand/middleware"

interface DeliveryState {
  marketLocationId: InitFormValueTypeString
  meterNumber: InitFormValueTypeString
  movingDate: InitFormValueTypeString
  previousSupplierId: InitFormValueTypeString
  electricityContract: InitFormValueTypeString
  cancelAsSoonAsPossible: InitFormValueTypeBoolean
  terminatedFromDate: InitFormValueTypeString
  noMeterId: InitFormValueTypeBoolean
  noMarketLocationId: InitFormValueTypeBoolean
  meterNumberContinue: boolean
}

type Actions = {
  reset: () => void
}

const initialState = {
  marketLocationId: initFormValue,
  meterNumber: initFormValue,
  movingDate: initFormValue,
  previousSupplierId: initFormValue,
  electricityContract: null,
  cancelAsSoonAsPossible: initFormValue2,
  terminatedFromDate: initFormValue,
  noMeterId: initFormValue2,
  noMarketLocationId: initFormValue2,
  meterNumberContinue: false,
}

export const useDeliveryFormModel = create(
  persist<DeliveryState & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: "delivery-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
