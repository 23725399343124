import { T } from "@transifex/react"
import Tooltip from "@mui/material/Tooltip"
import React from "react"
import { Box, ClickAwayListener, Grid, IconButton, Stack, useTheme } from "@mui/material"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { ENGLISH, LETTER_M, MONTH, YEAR } from "../../constants/common"
import { GreenTick, GreyTick } from "./TariffIcons"
import { Col1Type, Rate } from "../../types/generic"
import useRateCalculator from "../../hooks/useRateCalculator"
import { getCommaSeparatedTwoDecimalsNumber } from "../../utils/helpers"
import SmartMeterTooltip from "../SmartMeterTooltip"
import { t } from "@transifex/native"
import { useResponsive } from "../../hooks/useResponsive"
import NewDynamicPricingTooltip from "../DynamicPricingTooltip/NewDynamicPricingTooltip"
import NewFairBox from "../FairBox/NewFairBox"
import NewJoinButton from "../JoinButton/NewJoinButton"

const getTooltipcComponentsProps = theme => ({
  tooltip: {
    sx: {
      backgroundColor: "#16295A",
      borderRadius: "6px",
      minWidth: "500px !important",
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px !important",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "250px !important",
        background: "red",
      },
    },
  },
})

const TarriffTableItem = ({ item, priceCapAvailable }) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const theme = useTheme()

  const componentsProps = getTooltipcComponentsProps(theme)

  const isMobile = window.innerWidth < 768

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box key={`${item.label}-desktop`}>
        {(!priceCapAvailable && item.label !== "Ostrom Price Cap") || priceCapAvailable ? (
          <div data-label={`${item.label}-desktop`} className="column1-row-bg">
            <Stack
              px={2}
              flex={1}
              direction={item.subtext ? "column" : "row"}
              justifyContent={"space-between"}
              className={item.subtext ? "col" : "row"}
            >
              <p>
                <T _str={item.label} />
                {item.optionalText && (
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    <T _str={"(optional)"} />
                  </span>
                )}
              </p>
              {item.subtext && (
                <span>
                  <T _str={item.subtext} />
                </span>
              )}
              {item.tooltip &&
                (isMobile ? (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      item.tooltip ? (
                        <div className={"tariff-tooltip-2"}>
                          <T _str={item.tooltip} /> {item?.link}
                        </div>
                      ) : (
                        item.component
                      )
                    }
                    componentsProps={componentsProps}
                  >
                    <div className={"pl-5 tooltip-container"} data-label={item.label}>
                      <IconButton
                        aria-label={item.tooltip}
                        style={{ padding: 0, paddingLeft: 2, cursor: "pointer" }}
                        data-label={item.label}
                        onClick={() => {
                          if (open) {
                            handleClose()
                          } else {
                            handleOpen()
                          }
                        }}
                      >
                        <SvgImage name={"info"} className={"info-icon"} dataLabel={item.label} />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    disableInteractive={false}
                    title={
                      <div className={"tariff-tooltip-2"}>
                        <T _str={item.tooltip} /> {item?.link}
                      </div>
                    }
                    componentsProps={componentsProps}
                  >
                    <IconButton aria-label={item.tooltip}>
                      <SvgImage name={"info"} styles={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>
                ))}
              {item.component &&
                (isMobile ? (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={item.component}
                    componentsProps={componentsProps}
                  >
                    <div className={"pl-5 tooltip-container"} data-label={item.label}>
                      <IconButton
                        aria-label={item.tooltip}
                        style={{ padding: 0, paddingLeft: 2, cursor: "pointer" }}
                        data-label={item.label}
                        onClick={() => {
                          if (open) {
                            handleClose()
                          } else {
                            handleOpen()
                          }
                        }}
                      >
                        <SvgImage name={"info"} className={"info-icon"} dataLabel={item.label} />
                      </IconButton>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip disableInteractive={false} title={item.component} componentsProps={componentsProps}>
                    <IconButton aria-label={item.label}>
                      <SvgImage name={"info"} styles={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>
                ))}
            </Stack>
          </div>
        ) : (
          <></>
        )}
      </Box>
    </ClickAwayListener>
  )
}

interface TableDesktopProps {
  col1: Col1Type[]
  currentPlan: string
  locale: string
  rate: Rate
  onClickJoin: () => void
  priceCapAvailable: boolean
}

const NewTableDesktop = ({
  col1,
  currentPlan,
  locale,
  rate,
  onClickJoin = () => {},
  priceCapAvailable,
}: TableDesktopProps) => {
  const currPlan = currentPlan === LETTER_M ? MONTH : YEAR
  const { unitPrice, energyNetworkFee, basicFee, unitPricePerKwh, tariff } = useRateCalculator()

  const {
    deviceType: { mobile = false },
  } = useResponsive()

  return (
    <Grid container className="table-desktop-container">
      <Grid item xs={12}>
        <NewFairBox
          currentPlan={currentPlan}
          cost={tariff}
          buttonTitle={t("Join Ostrom")}
          header={"SimplyFair Tariff"}
          onClickJoin={onClickJoin}
          testID={"join-ostrom-btn"}
        />
      </Grid>

      <Grid item xs={12} mt={2} />

      <Grid item xs={6}>
        <Box className="tarriff-table-box">
          {col1.slice(0, 4).map(item => {
            return <TarriffTableItem key={item.label} item={item} priceCapAvailable={priceCapAvailable} />
          })}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box bgcolor={"#deefef"} borderRadius={1}>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <p>
                {getCommaSeparatedTwoDecimalsNumber(unitPricePerKwh)} <span>ct/kWh</span>
              </p>
              <span className="subtext text-teal" style={{ textTransform: locale === ENGLISH ? "lowercase" : "none" }}>
                = {getCommaSeparatedTwoDecimalsNumber(unitPrice)} €/
                <T _str={currPlan} />
              </span>
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <p>
                {getCommaSeparatedTwoDecimalsNumber(energyNetworkFee)}
                <span className={"text-teal"}>
                  {" "}
                  €/
                  <T _str={currPlan} />
                </span>
              </p>
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <p>
                {getCommaSeparatedTwoDecimalsNumber(basicFee)}
                <span className={"text-teal"}>
                  {" "}
                  €/
                  <T _str={currPlan} />
                </span>
              </p>
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <p>
                -{rate.footprint?.kgCO2Emissions} <span className={"subtext text-teal"}>kg CO₂</span>
              </p>
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} my={2} />
      <Grid item xs={6}>
        <Box className="tarriff-table-box">
          {col1.slice(4, 8).map(item => (
            <TarriffTableItem key={item.label} item={item} priceCapAvailable={priceCapAvailable} />
          ))}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box bgcolor={"#deefef"} borderRadius={1}>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <GreyTick />
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <GreyTick />
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <GreenTick />
            </Stack>
          </Box>
          <Box className="column1-row-bg2">
            <Stack px={2} flex={1} alignItems="center">
              <GreenTick />
            </Stack>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} mt={1} mb={3}>
        <Stack justifyContent="center" className="table-join-btn">
          <Box textAlign={"center"} mb={2}>
            <span className="all-prices">
              <T _str={"All prices include VAT"} />
            </span>
          </Box>
          <NewJoinButton title={t("Join Ostrom")} onClickJoin={onClickJoin} testID={"join-ostrom-btn-simply-fair"} />
        </Stack>
      </Grid>

      {!mobile && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: -320,
            }}
          >
            <NewDynamicPricingTooltip />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 270,
              left: -337,
            }}
          >
            <SmartMeterTooltip />
          </div>
        </>
      )}
    </Grid>
  )
}

export default NewTableDesktop
