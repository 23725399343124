import React from "react"
import { createBrowserRouter } from "react-router-dom"
import ThankYou from "../pages/ThankYou/ThankYou"
import MaintenancePage from "../pages/Maintenance"
import HighDemandPage from "../pages/HighDemand"
import NotFoundPage from "../pages/NotFoundPage"
import ReferralPage from "../components/ReferralPage/ReferralPage"
import SignupUserInfoPage from "../pages/SignupFormPages/user-info"
import SignupDeliveryPage from "../pages/SignupFormPages/delivery"
import SignupPriceCapPage from "../pages/SignupFormPages/add-ons"
import SignupPaymentPage from "../pages/SignupFormPages/payment"
import SignupSummaryPage from "../pages/SignupFormPages/summary"
import PartnerLoginIndex from "../pages/Partner"
import ChooseContractPage from "../pages/Partner/choose-contract"
import ConnectionSuccessPage from "../pages/Partner/connection-success"
import ConnectionErrorPage from "../pages/Partner/connection-error"
import TariffPlanFairPage from "../pages/TariffPlan/TariffPlanFair"
import DynamicGraphPage from "../pages/DynamicGraph"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ReferralPage />,
  },
  {
    path: "/tariff-plan",
    element: <TariffPlanFairPage />,
  },
  {
    path: "/personal-info",
    element: <SignupUserInfoPage />,
  },
  {
    path: "/delivery",
    element: <SignupDeliveryPage />,
  },
  {
    path: "/add-ons",
    element: <SignupPriceCapPage />,
  },
  {
    path: "/payment",
    element: <SignupPaymentPage />,
  },
  {
    path: "/payment2",
    element: <SignupPaymentPage />,
  },
  {
    path: "/summary",
    element: <SignupSummaryPage />,
  },
  {
    path: "/thank-you",
    element: <ThankYou />,
  },
  {
    path: "/check",
    element: <ThankYou />,
  },
  {
    path: "/pending-signup",
    element: <ThankYou />,
  },
  {
    path: "/strompreis",
    element: <DynamicGraphPage />,
  },
  {
    path: "/maintenance",
    element: <MaintenancePage />,
  },
  {
    path: "/high-demand",
    element: <HighDemandPage />,
  },
  {
    path: "/link",
    element: <PartnerLoginIndex />,
  },
  {
    path: "/link/choose-contract",
    element: <ChooseContractPage />,
  },
  {
    path: "/link/connection-success",
    element: <ConnectionSuccessPage />,
  },
  {
    path: "/link/connection-error",
    element: <ConnectionErrorPage />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
])
