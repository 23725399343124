import React, { useEffect, useState } from "react"
import { T, useLocale } from "@transifex/react"
import { useShallow } from "zustand/react/shallow"

import { privacyPoliceUrl, termsAndConditionsPriceCapUrl, termsAndConditionsUrl } from "../../../constants/common"
import CheckBox from "../../../components/CheckBox/CheckBox"
import { useGeneralModel } from "../../../models/useGeneralModel"
import SummaryTarriffInfoCard from "../../../components/Summary/SummaryTarriffInfoCard"
import SummaryUserInfoCard from "../../../components/Summary/SummaryUserInfoCard"
import SummaryDeliveryInfoCard from "../../../components/Summary/SummaryDeliveryInfoCard"
import SummaryPaymentInfoCard from "../../../components/Summary/SummaryPaymentInfoCard"
import { Alert } from "@mui/material"
import SummaryPriceCapInfoCard from "../../../components/Summary/SummaryPriceCapInfoCard"
import useIsPriceCapAvailable from "../../../hooks/useIsPriceCapAvailable"
import SummarySmartMeterInfoCard from "../../../components/Summary/SummarySmartMeter"
import { usePlanFormModel } from "../../../models/usePlanFormModel"
import { checkSmartMeterAvailability } from "../../../service/api"

export default function SummaryForm({ isSubmitting, signupErrors }: { signupErrors: string[]; isSubmitting: boolean }) {
  const {
    isDynamic,
    hasElectricVehicle,
    hasHeatPump,
    hasSmartMeter,
    interestedInThgQuota,
    priceCap,
    acceptPartnership,
    rate,
    termsAndConditionsPriceCap,
    termsAndConditions,
  } = useGeneralModel(useShallow(state => state))

  const { postalCode, usage } = usePlanFormModel(state => state)

  const isPriceCapAvailable = useIsPriceCapAvailable()

  const showPriceCap = !isDynamic && isPriceCapAvailable

  const locale = useLocale()
  const [smartMeterOfferAvailable, setSmartMeterOfferAvailable] = useState(false)

  useEffect(() => {
    const handlePostalCodeChange = async () => {
      setSmartMeterOfferAvailable(false)
      if (postalCode && usage) {
        await checkSmartMeterAvailability(postalCode, usage)
          .then(res => {
            if (res?.data) {
              setSmartMeterOfferAvailable(res?.data?.isEligible)
              useGeneralModel.setState({
                smartMeterOfferAvailable: res.data.isEligible,
                smartMeterPostalCodeId: res.data.smartMeterPostalCodeId,
                smartMeterPrice: res.data.smartMeterPrice,
                smartMeterOfferSeen: res.data.isEligible,
              })
            }
          })
          .catch(() => {
            useGeneralModel.setState({ smartMeterOfferSeen: false })
          })
      }
    }
    handlePostalCodeChange()
  }, [postalCode, usage])

  return (
    <>
      <div
        className={"form-container bg-white-color summary-container px-0 pb-0"}
        style={{ paddingTop: 0 }}
        data-test-id={"summary-container"}
      >
        <div className={"form-title form-title-summary"}>
          <div className={"title bg-white-color"} style={{ paddingTop: 15 }}>
            <T _str="Summary" />
          </div>
          <div className={"line-container"} style={{ paddingTop: 10 }}>
            <div className={"line"} />
          </div>
        </div>

        <div
          className={"form-content form-content-summary bg-white-color summary-form-title "}
          style={{ paddingBottom: 20 }}
        >
          <SummaryTarriffInfoCard />
          <SummaryUserInfoCard />
          <SummaryDeliveryInfoCard />
          {showPriceCap && <SummaryPriceCapInfoCard />}
          {smartMeterOfferAvailable && <SummarySmartMeterInfoCard />}
          <SummaryPaymentInfoCard />
        </div>

        {isSubmitting === false && signupErrors.length > 0 && (
          <div className={"form-content  bg-white-color mt-20 py-20"} id={"signup-error-div"}>
            {signupErrors.map(err => (
              <Alert key={err} className={"mt-20"} severity="warning">
                {<div>{err}</div>}
              </Alert>
            ))}
          </div>
        )}
        {isSubmitting === false && signupErrors.length > 0 && (
          <div>
            <input type={"hidden"} id={"error-signup"} name={"error-signup"} value={signupErrors} />
          </div>
        )}
      </div>

      <div className={"stepper-from-container agreement-container"}>
        <div className={"form-content bg-white-color form-container"} style={{ padding: "28px 30px" }}>
          <div className={"summary-checkbox-input"}>
            <CheckBox
              // @ts-ignore
              label={
                <span>
                  <T
                    _str={"I agree to Ostrom’s {terms} and {cancellation}"}
                    terms={
                      <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
                        <T _str={"terms and conditions"} />
                      </a>
                    }
                    cancellation={
                      <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
                        <T _str={"cancellation policy"} />
                      </a>
                    }
                  />
                  {"."}
                </span>
              }
              value={termsAndConditions.value}
              showError={termsAndConditions.error !== ""}
              errorText={termsAndConditions.error}
              labelType={"component"}
              onChange={value => {
                useGeneralModel.setState({ termsAndConditions: { ...termsAndConditions, value } })
              }}
              data-test-id={"terms-conditions-checkbox"}
            />
          </div>
          {showPriceCap && priceCap ? (
            <div className={"summary-checkbox-input"}>
              <CheckBox
                // @ts-ignore
                label={
                  <span>
                    <T _str={"I agree to the "} />
                    <a href={termsAndConditionsPriceCapUrl} target="_blank" rel="noreferrer">
                      <T _str={"terms and conditions of Ostrom’s Price Cap"} />
                    </a>
                    {"."}
                  </span>
                }
                value={termsAndConditionsPriceCap.value}
                showError={termsAndConditionsPriceCap.error !== ""}
                errorText={termsAndConditionsPriceCap.error}
                labelType={"component"}
                onChange={value => {
                  useGeneralModel.setState({ termsAndConditionsPriceCap: { ...termsAndConditionsPriceCap, value } })
                }}
                data-test-id={"terms-conditions-price-cap-checkbox"}
              />
            </div>
          ) : (
            <></>
          )}
          {rate?.partnerName ? (
            <div className={"summary-checkbox-input"}>
              <CheckBox
                //@ts-ignore
                label={
                  <span>
                    <T
                      _str="I consent to my personal data being passed on to {partnerName} in connection with my energy contract for the purpose of analysis and consolidated customer support. This consent can be revoked at any time by contacting Ostrom. Further information can be found in the {linkUrl}."
                      partnerName={<strong data-test-id="accept-partnership-name">{rate.partnerName}</strong>}
                      linkUrl={
                        <a
                          href={`https://ostrom.webflow.io/privacy-policy?lang=${locale?.includes("de") ? "de" : "en"}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong>
                            <T _str="privacy policy" />
                          </strong>
                        </a>
                      }
                    />
                  </span>
                }
                value={acceptPartnership.value}
                showError={acceptPartnership.error !== ""}
                errorText={acceptPartnership.error}
                labelType={"component"}
                onChange={value => {
                  useGeneralModel.setState({ acceptPartnership: { ...acceptPartnership, value } })
                }}
                data-test-id={"accept-partnership-checkbox"}
              />
            </div>
          ) : null}
          <span className="privacy-policy">
            <T
              _str={"Further information on data protection can be found in the {linkUrl}"}
              linkUrl={
                <a href={`https://ostrom.webflow.io/privacy-policy?lang=${locale?.includes("de") ? "de" : "en"}`}>
                  <strong>
                    <T _str="privacy policy" />
                  </strong>
                </a>
              }
            />
            {"."}
          </span>
        </div>
      </div>
    </>
  )
}
