import React, { useState } from "react"
import { T } from "@transifex/react"
import JoinButton from "../JoinButton/JoinButton"
import "../Tariff/NewTariffPlan.scss"
import { ClickAwayListener, IconButton, useTheme } from "@mui/material"
import { SvgImage } from "../../assets/svgs/SvgImage"
import Tooltip from "@mui/material/Tooltip"
import { LETTER_M, MONTH, YEAR } from "../../constants/common"
import NewDynamicTooltipBody from "../DynamicTooltipBody/NewDynamicTooltipBody"

const getTooltipcomponentProps = theme => ({
  tooltip: {
    sx: {
      background: "#16295A",
      borderRadius: "6px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "230px !important",
        fontSize: 14,
      },
      320: {
        minWidth: "250px !important",
      },
    },
  },
})

const NewFairBox = ({ currentPlan, cost, buttonTitle = "", header, subtext = "", onClickJoin, testID = "" }) => {
  const [openTooltip, setOpenTooltip] = useState(false)

  const theme = useTheme()

  const componentTooltipProps = getTooltipcomponentProps(theme)

  const handleOnClickJoin = () => {
    onClickJoin()
  }

  const isMobile = window.innerWidth < 1200

  return (
    <div className={"button-container-fair"}>
      <div className="subtext-height">
        <p className={"subtext"}>
          <T _str={header} />{" "}
          {isMobile && (
            <ClickAwayListener
              onClickAway={() => {
                setOpenTooltip(false)
              }}
            >
              <Tooltip
                disableInteractive={false}
                PopperProps={{
                  disablePortal: false,
                }}
                onClose={() => {
                  setOpenTooltip(false)
                }}
                open={openTooltip}
                disableHoverListener
                disableFocusListener
                disableTouchListener
                title={
                  <div className="dynamic" style={{ paddingTop: 20 }}>
                    <NewDynamicTooltipBody />
                  </div>
                }
                placement={isMobile ? "bottom" : "bottom-start"}
                componentsProps={componentTooltipProps}
              >
                <IconButton
                  style={{ padding: 0, paddingLeft: 2, cursor: "pointer" }}
                  onClick={() => {
                    setOpenTooltip(!openTooltip)
                  }}
                  data-label={"dynamic-tooltip"}
                >
                  <SvgImage name={"info"} className={"info-icon"} dataLabel={"dynamic-tooltip"} />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          )}
        </p>
        {subtext && (
          <span className={"header-sub"}>
            <T _str={subtext} />
          </span>
        )}
      </div>

      <div className={"cost-view-container"}>
        <div className={"cost-text"}>{cost} €</div>
        <div className={"cost-description"}>
          {currentPlan === LETTER_M ? <T _str={`/${MONTH}`} /> : <T _str={`/${YEAR}`} />}
        </div>
      </div>
      <div className={"button-wrapper"}>
        <JoinButton title={buttonTitle} onClickJoin={handleOnClickJoin} testID={testID} />
      </div>
    </div>
  )
}

export default NewFairBox
