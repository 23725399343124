import { T } from "@transifex/react"
import React, { useEffect, useState } from "react"

const DynamicTooltipBody = ({ isDynamic }) => {
  const [showDynamicTooltip, setShowDynamicTooltip] = useState(false)

  useEffect(() => {
    setShowDynamicTooltip(isDynamic)
  }, [isDynamic])

  return (
    <div>
      <div className={"dynamic-item-container"} style={{ border: "none", paddingBottom: 0 }}>
        {showDynamicTooltip ? (
          <div className="dynamic-item-content">
            <p>
              <T
                _str={
                  "The electricity price (or “spot price”) on the wholesale energy market varies hourly. In times of heavy winds, sunshine, or at night, electricity is cheaper."
                }
              />
            </p>

            <p style={{ paddingTop: 16 }}>
              <T
                _str={
                  "The Unit Price used to calculate this price is based on today’s average spot price + taxes and levies for the postcode you entered."
                }
              />
            </p>

            <p style={{ paddingTop: 16 }}>
              <span style={{ textDecoration: "underline" }}>
                <T _str={"Please note: "} />
              </span>
              <T
                _str={
                  'You can only sign up for this tariff if you have a smart meter. If you’re unsure, see the "Smart Meter Required" section of the table, or chat to our team via the live chat.'
                }
              />
            </p>
          </div>
        ) : (
          <div className="dynamic-item-content">
            <p>
              <T
                _str={
                  "The electricity price (or “spot price”) on the wholesale energy market varies hourly. In times of heavy winds, sunshine, or at night, electricity is cheaper."
                }
              />
            </p>

            <p style={{ paddingTop: 16 }}>
              <T
                _str={
                  "The Unit Price used to calculate this price is based on today’s average spot price + taxes and levies for the postcode you entered."
                }
              />
            </p>
            <p style={{ paddingTop: 16 }}>
              <span style={{ fontWeight: 600, textDecoration: "underline" }}>
                <T _str={"Please note:"} />
              </span>
              <T
                _str={
                  " You can only sign up for this tariff if you have a smart meter. If you’re unsure, see the “Smart Meter Required“ section of the table, or chat to our team via the live chat."
                }
              />
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default DynamicTooltipBody
