import React, { useState } from "react"
import "./accordion.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"

interface Item {
  title: string | JSX.Element
  content: string | JSX.Element
  key: string
}

interface AccordionItemProps {
  item: Item
  isOpen: boolean
  onClickAccordion: (x: Item) => void
}

function AccordionItem({ item, isOpen, onClickAccordion }: Readonly<AccordionItemProps>) {
  function handleClick() {
    onClickAccordion(item)
  }

  return (
    <div className={"accordion-item-container"}>
      <div className="accordion-item-title" tabIndex={0} role="button" onClick={handleClick}>
        {item.title} {isOpen ? <SvgImage name={"minus-icon"} /> : <SvgImage name={"plus-icon"} />}
      </div>
      {isOpen && <div className="accordion-item-content">{item.content}</div>}
    </div>
  )
}

interface AccordionProps {
  title: string | JSX.Element
  items: Array<Item>
  footer: string | JSX.Element
  handleClose: () => void
}

function Accordion({ title, items, footer, handleClose }: Readonly<AccordionProps>) {
  const [selectedAccordion, setSelectedAccordion] = useState(null)

  const onSelectItem = (item: Item) => {
    if (item?.title == selectedAccordion?.title) {
      setSelectedAccordion(null)
    } else {
      setSelectedAccordion(item)
    }
  }

  return (
    <div className="accordion">
      <div className="accordion-title-block">
        <div className="accordion-title">{title}</div>
        <div
          className="accordion-close"
          tabIndex={0}
          role="button"
          onClick={handleClose}
          data-test-id={"accordion-close"}
        >
          <SvgImage name={"close-icon"} />
        </div>
      </div>
      <div>
        {items.map(item => (
          <AccordionItem
            key={item?.key}
            item={item}
            isOpen={selectedAccordion?.title === item?.title}
            onClickAccordion={onSelectItem}
          />
        ))}
      </div>
      <div className="accordion-footer">{footer}</div>
    </div>
  )
}

export default Accordion
