import { useNavigate } from "react-router-dom"
import { usePaymentModel } from "../models/usePaymentFormModel"
import { isEmptyOrSpaces, scrollToSection } from "../utils"
import { t } from "@transifex/native"
import { DIRECT_DEBIT, SUMMARY_PAGE_PATH } from "../constants/common"
import { useResponsive } from "./useResponsive"
import { useCallback } from "react"
import { FormPageStep } from "../types/generic"
import useCurrentStep from "./useCurrentStep"
import useValidatePlanForm from "./useValidatePlanForm"

export default function usePaymentInfoValidate() {
  const navigate = useNavigate()

  const { deviceType } = useResponsive()

  const { currentStep } = useCurrentStep()

  const { validatePlanForm } = useValidatePlanForm()

  const errorMessage = "Required field"

  // step is usefull to navigate to a specific form page using the stepper
  const handleNext = useCallback(
    async (step?: FormPageStep, isValidationOnly = false) => {
      const {
        isDifBillingAdr,
        accountHolderName,
        paymentMethod,
        iban,
        ibanError,
        ibanValid,
        billingFirstName,
        billingCity,
        billingHouseNumber,
        billingStreet,
        billingSurname,
        billingZip,
      } = usePaymentModel.getState()

      if (step) {
        const diff = step.index - currentStep.index
        if (diff > 1) return // This condition prevents the navigation to move more than one step ahead
      }

      const planFormIsValid = validatePlanForm()

      if (!planFormIsValid) {
        return 0
      }

      let p = 1
      if (paymentMethod === DIRECT_DEBIT) {
        if (isEmptyOrSpaces(iban.value)) {
          usePaymentModel.setState({ iban: { ...iban, valid: false, error: t(errorMessage) } })
          p *= 0
        }
        if (ibanValid == null) p *= 0
        if (ibanValid === false || ibanError !== "") {
          usePaymentModel.setState({
            iban: {
              ...iban,
              valid: false,
              error: t("It is not valid"),
            },
          })
          p *= 0
        }
        if (isEmptyOrSpaces(accountHolderName.value)) {
          usePaymentModel.setState({
            accountHolderName: {
              ...accountHolderName,
              valid: false,
              error: t(errorMessage),
            },
          })
          p *= 0
        }
      }
      if (isDifBillingAdr) {
        // go through each billing value and set to error if they are empty
        const billingInfo = [
          { key: "billingCity", data: billingCity },
          { key: "billingFirstName", data: billingFirstName },
          { key: "billingSurname", data: billingSurname },
          { key: "billingHouseNumber", data: billingHouseNumber },
          { key: "billingZip", data: billingZip },
          { key: "billingStreet", data: billingStreet },
        ]

        billingInfo.forEach(d => {
          if (isEmptyOrSpaces(d.data.value)) {
            usePaymentModel.setState({
              [d.key]: {
                ...d.data,
                valid: false,
                error: t(errorMessage),
              },
            })
            p *= 0
          }
        })
      }
      scrollToSection("stepper-container-section", deviceType.mobile)
      if (p === 0) {
        return 0
      }
      scrollToSection()
      if (isValidationOnly) {
        return p
      }
      if (step) {
        navigate(`${step.url}${window.location.search}`)
      } else {
        navigate(`${SUMMARY_PAGE_PATH}${window.location.search}`)
      }
    },
    [deviceType, navigate]
  )

  return { handleNext }
}
