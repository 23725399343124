import axiosClient from "./client"
import { PartnerLinkType, PartnerLoginType } from "../types/partner"
import { usePartnerModel } from "../models/usePartnerModel"
import { enqueueSnackbar } from "notistack"

async function applicationLogin(
  linkId: string,
  postData: {
    email: string
    password: string
  }
): Promise<Partial<boolean>> {
  return axiosClient
    .post<PartnerLoginType>(`client-application-user-links/${linkId}/login`, postData)
    .then(({ data }) => {
      usePartnerModel.setState({ user: data })
      return true
    })
  // .catch(e => {
  //   usePartnerModel.setState({ user: null })
  //   enqueueSnackbar(e?.response?.data?.message ?? e?.response?.data?.error, {
  //     variant: "error",
  //     anchorOrigin: {
  //       horizontal: "center",
  //       vertical: "top",
  //     },
  //     autoHideDuration: 3000,
  //   })
  //   return false
  // })
}

async function applicationLinkConfirmation(
  linkId: string,
  postData: {
    confirmationCode: string
    contractId: string
  }
) {
  return axiosClient.post(`client-application-user-links/${linkId}/confirm`, postData)
}

async function getApplicationLinkData(linkId: string): Promise<
  Partial<{
    error: string
    message: string
    data: PartnerLinkType
  }>
> {
  return axiosClient.get<PartnerLinkType>(`client-application-user-links/${linkId}`).then(({ data }) => {
    usePartnerModel.setState({ linkData: data })
    return { data }
  })
}

export const PartnerApi = { applicationLogin, applicationLinkConfirmation, getApplicationLinkData }
