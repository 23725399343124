import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { usePaymentModel } from "../models/usePaymentFormModel"
import { useUserInfoModel } from "../models/userInfoModel"
import { useGeneralModel } from "../models/useGeneralModel"
import { convertToMoment, isEmptyOrSpaces, scrollToSection } from "../utils"
import { t } from "@transifex/native"
import moment from "moment"
import { DELIVERY_PAGE_PATH, MAX_AGE, MIN_AGE, STEP_4 } from "../constants/common"
import { useResponsive } from "./useResponsive"
import { FormPageStep } from "../types/generic"
import useCurrentStep from "./useCurrentStep"
import useValidatePlanForm from "./useValidatePlanForm"
import { ValidateEmail } from "../utils/helpers"

export default function useUserInfoValidation() {
  const navigate = useNavigate()

  const { deviceType } = useResponsive()

  const { currentStep } = useCurrentStep()

  const { validatePlanForm } = useValidatePlanForm()

  const errorMessage = "Required field"

  // step is usefull to navigate to a specific form page using the stepper
  const handleNext = useCallback(
    async (step?: FormPageStep, isValidationOnly = false) => {
      const { companyName, firstName, street, houseNumber, email, surname, dob, phone } = useUserInfoModel.getState()
      const { accountHolderName } = usePaymentModel.getState()
      const { personalTab, isEmailInUse } = useGeneralModel.getState()

      if (step) {
        const diff = step.index - currentStep.index
        if (diff > 1) return // This condition prevents the navigation to move more than one step ahead
      }

      const planFormIsValid = validatePlanForm()

      if (!planFormIsValid) {
        return 0
      }

      let p = 1
      // scroll to section on redirect
      scrollToSection("stepper-container-section")

      if (personalTab !== "home") {
        if (isEmptyOrSpaces(companyName.value)) {
          useUserInfoModel.setState({ companyName: { ...companyName, valid: false, error: t(errorMessage) } })
          p *= 0
        }
      } else {
        useUserInfoModel.setState({ companyName: { ...companyName, valid: true, error: "" } })
      }
      if (isEmptyOrSpaces(firstName.value)) {
        useUserInfoModel.setState({ firstName: { ...firstName, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      if (isEmptyOrSpaces(street.value)) {
        useUserInfoModel.setState({ street: { ...street, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      if (isEmptyOrSpaces(houseNumber.value)) {
        useUserInfoModel.setState({ houseNumber: { ...houseNumber, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      if (!isEmailInUse) {
        if (isEmptyOrSpaces(email.value)) {
          useUserInfoModel.setState({ email: { ...email, valid: false, error: t(errorMessage) } })
          p *= 0
        }
        if (email.error !== "") {
          useUserInfoModel.setState({ email: { ...email, valid: false, error: email.error } })
          p *= 0
        }
        if (ValidateEmail(email.value) === false) {
          useUserInfoModel.setState({ email: { ...email, valid: false, error: t("Valid email is required") } })
          p *= 0
        }
        if (email.valid === false) {
          useUserInfoModel.setState({ email: { ...email, valid: false, error: t("Valid email is required") } })
          p *= 0
        }
      } else {
        p *= 0
      }
      if (isEmptyOrSpaces(surname.value)) {
        useUserInfoModel.setState({ surname: { ...surname, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      if (isEmptyOrSpaces(dob.value) && personalTab === "home") {
        useUserInfoModel.setState({ dob: { ...dob, valid: false, error: t(errorMessage) } })
        p *= 0
      } else if ((dob.error || !dob.valid) && personalTab === "home") {
        useUserInfoModel.setState({ dob: { ...dob, valid: false, error: t("Invalid Date - DD.MM.YYYY") } })
        p *= 0
      } else {
        const momentDate = convertToMoment(dob.value)
        const diff = moment().diff(momentDate, "years")
        let valid = true
        let error = ""
        if (diff < MIN_AGE) {
          valid = false
          error = t("Must be older than 18")
          p *= 0
        } else if (diff > MAX_AGE) {
          valid = false
          error = t("Invalid date")
          p *= 0
        }
        if (personalTab === "home") {
          useUserInfoModel.setState({ dob: { ...dob, valid, error } })
        } else {
          useUserInfoModel.setState({ dob: { ...dob, valid: true, error: "" } })
        }
      }
      if (isEmptyOrSpaces(phone.value) || phone.value.length < STEP_4) {
        useUserInfoModel.setState({ phone: { ...phone, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      if (isEmptyOrSpaces(email.value)) {
        useUserInfoModel.setState({ email: { ...email, valid: false, error: t(errorMessage) } })
        p *= 0
      }
      scrollToSection("stepper-container-section", deviceType.mobile)
      if (p === 0) {
        return 0
      }
      if (isEmptyOrSpaces(accountHolderName.value)) {
        usePaymentModel.setState({
          accountHolderName: {
            ...accountHolderName,
            value: firstName.value + " " + surname.value,
          },
        })
      }

      if (isValidationOnly) {
        return p
      }

      if (step) {
        navigate(`${step.url}${window.location.search}`)
      } else {
        navigate(`${DELIVERY_PAGE_PATH}${window.location.search}`)
      }
    },
    [deviceType, navigate]
  )

  return { handleNext }
}
