import { t } from "@transifex/native"

const languages = [
  { code: "en", name: t("English") },
  { code: "de_DE", name: t("German") },
]
const STR_MONTHLY = "monthly"
const STR_YEARLY = "yearly"
const STR_RATE_TARIFF = "tariff"
const STR_RATE_OSTROM = "ostrom"
const STR_RATE_SAVING = "savings"
const STR_RATE_BASIC_FEE = "basicFee"
const STR_RATE_BASIC_PROVIDER = "basicProvider"
const STR_RATE_ENERGY_CONSUMPTION = "energyConsumption"
const STR_RATE_ENERGY_NETWORK_FEE = "networkFee"
const STR_RATE_UNIT_PRICE_PER_KWH = "unitPricePerkWH"
const STR_RATE_UNIT_PRICE = "unitPrice"
const STR_RATE_NULL = "--"
export {
  languages,
  STR_MONTHLY,
  STR_YEARLY,
  STR_RATE_TARIFF,
  STR_RATE_OSTROM,
  STR_RATE_SAVING,
  STR_RATE_BASIC_FEE,
  STR_RATE_BASIC_PROVIDER,
  STR_RATE_ENERGY_CONSUMPTION,
  STR_RATE_ENERGY_NETWORK_FEE,
  STR_RATE_UNIT_PRICE_PER_KWH,
  STR_RATE_UNIT_PRICE,
  STR_RATE_NULL,
}
