import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import { useGeneralModel } from "../models/useGeneralModel"
import { DEFAULT_VALUE_0 } from "../constants/common"

export default function useRateCalculator() {
  const { rate } = useGeneralModel(useShallow(state => state))

  const tariff = useMemo(() => {
    return rate?.ostrom ? rate?.ostrom[0]?.tariff : DEFAULT_VALUE_0
  }, [rate])

  const tariffDynamic = useMemo(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.tariff : DEFAULT_VALUE_0
  }, [rate])

  const unitPriceDynamic = useMemo(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.unitPrice : 0
  }, [rate])

  const unitPricePerKwhDynamic = useMemo(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.unitPricePerkWH : 0
  }, [rate])

  const unitPrice = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[0]?.unitPrice : 0
  }, [rate])

  const unitPricePerKwh = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[0]?.unitPricePerkWH : 0
  }, [rate])

  const energyNetworkFee = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[0]?.networkFee : 0
  }, [rate])

  const basicFee = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[0]?.basicFee : 0
  }, [rate])

  const energyNetworkFeeDynamic = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.networkFee : 0
  }, [rate])

  const basicFeeDynamic = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.basicFee : 0
  }, [rate])

  const averageFeeDynamic = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.averageHourlyPriceToday : 0
  }, [rate])

  const minPriceTodayDynamic = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.minHourlyPriceToday : 0
  }, [rate])

  const maxPriceTodayDynamic = useMemo<number>(() => {
    return rate?.ostrom ? rate?.ostrom[1]?.maxHourlyPriceToday : 0
  }, [rate])

  const kgCO2Emissions = useMemo<number>(() => {
    return rate?.ostrom ? rate.footprint?.kgCO2Emissions : 0
  }, [rate])

  return {
    unitPrice,
    unitPricePerKwh,
    energyNetworkFee,
    basicFee,
    energyNetworkFeeDynamic,
    basicFeeDynamic,
    averageFeeDynamic,
    minPriceTodayDynamic,
    maxPriceTodayDynamic,
    kgCO2Emissions,
    tariff,
    tariffDynamic,
    unitPriceDynamic,
    unitPricePerKwhDynamic,
  }
}
