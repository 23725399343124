import { T } from "@transifex/react"
import React from "react"
import { useShallow } from "zustand/react/shallow"

import { ANNAUL_FEE_PRICE_CAP, ADDONS_PAGE_PATH, PRICE_CAP_AMOUNT } from "../../constants/common"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useNavigate } from "react-router-dom"
import { scrollToSection } from "../../utils"

export default function SummaryPriceCapInfoCard() {
  const { priceCap } = useGeneralModel(useShallow(state => state))
  const navigate = useNavigate()
  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Price Cap"} />
          </div>
        </div>
        <div
          className={"btn-edit"}
          onClick={() => {
            scrollToSection("stepper-container-section")
            navigate(`${ADDONS_PAGE_PATH}${window.location.search}`)
          }}
        >
          <SvgImage name={"edit"} />
          <p>
            <T _str={"edit"} />
          </p>
        </div>
      </div>
      <div className={"info-card-content"}>
        {priceCap ? (
          <>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"PRICE CAP"} />
              </div>
              <div className={"item-value"}>
                <T _str={"Yes"} />
              </div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"PRICE CAPPED AT"} />
              </div>
              <div className={"item-value"}>{PRICE_CAP_AMOUNT} ct/kWh</div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"ANNUAL FEE"} />
              </div>
              <div className={"item-value"}>{ANNAUL_FEE_PRICE_CAP} €</div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"MIN. CONTRACT"} />
              </div>
              <div className={"item-value"}>
                <T _str={"1 Year"} />
              </div>
            </div>
          </>
        ) : (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"PRICE CAP"} />
            </div>
            <div className={"item-value"}>
              <T _str={"No"} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
