import React from "react"

import { Container } from "@mui/material"
import { Box } from "@mui/system"
import "./style.scss"
import InfoCard from "./component/InfoCard"
import { t } from "@transifex/native"
import { SvgImage } from "../../assets/svgs/SvgImage"
import PartnerContainer from "./component/PartnerContainer"
import HeaderTitle from "./component/HeaderTitle"
import { useShallow } from "zustand/react/shallow"
import { usePartnerModel } from "../../models/usePartnerModel"
import { T, UT } from "@transifex/react"

const ConnectionErrorPage = () => {
  const { user } = usePartnerModel(
    useShallow(state => ({
      linkData: state.linkData,
      user: state.user,
    }))
  )

  return (
    <PartnerContainer>
      <Box data-test-id="loginForm" className={"column-centered"}>
        <Container maxWidth="sm">
          <HeaderTitle title={<T _str="Connect Account" />} />
        </Container>
        <Container maxWidth="sm" className={"card shadowProp"}>
          {user && (!user?.contracts || !user?.contracts?.length) ? (
            <InfoCard
              renderDescription={
                <T
                  _str={
                    "Your application is still being processed, you should wait for it to be completed to connect your account."
                  }
                />
              }
              title={<T _str="Your application is still being processed" />}
              renderImg={<SvgImage name="time" styles={{ height: 60 }} />}
            />
          ) : (
            <InfoCard
              renderDescription={
                <UT
                  _str={
                    "<strong>Invalid</strong> or <strong>expired link</strong>, please contact partner support team."
                  }
                />
              }
              title={t("Whoops!")}
              renderImg={<SvgImage name="smiley-sad" styles={{ height: 60 }} />}
            />
          )}
        </Container>
      </Box>
    </PartnerContainer>
  )
}

export default ConnectionErrorPage
