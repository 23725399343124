import { usePartnerModel } from "../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { ContractCode } from "../types/generic"
import { useMemo } from "react"
import { t } from "@transifex/native"

function usePartnerContractSelection() {
  const selectedContract = usePartnerModel(useShallow(state => state.selectedContract))

  const isAlreadyConnected = selectedContract && selectedContract?.isConnectedToPartner
  const isSimplyFair = selectedContract && selectedContract?.productCode === ContractCode.SIMPLY_FAIR
  const isSimplyDynamic = selectedContract && selectedContract?.productCode === ContractCode.SIMPLY_DYNAMIC
  const addressLabel = useMemo(() => {
    if (selectedContract?.address) {
      return `${selectedContract.address.street} ${selectedContract.address.houseNumber}, ${selectedContract.address.zip} ${selectedContract.address.city}`
    }
    return t("Select a contract")
  }, [selectedContract])

  return {
    isAlreadyConnected,
    isSimplyFair,
    isSimplyDynamic,
    addressLabel,
    hasError: !selectedContract || isAlreadyConnected || !isSimplyDynamic,
  }
}

export default usePartnerContractSelection
