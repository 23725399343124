import { Cookie } from "js-cookie"
import { useCallback } from "react"
import { usePlanFormModel } from "../models/usePlanFormModel"
import { PlanFormType } from "../types/form"
import { DEFAULT_KWH_CONSUMPTION, ENGLISH } from "../constants/common"
import { useGeneralModel } from "../models/useGeneralModel"
import { useLocale } from "@transifex/react"
import { tx } from "@transifex/native"

export const useInitialParams = () => {
  const getInitialUrlParams = useCallback(() => {
    const url = window.location.search
    const params = new URLSearchParams(url)
    const postalCode = params.get("postalCode")
    const cityId = params.get("cityId")
    const cityName = params.get("cityName")
    const usage = params.get("usage") ?? DEFAULT_KWH_CONSUMPTION
    const referralCode1 = params.get("referralCode")
    const product = params.get("product")
    const clientApplicationId = params.get("clientApplicationId")
    const externalUserId = params.get("externalUserId")
    const externalTransactionId = params.get("externalTransactionId")
    const language = params.get("language")
    let newParams = {
      usage,
      cityId: cityId || undefined,
      postalCode: postalCode || undefined,
      cityName: cityName || undefined,
      referralCode: referralCode1 || undefined,
      product,
      clientApplicationId,
      externalUserId,
      externalTransactionId,
    } as Partial<PlanFormType>
    // remove empty properties from newParams
    newParams = Object.entries(newParams).reduce(
      (prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev),
      {} as Partial<PlanFormType>
    )
    usePlanFormModel.setState({ ...newParams })

    tx.setCurrentLocale(language ?? ENGLISH)
      .then(() => {
        Cookie?.set("locale", language)
        // english is the default language, so we don't need to set it
        useGeneralModel.setState({ locale: language ?? ENGLISH })
      })
      .catch(err => console.log(err))
    // }
  }, [])

  return { getInitialUrlParams }
}

export default useInitialParams
