import React from "react"
import SignupContainer from "../../components/SignupContainer/SignupContainer"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import { Button } from "@mui/material"
import { T } from "@transifex/react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import UserInfoForm from "./forms/user-form"

import "./forms.scss"
import useUserInfoValidation from "../../hooks/useUserInfoValidation"

function SignupUserInfoPage() {
  const { handleNext } = useUserInfoValidation()

  return (
    <NavigationWrapper>
      <SignupContainer handleNext={handleNext}>
        <UserInfoForm />
        <div className={"form-action"}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={"btn-further"}
              style={{
                marginRight: 0,
              }}
              data-test-id={"stepper-next-btn"}
            >
              <>
                <T _str={"Next"} />
                <SvgImage className={"next-icon"} name={"icon-arrow-right"} />{" "}
              </>
            </Button>
          </div>
        </div>
      </SignupContainer>
    </NavigationWrapper>
  )
}

export default React.memo(SignupUserInfoPage)
