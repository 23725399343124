import React from "react"
import { T, useLocale } from "@transifex/react"
import { tx } from "@transifex/native"
import "./LanguageSwicher.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import Cookie from "js-cookie"
import { useLocation, useNavigate } from "react-router-dom"
import { Menu, Button, MenuItem } from "@mui/material"
import { ENGLISH } from "../../constants/common"
import { sendErrorToSentry } from "../../utils/helpers"
import { styled } from "@mui/system"
import { languages } from "../../constants/global"
import { useGeneralModel } from "../../models/useGeneralModel"
import { getLanguage } from "../../utils"

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    "top": button => (button ? "80px !important" : "412px !important"),
    "width": 104,
    "background": " #F0EDED",
    "margin": 0,
    "& .MuiList-padding": {
      padding: "8px 0",
    },
    "& li": {
      "fontFamily": "Inter,sans-serif",
      "fontStyle": "normal",
      "fontWeight": 500,
      "fontSize": 16,
      "lineHeight": "150%",
      "color": "#000000",
      "padding": "0 0 0 12px",
      "&:hover": {
        fontWeight: 700,
      },
    },
    "& li:not(:last-child)": {
      paddingBottom: 10,
    },
  },
}))

export default function LanguageSwicher({
  className,
  button = true,
}: Readonly<{ className?: string; button?: boolean }>) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const location = useLocation()
  const navigate = useNavigate()

  let locale = useLocale()
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleSelectLang = locale => {
    setAnchorEl(null)
    Cookie.set("locale", locale)
    tx.setCurrentLocale(locale)
      .then(() => {
        if (location.search.includes("language")) {
          const search = new URLSearchParams(location.search)
          search.set("language", locale)
          navigate(location.pathname + "?" + search.toString())
          return
        }
        const pathName = !location.search
          ? `${location.pathname}?language=${locale}`
          : `${location.pathname}${location.search}&language=${locale}`
        useGeneralModel.setState({ language: getLanguage(locale), locale })
        navigate(pathName)
      })
      .catch(err => sendErrorToSentry(err, { message: "Unable to set new language" }))
  }

  const currentLang = () => {
    let index = languages.findIndex(x => x.code === locale)
    if (index > -1) return languages[index]
    else return { name: "English", code: ENGLISH }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={"language-switcher"}>
      {button && (
        <Button
          className={className + " languageBtn"}
          aria-haspopup="true"
          aria-controls={Boolean(anchorEl) ? "simple-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          onClick={handleClick}
        >
          <p>{<T _str={currentLang().name} />}</p>
          <SvgImage name={"language-arrow"} />
        </Button>
      )}
      {!button && (
        <span
          className={className + " mobile-languageBtn"}
          aria-controls={Boolean(anchorEl) ? "simple-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <p> {<T _str={currentLang().name} />}</p>
          <SvgImage name={"language-arrow"} />
        </span>
      )}
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent !important",
          },
        }}
      >
        {languages.map(({ code, name }) => (
          <MenuItem
            key={code}
            onClick={() => {
              handleSelectLang(code)
            }}
          >
            <T _str={name} />
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
