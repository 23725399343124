import { PlanFormType } from "./../types/form"
import { create } from "zustand"
import { DEFAULT_KWH_CONSUMPTION } from "../constants/common"
import { persist, createJSONStorage } from "zustand/middleware"
import { PartnerLinkType, PartnerLoginType } from "../types/partner"
import { Contract } from "../types/generic"

type Actions = {
  reset: () => void
}

interface PartnerState {
  linkData: PartnerLinkType | null
  user: PartnerLoginType | null
  selectedContract: Contract | null
}

const initialState = {
  linkData: null,
  user: null,
  selectedContract: null,
} as PartnerState

export const usePartnerModel = create(
  persist<PartnerState & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(() => ({ ...initialState }))
      },
    }),
    {
      name: "partner-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
