import React, { useEffect, useState } from "react"
import CountryInputField from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./countryCode.scss"
import "../TextField/TextField.scss"
import { T } from "@transifex/react"

const CountryCodeInput = ({ showError, helpText, label, onChange, value, inputProps = {} }) => {
  const [phoneValue, setPhoneValue] = useState(0)
  const [countryCode, setCountryCode] = useState(null)

  const handleChange = (_phone, country, e) => {
    setCountryCode(country?.dialCode)
    onChange(e.target.value, country.dialCode)
  }

  useEffect(() => {
    const selectedFlag = document.querySelector(".selected-flag")
    if (selectedFlag) {
      selectedFlag.setAttribute("tabindex", "1")
    }
  }, [])

  useEffect(() => {
    if (value) {
      setPhoneValue(value)
    }
  }, [value])

  useEffect(() => {
    if (countryCode) {
      setPhoneValue(`${countryCode}`)
    }
  }, [countryCode])

  return (
    <div className={"text-field-container country-code-input"}>
      {label !== "" && (
        <div className={"label"}>
          <T _str={label} />
        </div>
      )}
      <CountryInputField
        country={"de"}
        searchClass="search-class"
        searchStyle={{ margin: "0", width: "97%", height: "30px" }}
        enableSearch
        disableSearchIcon
        onChange={handleChange}
        value={phoneValue}
        inputProps={inputProps}
      />
      {showError && (
        <div className={"error-description"}>
          <T _str={helpText} />{" "}
        </div>
      )}
    </div>
  )
}

export default CountryCodeInput
