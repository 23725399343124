import React, { useState, useEffect, useMemo } from "react"
import { T, useLocale } from "@transifex/react"
import { t } from "@transifex/native"
import { IconButton, Tooltip, useTheme } from "@mui/material"
import { useShallow } from "zustand/react/shallow"

import { SvgImage } from "../../../assets/svgs/SvgImage"
import TextField from "../../../components/TextField/TextField"
import CheckBox from "../../../components/CheckBox/CheckBox"
import {
  CHANGE_OF_SUPPLIER,
  DEFAULT_VALUE_0,
  DEFAULT_VALUE_1,
  ENGLISH,
  MARKET_LOCATION_ID,
  METER_NUMBER,
  METER_NUMBER_TYPE,
  MOBILE_WIDTH,
  MOVE,
} from "../../../constants/common"
import AutoComplete from "../../../components/SupplierInput/AutoComplete"
import { HelpOutline } from "@mui/icons-material"
import { getOldSupplier, validateMarketLocationId } from "../../../service/api"
import { useDeliveryFormModel } from "../../../models/useDeliveryFormModel"
import { useGeneralModel } from "../../../models/useGeneralModel"

const getTooltipcComponentsProps = theme => ({
  tooltip: {
    sx: {
      backgroundColor: "#16295A",
      borderRadius: "6px",
      minWidth: "500px !important",
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px !important",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "250px !important",
      },
    },
  },
})

const cancelTooltip = () => {
  return (
    <div className={"cancel-tooltip"}>
      <div className={"tool-tip-text"}>
        <p>
          {" "}
          <span className={"bold-text"}>
            <T _str={"PLEASE NOTE"} />
          </span>{" "}
          <T _str={"if either of the following applies to you, you must cancel your current contract yourself:"} />
        </p>
      </div>
      <div className={"info-list"}>
        <ul>
          <li>
            <T
              _str={
                "You’re making use of your Sonderkündigungsrecht (Special Cancellation Right), for example, after your current provider increased their price."
              }
            />
          </li>
          <li>
            <T _str={"Your cancellation period falls within the next 14 days."} />
          </li>
        </ul>
      </div>
      <div>
        <p>
          <T _str={"Feel free to contact us via the live chat (bottom right) if you have any questions."} />
        </p>
      </div>
    </div>
  )
}

interface DeliveryFormProps {
  setOpenMarketLocationDlg: (e: boolean) => void
  setOpenMeterNumberDlg: (e: boolean) => void
}

export default function DeliveryForm({ setOpenMarketLocationDlg, setOpenMeterNumberDlg }: Readonly<DeliveryFormProps>) {
  const [open, setOpen] = useState(false)
  const locale = useLocale()
  const isMobile = useMemo(() => window.innerWidth <= MOBILE_WIDTH, [window.innerWidth])

  const {
    marketLocationId,
    meterNumber,
    movingDate,
    previousSupplierId,
    cancelAsSoonAsPossible,
    terminatedFromDate,
    noMarketLocationId,
    noMeterId,
  } = useDeliveryFormModel(useShallow(state => state))

  const { electricityContract, deliveryTab1, deliveryTab2, oldSupplier, isPendingApplicationAllowed } = useGeneralModel(
    useShallow(state => state)
  )

  useEffect(() => {
    getOldSupplier().then(data => {
      if (data) {
        useGeneralModel.setState({
          oldSupplier: data.data,
        })
      }
    })
    // eslint-disable-next-line
  }, [location])

  const openTooltip = () => {
    setOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (noMarketLocationId.value) {
      useDeliveryFormModel.setState({
        marketLocationId: {
          ...marketLocationId,
          error: "",
          valid: true,
        },
      })
    }
    if (noMeterId.value) {
      useDeliveryFormModel.setState({
        meterNumber: {
          ...meterNumber,
          error: "",
          valid: true,
        },
      })
    }
  }, [noMarketLocationId.value, noMeterId.value])

  useEffect(() => {
    if (deliveryTab1 === 1) {
      useGeneralModel.setState({
        deliveryTab2: 1,
      })
    }
  }, [deliveryTab1])

  useEffect(() => {
    useGeneralModel.setState({
      electricityContractType: deliveryTab1 === DEFAULT_VALUE_0 ? CHANGE_OF_SUPPLIER : MOVE,
    })
    // eslint-disable-next-line
  }, [deliveryTab1])

  useEffect(() => {
    useGeneralModel.setState({
      previousContractTerminated: electricityContract === "yes",
    })
    // eslint-disable-next-line
  }, [electricityContract])

  useEffect(() => {
    if (deliveryTab1 === DEFAULT_VALUE_0) {
      if (deliveryTab2 === DEFAULT_VALUE_1) {
        useGeneralModel.setState({
          electricityIdentifierType: METER_NUMBER,
        })
      } else {
        useGeneralModel.setState({
          electricityIdentifierType: MARKET_LOCATION_ID,
        })
      }
    } else {
      useGeneralModel.setState({
        electricityIdentifierType: METER_NUMBER,
      })
    }
    // eslint-disable-next-line
  }, [deliveryTab2, deliveryTab1])

  useEffect(() => {
    if (marketLocationId?.value?.length !== 11) {
      useDeliveryFormModel.setState({
        marketLocationId: {
          ...marketLocationId,
          error: "",
          valid: false,
        },
      })
    } else {
      validateMarketLocationId(marketLocationId.value).then(res => {
        if (res.data !== true) {
          useDeliveryFormModel.setState({
            marketLocationId: {
              ...marketLocationId,
              valid: false,
              error: t("Invalid Marktlokations ID. Is this actually your meter number?"),
            },
          })
        } else {
          useDeliveryFormModel.setState({
            marketLocationId: {
              ...marketLocationId,
              valid: true,
              error: "",
            },
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [marketLocationId.value])

  const theme = useTheme()

  const componentsProps = getTooltipcComponentsProps(theme)

  return (
    <>
      <div className={"form-container bg-white-color"}>
        <div className={"form-title"}>
          <div className={"title bg-white-color"}>
            <T _str="Delivery Details" />
          </div>
          <div className={"line-container"}>
            <div className={"line"} />
          </div>
        </div>
        <div className={"form-content pb-0"}>
          <div className={"tab-content-title"}>
            <T _str={"DO YOU ALREADY HAVE AN EXISTING ELECTRICITY CONTRACT?"} />
          </div>
          <div className={"form-tab-container "}>
            <div
              className={"form-tab" + (deliveryTab1 === 0 ? " active" : "")}
              onClick={() => useGeneralModel.setState({ deliveryTab1: 0 })}
              style={{ height: 122 }}
            >
              <div className={"tab-title"}>
                {deliveryTab1 === 0 ? (
                  <SvgImage name={"change-supplier-active"} />
                ) : (
                  <SvgImage name={"change-supplier"} />
                )}
                <T _str={"CHANGE OF PROVIDER"} />
                <div className={"description"}>
                  <T _str={"Yes, but I want to switch to Ostrom"} />
                </div>
                <p style={{ margin: 0 }}>
                  <input type="radio" name="radio-signup" onChange={() => {}} checked={deliveryTab1 === 0} />
                </p>
              </div>
            </div>
            <div
              className={"form-tab" + (deliveryTab1 === 1 ? " active" : "")}
              onClick={() => useGeneralModel.setState({ deliveryTab1: 1 })}
              style={{ height: 122 }}
            >
              <div className={"tab-title"}>
                {deliveryTab1 === 1 ? <SvgImage name={"move-active"} /> : <SvgImage name={"move"} />}
                <T _str={"MOVING"} />
                <div className={"description"}>
                  <T _str={"No, I'm moving to a new residence"} />
                </div>
                <p style={{ margin: 0 }}>
                  <input type="radio" name="radio-signup" onChange={() => {}} checked={deliveryTab1 === 1} />
                </p>
              </div>
            </div>
          </div>

          {deliveryTab1 === 1 && (
            <div
              className={"personal-input-controller pb-0 mb-0"}
              style={{ columnGap: 40, rowGap: 0, flexDirection: "row-reverse" }}
            >
              <div className={"mt-20 single-input-mobile"} style={{ width: "48%" }}>
                <div className={"tab-content-title  mb-2"}>
                  <T _str={"DATE OF KEY HANDOVER"} />
                </div>
                <div className={"personal-text-field"} style={{ flex: 0.65 }}>
                  <TextField
                    active={true}
                    defaultValue={movingDate?.value}
                    helpText={movingDate?.error}
                    showError={movingDate?.error !== ""}
                    onChange={e => {
                      useDeliveryFormModel.setState({
                        movingDate: {
                          ...movingDate,
                          value: e.value,
                          valid: e.valid,
                          error: "",
                        },
                      })
                    }}
                    type={"date"}
                    className={"mt-0"}
                    placeholder={"DD.MM.YYYY"}
                    {...(isMobile ? { inputMode: "numeric" } : {})}
                  />
                </div>
              </div>
            </div>
          )}
          {deliveryTab1 === 0 && (
            <div>
              <div className={"tab-content-title mt-20"}>
                <T _str={"PLEASE GIVE US YOUR..."} />
              </div>
              <div className={"form-tab-container "}>
                <div
                  className={"form-tab" + (deliveryTab2 === 1 ? " active" : "")}
                  onClick={() => {
                    useGeneralModel.setState({
                      deliveryTab2: 1,
                    })
                  }}
                  style={{ height: 122 }}
                >
                  <div className={"tab-title"}>
                    {deliveryTab2 === 1 ? (
                      <SvgImage name={"meter-number-active"} />
                    ) : (
                      <SvgImage name={"meter-number"} />
                    )}
                    <i>Zählernummer</i>
                    <div className={"description"}>
                      <T _str={"(Meter ID)"} />
                    </div>
                    <p style={{ margin: 0 }}>
                      <input type="radio" name="radio-id-type" onChange={() => {}} checked={deliveryTab2 === 1} />
                    </p>
                  </div>
                </div>
                <div
                  className={"form-tab" + (deliveryTab2 === 0 ? " active" : "")}
                  onClick={() => {
                    useGeneralModel.setState({
                      deliveryTab2: 0,
                    })
                  }}
                  style={{ height: 122 }}
                >
                  <div className={"tab-title"}>
                    {deliveryTab2 === 0 ? (
                      <SvgImage name={"market-location-active"} />
                    ) : (
                      <SvgImage name={"market-location"} />
                    )}
                    <i>Marktlokations-ID</i>
                    <div className={"description"}>
                      <T _str={"(Market Location ID)"} />
                    </div>
                    <p style={{ margin: 0 }}>
                      <input type="radio" name="radio-id-type" onChange={() => {}} checked={deliveryTab2 === 0} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {deliveryTab2 === 0 && (
            <div className={"personal-input-controller input-checkbox-container"} style={{ rowGap: 0 }}>
              <div className={"single-input"}>
                <div className={"tab-content-title mt-20"}>
                  <T _str={"I HAVE MY "} />
                  <i>MARKTLOKATIONS-ID</i>
                </div>
                <div className={"personal-text-field personal-input-info"} style={{ flex: 0.65 }}>
                  <TextField
                    active={true}
                    defaultValue={marketLocationId.value}
                    helpText={marketLocationId.error}
                    showError={marketLocationId.error !== ""}
                    onChange={e => {
                      useDeliveryFormModel.setState({
                        marketLocationId: {
                          ...marketLocationId,
                          value: e.value,
                          error: e.error,
                        },
                      })
                    }}
                    className={"mt-0"}
                    placeholder={
                      locale === ENGLISH
                        ? "11 digits found on your electricity bill"
                        : "11-stellige Nummer auf deiner Stromrechnung"
                    }
                    maxLength={11}
                    {...(isMobile ? { inputMode: "numeric" } : {})}
                  />
                </div>
                <div
                  className={"personal-text-field text-secondary info-tooltip"}
                  style={{
                    flex: 0.35,
                    alignItems: "flex-start",
                    marginTop: marketLocationId.error !== "" ? (isMobile ? 33 : 18) : 0,
                  }}
                  onClick={() => {
                    setOpenMarketLocationDlg(true)
                  }}
                >
                  <div className={"warning"} style={{ marginTop: 0 }}>
                    <T _str={"Where do I find my "} />
                    <span style={{ fontStyle: "italic" }}>Marktlokations-ID</span>?
                  </div>
                </div>
              </div>
              <div className={"single-checkbox-input single-meter-checkbox"}>
                <div className={"personal-text-field"}>
                  {isPendingApplicationAllowed && (
                    <div>
                      <div className={"tab-content-title mt-20 pb-4"}>
                        <p style={{ margin: 0 }}>
                          <T _str={"I DO NOT HAVE MY "} /> <i> MARKTLOKATIONS-ID</i>
                          {locale !== ENGLISH && " NICHT"}
                        </p>
                      </div>
                      <CheckBox
                        className={"text-small"}
                        // @ts-ignore
                        label={
                          <React.Fragment>
                            <T _str={"I will submit my "} />
                            <i>Marktlokations-ID</i>
                            <T
                              _str={
                                " (Market Location ID) via the Ostrom App within the next 14 days. I am aware my application is on hold until then, and that it will be cancelled if I don’t submit my "
                              }
                            />
                            <i>Marktlokations-ID</i>
                            <T _str={" within this time."} />
                          </React.Fragment>
                        }
                        value={noMarketLocationId.value}
                        showError={marketLocationId.error !== ""}
                        errorText={marketLocationId.error}
                        labelType={"component"}
                        onChange={v => {
                          useDeliveryFormModel.setState({
                            noMarketLocationId: {
                              ...noMarketLocationId,
                              value: v,
                            },
                          })
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {deliveryTab2 === 1 && (
            <div className={"personal-input-controller input-checkbox-container "} style={{ columnGap: 40, rowGap: 0 }}>
              <div className={"single-input"}>
                <div className={"tab-content-title mt-20 pb-8"}>
                  <T _str={"I HAVE MY "} /> <i>ZÄHLERNUMMER</i>
                </div>
                {locale === ENGLISH ? (
                  <div className={"personal-text-field personal-input-info"}>
                    <TextField
                      active={true}
                      defaultValue={meterNumber.value}
                      type={METER_NUMBER_TYPE}
                      showError={false}
                      onChange={e => {
                        useDeliveryFormModel.setState({
                          meterNumber: {
                            ...meterNumber,
                            valid: false,
                            value: e.value,
                            error: "",
                          },
                          ...(e.value !== meterNumber.value ? { meterNumberContinue: false } : {}),
                        })
                      }}
                      className={"mt-0"}
                      placeholder={"Up to 20 characters found on your meter"}
                      maxLength={40}
                      data-test-id={"meter-number-input-english"}
                    />
                  </div>
                ) : (
                  <div className={"personal-text-field personal-input-info "}>
                    <TextField
                      active={true}
                      defaultValue={meterNumber.value}
                      showError={false}
                      type={METER_NUMBER_TYPE}
                      onChange={e => {
                        useDeliveryFormModel.setState({
                          meterNumber: {
                            ...meterNumber,
                            valid: false,
                            value: e.value,
                            error: "",
                          },
                          ...(e.value !== meterNumber.value ? { meterNumberContinue: false } : {}),
                        })
                      }}
                      className={"mt-0"}
                      placeholder={"Bis zu 20 Zeichen auf deinem Zähler"}
                      maxLength={40}
                      data-test-id={"meter-number-input-german"}
                    />
                  </div>
                )}
                <div
                  className={"personal-text-field  text-secondary info-tooltip"}
                  style={{ flex: 0.35, flexDirection: "row", marginTop: meterNumber.error !== "" ? 18 : 0 }}
                  onClick={() => {
                    setOpenMeterNumberDlg(true)
                  }}
                  data-test-id={"meter-number-tooltip"}
                >
                  <div className={"warning"} style={{ marginTop: 0 }}>
                    <T _str={"Where do I find my "} />
                    <span style={{ fontStyle: "italic" }}>Zählernummer</span>?
                  </div>
                </div>
              </div>

              <div className={"single-checkbox-input single-meter-checkbox"}>
                {isPendingApplicationAllowed && (
                  <div className={"personal-text-field"}>
                    <div>
                      <div className={"tab-content-title mt-20 pb-4"}>
                        <p style={{ margin: 0 }}>
                          <T _str={"I DO NOT HAVE MY "} />
                          <i> ZÄHLERNUMMER</i>
                          {locale !== ENGLISH && " NICHT"}
                        </p>
                      </div>
                      <CheckBox
                        className={"text-small"}
                        // @ts-ignore
                        label={
                          <React.Fragment>
                            <T _str={"I will submit my "} />
                            <i>Zählernummer</i>
                            <T
                              _str={
                                " (Meter ID) via the Ostrom App within the next 14 days. I am aware my application is on hold until then, and that it will be cancelled if I don’t submit my "
                              }
                            />
                            <i>Zählernummer</i>
                            <T _str={" (Meter ID) within this time."} />
                          </React.Fragment>
                        }
                        value={noMeterId.value}
                        showError={meterNumber.error !== ""}
                        errorText={meterNumber.error}
                        labelType={"component"}
                        onChange={v => {
                          useDeliveryFormModel.setState({
                            noMeterId: {
                              ...noMeterId,
                              value: v,
                            },
                          })
                        }}
                        data-test-id={"no-meter-id-checkbox"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {deliveryTab1 === 0 && (
        <div className={"form-container form-e-container bg-white-color mt-20"}>
          <div className={"form-title"}>
            <div className={"title bg-white-color"}>
              <span>
                <T _str="Current Energy Provider" />
              </span>
            </div>
            <div className={"line-container"}>
              <div className={"line"} />
            </div>
          </div>
          <div className={"form-content pb-0"}>
            <div className={"tab-content-title"}>
              <T _str={"Have you already cancelled your contract?"} />
            </div>
            <div className={"form-tab-container "}>
              <div
                className={" form-tab-electricity form-tab" + (electricityContract === "no" ? " active" : "")}
                onClick={() => useGeneralModel.setState({ electricityContract: "no" })}
                style={{ height: 73, paddingTop: 16, paddingBottom: 20 }}
              >
                <div className={"tab-title"}>
                  <T _str={"NO"} />
                  <p style={{ margin: 0 }}>
                    <input
                      type="radio"
                      name="radio-cancelled"
                      onChange={() => {}}
                      checked={electricityContract === "no"}
                    />
                  </p>
                </div>
              </div>
              <div
                style={{ height: 73, paddingTop: 16, paddingBottom: 20 }}
                className={" form-tab-electricity form-tab" + (electricityContract === "yes" ? " active" : "")}
                onClick={() => useGeneralModel.setState({ electricityContract: "yes" })}
              >
                <div className={"tab-title"}>
                  <T _str={"YES"} />
                  <p style={{ margin: 0 }}>
                    <input
                      type="radio"
                      name="radio-cancelled"
                      onChange={() => {}}
                      checked={electricityContract === "yes"}
                    />
                  </p>
                </div>
              </div>
            </div>

            {electricityContract === "no" && (
              <>
                <div className={"tab-content-title mt-20 pa-0 "}>
                  <T _str={"CURRENT ENERGY PROVIDER"} />
                </div>
                <div className={"personal-input-controller input-checkbox-container"} style={{ rowGap: 0 }}>
                  <div className={"single-input"}>
                    <div className={"personal-text-field select-trailing-icon icon-delivery"}>
                      <AutoComplete
                        active={true}
                        useDefaultValue={false}
                        defaultValue={previousSupplierId.value}
                        showError={previousSupplierId.error !== ""}
                        helpText={previousSupplierId.error}
                        className={"mt-0"}
                        labelKey={"name"}
                        indexKey={"id"}
                        onChange={e => {
                          useDeliveryFormModel.setState({
                            previousSupplierId: {
                              ...previousSupplierId,
                              value: e.value,
                              valid: e.valid,
                              // @ts-ignore
                              codeNumber: e.supplierCodeNumber,
                              error: "",
                            },
                          })
                        }}
                        placeholder={t("Select")}
                        list={oldSupplier}
                        trailingIcon={"arrow-down"}
                        data-test-id={"current-supplier-input"}
                      />
                    </div>
                  </div>
                  <div className={"single-checkbox-input single-meter-checkbox"} id={"askostromtocancelcheckbox"}>
                    <div className={"personal-text-field"} style={{ columnGap: 5, display: "inline-block" }}>
                      {electricityContract === "no" && (
                        <CheckBox
                          className={"text-lower"}
                          // @ts-ignore
                          label={
                            <React.Fragment>
                              <T
                                _str={"I would like Ostrom to cancel this contract on my behalf as soon as possible."}
                              />
                              {isMobile ? (
                                <Tooltip
                                  disableInteractive={false}
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  disableFocusListener
                                  disableHoverListener
                                  enterTouchDelay={0}
                                  leaveTouchDelay={3000}
                                  title={cancelTooltip()}
                                  placement="bottom"
                                  open={open}
                                  onClose={() => setOpen(false)}
                                  componentsProps={componentsProps}
                                >
                                  <IconButton className={"help"} onClick={openTooltip}>
                                    <HelpOutline style={{ color: "#00c1b1" }} />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  disableInteractive={false}
                                  title={cancelTooltip()}
                                  placement="bottom"
                                  componentsProps={componentsProps}
                                >
                                  <IconButton className={"help"}>
                                    <HelpOutline style={{ color: "#00c1b1" }} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </React.Fragment>
                          }
                          value={cancelAsSoonAsPossible.value}
                          showError={cancelAsSoonAsPossible.error !== ""}
                          errorText={cancelAsSoonAsPossible.error}
                          labelType={"component"}
                          onChange={v => {
                            useDeliveryFormModel.setState({
                              cancelAsSoonAsPossible: {
                                ...cancelAsSoonAsPossible,
                                valid: null,
                                error: "",
                                value: !!v,
                              },
                            })
                          }}
                          data-test-id={"cancel-contract-checkbox"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {electricityContract === "yes" && (
              <div className={"personal-input-controller"} style={{ columnGap: 5, flexDirection: "row-reverse" }}>
                <div className={"single-input-mobile"} style={{ width: "48%" }}>
                  <div className={"tab-content-title mt-20"}>
                    <T _str={"Contract End Date"} />
                  </div>
                  <div className={"personal-text-field"} style={{ flex: 0.65 }}>
                    <TextField
                      active={true}
                      defaultValue={terminatedFromDate?.value}
                      helpText={terminatedFromDate?.error}
                      showError={terminatedFromDate?.error !== ""}
                      onChange={e => {
                        useDeliveryFormModel.setState({
                          terminatedFromDate: {
                            ...terminatedFromDate,
                            value: e.value,
                            valid: e.valid,
                            error: "",
                          },
                        })
                      }}
                      type={"date"}
                      className={"mt-0"}
                      placeholder={"DD.MM.YYYY"}
                      {...(isMobile ? { inputMode: "numeric" } : {})}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
