export default function Twitter({ width = 23, height = 27, color = "#16295A" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.571 8.77855C21.4644 8.10854 22.2684 7.30454 22.8937 6.36653C22.0897 6.72386 21.1517 6.99187 20.2137 7.0812C21.1964 6.50053 21.911 5.60719 22.2684 4.49051C21.375 5.02652 20.3477 5.42852 19.3204 5.65185C18.427 4.71385 17.221 4.17784 15.881 4.17784C13.2903 4.17784 11.1909 6.27719 11.1909 8.86788C11.1909 9.22522 11.2356 9.58256 11.3249 9.93989C7.43891 9.71656 3.95488 7.84054 1.63219 5.02652C1.23019 5.69652 1.00686 6.50053 1.00686 7.39387C1.00686 9.00188 1.81086 10.4312 3.10621 11.2799C2.34687 11.2352 1.58753 11.0566 0.962189 10.6992V10.7439C0.962189 13.0219 2.5702 14.8979 4.71422 15.3446C4.35689 15.4339 3.91022 15.5233 3.50821 15.5233C3.19554 15.5233 2.92754 15.4786 2.61487 15.4339C3.19554 17.31 4.93756 18.65 6.99224 18.6946C5.38423 19.9453 3.37421 20.7047 1.18552 20.7047C0.783521 20.7047 0.426184 20.66 0.0688477 20.6153C2.12353 21.9553 4.58022 22.7147 7.26025 22.7147C15.881 22.7147 20.571 15.6126 20.571 9.40389C20.571 9.18055 20.571 9.00188 20.571 8.77855Z"
        fill={color}
      />
    </svg>
  )
}
