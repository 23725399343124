import React from "react"
import { createRoot } from "react-dom/client"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { SnackbarProvider } from "notistack"
if (window.location.hostname !== "localhost" || process.env.NODE_ENV === "production") {
  console.log = console.warn = console.error = () => {}
}

const root = createRoot(document.getElementById("root"))
root.render(
  <SnackbarProvider maxSnack={3}>
    <App />
  </SnackbarProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
