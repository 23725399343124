import { Box, IconButton } from "@mui/material"
import React, { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { SvgImage } from "../../../assets/svgs/SvgImage"

function HeaderTitle({ title, showBackBtn }: Readonly<{ title: string | ReactNode; showBackBtn?: boolean }>) {
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1)
  }
  return (
    <Box className={"column-centered"} sx={{ position: "relative", paddingTop: "60px" }}>
      <img alt="" src={require("../../../assets/images/logo-transparent.png")} className={"header-logo"} />
      <div id="connect-header">
        <h3 className="title">{title}</h3>
        {showBackBtn ? (
          <IconButton id="arrow-back" onClick={handleBack}>
            <SvgImage name="back-chevron" />
          </IconButton>
        ) : null}
      </div>
    </Box>
  )
}

export default HeaderTitle
