import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useGeneralModel } from "../models/useGeneralModel"
import { convertToMoment, isEmptyOrSpaces, scrollToSection, validateMeterNumberMininumRequirements } from "../utils"
import { t } from "@transifex/native"
import moment from "moment"
import {
  CHANGE_OF_SUPPLIER,
  FUTURE_DATE_LIMIT,
  PAST_DATE_LIMIT,
  PAYMENT_PAGE_PATH,
  ADDONS_PAGE_PATH,
} from "../constants/common"
import { useResponsive } from "./useResponsive"
import { useDeliveryFormModel } from "../models/useDeliveryFormModel"
import { FormPageStep } from "../types/generic"
import useIsPriceCapAvailable from "./useIsPriceCapAvailable"
import useCurrentStep from "./useCurrentStep"
import useValidatePlanForm from "./useValidatePlanForm"

export default function useDeliveryInfoValidation({
  setOpenMeterIdErrorDlg,
  checkMeterNumber,
}: {
  setOpenMeterIdErrorDlg: (value: React.SetStateAction<boolean>) => void
  checkMeterNumber: () => Promise<0 | 1>
}) {
  const navigate = useNavigate()

  const { validatePlanForm } = useValidatePlanForm()

  const { deviceType } = useResponsive()

  const isPriceCapAvailable = useIsPriceCapAvailable()
  const { smartMeterOfferAvailable } = useGeneralModel.getState()

  const { currentStep } = useCurrentStep()

  const errorMessage = "Required field"

  // step is usefull to navigate to a specific form page using the stepper
  const handleNext = useCallback(
    async (step?: FormPageStep, isValidationOnly?: boolean) => {
      const {
        previousSupplierId,
        terminatedFromDate,
        cancelAsSoonAsPossible,
        marketLocationId,
        noMarketLocationId,
        movingDate,
        meterNumber,
        noMeterId,
        meterNumberContinue,
      } = useDeliveryFormModel.getState()

      const { electricityContract, electricityContractType, electricityIdentifierType, isDynamic } =
        useGeneralModel.getState()

      if (step) {
        const diff = step.index - currentStep.index
        if (diff > 1) return // This condition prevents the navigation to move more than one step ahead
      }

      const planFormIsValid = validatePlanForm()

      if (!planFormIsValid) {
        return 0
      }

      let p = 1

      if (electricityContractType === CHANGE_OF_SUPPLIER) {
        if (isEmptyOrSpaces(previousSupplierId.value) && electricityContract === "no") {
          useDeliveryFormModel.setState({
            previousSupplierId: { ...previousSupplierId, valid: false, error: t("Please select current provider") },
          })
          p *= 0
        }
        if (previousSupplierId.valid === false && electricityContract === "no") {
          useDeliveryFormModel.setState({
            previousSupplierId: { ...previousSupplierId, valid: false, error: t("Please select current provider") },
          })
          p *= 0
        }
        if (electricityContract === "yes") {
          if (!terminatedFromDate.value) {
            useDeliveryFormModel.setState({
              terminatedFromDate: { ...terminatedFromDate, valid: false, error: t(errorMessage) },
            })
            p *= 0
          } else if (terminatedFromDate.error) {
            useDeliveryFormModel.setState({
              terminatedFromDate: { ...terminatedFromDate, valid: false, error: terminatedFromDate.error },
            })
            p *= 0
          } else if (!terminatedFromDate.valid) {
            useDeliveryFormModel.setState({
              terminatedFromDate: { ...terminatedFromDate, valid: false, error: t("Invalid Date - DD.MM.YYYY") },
            })
            p *= 0
          } else {
            const momentDate = convertToMoment(terminatedFromDate.value)
            const diff = moment().diff(momentDate, "days")
            const yearsDiff = moment().diff(momentDate, "days")
            const currentDate = moment()
            if (currentDate > momentDate && diff > PAST_DATE_LIMIT) {
              useDeliveryFormModel.setState({
                terminatedFromDate: {
                  ...terminatedFromDate,
                  valid: false,
                  error: t(
                    "Date of termination should not be more than 42 days in the past. Please register first with your basic supplier, then we can switch you to Ostrom."
                  ),
                },
              })
              p *= 0
            }
            if (momentDate > currentDate && Math.abs(yearsDiff) > FUTURE_DATE_LIMIT) {
              useDeliveryFormModel.setState({
                terminatedFromDate: { ...terminatedFromDate, valid: false, error: t("Please verify date") },
              })
              p *= 0
            }
          }
        }
        if (electricityContract === "no") {
          if (cancelAsSoonAsPossible.value === false) {
            useDeliveryFormModel.setState({
              cancelAsSoonAsPossible: { ...cancelAsSoonAsPossible, error: t(errorMessage) },
            })
            p *= 0
          }
        }
        if (electricityIdentifierType === "MARKET_LOCATION_ID") {
          if (isEmptyOrSpaces(marketLocationId.value) && !noMarketLocationId.value) {
            useDeliveryFormModel.setState({
              marketLocationId: {
                ...marketLocationId,
                valid: false,
                error: isEmptyOrSpaces(marketLocationId.value)
                  ? "Please provide Marktlokations-ID or check box"
                  : "The marketlocation id should be 11 characters",
              },
            })
            p *= 0
          }
          if (!marketLocationId.valid && !noMarketLocationId.value) {
            useDeliveryFormModel.setState({
              marketLocationId: {
                ...marketLocationId,
                valid: false,
                error: isEmptyOrSpaces(marketLocationId.value)
                  ? t("Please provide Market Location ID or check box")
                  : marketLocationId.value.length !== 11
                    ? t("Must be 11 digits")
                    : t("Invalid Marktlokations ID. Is this actually your meter number?"),
              },
            })
            p *= 0
          }
          if (noMarketLocationId.value) {
            useDeliveryFormModel.setState({
              marketLocationId: {
                ...marketLocationId,
                valid: true,
                error: "",
              },
            })
          }
        } else {
          if (p === 0) return 0
          if (meterNumberContinue) {
            p = 1
          } else if (
            isEmptyOrSpaces(meterNumber.value) &&
            !noMeterId.value &&
            (!marketLocationId.value || !noMarketLocationId.value)
          ) {
            useDeliveryFormModel.setState({
              meterNumber: {
                ...meterNumber,
                valid: false,
                error: t("Please provide Zählernummer or check the box"),
              },
            })
            setOpenMeterIdErrorDlg(true)
            p *= 0
          } else if (!validateMeterNumberMininumRequirements(meterNumber.value) && !noMeterId.value) {
            useDeliveryFormModel.setState({
              meterNumber: {
                ...meterNumber,
                valid: false,
                error: t("Does not meet the minimum requirements"),
              },
            })
            setOpenMeterIdErrorDlg(true)
            p *= 0
          } else if (validateMeterNumberMininumRequirements(meterNumber.value) && !meterNumber.valid) {
            p = await checkMeterNumber()
          }
        }
      } else {
        if (!movingDate.value) {
          useDeliveryFormModel.setState({ movingDate: { ...movingDate, valid: false, error: t(errorMessage) } })
          p *= 0
        } else if (movingDate.error) {
          useDeliveryFormModel.setState({ movingDate: { ...movingDate, valid: false, error: movingDate.error } })
          p *= 0
        } else if (!movingDate.valid) {
          useDeliveryFormModel.setState({
            movingDate: { ...movingDate, valid: false, error: t("Invalid Date - DD.MM.YYYY") },
          })
          p *= 0
        } else {
          const momentDate = convertToMoment(movingDate.value)
          const diff = moment().diff(momentDate, "days")
          const yearsDiff = moment().diff(momentDate, "days")
          const currentDate = moment()
          if (currentDate > momentDate && Math.abs(diff) > PAST_DATE_LIMIT) {
            useDeliveryFormModel.setState({
              movingDate: {
                ...movingDate,
                valid: false,
                error: t("The date of move cannot be more than 42 days in the past."),
              },
            })
            p *= 0
          }
          if (momentDate > currentDate && Math.abs(yearsDiff) > FUTURE_DATE_LIMIT) {
            useDeliveryFormModel.setState({
              movingDate: { ...movingDate, valid: false, error: t("Please verify date") },
            })
            p *= 0
          }
        }
        if (p === 0) return 0
        if (meterNumberContinue) {
          p = 1
        } else if (
          isEmptyOrSpaces(meterNumber.value) &&
          !noMeterId.value &&
          (!marketLocationId.value || !noMarketLocationId.value)
        ) {
          useDeliveryFormModel.setState({
            meterNumber: {
              ...meterNumber,
              valid: false,
              error: t("Please provide Zählernummer or check the box"),
            },
          })
          p *= 0
        } else if (!validateMeterNumberMininumRequirements(meterNumber.value) && !noMeterId.value) {
          useDeliveryFormModel.setState({
            meterNumber: {
              ...meterNumber,
              valid: false,
              error: t("Does not meet the minimum requirements"),
            },
          })
          setOpenMeterIdErrorDlg(true)
          p *= 0
        } else if (validateMeterNumberMininumRequirements(meterNumber.value) && !meterNumber.valid) {
          p = await checkMeterNumber()
        }
      }
      scrollToSection("stepper-container-section", deviceType.mobile)
      if (p === 0) {
        return 0
      }

      const showPriceCap = !isDynamic && isPriceCapAvailable
      // scroll to section on redirect
      scrollToSection("stepper-container-section")
      if (isValidationOnly) {
        return p
      }
      if (step) {
        navigate(`${step.url}${window.location.search}`)
      } else {
        const path = showPriceCap || smartMeterOfferAvailable ? ADDONS_PAGE_PATH : PAYMENT_PAGE_PATH
        navigate(path + window.location.search)
      }
    },
    [deviceType, navigate, setOpenMeterIdErrorDlg, checkMeterNumber]
  )

  return { handleNext }
}
