import React, { useCallback, useEffect, useState } from "react"
import "./PlanForm.scss"
import TextField from "../TextField/TextField"
import { t } from "@transifex/native"

import { POSTAL_CODE_LENGTH } from "../../constants/common"
import { InitFormValueTypeString } from "../../types/form"
import { City } from "../../types/generic"
import { debounce } from "lodash"
import { getCitiesByPostalCode } from "../../service/api"
import { useGeneralModel } from "../../models/useGeneralModel"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useShallow } from "zustand/react/shallow"
import { padPostalCodeZeros } from "../../utils"
import { useResponsive } from "../../hooks/useResponsive"

interface PostcodeSection {
  style?: React.CSSProperties
  locale: string
}

const PostcodeSection = (props: PostcodeSection) => {
  const [formattedPostCode, setFormattedPostCode] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const [showDropdownLoading, setShowDropdownLoading] = useState(false)
  const [postcodeError, setPostcodeError] = useState("")

  const { cityName, postalCode = null } = usePlanFormModel(
    useShallow(state => ({
      usage: state.usage,
      cityId: state.cityId,
      cityName: state.cityName,
      postalCode: state.postalCode,
    }))
  )

  const { cities } = useGeneralModel(useShallow(state => state))

  const { deviceType } = useResponsive()

  // debounce postal code change
  const debouncedPostalCodeChange = useCallback(
    debounce((postalCodeValue: InitFormValueTypeString) => {
      handlePostalCode(postalCodeValue)
    }, 500),
    []
  )

  const fetchPostalCodeCities = async (postalCode: string) => {
    if (postalCode.length === 5) {
      setShowDropdown(true)
      setShowDropdownLoading(true)
      getCitiesByPostalCode(postalCode).then(({ data }) => {
        if (data?.length === 0) {
          setShowDropdownLoading(false)
          setShowDropdown(false)
          setPostcodeError(t("Postcode does not exist"))
          useGeneralModel.setState({ cities: [] })
          return () => {}
        }
        setShowDropdownLoading(false)
        setPostcodeError("")
        useGeneralModel.setState({ cities: data })
      })
    }
  }

  const handlePostalCode = (postalCodeValue: InitFormValueTypeString) => {
    const rate = useGeneralModel.getState().rate
    if (postalCodeValue.value.replace(/\s+/g, "") !== postalCodeValue.value) {
      return
    }
    setFormattedPostCode(postalCodeValue.value.replace(/\s+/g, ""))
    // @ts-ignore
    const isValidPostalcode = [...postalCodeValue.value].every(c => "0123456789".includes(c))
    if (isValidPostalcode && postalCodeValue.value.length === 5) {
      setPostcodeError("")
      fetchPostalCodeCities(postalCodeValue.value)
    }
    if (rate) {
      useGeneralModel.setState({ rate: { ...rate, ostrom: null } })
    }
    if (!postalCodeValue.value) {
      clearPostalCode()
    }
  }

  const clearPostalCode = () => {
    const rate = useGeneralModel.getState().rate
    setFormattedPostCode("")
    setPostcodeError("")
    setShowDropdown(false)
    usePlanFormModel.setState({ cityId: null, cityName: "", postalCode: null })
    if (rate) {
      useGeneralModel.setState({ rate: { ...rate, ostrom: null } })
    }
  }

  useEffect(() => {
    const handleChange = () => {
      if (postalCode && cityName) {
        setFormattedPostCode(`${postalCode} ${cityName}`)
      }
    }

    handleChange()
  }, [postalCode, cityName])

  const setSelectedCity = (city: City) => () => {
    setFormattedPostCode(`${postalCode} ${city.name}`)
    const postCode = padPostalCodeZeros(city.postcode, POSTAL_CODE_LENGTH)
    usePlanFormModel.setState({ cityId: city.id, cityName: city.name, postalCode: postCode })
    useGeneralModel.setState({ cities: [] })
    setShowDropdown(false)
    setShowDropdownLoading(false)
  }

  return (
    <div className="input-controller" style={props.style ? props.style : {}}>
      <TextField
        type={"text"}
        onChange={e => debouncedPostalCodeChange(e)}
        label={t("POSTCODE")}
        leadIcon={"Location"}
        trailingIcon={"close"}
        showError={postcodeError !== ""}
        helpText={postcodeError}
        placeholder={t("Enter Postcode")}
        defaultValue={formattedPostCode}
        clearInput={clearPostalCode}
        autoComplete={"new-password"}
        id={"search-postcode"}
        data-test-id={deviceType.mobile ? "postcode-input-mobile" : "postcode-input"}
      />

      {showDropdown && (
        <div className="input-controller" style={props.style}>
          <div
            className={"dropdown-container"}
            data-test-id={deviceType.mobile ? "postcode-dropdown-mobile" : "postcode-dropdown"}
          >
            {showDropdownLoading && <p>loading...</p>}
            {cities?.length !== 0 && (postalCode || formattedPostCode) && (
              <div className={"dropdown-cities-list"}>
                {cities?.map(city => (
                  <p
                    // @ts-ignore
                    onClick={setSelectedCity(city)}
                    key={city.name}
                  >
                    {`${postalCode || formattedPostCode.replace(/\s+/g, "")} ${city.name}`}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(PostcodeSection)
