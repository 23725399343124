import React, { useState, useEffect } from "react"
import { T } from "@transifex/react"
import "./JoinButton.scss"
import { DynamicPricingDialog } from "../DynamicPricingDialog/DynamicPricingDialog"
import { DYNAMIC_MIN_USAGE } from "../../constants/common"
import Tooltip from "@mui/material/Tooltip"
import DynamicTooltipBody from "../DynamicTooltipBody/DynamicTooltipBody"
import { useGeneralModel } from "../../models/useGeneralModel"
import { ProductCode } from "../../types/generic"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useShallow } from "zustand/react/shallow"
import { ClickAwayListener, useTheme } from "@mui/material"

const getTooltipcComponentsProps = theme => ({
  tooltip: {
    sx: {
      backgroundColor: "#16295A",
      borderRadius: "6px",
      minWidth: "500px !important",
      [theme.breakpoints.down("sm")]: {
        minWidth: "280px !important",
        position: "absolute",
        top: 0,
        left: "calc(100% - 100px)",
        fontSize: 14,
      },
      320: {
        minWidth: "250px !important",
        background: "red",
      },
    },
  },
})

const JoinButton = ({ title, onClickJoin, isDynamic = false, testID = "" }) => {
  const [isDlgOpen, setIsDlgOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleClick = () => {
    if (!isDisabled) {
      const selectedProductCode = isDynamic ? ProductCode.basisDynamic : ProductCode.basisProdukt
      useGeneralModel.setState({ productCode: selectedProductCode })
      if (isDynamic) {
        setIsDlgOpen(true)
        return
      }
      onClickJoin()
    }
  }

  const handleOnOk = () => {
    onClickJoin()
  }

  const handleOnBack = () => {
    setIsDlgOpen(false)
  }

  const usage = usePlanFormModel(useShallow(state => state.usage))

  useEffect(() => {
    if (usage) {
      setIsDisabled(+usage < DYNAMIC_MIN_USAGE && isDynamic)
    } else {
      setIsDisabled(false)
    }
  }, [usage, isDynamic])

  useEffect(() => {
    window.addEventListener("click", e => {
      // @ts-ignore
      if (e.target.dataset?.testId !== "join-ostrom-btn-mobile-dynamic") {
        setOpenTooltip(false)
      }
    })
  }, [])

  const theme = useTheme()

  const componentsProps = getTooltipcComponentsProps(theme)

  return (
    <>
      <DynamicPricingDialog
        openStatus={isDlgOpen}
        onOK={handleOnOk}
        onBack={handleOnBack}
        onClose={() => {
          setIsDlgOpen(false)
        }}
        testID={testID}
      />
      <ClickAwayListener
        onClickAway={() => {
          setOpenTooltip(false)
        }}
      >
        <Tooltip
          disableInteractive={false}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => {
            setOpenTooltip(false)
          }}
          open={openTooltip}
          disableHoverListener
          disableFocusListener
          disableTouchListener
          title={<DynamicTooltipBody isDynamic={!isDisabled} />}
          placement={"top"}
          componentsProps={componentsProps}
        >
          <div
            onClick={() => {
              if (isDisabled) {
                setOpenTooltip(true)
              }
            }}
            data-label={"dynamic-tooltip"}
            className={"tooltip-container"}
            onBlur={() => {
              setOpenTooltip(false)
            }}
          >
            <button
              className="join-button-container"
              style={{ backgroundColor: isDisabled && "#E7E8EA" }}
              onClick={handleClick}
              data-test-id={testID}
            >
              <T _str={title} />{" "}
              <span>
                <T _str={"(in only 5 minutes)"} />
              </span>
            </button>
          </div>
        </Tooltip>
      </ClickAwayListener>
    </>
  )
}

export default JoinButton
