import React from "react"
import propTypes from "prop-types"
import { T } from "@transifex/react"

import { SvgImage } from "../../assets/svgs/SvgImage"

import "./style.scss"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import ReviewComponent from "../../components/Reviews"

const MaintenancePage = () => {
  return (
    <NavigationWrapper>
      <div className="maintenance-container">
        <div className="wrapper">
          <div className="text-error">
            <SvgImage name="maintenance" className="footer-logo w-inline-block" />
          </div>
          <br />
          <br />
          <div className="title-text">
            <T _str={`Sorry, our Tariff Calculator is\ncurrently down for maintenance`} />
          </div>
          <br />
          <br />
          <div className="maintenance-text">
            <T
              _str={
                "We’ll be back online soon! If you have any urgent questions, contact us via the live chat at the bottom right of your screen."
              }
            />
          </div>
          <br />
          <br />
          <div className="maintenance-text">
            <T
              _str={
                "If you’d like to be notified once we’re live again, leave your email address in the chat or send a short email to"
              }
            />{" "}
            <a className="mail-link" href={"mailto:hallo@ostrom.de"}>
              <span>
                <T _str={"hallo@ostrom.de"} />
              </span>
            </a>
          </div>
        </div>
      </div>
      <ReviewComponent />
    </NavigationWrapper>
  )
}

export default MaintenancePage

MaintenancePage.propTypes = {
  deviceType: propTypes.object,
}
