import React, { useEffect, useState } from "react"
import "./ReferralInput.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useNavigate } from "react-router-dom"
import { getReferralType } from "../../service/api"
import { REFERRAL_CODE_LENGTH, REFERRAL_CODE_TYPE } from "../../constants/common"
import { getReferralInfo } from "../../utils"

const ReferralInput = ({ value, valid, setError, setIsValid, getCode, calculate }) => {
  const [code, setCode] = useState("")

  const navigate = useNavigate()

  useEffect(() => {
    setCode(value)
  }, [value])

  const setRefValue = value => {
    setCode(value)
    getCode(value)
    const url = `?referralCode=${value}`
    navigate(url)
  }

  const onChangeCode = e => {
    const value = e.target.value
    if (value.length === REFERRAL_CODE_LENGTH) {
      getReferralType(value).then(({ data }) => {
        const refType = data
        const [valid, errMsg] = getReferralInfo(refType?.status)
        setError(errMsg)
        if (
          refType?.status === REFERRAL_CODE_TYPE.UNKNOWN ||
          refType?.status === REFERRAL_CODE_TYPE.INACTIVE ||
          refType?.status === REFERRAL_CODE_TYPE.EXPIRED
        ) {
          const url = `?referralCode=${""}`
          navigate(url)
          setCode("")
          getCode("")
        } else {
          setRefValue(value)
          setIsValid(valid)
          setError("")
        }
      })
      return
    }
    setRefValue(value)
  }

  return (
    <>
      <div className={"referral-input"}>
        <input
          data-test-id={"referral-page-refer-input"}
          value={code}
          onChange={onChangeCode}
          onBlur={calculate}
          disabled
        />
        {valid && <SvgImage name={"valid"} />}
      </div>
    </>
  )
}

export default ReferralInput
