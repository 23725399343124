import React, { useCallback } from "react"
import { usePaymentModel } from "../models/usePaymentFormModel"
import { formatIban, isEmptyOrSpaces } from "../utils"
import { t } from "@transifex/native"
import { IBAN_LENGTH } from "../constants/common"
import { validateIban } from "../service/api"
import { InitFormValueTypeString } from "../types/form"

export default function useValidateIban() {
  const validateIbanValue = useCallback((ibanValue: string, iban: InitFormValueTypeString) => {
    if (isEmptyOrSpaces(ibanValue)) {
      return () => {}
    }
    //check if iban is German
    if (ibanValue.length >= 2 && !ibanValue.toLowerCase().startsWith("de")) {
      usePaymentModel.setState({
        iban: {
          ...iban,
          valid: false,
          error: t(
            'Please choose "TRANSFER" for now. Afterwards, our customer support will be happy to add your international IBAN.'
          ),
        },
      })
      return
    }

    if (ibanValue.replaceAll("_", "").length === IBAN_LENGTH) {
      if (!ibanValue.toLowerCase().startsWith("de")) {
        usePaymentModel.setState({
          iban: {
            ...iban,
            valid: false,
            error: t(
              'Please choose "TRANSFER" for now. Afterwards, our customer support will be happy to add your international IBAN.'
            ),
          },
        })
        return
      }
      validateIban(formatIban(ibanValue).replace(/\s+/g, "")).then(res => {
        usePaymentModel.setState({ ibanValid: res.data })
        if (res.data === false) {
          usePaymentModel.setState({ ibanError: t("It is not valid") })
        } else {
          usePaymentModel.setState({ ibanError: "" })
        }
      })
    } else {
      usePaymentModel.setState({ ibanValid: false })
    }
  }, [])

  return {
    validateIbanValue,
  }
}
