import { initFormValue } from "./../utils/index"
import { create } from "zustand"
import { InitFormValueTypeString } from "../types/form"
import { PaymentMethod } from "../types/generic"
import { persist, createJSONStorage } from "zustand/middleware"

interface PaymentState {
  iban: InitFormValueTypeString
  accountHolderName: InitFormValueTypeString
  billingFirstName: InitFormValueTypeString
  billingSurname: InitFormValueTypeString
  companyName: InitFormValueTypeString
  billingStreet: InitFormValueTypeString
  billingHouseNumber: InitFormValueTypeString
  billingZip: InitFormValueTypeString
  billingCity: InitFormValueTypeString
  paymentMethod: PaymentMethod
  isDifBillingAdr: boolean
  ibanValid?: boolean
  ibanError?: string
}

const initialState = {
  iban: initFormValue,
  accountHolderName: initFormValue,
  billingFirstName: initFormValue,
  billingSurname: initFormValue,
  companyName: initFormValue,
  billingStreet: initFormValue,
  billingHouseNumber: initFormValue,
  billingZip: initFormValue,
  billingCity: initFormValue,
  paymentMethod: "DIRECT_DEBIT",
  isDifBillingAdr: false,
  ibanError: "",
  ibanValid: null,
} as PaymentState

type Actions = {
  reset: () => void
}

export const usePaymentModel = create(
  persist<PaymentState & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: "payment-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
