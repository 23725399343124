import React, { useEffect } from "react"
import { T } from "@transifex/react"
import { Formik } from "formik"
import * as Yup from "yup"
import { t } from "@transifex/native"

import { Container, IconButton, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { Close, VisibilityOff, VisibilityOutlined } from "@mui/icons-material"
import "./style.scss"
import { PartnerApi } from "../../service/partnerApi"
import { SvgImage } from "../../assets/svgs/SvgImage"
import LoadingButton from "../../components/LoadingButton"
import { usePartnerModel } from "../../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { useNavigate } from "react-router-dom"
import { enqueueSnackbar } from "notistack"
import PartnerContainer from "./component/PartnerContainer"
import HeaderTitle from "./component/HeaderTitle"
import { getLinkDataParams } from "../../utils"

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(t("Invalid email address")).required(t("Email is required")),
  password: Yup.string().strict().trim(t("Remove whitespace")).min(4).required(t("Password is required")),
})

const PartnerLoginIndex = () => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [loginErr, setLoginErr] = React.useState("")

  const linkData = usePartnerModel(useShallow(state => state.linkData))

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const navigate = useNavigate()

  useEffect(() => {
    usePartnerModel.setState({ linkData: null, user: null, selectedContract: null })
    const urlParams = new URLSearchParams(window.location.search)
    const linkId = urlParams.get("id")
    if (linkId) {
      PartnerApi.getApplicationLinkData(linkId).catch(e => {
        usePartnerModel.setState({ linkData: null, user: null, selectedContract: null })
        navigate("connection-error")
      })
    } else {
      navigate("connection-error")
    }
  }, [])

  const handleLogin = async (values: { email: string; password: string }) => {
    if (linkData) {
      const res = await PartnerApi.applicationLogin(linkData.id, values).catch(e => {
        usePartnerModel.setState({ user: null })
        setLoginErr(e?.response?.data?.message ?? e?.response?.data?.error)
      })
      if (res) {
        navigate("choose-contract")
      }
    } else {
      usePartnerModel.setState({ linkData: null, user: null, selectedContract: null })
      enqueueSnackbar("Link data not found", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
      })
    }
  }

  const linkDataUrl = getLinkDataParams(linkData)

  return (
    <PartnerContainer>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        validateOnMount={true}
        onSubmit={async values => {
          await handleLogin(values)
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, isValid, touched, values, errors }) => {
          const emailError = (touched.email && errors.email) || loginErr
          const passwordError = (touched.password && errors.password) || loginErr
          return (
            <Box data-test-id="loginForm" className={"column-centered"}>
              <Container maxWidth="sm">
                <HeaderTitle title={<T _str="Connect To Ostrom" />} />
              </Container>
              <Container maxWidth="sm" className={"card shadowProp"}>
                <Box
                  sx={{
                    backgroundColor: emailError || values.email ? "#fff" : "#F0F7F7",
                    border: emailError ? `2px solid #E91B1B` : values.email ? "2px solid #F0F7F7" : "none",
                  }}
                  className={"inputbox"}
                >
                  <SvgImage name="account-circle" styles={{ width: "20px", height: "20px" }} />
                  <TextField
                    id="input-with-sx"
                    value={values.email}
                    name="email"
                    label={<T _str="Email" />}
                    onBlur={handleBlur("email")}
                    onChange={handleChange("email")}
                    onFocus={() => setLoginErr("")}
                    variant="standard"
                    fullWidth
                    error={!!emailError}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      "mb": "10px",
                      "marginLeft": "7px",
                      "& input": {
                        color: emailError ? "#E91B1B" : "#0B152D",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 700,
                        fontSize: "16px",
                      },
                      "& label": {
                        "color": "#6D7381",
                        "fontFamily": "Inter, sans-serif",
                        "fontWeight": 600,
                        "fontSize": "16px",
                        "lineHeight": "24px",
                        "top": "-2px",
                        // transform: "translate(0, 5px) scale(0.7)",
                        "transform": values.email ? "translate(0, 5px) scale(0.7)" : "translate(0, 20px) scale(1)",
                        "&.Mui-focused": {
                          "color": "#6D7381",
                          "transform": "translate(0, 5px) scale(0.7)",
                          "&.Mui-error": {
                            color: "#E91B1B",
                          },
                        },
                        "&.Mui-error": {
                          color: "#E91B1B",
                        },
                      },
                    }}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      handleChange("email")("")
                    }}
                  >
                    {!!emailError && values.email ? <Close /> : null}
                  </IconButton>
                </Box>
                <span className={"auth-error"}>{t(emailError || loginErr)}</span>
                <Box
                  sx={{
                    backgroundColor: passwordError || values.password ? "#fff" : "#F0F7F7",
                    border: passwordError ? `2px solid #E91B1B` : values.password ? "2px solid #F0F7F7" : "none",
                    mt: 1.5,
                  }}
                  className={"inputbox"}
                >
                  <SvgImage name="lock-outline" styles={{ width: "20px", height: "20px" }} />
                  <TextField
                    id="input-with-sx"
                    label={<T _str="Password" />}
                    name="password"
                    variant="standard"
                    onBlur={handleBlur("password")}
                    onChange={handleChange("password")}
                    onFocus={() => setLoginErr("")}
                    error={!!passwordError}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      "mb": "10px",
                      "marginLeft": "5px",
                      "& input": {
                        color: passwordError ? "#E91B1B" : "#0B152D",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 700,
                        fontSize: "16px",
                      },
                      "& label": {
                        "color": "#6D7381",
                        "fontFamily": "Inter, sans-serif",
                        "fontWeight": 600,
                        "fontSize": "16px",
                        "lineHeight": "24px",
                        "top": "-2px",
                        "transform": values.password ? "translate(0, 5px) scale(0.7)" : "translate(0, 20px) scale(1)",
                        "&.Mui-focused": {
                          "color": "#6D7381",
                          "transform": "translate(0, 5px) scale(0.7)",
                          "&.Mui-error": {
                            color: "#E91B1B",
                          },
                        },
                        "&.Mui-error": {
                          color: "#E91B1B",
                        },
                      },
                    }}
                  />
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <VisibilityOutlined />}
                  </IconButton>
                </Box>
                <span className={"auth-error"}>{t(passwordError || loginErr)}</span>
                <LoadingButton
                  // @ts-ignore
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                  variant="contained"
                  fullWidth
                  id="main-btn"
                  sx={{
                    mt: 1.5,
                  }}
                >
                  <T _str={"Login"} />
                </LoadingButton>
              </Container>

              <Container maxWidth="sm">
                <Box sx={{ mt: 1 }} className={"column-centered footer-div"}>
                  <p className={linkData?.referralCode ? "infoText" : "not-a-customer"}>
                    <T _str="Not an Ostrom customer yet?" />
                  </p>
                  {!linkData?.referralCode ? (
                    <p className={"infoText"}>
                      <T _str="Download our app to sign up" />
                    </p>
                  ) : (
                    <a href={`/?${linkDataUrl}`} className={"signupText"}>
                      <T _str={"Sign up now"} />
                    </a>
                  )}
                </Box>
                {!linkData?.referralCode ? (
                  <div className={"phone-app-container"}>
                    <a href="https://app.eu.adjust.com/1aeovwka" target={"_blank"} rel="noreferrer">
                      <SvgImage name={"AppStore"} />
                    </a>
                    <a href="https://app.eu.adjust.com/1aeovwka" target={"_blank"} rel="noreferrer">
                      <SvgImage name={"Android"} />
                    </a>
                  </div>
                ) : null}
              </Container>
            </Box>
          )
        }}
      </Formik>
    </PartnerContainer>
  )
}

export default PartnerLoginIndex
