import { initFormValue } from "./../utils/index"
import { create } from "zustand"
import { InitFormValueTypeString } from "../types/form"
import { persist, createJSONStorage } from "zustand/middleware"

interface UserInfoState {
  email: InitFormValueTypeString
  firstName: InitFormValueTypeString
  surname: InitFormValueTypeString
  dob: InitFormValueTypeString
  companyName: InitFormValueTypeString
  phone: InitFormValueTypeString
  street: InitFormValueTypeString
  houseNumber: InitFormValueTypeString
}

const initialState = {
  email: initFormValue,
  firstName: initFormValue,
  surname: initFormValue,
  dob: initFormValue,
  companyName: initFormValue,
  phone: initFormValue,
  street: initFormValue,
  houseNumber: initFormValue,
}

type Actions = {
  reset: () => void
}

export const useUserInfoModel = create(
  persist<UserInfoState & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
    }),
    {
      name: "user-info-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
