import React, { useMemo, useState } from "react"
import "./PlanForm.scss"
import { T } from "@transifex/react"
import { ClickAwayListener, IconButton, Tooltip, useTheme } from "@mui/material"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { t } from "@transifex/native"

import { LETTER_M, MONTH, YEAR } from "../../constants/common"
import useRateCalculator from "../../hooks/useRateCalculator"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useShallow } from "zustand/react/shallow"
import { useResponsive } from "../../hooks/useResponsive"
import { getCommaSeparatedTwoDecimalsNumber } from "../../utils/helpers"
import { STR_RATE_NULL, STR_RATE_OSTROM, STR_RATE_TARIFF } from "../../constants/global"

const getTooltipcComponentsProps = theme => ({
  tooltip: {
    sx: {
      backgroundColor: "#16295A",
      borderRadius: "6px",
      minWidth: "480px !important",
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px !important",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "250px !important",
      },
    },
  },
})

interface TariffTooltipTextProps {
  currentPlan: string
  unitPrice: number
  unitPricePerKwh: number
  energyNetworkFee: number
  basicFee: number
  kgCO2Emissions: number
}

const TariffTooltipText = (props: TariffTooltipTextProps) => {
  return (
    <div className="tariff-tooltip-container">
      <table>
        <tbody>
          <tr>
            <th>
              <T _str={"Unit Price"} />
              <div>
                (<T _str={"Today's Average"} />)
              </div>
            </th>
            <td>
              <span>{getCommaSeparatedTwoDecimalsNumber(props.unitPricePerKwh)}</span>
              {" ct/kWh"}
              <div>
                <span>{props.unitPrice}</span> €/
                <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Grid Fees"} />
            </th>
            <td>
              <span>{props.energyNetworkFee}</span>
              {" €/"}
              <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Basic Fee"} />
            </th>
            <td>
              <span>{getCommaSeparatedTwoDecimalsNumber(props.basicFee)}</span>
              {" €/"}
              <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Emission Savings"} />
            </th>
            <td>
              <span>-{props.kgCO2Emissions}</span>
              {" kg CO₂"}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 10 }}>
        <T _str={"All prices incl. VAT"} />
      </div>
    </div>
  )
}

interface AmountDisplaySectionProps {
  extraClass: string
  setReferralAmount?: (x: number) => void
}

const NewAmountDisplaySection = (props: AmountDisplaySectionProps) => {
  const { rate, currentPlan, localRate } = useGeneralModel(useShallow(state => state))

  const [openTariffTooltip, setOpenTariffTooltip] = useState(false)

  const { deviceType } = useResponsive()

  const { unitPrice, unitPricePerKwh, energyNetworkFee, basicFee, kgCO2Emissions } = useRateCalculator()

  const getRateData = (key: string, provider: string, planKey = "") => {
    if (planKey === "") planKey = currentPlan === LETTER_M ? MONTH : YEAR
    if (rate && rate?.[provider] && rate?.[provider]?.[planKey] && rate?.[provider]?.[planKey]?.[key]) {
      if (key === STR_RATE_TARIFF) {
        if (isNaN(rate[provider][planKey][key])) {
          props.setReferralAmount(0)
          return 0
        }
        if (parseInt(rate[provider][planKey][key]) === 0) props.setReferralAmount(0)
      }
      const totalRate = parseInt(getCommaSeparatedTwoDecimalsNumber(rate[provider][planKey][key]))
      if (totalRate !== +localRate) {
        useGeneralModel.setState({ localRate: totalRate })
      }

      return totalRate
    }
    return STR_RATE_NULL
  }

  // @ts-ignore
  const regularCost = parseInt(getRateData(STR_RATE_TARIFF, STR_RATE_OSTROM, "monthly")) || localRate

  const selectedTariff = useMemo(() => {
    if (rate?.ostrom) {
      const productKey = 0
      const { tariff = 0 } = rate?.ostrom?.[productKey] ?? {}
      return tariff
    }
  }, [rate])

  const amount = selectedTariff ?? regularCost

  const tariffToolTip = (
    <TariffTooltipText
      currentPlan={currentPlan}
      unitPricePerKwh={unitPricePerKwh}
      unitPrice={unitPrice}
      energyNetworkFee={energyNetworkFee}
      basicFee={basicFee}
      kgCO2Emissions={kgCO2Emissions}
    />
  )

  const theme = useTheme()

  const componentsProps = getTooltipcComponentsProps(theme)

  return (
    <div className={`input-controller amount-display-block ${props.extraClass}`}>
      <div>
        <div className="amount-display-label">
          <T _str={t("SIMPLYFAIR TARIFF")} />
          {!deviceType.mobile ? (
            <Tooltip
              disableInteractive={false}
              placement={window.innerWidth > 1200 ? "top" : "top-end"}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title={tariffToolTip}
              componentsProps={componentsProps}
            >
              <IconButton>
                <SvgImage name={"info"} className={"info-icon"} />
              </IconButton>
            </Tooltip>
          ) : (
            <ClickAwayListener onClickAway={() => setOpenTariffTooltip(false)}>
              <Tooltip
                disableInteractive={false}
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top-end"
                open={openTariffTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tariffToolTip}
                componentsProps={componentsProps}
              >
                <IconButton onClick={() => setOpenTariffTooltip(true)}>
                  <SvgImage name={"info"} />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          )}
        </div>
        <div className="amount-display-field">
          <div className="amount">
            {amount} €
            <span>
              <span className="plan">
                /<T _str={"month"} />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NewAmountDisplaySection)
