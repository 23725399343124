import { T } from "@transifex/react"
import Mobile from "../../assets/svgs/mobile.svg"
import GooglePlay from "../../assets/svgs/googlePlay.svg"
import AppStore from "../../assets/svgs/AppStore.svg"
import "./DownloadApp.scss"
import { APP_STORE_URL, PLAY_STORE_URL } from "../../constants/common"

const DownloadApp = () => {
  return (
    <div className={"download-section"}>
      <div className={"download-header"}>
        <h2>
          <T _str={"Digitizing The Energy Market"} />{" "}
        </h2>
        <p>
          <T
            _str={
              'Because let’s be honest: it’s overdue. Simply sign up on our website and manage everything in our app. No need for "email templates" or translation apps!'
            }
          />{" "}
        </p>
      </div>
      <div className={"download-digital"}>
        <div className={"download-image"}>
          <img src={Mobile} alt={"Digital"} />
        </div>
        <div className={"download-list"}>
          <div className={"download-list-item"}>
            <div className={"download-item-header"}>
              <h3>
                <T _str={"All In One Place"} />
              </h3>
              <p>
                <T
                  _str={
                    "Access your annual invoices, submit and track your meter reading, and adapt your monthly payments all within the Ostrom app."
                  }
                />
              </p>
            </div>
          </div>
          <div className={"download-list-item"}>
            <div className={"download-item-header"}>
              <h3>
                <T _str={"On-Demand Support"} />
              </h3>
              <p>
                <T
                  _str={
                    "With our live customer support chat, get answers to your questions within seconds — not hours or days!"
                  }
                />
              </p>
            </div>
          </div>
          <div className={"download-list-item"}>
            <div className={"download-item-header"}>
              <h3>
                <T _str={"English? German? You Choose!"} />
              </h3>
              <p>
                <T
                  _str={
                    "Deutsche Sprache, schwere Sprache. We're a proud German startup, but we also know our language ain’t easy. To maintain full transparency we offer everything — from signup to contract — in English as well."
                  }
                />
              </p>
            </div>
          </div>
          <div className={"download-svg"}>
            <div className={"download-svg-item"}>
              <a href={APP_STORE_URL} target={"_blank"} rel="noreferrer">
                <img src={AppStore} alt={"app-store"} />
              </a>
            </div>
            <div className={"download-svg-item"} alt={"google-play"}>
              <a href={PLAY_STORE_URL} target={"_blank"} rel="noreferrer">
                <img src={GooglePlay} alt={"google-play"} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DownloadApp
