import React from "react"
import { SvgImage } from "../../assets/svgs/SvgImage"

export const GreenTick = () => {
  return (
    <div className="row">
      <SvgImage name="green-tick" />
    </div>
  )
}
export const GreyTick = () => {
  return (
    <div className="row">
      <SvgImage name="tickcirclegrey" />
    </div>
  )
}

export const WhiteTick = () => {
  return (
    <div className="row">
      <SvgImage name="white-tick" />
    </div>
  )
}

export const CancelCirle = () => {
  return (
    <div className="row">
      <SvgImage name="cancel-circle" />
    </div>
  )
}
export const CloseIconRed = () => {
  return (
    <div className="row">
      <SvgImage name="close-icon-red" />
    </div>
  )
}
