import { PlanFormType } from "./../types/form"
import { create } from "zustand"
import { DEFAULT_KWH_CONSUMPTION } from "../constants/common"
import { persist, createJSONStorage } from "zustand/middleware"

type Actions = {
  reset: () => void
}

const initialState = {
  usage: DEFAULT_KWH_CONSUMPTION,
}

export const usePlanFormModel = create(
  persist<PlanFormType & Actions>(
    set => ({
      ...initialState,
      reset: () => {
        set(() => ({ ...initialState }))
      },
    }),
    {
      name: "plan-form-storage",
      storage: createJSONStorage(() => sessionStorage),
      skipHydration: true,
    }
  )
)
