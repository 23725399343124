import { styles } from "./styles"
import { T } from "@transifex/react"
import React from "react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import PropTypes from "prop-types"
import { getCommaSeparatedTwoDecimalsNumber } from "../../utils/helpers"

export const DayGraphFlyOut = ({ x, y, datum }) => (
  <g style={{ pointerEvents: "none" }}>
    <foreignObject width={152} height={105} x={x} y={y} style={styles.customTooltipWrapper}>
      <div style={styles.tooltipRow}>
        <p style={styles.tooltipBoldText}>{`${datum.labelData.date}`}</p>
        <p style={styles.tooltipBoldText}>{`${datum.labelData.time}`}</p>
      </div>
      <div style={{ ...styles.tooltipRow, marginBottom: 0 }}>
        <p style={styles.tooltipLightText}>
          <T _str={"Market Price"} />
        </p>
        <p style={{ ...styles.tooltipLightText, color: "#0B152D" }}>
          {getCommaSeparatedTwoDecimalsNumber(datum.labelData.spotPrice)}
        </p>
      </div>
      <div style={{ ...styles.tooltipRow, marginBottom: 0 }}>
        <p style={styles.tooltipLightText}>
          <T _str={"Taxes & Levies"} />
        </p>
        <p style={{ ...styles.tooltipLightText, color: "#0B152D" }}>
          {getCommaSeparatedTwoDecimalsNumber(datum.labelData.taxesAndLevies)}
        </p>
      </div>
      <div style={{ height: 4 }}>
        <SvgImage name={"victory-tooltip-path"} styles={{ height: "auto" }} />
      </div>

      <div style={{ ...styles.tooltipRow, marginBottom: 0, marginTop: 16 }}>
        <p style={styles.tooltipLightText}>
          <T _str={"Total"} />
        </p>
        <p style={styles.tooltipBoldText}>
          {getCommaSeparatedTwoDecimalsNumber(datum.labelData.totalPrice)}{" "}
          <span style={{ ...styles.tooltipLightText, color: "#16295A" }}>
            <T _str={"ct/kWh"} />
          </span>
        </p>
      </div>
    </foreignObject>
  </g>
)

export const MonthGraphFlyOut = ({ x, y, datum }) => (
  <g style={{ pointerEvents: "none" }}>
    <foreignObject width={160} height={105} x={x} y={y} style={styles.customTooltipWrapper}>
      <div style={styles.tooltipRow}>
        <p style={styles.tooltipBoldText}>{`${datum.labelData.date}`}</p>
      </div>
      <div>
        <div style={{ ...styles.tooltipRow, marginBottom: 0 }}>
          <p style={styles.tooltipLightText}>
            <T _str={"Av. Market Price"} />
          </p>
          <p style={{ ...styles.tooltipLightText, color: "#0B152D" }}>
            {getCommaSeparatedTwoDecimalsNumber(datum.labelData.spotPrice)}
          </p>
        </div>
        <div style={{ ...styles.tooltipRow, marginBottom: 0 }}>
          <p style={styles.tooltipLightText}>
            <T _str={"Av. Taxes & Levies"} />
          </p>
          <p style={{ ...styles.tooltipLightText, color: "#0B152D" }}>
            {getCommaSeparatedTwoDecimalsNumber(datum.labelData.taxesAndLevies)}
          </p>
        </div>
      </div>
      <div style={{ height: 4 }}>
        <SvgImage name={"victory-tooltip-path"} styles={{ height: "auto" }} />
      </div>
      <div style={{ ...styles.tooltipRow, marginBottom: 0, marginTop: 16 }}>
        <p style={styles.tooltipLightText}>
          <T _str={"Total"} />
        </p>
        <p style={styles.tooltipBoldText}>
          {getCommaSeparatedTwoDecimalsNumber(datum.labelData.totalPrice)}{" "}
          <span style={{ ...styles.tooltipLightText, color: "#16295A" }}>
            <T _str={"ct/kWh"} />
          </span>
        </p>
      </div>
    </foreignObject>
  </g>
)

MonthGraphFlyOut.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  datum: PropTypes.object,
}
DayGraphFlyOut.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  datum: PropTypes.object,
}
