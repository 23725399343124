import React from "react"
import { Box } from "@mui/material"

import NavigationWrapper from "../../layouts/NavigationWrapper"
import NewDynamicPricingGraph from "../../components/DynamicPricingGraph/NewDynamicPricingGraph"
import "./style.scss"

export default function DynamicGraphPage() {
  return (
    <NavigationWrapper>
      <Box mt={8}>
        <NewDynamicPricingGraph />
      </Box>
    </NavigationWrapper>
  )
}
