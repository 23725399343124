import React, { useCallback, useEffect, useState } from "react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import "./TextField.scss"
import { T, useT } from "@transifex/react"
import { t } from "@transifex/native"
import moment from "moment"
import InputMask from "react-input-mask"
import { IBAN, DATE, PASSWORD, EMAIL, METER_NUMBER_TYPE } from "../../constants/common"
import { removeSpecialCharsExceptDash } from "../../utils"
import { ValidateEmail, ValidateMeterNumber, ValidatePassword } from "../../utils/helpers"

interface TextFieldProps {
  style?: any
  required?: boolean
  className?: string
  defaultValue: string
  onChange?: (value: any) => void
  onBlur?: (value: any) => void
  showError?: boolean
  helpText?: string
  placeholder?: string
  type?: "text" | "email" | "password" | "number" | "date" | "iban" | "meterNumber" | "tel"
  description?: string
  hideDetail?: boolean
  leadIcon?: string
  trailingIcon?: string
  trailingText?: string
  active?: boolean
  label?: string
  disabled?: boolean
  length?: number
  maxLength?: number
  minLength?: number
  autoFocus?: boolean
  onClickError?: () => void
  clearInput?: () => void
  extraErrorText?: string
  warningText?: string
  id?: string
  autoComplete?: string
}

export default function TextField({
  style,
  required = false,
  className = "",
  defaultValue = "",
  onChange,
  onBlur,
  showError = false,
  helpText = "Required field",
  placeholder = "",
  type,
  description = "",
  hideDetail = false,
  leadIcon = "",
  trailingIcon = "",
  trailingText = "",
  active = false,
  label = "",
  disabled = false,
  length = null,
  maxLength = null,
  autoFocus = false,
  onClickError,
  clearInput,
  extraErrorText = null,
  warningText = null,
  ...props
}: TextFieldProps) {
  const [value, setValue] = useState(defaultValue)
  const [isDatePlaceHolder] = useState(true)
  const [date, setDate] = useState(defaultValue)

  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  const setInvalidDate = () => {
    onChange({
      value: date,
      valid: false,
      error: t("Invalid Date - DD.MM.YYYY"),
    })
  }

  const handleDate = useCallback(async () => {
    if (type === DATE) {
      if (date.includes("_") || date.length !== 10) {
        setInvalidDate()
        return
      }
      try {
        const formatted = date.split(".").join("-")
        let validDate = moment(formatted, "DD-MM-YYYY")
        if (validDate.isValid()) {
          onChange({ value: date, valid: true, error: "" })
        } else {
          setInvalidDate()
        }
      } catch (error) {
        setInvalidDate()
      }
    }
  }, [date, type])

  useEffect(() => {
    let valid = false
    switch (type) {
      case EMAIL:
        valid = ValidateEmail(defaultValue)
        onChange({ value: defaultValue, valid: valid, error: "" })
        setValue(defaultValue)
        break
      case DATE: {
        const dateList = defaultValue.split(".")
        setDay(dateList[0])
        setMonth(dateList[1])
        setYear(dateList[2])
        setDate(defaultValue)
        break
      }
      case PASSWORD:
        valid = ValidatePassword(defaultValue)
        onChange({ value: defaultValue, valid: valid, error: "" })
        setValue(defaultValue)
        break
      case METER_NUMBER_TYPE:
        valid = true
        onChange({ value: removeSpecialCharsExceptDash(defaultValue), valid: valid, error: "" })
        setValue(removeSpecialCharsExceptDash(defaultValue))
        break

      default:
        valid = true
        setValue(defaultValue)
        break
    }
    // eslint-disable-next-line
  }, [defaultValue])

  useEffect(() => {
    if (date) handleDate()
    // eslint-disable-next-line
  }, [date, handleDate])

  const [validate, setValidate] = useState(true)
  const [errorText, setErrorText] = useState(helpText)

  useEffect(() => {
    setErrorText(helpText)
  }, [helpText])

  useEffect(() => {
    if (day && month && year) {
      const date = `${day}.${month}.${year}`
      setDate(date)
    }
  }, [day, month, year])

  const handleChange = e => {
    let valid = true
    if (placeholder === "Meter id (Zählernummer)") {
      if (ValidateMeterNumber(e.currentTarget.value) === false) return
    }
    if (type === PASSWORD) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "")
    }
    if (length !== null) {
      if (e.currentTarget.value.length !== length) {
        setValidate(false)
        onChange({
          value: e.currentTarget.value,
          valid: valid,
          error: "It must be equal to 11 characters",
        })
        setValue(e.currentTarget.value)
        return
      }
    }
    if (maxLength !== null) {
      if (e.currentTarget.value.length > maxLength) {
        return
      }
    }
    setValidate(valid)
    const newValue =
      type === METER_NUMBER_TYPE ? removeSpecialCharsExceptDash(e.currentTarget.value) : e.currentTarget.value
    onChange({ value: newValue, valid: valid, error: "" })
    setValue(newValue)
  }
  let inputClass = "text-field-input"
  inputClass += validate ? "" : " error"
  inputClass += showError ? " error" : ""
  inputClass += disabled ? " disabled" : ""
  inputClass += leadIcon === "" ? "" : " leadIcon"
  inputClass += trailingIcon === "" ? "" : " trailingIcon"
  inputClass += active ? " active-border" : ""
  const tPlaceHolder = t(placeholder)

  const handleClear = () => {
    setValue("")
    clearInput()
  }

  return (
    <div className={"text-field-container " + className} style={style}>
      {label !== "" && (
        <div className={"label"}>
          <T _str={label} />
        </div>
      )}
      {leadIcon !== "" && <SvgImage name={leadIcon} className={"leadIcon"} />}
      {type !== DATE && type !== IBAN && (
        <input
          disabled={disabled}
          required={required}
          type={type}
          autoFocus={autoFocus}
          className={inputClass}
          value={value}
          placeholder={tPlaceHolder}
          onBlur={onBlur}
          onChange={handleChange}
          {...props}
        />
      )}

      {type === DATE && (
        <div className={"date-input"}>
          <InputMask
            value={day}
            placeholder="DD"
            mask="99"
            maskChar={null}
            alwaysShowMask={false}
            className={inputClass + " date date-input-field" + (isDatePlaceHolder === true ? " placeholder" : "")}
            style={{ width: "26%" }}
            onChange={e => setDay(e.target.value)}
            data-test-id="day-input"
            {...props}
          />
          <InputMask
            value={month}
            placeholder="MM"
            mask="99"
            maskChar={null}
            alwaysShowMask={false}
            className={inputClass + " date date-input-field" + (isDatePlaceHolder === true ? " placeholder" : "")}
            onChange={e => setMonth(e.target.value)}
            style={{ width: "26%" }}
            data-test-id="month-input"
            {...props}
          />
          <InputMask
            value={year}
            placeholder="YYYY"
            mask="9999"
            maskChar={null}
            alwaysShowMask={false}
            className={inputClass + " date date-input-field" + (isDatePlaceHolder === true ? " placeholder" : "")}
            onChange={e => setYear(e.target.value)}
            style={{ width: "40%" }}
            data-test-id="year-input"
            {...props}
          />
          <InputMask
            value={date}
            mask="99.99.9999"
            maskChar={null}
            alwaysShowMask={false}
            className={inputClass + " date" + (isDatePlaceHolder === true ? " placeholder" : "")}
            onChange={e => setDate(e.target.value)}
            style={{ display: "none" }}
            {...props}
          />
        </div>
      )}
      {type === IBAN && (
        <InputMask
          disabled={disabled}
          required={required}
          type={"text"}
          autoFocus={autoFocus}
          className={inputClass}
          value={value}
          placeholder={tPlaceHolder}
          onBlur={onBlur}
          onChange={handleChange}
          mask="**** **** **** **** **** **"
          maskChar={null}
          alwaysShowMask={false}
          {...props}
        />
      )}
      {trailingIcon !== "" && (
        <div onClick={handleClear} style={{ cursor: "pointer" }}>
          <SvgImage name={trailingIcon} className={"trailingIcon"} />
        </div>
      )}
      {trailingText !== "" && (
        <span className={"trailingIcon trailingText"} style={{ display: "block" }}>
          {trailingText}
        </span>
      )}
      {description !== "" && !((!validate && !hideDetail) || showError) && (
        <div className={"description"}>
          <T _str={description} />
        </div>
      )}
      {((!validate && !hideDetail) || showError) && (
        <div className={"error-description"} data-test-id={"error-message"} onClick={onClickError && onClickError}>
          <T _str={errorText} />
          &nbsp;
          {extraErrorText && <strong>{extraErrorText}</strong>}
        </div>
      )}
      {warningText && (
        <div className={"error-description"} data-test-id={"warning-message"}>
          <T _str={warningText} />
        </div>
      )}
    </div>
  )
}
