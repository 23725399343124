import React, { useState } from "react"
import { Button, Dialog, DialogContent } from "@mui/material"
import { T, useLocale } from "@transifex/react"
import { useShallow } from "zustand/react/shallow"

import priceCapImg from "../../assets/images/price-cap-img.png"
import priceCapImgDe from "../../assets/images/price-cap-img-de.png"
import {
  ANNAUL_FEE_PRICE_CAP,
  DEFAULT_VALUE_0,
  DEFAULT_VALUE_1,
  PRICE_CAP_AMOUNT,
  ENGLISH,
} from "../../constants/common"
import Accordion from "../Accordion/Accordion"
import Transition from "../Transition/Transition"
import { useGeneralModel } from "../../models/useGeneralModel"

const termsAndConditionsPriceCapUrl = `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-price-cap`

const items = [
  {
    title: <T _str={"How much does it cost?"} />,
    content: (
      <T
        _str={
          "The annual fee for the Ostrom Price Cap is €49.90. This fee will be collected with your next invoice. For customers who pay by bank transfer, the fee is due 14 days after the start of the Ostrom Price Cap."
        }
      />
    ),
    key: "How much does it cost?",
  },
  {
    title: <T _str={"Am I eligible?"} />,
    content: (
      <>
        <div style={{ marginBottom: 8 }}>
          <T
            _str={
              "The Ostrom Price Cap is available to all SimplyFair Tariff customers, including sister tariffs, provided their estimated annual electricity consumption is below 15,000 kWh. It cannot be paired with our SimplyDynamic tariff."
            }
          />
        </div>
        <div style={{ marginBottom: 8 }}>
          <T _str={"Ostrom reserves the right to reject customers based on the aforementioned criteria."} />
        </div>
      </>
    ),
    key: "Am I eligible?",
  },
  {
    title: <T _str={"What if I want to cancel?"} />,
    content: (
      <>
        <div style={{ marginBottom: 8 }}>
          <T _str={"The minimum contract duration is one year. "} />
        </div>
        <div style={{ marginBottom: 8 }}>
          <T
            _str={
              "You must inform us that you’d like to cancel the Ostrom Price Cap at least one month before the end of your current term. If no cancellation occurs after one year, the Ostrom Price Cap will be extended and can be cancelled at any time with a notice period of one month."
            }
          />
        </div>
        <div style={{ marginBottom: 8 }}>
          <T
            _str={
              "In the event of early cancellation of your Ostrom SimplyFair contract, there is no entitlement to a refund of the annual fee."
            }
          />
        </div>
      </>
    ),
    key: "What if I want to cancel?",
  },
]

const faqTitle = <T _str={"Ostrom Price Cap FAQs"} />

const disclaimer = (
  <>
    <span style={{ fontWeight: "bold" }}>
      <T _str={"Please note:"} />
    </span>
    <T _str="Our binding contract is in German per our Terms and Conditions. For non-German speakers, we offer an English translation for convenience purposes only. For full T&Cs " />
    <a className="link" href={termsAndConditionsPriceCapUrl} target="_blank" rel="noreferrer">
      <T _str={"click here"} />
    </a>
    .
  </>
)

export default function PriceCapStep() {
  const [modalVisible, setModalVisible] = useState(false)
  const locale = useLocale()

  const { priceCap } = useGeneralModel(useShallow(state => state))

  return (
    <>
      <div className={"form-container bg-white-color"}>
        <div className={"form-title"}>
          <div className={"title bg-white-color mb-0"} data-test-id={"price-cap-title"}>
            <T _str="Protect Yourself Against High Energy Prices" />
          </div>
          <div className={"line-container"}>
            <div className={"line add-on-line"} />
          </div>
        </div>
        <div className={"form-container-border mb-20"}>
          <div className={"form-container-border-line"} />
        </div>
        <div className="text-content">
          <div className="text-block-container">
            <T
              _str={
                "Activate the Ostrom Price Cap with your SimplyFair tariff, and we’ll put a cap on your unit price for a whole year. You’ll never have to worry about rising energy prices again!"
              }
            />
          </div>
          <div className="text-block-container">
            <div className="text-block mobile">
              <div className={"text-title"}>
                <T _str={"How does it work?"} />
              </div>
              <div className="text-subtitle">
                <span style={{ fontWeight: "bold" }}>
                  <T _str={"For an annual fee of "} />
                  {`${ANNAUL_FEE_PRICE_CAP} € `}
                </span>
                <T _str={"we guarantee that your unit price will not rise above "} />
                <span style={{ fontWeight: "bold" }}>
                  {PRICE_CAP_AMOUNT}
                  <T _str={" cents/kWh "} />
                </span>
                <T
                  _str={
                    "for a year. During that time you will never pay more than this price cap, even if the energy market fluctuates."
                  }
                />
              </div>
            </div>
            <div className="text-block border">
              <div className={"text-title"}>
                <T _str={"If you sign up today, your price cap will be:"} />
              </div>
              <div className="price-cap-block">
                <span className="price-cap-amount">{PRICE_CAP_AMOUNT}</span> ct/kWh
              </div>
              <div className="subtext">
                <T _str={"Want to know more? "} />
                <Button
                  variant="text"
                  onClick={() => {
                    setModalVisible(!modalVisible)
                  }}
                  className="price-cap-modal-btn"
                  data-test-id={"price-cap-modal-btn"}
                >
                  <T _str={"Click here"} />.
                </Button>
              </div>
            </div>
          </div>
          <img
            src={locale === ENGLISH ? priceCapImg : priceCapImgDe}
            alt={"Price cap"}
            className={"price-cap-img"}
            data-test-id={"price-cap-img"}
          />
          <div className="price-cap-caption">
            <T
              _str={
                "Ostrom’s SimplyFair tariff is variable, which means we are always watching the energy market and adjusting our unit price accordingly. If you activate the Ostrom Price Cap, the lower of either the unit price of the SimplyFair tariff or the agreed price cap will be applied throughout the year."
              }
            />
          </div>
        </div>
        {modalVisible && (
          <Dialog
            open={modalVisible}
            onClose={() => {
              setModalVisible(false)
            }}
            // @ts-ignore
            TransitionComponent={Transition}
            keepMounted
            data-test-id={"price-cap-modal"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="referral-dialog"
          >
            <DialogContent>
              <Accordion
                title={faqTitle}
                items={items}
                footer={disclaimer}
                handleClose={() => {
                  setModalVisible(false)
                }}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
      <div className={"form-container form-container-border bg-white-color"}>
        <div className={"form-container-border-line"} />
      </div>
      <div className={"form-container form-e-container bg-white-color"}>
        <div className={"form-title"}>
          <div className={"title bg-white-color md-title"}>
            <span>
              <T _str="Would you like to activate the Ostrom Price Cap?" />
            </span>
          </div>
        </div>
        <div className={"form-content pb-0"}>
          <div className={"form-tab-container "}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => useGeneralModel.setState({ priceCap: DEFAULT_VALUE_1 })}
              className={" form-tab-price-cap form-tab" + (priceCap === DEFAULT_VALUE_1 ? " active" : "")}
              onClick={() => useGeneralModel.setState({ priceCap: DEFAULT_VALUE_1 })}
            >
              <div className={"tab-title"}>
                <T _str={"YES"} />
              </div>
              <div className="text-radio">
                <T _str={"Protect me against prices above "} />
                {`${PRICE_CAP_AMOUNT} ct/kwh`}
              </div>
              <p style={{ margin: 0 }}>
                <input
                  type="radio"
                  name="radio-yes"
                  data-test-id={"price-cap-yes"}
                  onChange={() => {}}
                  checked={priceCap === 1}
                />
              </p>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => useGeneralModel.setState({ priceCap: DEFAULT_VALUE_0 })}
              className={" form-tab-price-cap form-tab" + (priceCap === DEFAULT_VALUE_0 ? " active" : "")}
              onClick={() => useGeneralModel.setState({ priceCap: DEFAULT_VALUE_0 })}
            >
              <div className={"tab-title"}>
                <T _str={"NO"} />
              </div>
              <div className="text-radio">
                <T _str={"Continue with only the SimplyFair tariff"} />
              </div>
              <p style={{ margin: 0 }}>
                <input
                  type="radio"
                  name="radio-no"
                  data-test-id={"price-cap-no"}
                  onChange={() => {}}
                  checked={priceCap === DEFAULT_VALUE_0}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
