import React, { useEffect, useState } from "react"
import "./PlanForm.scss"
import { T } from "@transifex/react"
import { ClickAwayListener, IconButton, Tooltip, useTheme } from "@mui/material"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { t } from "@transifex/native"

import { LETTER_M, MONTH, YEAR } from "../../constants/common"
import useRateCalculator from "../../hooks/useRateCalculator"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useShallow } from "zustand/react/shallow"
import { useResponsive } from "../../hooks/useResponsive"
import { getCommaSeparatedTwoDecimalsNumber } from "../../utils/helpers"
import { STR_RATE_OSTROM, STR_RATE_TARIFF } from "../../constants/global"
import useGetRate from "../../hooks/useGetRate"

const getTooltipcComponentsProps = theme => ({
  tooltip: {
    sx: {
      backgroundColor: "#16295A",
      borderRadius: "6px",
      minWidth: "480px !important",
      [theme.breakpoints.down("sm")]: {
        minWidth: "300px !important",
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "250px !important",
      },
    },
  },
})

interface TariffTooltipTextProps {
  isDynamic: boolean
  currentPlan: string
  unitPrice: number
  unitPricePerKwh: number
  averageFeeDynamic: number
  minPriceTodayDynamic: number
  maxPriceTodayDynamic: number
  energyNetworkFee: number
  energyNetworkFeeDynamic: number
  basicFee: number
  basicFeeDynamic: number
  kgCO2Emissions: number
}

const TariffTooltipText = (props: TariffTooltipTextProps) => {
  return (
    <div className="tariff-tooltip-container">
      <table>
        <tbody>
          <tr>
            <th>
              <T _str={"Unit Price"} />
              {!props.isDynamic && (
                <div>
                  (<T _str={"Today's Average"} />)
                </div>
              )}
            </th>
            <td>
              <span>
                {!props.isDynamic
                  ? getCommaSeparatedTwoDecimalsNumber(props.unitPricePerKwh)
                  : getCommaSeparatedTwoDecimalsNumber(props.averageFeeDynamic)}
              </span>
              {" ct/kWh"}
              <div>
                {!props.isDynamic ? (
                  <>
                    <span>{props.unitPrice}</span> €/
                    <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
                  </>
                ) : (
                  <>
                    {getCommaSeparatedTwoDecimalsNumber(props.minPriceTodayDynamic)}
                    {" - "}
                    {getCommaSeparatedTwoDecimalsNumber(props.maxPriceTodayDynamic)}
                    {" ct/kWh"}
                  </>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Grid Fees"} />
            </th>
            <td>
              <span>
                {!props.isDynamic
                  ? props.energyNetworkFee
                  : getCommaSeparatedTwoDecimalsNumber(props.energyNetworkFeeDynamic)}
              </span>
              {" €/"}
              <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Basic Fee"} />
            </th>
            <td>
              <span>
                {!props.isDynamic
                  ? getCommaSeparatedTwoDecimalsNumber(props.basicFee)
                  : getCommaSeparatedTwoDecimalsNumber(props.basicFeeDynamic)}
              </span>
              {" €/"}
              <T _str={props.currentPlan === LETTER_M ? MONTH : YEAR} />
            </td>
          </tr>
          <tr>
            <th>
              <T _str={"Emission Savings"} />
            </th>
            <td>
              <span>-{props.kgCO2Emissions}</span>
              {" kg CO₂"}
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ fontSize: 10 }}>
        <T _str={"All prices incl. VAT"} />
      </div>
    </div>
  )
}

interface AmountDisplaySectionProps {
  extraClass: string
  setReferralAmount?: (x: number) => void
}

const AmountDisplaySection = (props: AmountDisplaySectionProps) => {
  const { isDynamic, currentPlan, localRate } = useGeneralModel(useShallow(state => state))

  const [openTariffTooltip, setOpenTariffTooltip] = useState(false)

  const { deviceType } = useResponsive()

  const {
    unitPrice,
    unitPricePerKwh,
    energyNetworkFee,
    basicFee,
    energyNetworkFeeDynamic,
    basicFeeDynamic,
    averageFeeDynamic,
    minPriceTodayDynamic,
    maxPriceTodayDynamic,
    kgCO2Emissions,
  } = useRateCalculator()

  const { getRateData, selectedTariff } = useGetRate()

  const rateData = getRateData(STR_RATE_TARIFF, STR_RATE_OSTROM, "monthly")

  useEffect(() => {
    if (rateData === 0) {
      props.setReferralAmount(0)
    }
  }, [rateData])

  // @ts-ignore
  const regularCost = parseInt(rateData) || localRate

  const amount = selectedTariff ?? regularCost

  const tariffToolTip = (
    <TariffTooltipText
      isDynamic={isDynamic}
      currentPlan={currentPlan}
      unitPricePerKwh={unitPricePerKwh}
      unitPrice={unitPrice}
      averageFeeDynamic={averageFeeDynamic}
      minPriceTodayDynamic={minPriceTodayDynamic}
      maxPriceTodayDynamic={maxPriceTodayDynamic}
      energyNetworkFee={energyNetworkFee}
      energyNetworkFeeDynamic={energyNetworkFeeDynamic}
      basicFee={basicFee}
      basicFeeDynamic={basicFeeDynamic}
      kgCO2Emissions={kgCO2Emissions}
    />
  )

  const theme = useTheme()

  const componentsProps = getTooltipcComponentsProps(theme)

  return (
    <div className={`input-controller amount-display-block ${props.extraClass}`}>
      <div>
        <div className="amount-display-label">
          <T _str={!isDynamic ? t("SIMPLYFAIR TARIFF") : t("SIMPLYDYNAMIC")} />
          {!deviceType.mobile ? (
            <Tooltip
              disableInteractive={false}
              placement={window.innerWidth > 1200 ? "top" : "top-end"}
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title={tariffToolTip}
              componentsProps={componentsProps}
            >
              <IconButton>
                <SvgImage name={"info"} className={"info-icon"} />
              </IconButton>
            </Tooltip>
          ) : (
            <ClickAwayListener onClickAway={() => setOpenTariffTooltip(false)}>
              <Tooltip
                disableInteractive={false}
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top-end"
                open={openTariffTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tariffToolTip}
                componentsProps={componentsProps}
              >
                <IconButton onClick={() => setOpenTariffTooltip(true)}>
                  <SvgImage name={"info"} />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          )}
        </div>
        <div className="amount-display-field">
          <div className="amount">
            {amount} €
            <span>
              <span className="plan">
                /<T _str={"month"} />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AmountDisplaySection)
