function noScriptTag() {
  const noscript = document.createElement("noscript")
  const iframeElement = document.createElement("iframe")
  iframeElement.src = "https://www.googletagmanager.com/ns.html?id=GTM-MPWL4BD"
  iframeElement.style.display = "none"
  iframeElement.height = "0"
  iframeElement.width = "0"
  iframeElement.style.visibility = "hidden"
  noscript.appendChild(iframeElement)
  return noscript
}

export function GoogleTagManager() {
  const googleTagScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://metrics.ostrom.de/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MPWL4BD');`

  let script = document.createElement("script")
  script.type = "text/javascript"
  script.innerHTML = googleTagScript
  document.getElementsByTagName("head")[0].appendChild(script)
  window.onload = function () {
    if (document.body != null) {
      document.body.appendChild(noScriptTag())
    }
  }
}
