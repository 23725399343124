import { useEffect, useState } from "react"

export const useResponsive = () => {
  const [deviceType, setDeviceType] = useState({
    mobile: false,
    deskTop: true,
  })

  const [desktop] = useState(1200)

  useEffect(() => {
    setListener()
  }, [desktop])

  function handleResize() {
    let width = document.documentElement.clientWidth
    if (width > desktop) {
      setDeviceType({ ...deviceType, mobile: false, deskTop: true })
    } else if (width <= desktop) {
      setDeviceType({ ...deviceType, mobile: true, deskTop: false })
    }
  }

  function setListener() {
    window.addEventListener("resize", handleResize)
    handleResize()
  }

  return { deviceType }
}
