import Generator4 from "../assets/images/generator-img-4.png"
import Generator6 from "../assets/images/generator-img-6.png"
import { t } from "@transifex/native"
import React from "react"
import { STR_MONTHLY, STR_YEARLY } from "./global"
import { Col1Type, SignupStepsType } from "../types/generic"

export const DEFAULT_VALUE_0 = 0
export const DEFAULT_VALUE_1 = 1
export const NUMBER_REGEX = /^[0-9\b]+$/
export const PRICE_CAP_AMOUNT = 35
export const ANNAUL_FEE_PRICE_CAP = "49,90"
export const MIN_USAGE = 600
export const MIN_DYNAMIC_USAGE = 6000
export const MAX_USAGE = 15000
export const DEFAULT_KWH_CONSUMPTION = 1400
export const DEFAULT_ONE_PERSON_VALUE = 900
export const DEFAULT_MORE_PERSONS_VALUE = 700
export const MOBILE_WIDTH = 1200
export const REFERRAL_CODE_LENGTH = 10
export const POSTAL_CODE_LENGTH = 5
export const REFERRAL_CODE_TYPE = {
  UNKNOWN: "UNKNOWN",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  INACTIVE: "INACTIVE",
}
export const TARIFF_RESPONSE_STATUS = {
  MAINTENANCE: "MAINTENANCE",
  HIGH_DEMAND: "HIGH_DEMAND",
  LIVE: "LIVE",
}
export const PLAY_STORE_URL =
  "https://wlla.adj.st/my/path?adjust_t=ujm8hg8&adjust_campaign=Home-Page&adjust_adgroup=Home-Page&adjust_creative=Android"
export const APP_STORE_URL =
  "https://wlla.adj.st/my/path?adjust_t=ujm8hg8&adjust_campaign=Home-Page&adjust_adgroup=Home-Page&adjust_creative=iOS"
// THESE VALUES ARE CALCULATED IN DAYS
export const PAST_DATE_LIMIT = 42
export const FUTURE_DATE_LIMIT = 730
// This includes the dots for formatting the iban
// the default length for the iban is 22 without the dots
export const IBAN_LENGTH = 27
export const IBAN = "iban"
export const DATE = "date"
export const PASSWORD = "password"
export const EMAIL = "email"
export const METER_NUMBER_TYPE = "meterNumber"
export const ENGLISH = "en"
export const MIN_AGE = 18
export const MAX_AGE = 100
export const REFERRAL_BONUS = 70
export const DELAYED_EMAIL_PROVIDERS = ["@t-online.de", "@magenta.de"]
export const Generators = [
  {
    title: t("Windpark Hohen Pritz"),
    location: t("Mecklenburg-Western Pomerania"),
    capacity: t("660 MWh p/y"),
    type: t("Wind"),
    reduction: t("240 tonnes p/y"),
    special: t(
      "Nestled amid Rostock and Schwerin, Windpark Hohen Pritz taps centuries of relentless winds in a region steeped in history. Among lush landscapes, the wind park, a modern sustainability symbol, stands with 200m turbines. Juxtaposed with a 1000-year-old church tower, it melds ancient heritage and cutting-edge technology, creating a vivid tableau of progress."
    ),
    image: Generator4,
  },
  {
    title: t("Ostrom HQ"),
    location: t("Berlin, Germany"),
    capacity: t("20 Ostrom members"),
    type: t("Biological"),
    reduction: t("158.4 t/a offset"),
    special: t(
      "Berlin is where Ostrom generates the rest of its power—our team here works hard every day to ensure our customers (both present and future) have all their energy questions answered."
    ),
    image: Generator6,
  },
]

export const GermanText =
  "Wir sind ein deutsches Startup und wissen: deutsche Sprache, schwere Sprache. Aber keine Sorge, unseren kompletten Service gibts auch auf Englisch!"

export const EnglishText = ` We're a proud
  German startup, but we also know our language ain’t easy. To maintain full transparency we offer
  everything — from signup to contract — in English as well.`

export const GOOGLE_EXPERIMENT_ID = "s0THLBHXTsO9CNMJvHsI2Q"

export const TARIFF_TABLE_VARIANT_ONE = {
  label: "1 Month Price Guarantee",
  tooltip: "",
  subtext: "We lower the price as soon as the market price drops.",
} as Col1Type

export const TARIFF_PAGE_PATH = "/tariff-plan"

export const ELECTRICITY_CONTRACT_TYPE = "electricityIdentifierType"
export const CHANGE_OF_SUPPLIER = "CHANGE_OF_SUPPLIER"
export const MOVE = "MOVE"
export const MARKET_LOCATION_ID = "MARKET_LOCATION_ID"
export const METER_NUMBER = "METER_NUMBER"
export const ELECTRICITY_CONTRACT = "electricityContract"
export const PAYMENT_TYPE = "paymentType"
export const OLD_SUPPLIER = "oldSupplier"
export const DIRECT_DEBIT = "DIRECT_DEBIT"
export const TRANSFER = "TRANSFER"

// Add constants for the page paths here
export const PERSONAL_INFO_PAGE_PATH = "/personal-info"
export const DELIVERY_PAGE_PATH = "/delivery"
export const ADDONS_PAGE_PATH = "/add-ons"
export const PAYMENT_PAGE_PATH = "/payment"
export const SUMMARY_PAGE_PATH = "/summary"
export const THANK_YOU_PAGE_PATH = "/thank-you"
export const PENDING_PAGE_PATH = "/pending-signup"
export const FAILED_CHECK_PAGE_PATH = "/check"
export const PAYMENT_WITH_BANNER_PATH = "/payment2"

// Local storage keys
export const LOCAL_ACTIVE_STEP = "activeStep"
export const LOCAL_PERSONAL_TAB = "personalTab"
export const LOCAL_DELIVERY_TAB_ONE = "deliveryTab1"
export const LOCAL_DELIVERY_TAB_TWO = "deliveryTab2"
export const LOCAL_PRICE_BREAK = "priceCap"
export const LOCAL_IS_PRICE_CAP_AVAILABLE = "isPriceCapAvailable"
export const LOCAL_PRODUCT_CODE = "productCode"

// Local storage form keys
export const LOCAL_FORM_EMAIL = "email"
export const LOCAL_FORM_FIRST_NAME = "firstName"
export const LOCAL_FORM_SURNAME = "surname"
export const LOCAL_FORM_DOB = "DOB"
export const LOCAL_FORM_COMPANY_NAME = "companyName"
export const LOCAL_FORM_AREA_CODE = "areaCode"
export const LOCAL_FORM_PHONE = "phone"
export const LOCAL_FORM_STREET = "street"
export const LOCAL_FORM_HOUSE_NUMBER = "houseNumber"
export const LOCAL_FORM_MARKET_LOCATION_ID = "marketLocationId"
export const LOCAL_FORM_METER_NUMBER = "meterNumber"
export const LOCAL_FORM_MOVING_DATE = "movingDate"
export const LOCAL_FORM_PREVIOUS_SUPPLIER_ID = "previousSupplierId"
export const LOCAL_FORM_TERMINATED_FROM_DATE = "terminatedFromDate"
export const LOCAL_FORM_CANCEL_POSSIBLE = "cancelAsSoonAsPossible"
export const LOCAL_FORM_NO_MARKET_LOCATION_ID = "noMarketLocationId"
export const LOCAL_FORM_PREVIOUS_CONTRACT_TERMINATED = "previousContractTerminated"
export const LOCAL_FORM_PAYMENT_METHOD = "paymentMethod"
export const LOCAL_FORM_IBAN = "iban"
export const LOCAL_FORM_IBAN_VALID = "ibanValid"
export const LOCAL_FORM_IBAN_ERROR = "ibanError"
export const LOCAL_FORM_ACCOUNT_HOLDER_NAME = "accountHolderName"
export const LOCAL_FORM_TERMS_AND_CONDITIONS = "termsAndConditions"
export const LOCAL_FORM_TERMS_AND_CONDITIONS_2 = "termsAndConditionsPriceCap"
export const LOCAL_FORM_ACCEPT_PARTNERSHIP = "acceptPartnership"
export const LOCAL_FORM_HAS_SMART_METER = "hasSmartMeter"
export const LOCAL_FORM_HAS_ELECTRIC_VEHICLE = "hasElectricVehicle"
export const LOCAL_FORM_HAS_HEAT_PUMP = "hasHeatPump"
export const LOCAL_FORM_INTERESTED_IN_THG_QUOTA = "interestedInThgQuota"
export const LOCAL_FORM_BILLING_FIRSTNAME = "billingFirstName"
export const LOCAL_FORM_BILLING_SURNAME = "billingSurname"
export const LOCAL_FORM_BILLING_STREET = "billingStreet"
export const LOCAL_FORM_BILLING_HOUSE_NUMBER = "billingHouseNumber"
export const LOCAL_FORM_BILLING_ZIP = "billingZip"
export const LOCAL_FORM_BILLING_CITY = "billingCity"
export const LOCAL_SIGN_UP_DATA = "signupData"

export const MOBILE_TABLE_LIST = [
  {
    label: "Flexible Monthly Plan",
    subtext: "No minimum terms, cancel anytime.",
  },
  {
    label: "Bilingual Customer Chat",
    subtext: "Responses in English or German, within seconds.",
  },
  {
    label: "The Ostrom App!",
    subtext: "Track your usage and payments all in one place.",
  },
  {
    label: "100% Green Electricity",
    subtext: "Sourced from TÜV certified renewable energy.",
  },
]

export const OSTROM_BLOG_URL =
  "https://www.ostrom.de/post/how-you-will-benefit-from-germanys-strompreisbremse-energy-price-brake"

export const LETTER_M = "M"
export const MONTH = "month"
export const YEAR = "year"
export const HOURLY = 0
export const MONTHLY = 1

export const getCurrentPlanKey = currentPlan => {
  return currentPlan === LETTER_M ? STR_MONTHLY : STR_YEARLY
}

export const links = [
  {
    name: t("Variable Tariff"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-tariff`,
    testID: "variable-tariff",
  },
  {
    name: t("Dynamic Pricing"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/dynamic-pricing`,
    testID: "dynamic-pricing",
  },
  {
    name: t("Smart EV Charging"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/smart-ev-charging`,
    testID: "smart-ev-charging",
  },
  { name: t("THG Quote"), path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/thg-quote`, testID: "thg-quote" },
  { name: t("Our Store"), path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/store`, testID: "our-store" },
  {
    name: t("Our Generators"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-generators`,
    testID: "our-generators",
  },
  { name: t("FAQs"), path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/faq`, testID: "faqs" },
]
export const aboutUs = [
  {
    name: t("About"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/about-us`,
    testID: "about-us",
  },
  {
    name: t("Jobs"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-team`,
    testID: "careers",
  },
  {
    name: t("Blog"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/blog`,
    testID: "blog",
  },
  {
    name: t("Press"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/press`,
    testID: "press",
  },
  {
    name: t("Electricity Labeling"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/electricity-labeling`,
    testID: "electricity-labeling",
  },
  {
    name: t("Cancel Contract"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/kuendigung-cancellation`,
    testID: "cancel-contract",
  },
]
export const infomation = [
  {
    name: t("T&C"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions`,
    testID: "t-and-c",
  },
  {
    name: t("T&C Dynamic Tariff"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-simply-dynamic`,
    testID: "tc-dynamic-tariff",
  },
  {
    name: t("T&C Store"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-marketplace`,
    testID: "tc-store",
  },
  {
    name: t("Compliance"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/compliance`,
    testID: "compliance",
  },
  {
    name: t("Mako"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/market-partner`,
    testID: "mako",
  },
  {
    name: t("Sitemap"),
    path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/html-sitemap`,
    testID: "sitemap",
  },
]

export const PRODUCT_BASIS_DYNAMIC = "basisDynamic" as const
export const PRODUCT_BASIS_PRODUCT = "basisProdukt" as const
export const BASIS_PRICE_BRAKE = "basisPriceBrake35" as const
export const CAMPAIGN_STANDARD = "standard" as const
export const DYNAMIC_MIN_USAGE = 6000

export const monthNamesShort = {
  en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  de: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Dez"],
}

export const monthNames = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  de: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
}

export const STEP_0 = 0
export const STEP_1 = 1
export const STEP_2 = 2
export const STEP_3 = 3
export const STEP_4 = 4

export const SKIP_SENTRY_MESSAGES = ["fbq is not defined", "Can't find variable: fbq"]

export const termsAndConditionsUrl = `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions`
export const termsAndConditionsPriceCapUrl = `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-price-cap`
export const privacyPoliceUrl = `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/privacy-policy`

export const signupStepsWithPriceCap = {
  "personal-info": { index: 0, name: "Personal Info", url: "/personal-info" },
  "delivery": { index: 1, name: "Delivery", url: "/delivery" },
  "add-ons": { index: 2, name: "Add-Ons", url: "/add-ons" },
  "payment": { index: 3, name: "Payment Details", url: "/payment" },
  "summary": { index: 4, name: "Summary", url: "/summary" },
} as SignupStepsType

export const signupStepsWithoutPriceCap = {
  "personal-info": { index: 0, name: "Personal Info", url: "/personal-info" },
  "delivery": { index: 1, name: "Delivery", url: "/delivery" },
  "payment": { index: 2, name: "Payment Details", url: "/payment" },
  "summary": { index: 3, name: "Summary", url: "/summary" },
} as SignupStepsType

export const stepsLabelWithPriceCap = ["personal-info", "delivery", "add-ons", "payment", "summary"]
export const stepsLabelWithNoPriceCap = ["personal-info", "delivery", "payment", "summary"]

export const GraphUrlPath = "/strompreis"
export const DEFAULT_CITY_ID = 2163
export const DEFAULT_POST_CODE = 10405

export const PENDING_SIGNUP_PAGE = "PendingSignUpPage"
export const THANK_YOU_PAGE = "ThankYouPage"
export const CHECK_FAIL_PAGE = "CheckFail"
export const SMART_METER_PRICE = 30
export const CREDIT_SCORE_ERROR_MESSAGE = "Sign up request rejected"
export const FREE_SMART_METER_USAGE = 2500
export const FREE_SMART_METER_OPT_OUT = 10000
