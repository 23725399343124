import React from "react"
import { Container } from "@mui/material"
import { T } from "@transifex/react"

import { SvgImage } from "../../assets/svgs/SvgImage"

import "./style.scss"
import TrustPilot from "../TrustPilot"

const ReviewComponent = () => {
  return (
    <div className="reviews-container">
      <div className={"reviews-background"}>
        <Container>
          <div className="back-container">
            <SvgImage name="quote-icon" />
          </div>
        </Container>
      </div>
      <div className="title">
        <T _str={"What our customers are saying"} />
      </div>
      <div className="description">
        <T
          _str={
            "We take pride in providing excellent customer service. But don’t just take our word for it: Hear it from customers who’ve already made the jump to Ostrom."
          }
        />
      </div>
      <TrustPilot />
    </div>
  )
}

export default ReviewComponent
