import { T } from "@transifex/react"
import React from "react"
import { useShallow } from "zustand/react/shallow"

import { ADDONS_PAGE_PATH, FREE_SMART_METER_USAGE } from "../../constants/common"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useNavigate } from "react-router-dom"
import { scrollToSection } from "../../utils"
import { usePlanFormModel } from "../../models/usePlanFormModel"

export default function SummarySmartMeterInfoCard() {
  const { smartMeterOrder, smartMeterPrice } = useGeneralModel(useShallow(state => state))
  const { usage } = usePlanFormModel(useShallow(state => state))
  const navigate = useNavigate()
  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Smart Meter"} />
          </div>
        </div>
        <div
          className={"btn-edit"}
          onClick={() => {
            scrollToSection("stepper-container-section")
            navigate(`${ADDONS_PAGE_PATH}${window.location.search}`)
          }}
        >
          <SvgImage name={"edit"} />
          <p>
            <T _str={"edit"} />
          </p>
        </div>
      </div>
      <div className={"info-card-content"}>
        {smartMeterOrder ? (
          <>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"SMART METER"} />
              </div>
              <div className={"item-value"}>
                <T _str={"Yes"} />
              </div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"SMART METER COST"} />
              </div>
              {
                <div className={"item-value"}>
                  {Number(usage) < FREE_SMART_METER_USAGE ? `${smartMeterPrice} €` : <T _str={"Free!"} />}
                </div>
              }
            </div>
            <div className={"info-col"}>
              <div className={"item-description note"}>
                <T _str="Your local grid operator will contact you to set up an installation appointment." />
              </div>
            </div>
          </>
        ) : (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"SMART METER"} />
            </div>
            <div className={"item-value"}>
              <T _str={"No"} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
