import React, { useEffect, useState, useMemo } from "react"
import {
  VictoryLabel,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryAxis,
  LineSegment,
  VictoryBar,
  VictoryVoronoiContainer,
} from "victory"
import { getTickCount } from "../../utils"
import { T } from "@transifex/react"
import { styles } from "./styles"
import { DayGraphFlyOut, MonthGraphFlyOut } from "./CustomVictoryTooltip"
import PropTypes from "prop-types"
import { HOURLY, MONTHLY } from "../../constants/common"

const DynamicGraph = ({ graphData, avgConsumption, strokeColor1, strokeColor2, toggleValue, fillColor }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    if (graphData) {
      setData(graphData)
    }
  }, [graphData])

  const maxY = useMemo(() => {
    if (data.length > 0 && avgConsumption) {
      return Math.max(...data.map(x => x.y), ...avgConsumption.map(x => x.y))
    }
    return 0
  }, [data, avgConsumption])

  const minWidth = 1000
  const isMobile = useMemo(() => window.innerWidth <= minWidth, [window.innerWidth])

  const minY = useMemo(() => {
    if (data.length > 0 && avgConsumption) {
      return Math.min(...data.map(x => x.y), ...avgConsumption.map(x => x.y))
    }
    return 0
  }, [data, avgConsumption])

  const avgLabel = ({ datum }) => (datum.x === data[data.length - 1].x ? datum.y.toFixed(2) : "").replace(".", ",")

  return (
    <>
      {data.length > 0 && (
        <div className={"graph-wrapper"} data-test-id={"spot-price-graph"}>
          <div className={"graph-container"}>
            <VictoryChart
              width={isMobile ? 500 : 970.068}
              height={isMobile ? 382.05 : 451.232}
              domain={{ y: [Math.floor(minY), Math.round(maxY) + 2] }}
              animate={{
                onLoad: { duration: 10 },
                duration: 1000,
                easing: "linear",
              }}
              padding={{
                right: 20,
                left: 20,
                top: minY <= 0 ? 15 : 35,
                bottom: minY <= 0 ? 28 : 30,
              }}
              minDomain={{ y: minY }}
              maxDomain={{
                y: maxY + maxY * 0.3,
              }}
              domainPadding={{ x: 35 }}
              containerComponent={<VictoryVoronoiContainer />}
            >
              <VictoryAxis
                tickValues={minY < 0 ? [Math.round(minY), 0, Math.round(maxY)] : []}
                dependentAxis
                axisComponent={<LineSegment lineComponent={<div></div>} />}
                tickLabelComponent={<VictoryLabel dy={-8} dx={20} />}
                style={styles.victoryAxisStyle(isMobile)}
              />

              {toggleValue === MONTHLY && (
                <VictoryBar
                  style={{
                    data: {
                      stroke: strokeColor1,
                      fill: fillColor,
                      width: isMobile ? 20 : 46,
                    },
                  }}
                  cornerRadius={isMobile ? 10 : 23}
                  data={data.reverse()}
                  labelComponent={<VictoryTooltip orientation={"bottom"} flyoutComponent={<MonthGraphFlyOut />} />}
                />
              )}
              {toggleValue === HOURLY && (
                <VictoryLine
                  style={{
                    data: {
                      stroke: strokeColor1,
                      strokeWidth: isMobile ? 5 : 10,
                    },
                  }}
                  data={data}
                  interpolation="cardinal"
                  labelComponent={<VictoryTooltip orientation={"bottom"} flyoutComponent={<DayGraphFlyOut />} />}
                />
              )}
              <VictoryLine
                data={avgConsumption}
                style={{
                  data: {
                    stroke: strokeColor2,
                    strokeWidth: isMobile ? 2 : 4,
                  },
                }}
                labels={avgLabel}
                labelComponent={<VictoryLabel dx={-2} dy={-3} style={styles.victoryLabelStyle} />}
              />
              <VictoryAxis
                offsetY={25}
                axisComponent={<LineSegment />}
                tickCount={getTickCount(toggleValue, data.length, isMobile)}
                style={{
                  tickLabels: styles.victoryTickLabel(isMobile),
                  axis: {
                    strokeWidth: () => (toggleValue === HOURLY ? 1 : 4),
                    stroke: "#C8C8C8",
                  },
                }}
              />
            </VictoryChart>
          </div>
          <div className={"graph-label"}>
            <div className={"label-item"}>
              <div className={"hyphen hyphen-teal"}></div>
              <p className={"bright-teal"}>
                <T _str={"SimplyDynamic Unit Price ct/kWh"} />
              </p>
            </div>
            <div className={"label-item"}>
              <div className={"hyphen hyphen-accent"}></div>
              <p className={"accent-blue"}>
                <T _str={" Average Unit Price ct/kWh"} />
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DynamicGraph

DynamicGraph.propTypes = {
  graphData: PropTypes.array,
  avgConsumption: PropTypes.array,
  toggleValue: PropTypes.number,
  fillColor: PropTypes.string,
  strokeColor1: PropTypes.string,
  strokeColor2: PropTypes.string,
}
