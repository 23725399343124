import React, { useCallback, useEffect, useState } from "react"
import "./NewTariffPlan.scss"
import { t } from "@transifex/native"
import { SvgImage } from "../../assets/svgs/SvgImage"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Alert,
  Box,
  Grid,
  Container,
  Stack,
} from "@mui/material"
import { T, UT } from "@transifex/react"
import getRate, { checkSmartMeterAvailability, getCitiesByPostalCode, validateReferralCode } from "../../service/api"
import { useNavigate } from "react-router-dom"
import TextField from "../TextField/TextField"
import { SentQuote } from "../Dialog/SentQuote/SentQuote"
import PageLoader from "../PageLoader/PageLoader"
import NewPlanForm from "../PlanForm/NewPlanForm"
import { useSnackbar } from "notistack"
import { scrollToSection, getReferralInfo } from "../../utils"
import { useShallow } from "zustand/react/shallow"

import { MAX_USAGE, MIN_USAGE, TARIFF_RESPONSE_STATUS, DEFAULT_VALUE_1, ENGLISH } from "../../constants/common"
import ServiceSection from "../ServiceSection/ServiceSection"
import ReviewComponent from "../Reviews"
import NewDynamicPricingGraph from "../DynamicPricingGraph/NewDynamicPricingGraph"
import { useFromPartner } from "../../hooks/useFromPartner"
import { PlanFormType } from "../../types/form"
import { PartnerParams } from "../../types/generic"
import Transition from "../Transition/Transition"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useGeneralModel } from "../../models/useGeneralModel"
import useIsPriceCapAvailable from "../../hooks/useIsPriceCapAvailable"
import useNewTableColumns from "../../hooks/useNewTableColumns"
import { useUserInfoModel } from "../../models/userInfoModel"
import { usePaymentModel } from "../../models/usePaymentFormModel"
import { useDeliveryFormModel } from "../../models/useDeliveryFormModel"
import useInitialParams from "../../hooks/useInitialParams"
import NewTableDesktop from "../Table/NewTableDesktop"

function NewTariffDeskTop() {
  const navigate = useNavigate()
  const [isDlgSentQuote, setIsDlgSentQuote] = useState(false)
  const [loading, setLoading] = useState(false)
  const [referralCode, setReferralCode] = useState("")
  const [referralAmount, setReferralAmount] = useState(0)
  const [openReferralDlg, setOpenReferralDlg] = useState(false)
  const [errorRefCode, setErrorRefCode] = useState("")
  const [toggleValue, setToggleValue] = useState(0)
  const [initialUrlReady, setInitialUrlReady] = useState(false)
  const [smartMeterAvailable, setSmartMeterAvailable] = useState(false)

  const { partnerParams, isFromPartner } = useFromPartner()

  const { getInitialUrlParams } = useInitialParams()

  const validateNewReferralCode = useCallback((code: string) => {
    validateReferralCode({ referralCode: code })
      .then(({ data }) => {
        const [isValid, errMsg] = getReferralInfo(data.status)
        if (!isValid || errMsg !== "") {
          usePlanFormModel.setState({ referralCode: "" })
          setReferralAmount(0)
          setReferralCode("")
          setErrorRefCode(t(errMsg))
          return
        }
        setReferralAmount(data.amount)
        setReferralCode(code)
        setErrorRefCode("")
        setOpenReferralDlg(false)
      })
      .catch(() => {
        setReferralAmount(0)
        setReferralCode("")
        setErrorRefCode(t("Invalid Referral Code"))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    // clear all form data on first load
    usePlanFormModel.persist.clearStorage()
    useUserInfoModel.persist.clearStorage()
    usePaymentModel.persist.clearStorage()
    useGeneralModel.persist.clearStorage()
    useDeliveryFormModel.persist.clearStorage()
    localStorage.setItem("customer-info", "")

    // @ts-ignore
    if (window?.Cypress) {
      //@ts-ignore
      window.usePlanFormModel = usePlanFormModel
      //@ts-ignore
      window.useGeneralModel = useGeneralModel
      //@ts-ignore
      window.useUserInfoModel = useUserInfoModel
      //@ts-ignore
      window.useDeliveryFormModel = useDeliveryFormModel
      //@ts-ignore
      window.usePaymentModel = usePaymentModel
    }
    getInitialUrlParams()
    setInitialUrlReady(true)
  }, [])

  const planForm = usePlanFormModel(
    useShallow(state => ({
      usage: state.usage,
      cityId: state.cityId,
      postalCode: state.postalCode,
      cityName: state.cityName,
      referralCode: state.referralCode,
      product: state.product,
      clientApplicationId: state.clientApplicationId,
      externalUserId: state.externalUserId,
      externalTransactionId: state.externalTransactionId,
    }))
  )

  const { rate, currentPlan, locale } = useGeneralModel(useShallow(state => state))

  useEffect(() => {
    if (isFromPartner) {
      setToggleValue(DEFAULT_VALUE_1)
    }
  }, [isFromPartner])

  useEffect(() => {
    const handlePlanFormChanges = async () => {
      const newPlanForm = Object.entries({ ...planForm, language: locale !== ENGLISH ? locale : "" }).reduce(
        (prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev),
        {} as Partial<PlanFormType>
      )

      let url = window.location.pathname

      const allParams = { ...partnerParams, ...newPlanForm } as Partial<PlanFormType> | Partial<PartnerParams>

      if (Object.keys(allParams).length > 0) {
        // convert object to string and remove undefined values
        // @ts-ignore
        url += "?" + new URLSearchParams(allParams).toString()
      }
      navigate(url)
      scrollToSection()
    }

    if (!initialUrlReady) {
      return () => {}
    }

    if (!planForm.usage) {
      return () => {}
    }
    if (planForm.usage === undefined) {
      return () => {}
    }
    if (+planForm?.usage < MIN_USAGE || +planForm?.usage > MAX_USAGE) {
      return () => {}
    }

    handlePlanFormChanges()
    // eslint-disable-next-line
  }, [planForm, locale, initialUrlReady])

  useEffect(() => {
    const handleCityChange = async () => {
      if (planForm.postalCode && planForm?.postalCode?.length === 5) {
        const { cityId, cityName } = usePlanFormModel.getState()
        await getCitiesByPostalCode(planForm.postalCode)
          .then(res => {
            if (res?.data?.length > 0) {
              // check if cityId and cityName is valid
              const isValidCityDetails = res.data.find(
                city => city?.id === parseInt(`${cityId}`) && city?.name === cityName
              )
              if (!isValidCityDetails) {
                usePlanFormModel.setState({ cityId: null, cityName: null, postalCode: null })
                useGeneralModel.setState({ cities: [], rate: null })
                return
              }
              useGeneralModel.setState({ cities: res.data })
            }
          })
          .catch(() => {
            usePlanFormModel.setState({ cityId: null, cityName: null, postalCode: null })
            useGeneralModel.setState({ cities: [], rate: null })
          })
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    handleCityChange()
  }, [planForm.postalCode, initialUrlReady])

  useEffect(() => {
    const handleChange = async () => {
      if (planForm?.referralCode) {
        validateNewReferralCode(planForm?.referralCode)
      } else {
        setReferralAmount(0)
        setReferralCode("")
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    handleChange()
  }, [planForm.referralCode, initialUrlReady])

  useEffect(() => {
    const handleChange = async () => {
      if (+planForm.usage >= MIN_USAGE && +planForm.usage <= MAX_USAGE) {
        setLoading(true)
        await getRate({
          cityId: planForm.cityId,
          usage: +planForm.usage,
          clientApplicationId: planForm?.clientApplicationId,
        })
          .then(res => {
            if (res.data) {
              if (res.data.status === TARIFF_RESPONSE_STATUS.MAINTENANCE) {
                navigate("/maintenance")
              } else if (res.data.status === TARIFF_RESPONSE_STATUS.HIGH_DEMAND) {
                navigate("/high-demand")
              } else {
                useGeneralModel.setState({
                  rate: res?.data,
                  isPendingApplicationAllowed: res?.data?.isPendingApplicationAllowed,
                })
              }
            }
          })
          .finally(() => setLoading(false))
      }
    }
    if (!initialUrlReady) {
      return () => {}
    }
    if (planForm.cityId && planForm.usage) {
      handleChange()
    } else {
      if (!planForm.cityId) {
        useGeneralModel.setState({ rate: null })
      }
    }
  }, [planForm.cityId, planForm.usage, initialUrlReady, planForm?.clientApplicationId])

  useEffect(() => {
    const handleCityChange = async () => {
      setSmartMeterAvailable(false)
      if (planForm.postalCode && planForm.usage && +planForm.usage >= MIN_USAGE && +planForm.usage <= MAX_USAGE) {
        await checkSmartMeterAvailability(planForm.postalCode, planForm.usage)
          .then(res => {
            if (res?.data?.isEligible) {
              setSmartMeterAvailable(true)
              useGeneralModel.setState({ smartMeterOfferAvailable: res.data.isEligible })
            }
          })
          .catch(() => {
            useGeneralModel.setState({ smartMeterOfferAvailable: false })
          })
      }
    }
    handleCityChange()
  }, [planForm.postalCode, planForm.usage])

  // @ts-ignore
  const savingsAvailable = rate && (rate?.basicProvider || rate?.ostrom?.monthly?.savings > 0)

  const priceCapAvailable = useIsPriceCapAvailable()

  let col1 = useNewTableColumns({ priceCapAvailable, savingsAvailable })

  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar()

  const handleBecomeCustomer = () => {
    if (
      !planForm.usage ||
      (planForm.usage && +planForm.usage < MIN_USAGE) ||
      (planForm.usage && +planForm.usage > MAX_USAGE)
    ) {
      enqueueSnackbar(t(`Please input postal code and consumption between 600 and 15000 kWh`), {
        variant: "warning",
      })
      return
    }
    navigate("/personal-info" + window.location.search)
    scrollToSection("stepper-container-section")
  }

  const handleValidateReferralCode = () => {
    if (referralCode.length !== 10) {
      setErrorRefCode(t("Invalid Referral Code"))
      return
    }
    setLoading(true)
    validateNewReferralCode(planForm?.referralCode)
  }

  const hideDialog = useCallback(() => {
    setIsDlgSentQuote(false)
  }, [])

  return (
    <div className="tariff-container-fair">
      <PageLoader loading={loading} />
      <SentQuote openStatus={isDlgSentQuote} onClose={hideDialog} />
      <div className="tariff-header">
        <div className="header-background"></div>
        <div>
          <div className="header-title">{<T _str="Tariff Plan" />}</div>
        </div>
      </div>
      <NewPlanForm referralAmount={referralAmount} errorRefCode={errorRefCode} setErrorRefCode={setErrorRefCode} />
      {smartMeterAvailable && (
        <div className="smar-meter-available">
          <div className="smart-meter-title">
            <span>{"⚡️ "}</span>
            <T _str={"Good news! Your postcode is eligible for a smart meter"} />
            {". "}
            <span>{"⚡️"}</span>
          </div>
          <div className="smart-meter-text">
            <T _str={"We'll explain how you can get your smart meter during the sign-up process."} />
          </div>
        </div>
      )}
      {/* @ts-ignore */}
      {rate !== null && rate?.ostrom?.length > 0 && (
        <>
          <div className="plan-container">
            {savingsAvailable && (
              <div className="plan-title-section">
                <div className="title">
                  <T _str="Save" />{" "}
                  <span style={{ color: "#00c1b1" }}>
                    {/* @ts-ignore */}
                    {rate?.ostrom?.yearly?.savings} € <T _str="per year" />{" "}
                  </span>
                  <T _str="by switching to Ostrom" />
                </div>
                <div>
                  <T _str="Calculations based on the default provider in your area and your estimated kWh" />
                </div>
              </div>
            )}
            <div className="plan-content">
              <NewTableDesktop
                currentPlan={currentPlan}
                rate={rate}
                locale={locale}
                col1={col1}
                onClickJoin={handleBecomeCustomer}
                priceCapAvailable={priceCapAvailable}
              />
            </div>
          </div>
          <Box className="simplyfair-info">
            <Container maxWidth="lg">
              <Grid container className="simplyfair-info-grid">
                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                  <Stack
                    spacing={2}
                    direction={{ xs: "column", md: "row" }}
                    justifyContent="center"
                    className="info-title"
                  >
                    <span className="simply-fair-includes">
                      <SvgImage name="green-tick" />
                    </span>
                    <span className="simply-fair-includes">
                      <T _str="Our SimplyFair Tariff Also Includes" />
                    </span>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <p>
                    <T _str="Flexible Monthly Plan" />
                  </p>
                  <p>
                    <UT _str="No minimum terms, <br />cancel anytime." />
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <p>
                    <T _str="Bilingual Customer Chat" />
                  </p>
                  <p>
                    <UT _str="Responses in English or German, <br /> within seconds." />
                  </p>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <p>
                    <T _str="100% Green Electricity" />
                  </p>
                  <p>
                    <UT _str="Sourced from renewable solar, <br /> wind, and hydro." />
                  </p>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
      {rate === null && <div style={{ height: 80 }}></div>}
      {/*Dynamic Pricing Graph Section*/}
      <div>{rate?.ostrom && <NewDynamicPricingGraph />}</div>
      <ReviewComponent />
      <ServiceSection />

      <Dialog
        open={openReferralDlg}
        onClose={() => {
          setOpenReferralDlg(false)
        }}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="referral-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            className="dialog-close-icon"
            size="small"
            onClick={() => {
              setOpenReferralDlg(false)
            }}
          >
            <SvgImage name="clarity_window-close-line" />
          </IconButton>
          <div className="dialog-icon-container">
            <SvgImage name="friend-referral" className="dialog-icon" />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={"dialog-content"}>
            <DialogContentText id="alert-dialog-description">
              {t(
                "Know an existing Ostrom client? Enter a referral code and you both get a 35€ bonus for switching to green energy!"
              )}
            </DialogContentText>
            <TextField
              placeholder={t("Referral Code")}
              defaultValue={referralCode}
              onChange={e => {
                setReferralCode(e.value)
              }}
              maxLength={10}
            />
            {errorRefCode !== "" && (
              <Alert className={"mt-20"} severity="warning">
                {errorRefCode}
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions className={"dialog-action"}>
          <Button
            onClick={() => {
              setOpenReferralDlg(false)
            }}
            className={"btn-primary round-50"}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleValidateReferralCode()
            }}
            className={"btn-primary secondary-color round-50"}
            autoFocus
          >
            {t("Apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(NewTariffDeskTop)
