import { Button, ButtonBaseProps, ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@mui/material"
import React, { ReactNode } from "react"

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

const LoadingButton = ({ ...props }: LoadingButtonProps) => {
  return (
    <Button {...props}>
      {props.loading ? (
        <img src={require("../../assets/images/ostrom-loader-blue.gif")} alt={"Loader"} style={{ width: "30px" }} />
      ) : (
        props.children
      )}
    </Button>
  )
}

export default LoadingButton
