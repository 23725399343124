import React from "react"
import { DIRECT_DEBIT, PAYMENT_PAGE_PATH } from "../../constants/common"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { T } from "@transifex/react"
import { useShallow } from "zustand/react/shallow"

import { usePaymentModel } from "../../models/usePaymentFormModel"
import { useNavigate } from "react-router-dom"
import { scrollToSection } from "../../utils"

export default function SummaryPaymentInfoCard() {
  const {
    isDifBillingAdr,
    paymentMethod,
    accountHolderName,
    iban,
    billingFirstName,
    billingCity,
    billingHouseNumber,
    billingStreet,
    billingSurname,
    billingZip,
  } = usePaymentModel(useShallow(state => state))

  const navigate = useNavigate()

  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Payment Details"} />
          </div>
        </div>
        <div
          className={"btn-edit"}
          onClick={() => {
            scrollToSection("stepper-container-section")
            navigate(`${PAYMENT_PAGE_PATH}${window.location.search}`)
          }}
        >
          <SvgImage name={"edit"} />
          <p>
            <T _str={"edit"} />
          </p>
        </div>
      </div>
      <div className={"info-card-content"}>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"Type"} />
          </div>
          <div className={"item-value"}>
            {paymentMethod === DIRECT_DEBIT && <T _str={"Direct Debit"} />}
            {paymentMethod !== DIRECT_DEBIT && <T _str={"TRANSFER"} />}
          </div>
        </div>

        {paymentMethod === DIRECT_DEBIT && (
          <>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"Account holder"} />
              </div>
              <div className={"item-value"}>{accountHolderName.value}</div>
            </div>
            <div className={"info-col"} style={{ minWidth: "100%" }}>
              <div className={"item-description"}>
                <T _str={"IBAN"} />
              </div>
              <div className={"item-value"}>{iban.value}</div>
            </div>
          </>
        )}
        {isDifBillingAdr && (
          <>
            <div className={"info-col info-col-subheading"} style={{ minWidth: "100%" }}>
              <T _str={"Billing Address"} />
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"First Name"} />
              </div>
              <div className={"item-value"}>{billingFirstName.value}</div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"Last Name"} />
              </div>
              <div className={"item-value"}>{billingSurname.value}</div>
            </div>
            <div className={"info-col"}>
              <div className={"item-description"}>
                <T _str={"Address"} />
              </div>
              <div className={"item-value"}>
                {billingStreet.value} {billingHouseNumber.value}, {billingZip.value} {billingCity.value}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={"info-card-content"}></div>
    </div>
  )
}
