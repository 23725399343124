import React, { useMemo } from "react"
import { Col1Type } from "../types/generic"
import { t } from "@transifex/native"
import { T } from "@transifex/react"
import { ANNAUL_FEE_PRICE_CAP, PRICE_CAP_AMOUNT } from "../constants/common"
import SmartMeter from "../assets/images/smart-meter.png"

export default function useTableColumns({ priceCapAvailable, savingsAvailable }) {
  const isMobile = window.innerWidth < 768

  let col1 = [
    {
      label: t("Unit Price"),
      tooltip: t(
        "The cost price per kWh of electricity, defined by the renewable energy plants generating your power."
      ),
    },
    {
      label: t("Grid Fees"),
      tooltip: t(
        "Fees for the usage and maintenance of the grid. They include the metering charges according to the T&C."
      ),
    },
    {
      label: t("Basic Fee"),
      tooltip: t(
        "This is Ostrom's service and administration fee. Traditional providers often add this fee as part of the unit price, which means the more you use, the more they make. However, we don’t want to profit from additional energy consumption—if anything we want to reduce it. So, we’ve made this a flat monthly fee. Just enough to pay our bills, too."
      ),
    },
    {
      label: t("Emission Savings"),
      tooltip: t(
        `The CO₂ emissions you'll save by choosing Ostrom as your green energy supplier compared to the energy generated from the average energy blend in Germany. These calculations are based on information from the `
      ),
      link: (
        <a
          target="_blank"
          href="https://www.umweltbundesamt.de/sites/default/files/medien/1410/publikationen/2020-04-01_climate-change_13-2020_strommix_2020_fin.pdf"
          rel="noreferrer"
        >
          Umwelt Bundesamt.
        </a>
      ),
    },
    {
      label: t("Smart Meter Required"),
      component: (
        <div className={"smart-meter-tooltip"}>
          <div className={"tariff-tooltip-2"} style={{ fontSize: 14 }}>
            <p className={"subtext"}>
              <T
                _str={
                  "Smart meters have an integrated communication gateway that regularly sends us your consumption data so we can bill you at the current hourly rate."
                }
              />
            </p>
            {isMobile ? (
              <>
                <p className={"subtext sub-heading"}>
                  <strong>
                    <T _str={"Do you have a smart meter installed?"} />
                  </strong>
                </p>
                <p className={"subtext"}>
                  <T
                    _str={
                      "You could save money with our SimplyDynamic tariff. Just sign up for our SimplyFair tariff and we’ll be in touch once we’ve validated your smart meter."
                    }
                  />
                </p>
                <p className={"subtext sub-heading"}>
                  <strong>
                    <T _str={"Interested in purchasing a smart meter?"} />
                  </strong>
                </p>
                <p className={"subtext"}>
                  <T
                    _str={"We also sell them! Order yours {link}."}
                    link={
                      <a
                        className="smart-link"
                        href="https://www.ostrom.de/smart-meter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <strong>
                          <T _str={"here"} />
                        </strong>
                      </a>
                    }
                  />
                </p>
              </>
            ) : (
              <>
                <p className={"subtext"}>
                  <T
                    _str={
                      "There's lots of different types of smart meters, but if you do have one it will look something like the one to the right. "
                    }
                  />
                </p>
                <br />
                <span style={{ fontSize: 10, fontStyle: "italic" }}>
                  <T _str={"Source: Lackmann"} />
                </span>
              </>
            )}
          </div>
          {!isMobile && (
            <div>
              <img style={{ width: 116, height: 181, paddingTop: 12 }} src={SmartMeter} alt="smart meter" />
            </div>
          )}
        </div>
      ),
    },
    {
      label: t("Hourly Billing"),
      component: (
        <div className={"tariff-tooltip-2"} style={{ fontSize: 14 }}>
          <p className={"subtext"} style={{ paddingBottom: 16 }}>
            <T
              _str={
                "The electricity price on the wholesale energy market varies hourly. In times of heavy winds, sunshine, or at night, electricity is cheaper."
              }
            />
          </p>
          <p className={"subtext"}>
            <T
              _str={
                " With our dynamic tariff you're billed by the hour, so you can take advantage of this by consuming electricity at the cheapest times."
              }
            />
          </p>
        </div>
      ),
    },
    {
      label: t("Price Cap Add-On"),
      optionalText: false,
      showPriceCap: priceCapAvailable,
      component: (
        <div className={"tariff-tooltip-2"} style={{ fontSize: 14 }}>
          <p className={"subtext"} style={{ paddingBottom: 16 }}>
            <T _str={"For an annual fee of "} />
            {`${ANNAUL_FEE_PRICE_CAP} € `}
            <T _str={"we guarantee that your unit price will not rise above "} />
            {PRICE_CAP_AMOUNT}
            <T _str={" cents/kWh "} />
            <T
              _str={
                "for a year. During that time you will never pay more than this price cap, even if the energy market fluctuates."
              }
            />
          </p>
          <p className={"subtext"}>
            <T _str={"More info when you click “Join Ostrom”."} />
          </p>
        </div>
      ),
    },
    {
      label: t("The Ostrom App!"),
      subtext: t("Track and optimise your usage, payments, and smart products—all in one place!"),
    },
    {
      label: t("100% Green Electricity"),
      subtext: t("Sourced from TÜV certified renewable energy."),
    },
    ...(savingsAvailable ? [{ label: t("Monthly savings") }] : []),
  ] as Col1Type[]

  return useMemo(() => col1, [priceCapAvailable, savingsAvailable])
}
