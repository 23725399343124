import NavigationWrapper from "../../layouts/NavigationWrapper"
import ReferralHeader from "../ReferralHeader/ReferralHeader"
import "./ReferralPage.scss"
import ServiceCards from "../ServiceCards/ServiceCards"
import ReviewComponent from "../Reviews"
import DownloadApp from "../DownloadApp/DownloadApp"
import ServiceSection from "../ServiceSection/ServiceSection"
import Generators from "../Generators/Generators"
import CalculateTariff from "../CalculateTariff/CalculateTariff"
import ReferralTariffCard from "../ReferralTariffCard/ReferralTariffCard"
import React, { useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useResponsive } from "../../hooks/useResponsive"

const ReferralPage = () => {
  const [code, setCode] = useState(null)
  const [refData, setRefData] = useState(null)

  const location = useLocation()

  const getRefCode = code => {
    setCode(code)
  }

  const getRefData = refData => {
    setRefData(refData)
  }

  if (!location.search.startsWith("?referralCode=")) {
    return <Navigate to="/tariff-plan" replace={true} />
  }

  return (
    <NavigationWrapper>
      <div className={"referral-page"}>
        <ReferralHeader getRefCode={getRefCode} getRefData={getRefData} refHeader={code} />
        <div className={"ref-tariff-card-wrapper"}>
          <ReferralTariffCard refCode={code} refData={refData} getRefCode={getRefCode} />
        </div>
        <ServiceCards />
        <div className={"referral-review"}>
          <ReviewComponent />
        </div>
        <DownloadApp />
        <Generators />
        <ServiceSection />
        <CalculateTariff refCode={code} refData={refData} getRefCode={getRefCode} />
      </div>
    </NavigationWrapper>
  )
}

export default React.memo(ReferralPage)
