import { useMemo } from "react"
import useRateCalculator from "./useRateCalculator"
import { PRICE_CAP_AMOUNT } from "../constants/common"

export default function useIsPriceCapAvailable() {
  const { unitPricePerKwh } = useRateCalculator()

  const priceCapAvailable = useMemo(() => {
    return unitPricePerKwh < PRICE_CAP_AMOUNT
  }, [unitPricePerKwh])

  return priceCapAvailable
}
