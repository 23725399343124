import { useCallback } from "react"
import { usePlanFormModel } from "../models/usePlanFormModel"
import { enqueueSnackbar } from "notistack"
import { t } from "@transifex/native"
import { MAX_USAGE, MIN_USAGE } from "../constants/common"

export default function useValidatePlanForm() {
  const validatePlanForm = useCallback(() => {
    const { postalCode, usage } = usePlanFormModel.getState()

    if (!postalCode) {
      enqueueSnackbar(t("We could not find zip code"), {
        variant: "warning",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      })
      return false
    }
    if (postalCode?.length < 4 || postalCode?.length > 5) {
      enqueueSnackbar(t(`Please input address and consumption between 600 and 15000 kWh`), {
        variant: "warning",
      })
      return false
    }
    if (!usage) {
      enqueueSnackbar(t("Please add usage"), {
        variant: "warning",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      })
      return false
    }
    if (+usage < MIN_USAGE || +usage > MAX_USAGE) {
      enqueueSnackbar(t(`Please input consumption between 600 and 15000 kWh`), {
        variant: "warning",
      })
      return false
    }
    return true
  }, [])

  return { validatePlanForm }
}
