import React, { useEffect, useState } from "react"

import { SvgImage } from "../assets/svgs/SvgImage"
import "./Header.scss"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import LanguageSwicher from "../components/LanguageSwicher/LanguageSwicher"
import { useLocation, useNavigate } from "react-router-dom"

import { T } from "@transifex/react"
import MobileMenu from "../components/MobileMenu/MobileMenu"
import { scrollToSection } from "../utils"
import { AppBar, Button, Container, Toolbar, Typography } from "@mui/material"
import { useResponsive } from "../hooks/useResponsive"

function openNewTab(url) {
  window.open(url)
}

export default function Header() {
  const { deviceType } = useResponsive()
  const location = useLocation()
  const navigate = useNavigate()
  const [showTariff, setShowTariff] = useState(false)

  function historyListen() {
    if (location.pathname === "/thank-you" || location.pathname === "/maintenance") {
      setShowTariff(true)
    }
  }

  useEffect(() => {
    historyListen()
    // eslint-disable-next-line
  }, [location])

  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }

  const handleClickLogo = () => {
    window.open("https://www.ostrom.de", "_blank", "noopener")
  }

  const handleGoTariff = () => {
    scrollToSection()
    navigate("/tariff-plan")
  }
  return (
    <>
      {deviceType.deskTop && (
        <AppBar color={"inherit"} className={"app-bar"}>
          <Container className="app-bar-container">
            <Toolbar>
              <Typography variant="h6" onClick={handleClickLogo}>
                <SvgImage name={"logo-green-dark"} className={"logo"} />
              </Typography>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {location.pathname === "/maintenance" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={"link-label"}
                      onClick={() => openNewTab(`${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-team`)}
                    >
                      <T _str="Our Team" />
                    </div>
                    <div
                      className={"link-label"}
                      onClick={() => openNewTab(`${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-generators`)}
                    >
                      <T _str="Our Generators" />
                    </div>
                    <div
                      className={"link-label"}
                      onClick={() => openNewTab(`${process.env.REACT_APP_OSTROM_WEBSITE_URL}/faq`)}
                    >
                      <T _str="FAQs" />
                    </div>
                    <div
                      className={"link-label"}
                      onClick={() => openNewTab(`${process.env.REACT_APP_OSTROM_WEBSITE_URL}/blog`)}
                    >
                      <T _str="Blog" />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginLeft: 15,
                  }}
                >
                  <LanguageSwicher className={"mx-20 ml-40"} />
                  {showTariff === true && (
                    <Button color="inherit" className={"tariff-btn mx-10"}>
                      <div
                        className={"label"}
                        onClick={() => {
                          handleGoTariff()
                        }}
                      >
                        <T _str="Calculate Tariff" />
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      )}
      {deviceType.mobile && (
        <AppBar color={"inherit"} className={"mobile-app-bar"}>
          <Toolbar>
            <Typography variant="h6" onClick={handleClickLogo}>
              <SvgImage name={"logo-green-dark"} className={"mobile-logo"} />
            </Typography>
            <MobileMenu />
          </Toolbar>
        </AppBar>
      )}
    </>
  )
}
