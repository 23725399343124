import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material"
import { t } from "@transifex/native"
import { T } from "@transifex/react"
import React, { useEffect, useState } from "react"
import "./DynamicPricingDialog.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import Transition from "../Transition/Transition"

export function DynamicPricingDialog({ openStatus, onClose, onOK, onBack, testID }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(openStatus)
  }, [openStatus])
  useEffect(() => {
    if (!open) {
      onClose()
    }
    // eslint-disable-next-line
  }, [open])
  // eslint-disable-next-line
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dynamic-pricing-dialog"
      maxWidth={"sm"}
      data-test-id={"dynamic-pricing-dialog"}
    >
      <DialogTitle id="alert-dialog-title" style={{ fontSize: 18, fontWeight: "600" }}>
        <IconButton
          className="dialog-close-icon"
          size="small"
          onClick={() => {
            setOpen(false)
          }}
        >
          <SvgImage name="close-icon" />
        </IconButton>
        <T _str={"Confirmation needed!"} />
      </DialogTitle>
      <DialogContent>
        <div className={"dialog-content"}>
          <p>
            <T
              _str={
                "Our dynamic tariff can only be utilized if you have a Smart Meter. If your yearly consumption is below 6.000 kWh (or pv panels below 7kW Peak), it is unlikely that your meter is fully smart. Fully smart means your meter has an installed gateway and can send real-time data."
              }
            />
          </p>
          <div>
            <p className={"sub-header"}>
              <T _str={"Still not quite sure?"} />
            </p>
            <p>
              <T
                _str={
                  "Contact our Customer Support, and we can help you! In the meantime, you can sign up with our SimplyFair tariff now, and if you do have a smart meter, we’ll switch you over to our SimplyDynamic tariff once confirmed."
                }
              />
            </p>
          </div>
          <div>
            <p className={"sub-header"}>
              <T _str={"Don’t have a smart meter?"} />
            </p>
            <p>
              <T
                _str={
                  "Just sign up for the SimplyFair tariff and hold tight! Soon we’ll be selling smart meters directly, so you can easily switch to SimplyDynamic once you’ve purchased one."
                }
              />
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={"dialog-action"}>
        <Button
          onClick={() => {
            onClose(false)
            onOK()
          }}
          className={"btn-primary secondary-color round-50"}
          autoFocus
          data-test-id={`${testID}-ok`}
        >
          {t("Ok, I understand")}
        </Button>
        <Button
          onClick={() => {
            onClose(false)
            onBack()
          }}
          className={"btn-primary secondary-color round-50 outline"}
          autoFocus
          data-test-id={`${testID}-back`}
        >
          {t("Go back to tariffs")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
