import { DescriptionOutlined, InsertDriveFileOutlined } from "@mui/icons-material"
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material"
import { T, useLocale } from "@transifex/react"
import React from "react"
import { usePartnerModel } from "../../../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { t } from "@transifex/native"
import { PartnerApi } from "../../../service/partnerApi"
import { enqueueSnackbar } from "notistack"
import LoadingButton from "../../../components/LoadingButton"
import { useNavigate } from "react-router-dom"

export default function PartnerPermission() {
  const [loading, setLoading] = React.useState(false)

  const navigate = useNavigate()

  const ScopeData = {
    order: {
      icon: (
        <DescriptionOutlined
          sx={{
            color: "#00c1b1",
            width: "32px",
            height: "32px",
          }}
        />
      ),
      title: t("View order data"),
    },
    contract: {
      icon: (
        <InsertDriveFileOutlined
          sx={{
            color: "#00c1b1",
            width: "32px",
            height: "32px",
          }}
        />
      ),
      title: t("View your contract data"),
    },
    consumption: {
      icon: <img alt="" src={require("../../../assets/images/ClockCountdown.png")} height={32} />,
      title: t("View smart meter consumption"),
    },
  }

  const { linkData, user, selectedContract } = usePartnerModel(
    useShallow(state => ({
      linkData: state.linkData,
      user: state.user,
      selectedContract: state.selectedContract,
    }))
  )

  const handleConfirm = async () => {
    if (user?.confirmationCode && selectedContract?.id) {
      setLoading(true)
      try {
        await PartnerApi.applicationLinkConfirmation(linkData.id, {
          confirmationCode: user.confirmationCode,
          contractId: selectedContract.id,
        })
        navigate("/link/connection-success")
      } catch (e) {
        const message = e?.response?.data?.message || e?.response?.data?.error || t("Something went wrong")
        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
          autoHideDuration: 3000,
        })
      } finally {
        setLoading(false)
      }
    } else {
      enqueueSnackbar("Confirmation code not found", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
        autoHideDuration: 3000,
      })
    }
  }

  const locale = useLocale()

  return selectedContract && user?.scopes?.length ? (
    <div>
      <h3 className="permission-title" style={{ textAlign: "center" }}>
        <T _str="This will allow {partnerName} to" partnerName={linkData.partnerName} />:
      </h3>
      <List>
        {user.scopes.map(scope => {
          const scopeType = scope.substring(0, scope.indexOf(":"))
          return (
            <ListItem
              key={scope}
              disablePadding
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                mb: 1,
              }}
            >
              <ListItemButton
                sx={{
                  height: "60px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                  }}
                >
                  {ScopeData[scopeType].icon}
                </ListItemIcon>
                <ListItemText
                  primary={ScopeData[scopeType].title}
                  primaryTypographyProps={{
                    id: "partner-permission1",
                  }}
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
      <Box sx={{ mt: 2 }}>
        <p className="confirm-text">
          <T
            _str="By tapping confirm, I consent to my personal data being passed on to {partnerName} in connection with my energy contract for the purpose of analysis and consolidated customer support. This consent can be revoked at any time by contacting Ostrom. Further information can be found in the {linkUrl}."
            partnerName={<strong>{linkData.partnerName}</strong>}
            linkUrl={
              <a href={`https://ostrom.webflow.io/privacy-policy?lang=${locale?.includes("de") ? "de" : "en"}`}>
                <strong>
                  <T _str="privacy policy" />
                </strong>
              </a>
            }
          />
        </p>
      </Box>
      <LoadingButton
        loading={loading}
        disabled={loading}
        onClick={handleConfirm}
        variant="contained"
        fullWidth
        id="main-btn"
        sx={{ mt: 3 }}
      >
        <T _str={"Confirm"} />
      </LoadingButton>
    </div>
  ) : null
}
