import { T } from "@transifex/react"
import React from "react"
import { useShallow } from "zustand/react/shallow"

import { useGeneralModel } from "../../models/useGeneralModel"
import useRateCalculator from "../../hooks/useRateCalculator"
import { LETTER_M, MONTH, YEAR } from "../../constants/common"
import { getCommaSeparatedTwoDecimalsNumber } from "../../utils/helpers"

export default function SummaryTarriffInfoCard() {
  const { isDynamic, currentPlan } = useGeneralModel(useShallow(state => state))
  const {
    tariff,
    tariffDynamic,
    averageFeeDynamic,
    unitPricePerKwh,
    energyNetworkFee,
    energyNetworkFeeDynamic,
    basicFee,
    basicFeeDynamic,
  } = useRateCalculator()
  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Tariff Details"} />
          </div>
        </div>
      </div>
      <div className={"info-card-content"}>
        <div className={"info-col web"}>
          <div className={"item-description"}>
            <T _str={"Tariff"} />
          </div>
          <div className={"item-value"}>{!isDynamic ? <T _str={"SimplyFair"} /> : <T _str={"SimplyDynamic"} />}</div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"MONTHLY DEPOSIT"} />
          </div>
          <div className={"item-value"}>
            {!isDynamic ? tariff : tariffDynamic} <T _str={"€ /month"} />
          </div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"UNIT PRICE"} />
          </div>
          <div className={"item-value"}>
            {!isDynamic
              ? getCommaSeparatedTwoDecimalsNumber(unitPricePerKwh)
              : getCommaSeparatedTwoDecimalsNumber(averageFeeDynamic)}
            {" ct/kWh"}
          </div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"GRID FEES"} />
          </div>
          <div className={"item-value"}>
            {!isDynamic
              ? getCommaSeparatedTwoDecimalsNumber(energyNetworkFee)
              : getCommaSeparatedTwoDecimalsNumber(energyNetworkFeeDynamic)}
            {" €/"}
            <T _str={currentPlan === LETTER_M ? MONTH : YEAR} />
          </div>
        </div>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"BASIC FEE"} />
          </div>
          <div className={"item-value"}>
            {!isDynamic
              ? getCommaSeparatedTwoDecimalsNumber(basicFee)
              : getCommaSeparatedTwoDecimalsNumber(basicFeeDynamic)}
            {" €/"}
            <T _str={currentPlan === LETTER_M ? MONTH : YEAR} />
          </div>
        </div>
      </div>
    </div>
  )
}
