import { T } from "@transifex/react"
import "./Generators.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import GeneratorMap from "../../assets/images/generator-map.png"
import GeneratorCard from "../GeneratorCard/GeneratorCard"
import React, { useState } from "react"

const Generators = () => {
  const [counter, setCounter] = useState(0)
  return (
    <div className={"generators-wrapper"} data-test-id={"generators-container"}>
      <div className={"generator-heading"}>
        <h2>
          <T _str={"Where Ostrom Gets Power\n"} />
        </h2>
        <p>
          <T
            _str={
              "We partner with generators in Germany to directly purchase their electricity wherever we can, as well as buying renewable electricity on the wholesale market. This way we can be sure that for every unit you use, a new solar, wind, or hydro unit is produced and put on the grid."
            }
          />
        </p>
      </div>
      <div className={"generator-map-card"}>
        <div className={"generator-map"} data-test-id={"generator-map-id"}>
          <SvgImage
            name={"generator-icon"}
            className={"generator-icon generator-icon-0"}
            onClick={() => setCounter(0)}
            dataTestId={"generator-icon-0"}
          />
          <SvgImage
            name={"ostrom-generator-icon"}
            className={"generator-icon generator-icon-1"}
            dataTestId={"generator-icon-1"}
            onClick={() => setCounter(1)}
          />
          <img src={GeneratorMap} alt={"Generator map"} className={"generator-svg"} />
        </div>
        <div className={"generator-details"}>
          <div className={"generator-header"}>
            <h2>
              <T _str={"Our Generators are Genuinely Green"} />
            </h2>
            <p>
              <T
                _str={
                  "We have only partnered with reliable green electricity providers across Germany. So you are always confident where your Ostrom power comes from. See for yourself!"
                }
              />
            </p>
          </div>
        </div>
        <div className={"generator-card-wrapper"} data-test-id={"generator-card-id"}>
          <GeneratorCard setGenerator={counter} />
        </div>
      </div>
    </div>
  )
}

export default Generators
