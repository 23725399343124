import { T } from "@transifex/react"
import "./GeneratorCard.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { Generators } from "../../constants/common"
import React, { useEffect, useState } from "react"

const GeneratorCard = ({ setGenerator }) => {
  const [counter, setCounter] = useState(0)
  const [currentGenerator, setCurrentGenerator] = useState(Generators[counter])
  const [sliderClass, setSliderClass] = useState("generator-card-item")

  const setSlider = (direction = "next") => {
    direction === "next"
      ? setSliderClass("generator-card-item generator-slide")
      : setSliderClass("generator-card-item generator-slide-previous")

    setTimeout(() => {
      setSliderClass("generator-card-item")
    }, 300)
  }

  const onPrevious = () => {
    if (counter === 0) {
      setCounter(Generators.length - 1)
      setSlider("previous")
      return
    }
    setSlider("previous")
    setCounter(prevState => prevState - 1)
  }

  const onNext = () => {
    if (counter === Generators.length - 1) {
      setCounter(0)
      setSlider()
      return
    }
    setSlider()
    setCounter(prevState => prevState + 1)
  }

  useEffect(() => {
    setCurrentGenerator(Generators[counter])
  }, [counter])

  useEffect(() => {
    setCounter(setGenerator)
  }, [setGenerator])

  return (
    <div className={"generator-card"} data-test-id={"generator-card-item-container"}>
      <div className={sliderClass} data-test-id={`generator-card-item-${counter}`}>
        <div className={"generator-image"}>
          <img src={currentGenerator.image} alt={"Generator"} />
        </div>
        <div className={"generator-card-details"}>
          <h3 className={"generator-title"}>{currentGenerator.title}</h3>
          <div className={"generator-info"}>
            <div className={"generator-info-list"}>
              <div className={"generator-info-list-item"}>
                <h4>
                  <T _str={"Location"} />
                </h4>
                <p>
                  <T _str={currentGenerator.location} />
                </p>
              </div>
            </div>
            <div className={"generator-info-list"}>
              <div className={"generator-info-list-item"}>
                <h4>
                  <T _str={"Generation type"} />
                </h4>
                <p>
                  <T _str={currentGenerator.type} />
                </p>
              </div>
            </div>
          </div>
          <div className={"generator-description"}>
            <h4>
              <T _str={"Why is it special?"} />
            </h4>
            <p>
              <T _str={currentGenerator.special} />
            </p>
          </div>
        </div>
      </div>
      <div className={"generator-action"} data-test-id={"generator-action"}>
        <div className={"generator-action-item"} onClick={onPrevious} data-test-id={"generator-action-item-previous"}>
          <SvgImage className={"generator-action-icon"} name={"previous"} />
          <p>
            <T _str={"Previous"} />
          </p>
        </div>
        <div className={"generator-action-item"} onClick={onNext} data-test-id={"generator-action-item-next"}>
          <p>
            <T _str={"Next"} />
          </p>
          <SvgImage name={"next"} />
        </div>
      </div>
    </div>
  )
}

export default GeneratorCard
