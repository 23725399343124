import React from "react"
import { CHANGE_OF_SUPPLIER, DEFAULT_VALUE_0, DEFAULT_VALUE_1, DELIVERY_PAGE_PATH } from "../../constants/common"
import { T } from "@transifex/react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useDeliveryFormModel } from "../../models/useDeliveryFormModel"
import { useGeneralModel } from "../../models/useGeneralModel"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { useNavigate } from "react-router-dom"
import { scrollToSection } from "../../utils"
import { useShallow } from "zustand/react/shallow"

export default function SummaryDeliveryInfoCard() {
  const {
    previousSupplierId,
    noMarketLocationId,
    movingDate,
    terminatedFromDate,
    noMeterId,
    meterNumber,
    marketLocationId,
  } = useDeliveryFormModel(useShallow(state => state))
  const { electricityContract, electricityContractType, deliveryTab2, deliveryTab1, oldSupplier } = useGeneralModel(
    useShallow(state => state)
  )
  const planForm = usePlanFormModel(useShallow(state => state))

  const navigate = useNavigate()

  function getSupplier() {
    try {
      let idx = oldSupplier.findIndex(item => item.codeNumber === previousSupplierId.value)
      return oldSupplier[idx].name
    } catch (e) {
      return previousSupplierId.value
    }
  }

  const getSupplierName = () => {
    if (previousSupplierId.value) {
      return oldSupplier.find(supplier => supplier.id === previousSupplierId.value)?.name || ""
    }
  }

  return (
    <div className={"info-card"}>
      <div className={"info-card-label"}>
        <div className={"info-title"}>
          <div>
            <T _str={"Delivery"} />
          </div>
        </div>
        <div
          className={"btn-edit"}
          onClick={() => {
            scrollToSection("stepper-container-section")
            navigate(`${DELIVERY_PAGE_PATH}${window.location.search}`)
          }}
        >
          <SvgImage name={"edit"} />
          <p>
            <T _str={"edit"} />
          </p>
        </div>
      </div>
      <div className={"info-card-content"}>
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"Type"} />
          </div>
          <div className={"item-value"}>
            {electricityContractType === CHANGE_OF_SUPPLIER && <T _str={"Change Supplier"} />}
            {electricityContractType !== CHANGE_OF_SUPPLIER && <T _str={"Move"} />}
          </div>
        </div>
        {electricityContractType === CHANGE_OF_SUPPLIER && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"CURRENT PROVIDER"} />
            </div>
            <div className={"item-value"} title={getSupplier()}>
              {previousSupplierId.value && getSupplierName()}
            </div>
          </div>
        )}
        {deliveryTab2 === DEFAULT_VALUE_0 && deliveryTab1 === DEFAULT_VALUE_0 && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"MARKET LOCATION ID"} />
            </div>
            <div className={"item-value"}>{noMarketLocationId.value ? "" : marketLocationId.value}</div>
          </div>
        )}
        {(deliveryTab2 === DEFAULT_VALUE_1 || deliveryTab1 === DEFAULT_VALUE_1) && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"METER ID"} />
            </div>
            <div className={"item-value"}>
              {noMeterId.value ? <T _str={"Will submit within 14 days"} /> : meterNumber.value}
            </div>
          </div>
        )}
        <div className={"info-col"}>
          <div className={"item-description"}>
            <T _str={"Yearly Consumption"} />
          </div>
          <div className={"item-value"}>{planForm?.usage} kWh</div>
        </div>
        {electricityContractType !== CHANGE_OF_SUPPLIER && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"DATE OF KEY HANDOVER"} />
            </div>
            <div className={"item-value"}>{movingDate.value}</div>
          </div>
        )}
        {electricityContractType === CHANGE_OF_SUPPLIER && electricityContract === "yes" && (
          <div className={"info-col"}>
            <div className={"item-description"}>
              <T _str={"Terminate in date"} />
            </div>
            <div className={"item-value"}>{terminatedFromDate.value}</div>
          </div>
        )}
      </div>
    </div>
  )
}
