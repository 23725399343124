import { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"
import Cookie from "js-cookie"

import { usePartnerModel } from "../models/usePartnerModel"
import { ENGLISH } from "../constants/common"
import { useTX } from "@transifex/react"

function usePartnerLanguage() {
  const linkData = usePartnerModel(useShallow(state => state.linkData))

  const txc = useTX()

  useEffect(() => {
    if (linkData) {
      const engLang = linkData?.language?.includes("en")
      txc.setCurrentLocale(engLang ? ENGLISH : "de_DE")
      Cookie.set("locale", engLang ? ENGLISH : "de_DE")
    }
  }, [linkData])
}

export default usePartnerLanguage
