import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Container } from "@mui/material"
import { T, useLocale } from "@transifex/react"
import { useLocation, useNavigate } from "react-router-dom"

import { SvgImage } from "../../assets/svgs/SvgImage"
import "./ThankYou.scss"
import Cookie from "js-cookie"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import enImg from "../../assets/images/phone-mockup-en.png"
import deImg from "../../assets/images/phone-mockup-de.png"
import {
  CHECK_FAIL_PAGE,
  ENGLISH,
  EnglishText,
  GermanText,
  PENDING_PAGE_PATH,
  PENDING_SIGNUP_PAGE,
  REFERRAL_BONUS,
  THANK_YOU_PAGE,
  THANK_YOU_PAGE_PATH,
} from "../../constants/common"
import { PendingApplicationDescription } from "../../components/PendingApplicationDescription"
import { usePartnerModel } from "../../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { useUserInfoModel } from "../../models/userInfoModel"
import { usePaymentModel } from "../../models/usePaymentFormModel"
import { useGeneralModel } from "../../models/useGeneralModel"
import { useDeliveryFormModel } from "../../models/useDeliveryFormModel"

const ThankYouMessage = ({ classStyle }) => {
  return (
    <div className={classStyle}>
      <T _str={"...In the mean time, why not tell your friends about Ostrom. When they sign up using"} />{" "}
      <T _str={"your unique referral code"} />{" "}
      <span className={"sub-title"}>
        <T _str={"you’ll both get up to"} /> <span style={{ whiteSpace: "nowrap" }}>{`${REFERRAL_BONUS} €!`}</span>
      </span>
    </div>
  )
}

export default function ThankYou() {
  const reviews = [
    {
      comment:
        '"That was easy! I switched from my old provider to Ostrom because their tariff is super transparent and everything is digital. Switching was absolutely uncomplicated and without any annoying paperwork."',
      userName: "Dante",
      provider: "1 Trustpilot",
    },
    {
      comment:
        '"As a foreigner in Germany, I am totally confused by the electricity setup. A friend of mine recommended Ostrom energy to me. [...] Their service is in English and everything is digital. Highly recommended!"',
      userName: "Jessica",
      provider: "2 Trustpilot",
    },
    {
      comment:
        '"That was easy! I switched from my old provider to Ostrom because their tariff is super transparent and everything is digital. Switching was absolutely uncomplicated and without any annoying paperwork."',
      userName: "Dante",
      provider: "3 Trustpilot",
    },
    {
      comment:
        '"As a foreigner in Germany, I am totally confused by the electricity setup. A friend of mine recommended Ostrom energy to me. [...] Their service is in English and everything is digital. Highly recommended!"',
      userName: "Jessica",
      provider: "4 Trustpilot",
    },
  ]

  let filterReviews = []

  let tabCount = 0

  reviews.forEach((item, index) => {
    tabCount = index / 2
    if (!filterReviews[tabCount]) {
      filterReviews[tabCount] = [item]
    } else {
      filterReviews[tabCount].push(item)
    }
  })

  const [lang, setLang] = useState(ENGLISH)
  const langValue = useLocale()
  const location = useLocation()
  const navigate = useNavigate()
  const termsAndConditionsUrl = `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-referrals`

  useEffect(() => {
    const locale = Cookie.get("locale")
    if (locale) setLang(locale)
    // clearFormCache()
    useUserInfoModel.getState().reset()
    usePaymentModel.getState().reset()
    useGeneralModel.getState().reset()
    useDeliveryFormModel.getState().reset()
  }, [])

  useEffect(() => {
    handleLangChange()
  }, [location])

  const handleLangChange = () => {
    let params = new URLSearchParams(location.search).get("language")
    if (params) {
      setLang(params)
    }
  }

  if (localStorage.getItem("customer-info") === null) {
    navigate("/tariff-plan")
  }

  const pageToDisplay = useMemo(() => {
    return location.pathname === "/pending-signup"
      ? "PendingSignUpPage"
      : location.pathname === "/thank-you"
        ? "ThankYouPage"
        : "CheckFailPage"
  }, [location.pathname])

  const customer = useMemo(() => {
    if (localStorage["customer-info"]) {
      return JSON.parse(JSON.parse(JSON.stringify(localStorage["customer-info"])))
    }
  }, [localStorage["customer-info"]])

  const referralCode = customer?.data?.refereeCode
  const referrerCode = customer?.data?.referrerCode
  const refereeCode = customer?.data?.refereeCode
  const referralPageLink = customer?.data?.referralPageLink

  const linkData = usePartnerModel(useShallow(state => state.linkData))

  const handleShareSocial = social => {
    const link = referralPageLink
    const description =
      langValue === ENGLISH
        ? `Hey! I've just signed up with Ostrom‚ a green energy provider with affordable, flexible monthly contracts. They'll even deal with your ex! Ugh, I mean your old provider. Also everything's in English! If you sign up with my referral code, we'll both get up to ${REFERRAL_BONUS} € when you make the switch`
        : `Hey! Ich hab mich gerade bei Ostrom angemeldet – einem digitalen Ökostromanbieter mit günstigen, flexiblen Monatsverträgen. Ich musste nicht mal meinen alten Vertrag kündigen, denn das Ostrom Team kümmert sich um alles! Wenn du dich mit meinem Empfehlungscode anmeldest, bekommen wir beide bis zu ${REFERRAL_BONUS} €!`

    let shareLink
    switch (social) {
      case "twitter":
        shareLink = `https://twitter.com/intent/tweet?url=${link}&text=${description}`
        window.open(shareLink, "_blank")
        break
      case "facebook":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${description}`
        window.open(shareLink, "_blank")
        break
      case "instagram":
        shareLink = `https://www.instagram.com/?url=${link}`
        window.open(shareLink, "_blank")
        break
      case "email":
        shareLink = `mailto:?subject=Ostrom Referral Code&body=${description} ${link}`
        window.open(shareLink, "_blank")
        break
      case "phone":
        shareLink = `https://api.whatsapp.com/send?text=${description}
        ${link}`
        window.open(shareLink, "_blank")
        break
      case "telegram":
        shareLink = `https://telegram.me/share/url?url=${link}&text=${description}`
        window.open(shareLink, "_blank")
        break
      default:
        break
    }
  }

  const handleCopy = () => {
    const textField = document.createElement("textarea")
    textField.innerText = referralCode
    document.body.appendChild(textField)
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999)
    } else {
      textField.select()
    }
    document.execCommand("copy")
    textField.remove()
  }

  if (pageToDisplay === "CheckFailPage") {
    return (
      <NavigationWrapper>
        <div className="referral-result-container">
          <div className={"referral-content-check"}>
            <div className="tariff-header">
              <div className="header-background"></div>
            </div>
            <div className={"message-background"}>
              <div className={"title-check"}>
                <T _str={"Thanks for signing up!"} />
              </div>
              <div className={"description-check"}>
                <T _str={"We’re currently processing your application. We’ll be in touch via email soon."} />{" "}
              </div>
            </div>
          </div>
        </div>
      </NavigationWrapper>
    )
  }

  return (
    <NavigationWrapper>
      <Container className="referral-result-container">
        <input type={"hidden"} id={"id"} name={"id"} value={customer?.data?.id} />
        <input type={"hidden"} id={"campaign"} name={"campaign"} value={customer?.data?.campaign} />
        <input type={"hidden"} id={"productCode"} name={"productCode"} value={customer?.data?.productCode} />
        <input type={"hidden"} id={"user-language"} name={"user-language"} value={customer?.data?.language} />
        <input type={"hidden"} id={"email"} name={"email"} value={customer?.data?.email} />
        <input type={"hidden"} id={"firstName"} name={"firstName"} value={customer?.data?.firstName} />
        <input type={"hidden"} id={"customerId"} name={"customerId"} value={customer?.data?.customerId} />
        <input type={"hidden"} id={"orderId"} name={"orderId"} value={customer?.data?.orderId} />
        <input type={"hidden"} id={"usage"} name={"usage"} value={customer?.data?.usage} />
        <input type={"hidden"} id={"price"} name={"price"} value={customer?.data?.price} />
        <input
          type={"hidden"}
          id={"pendingApplication"}
          name={"pendingApplication"}
          value={customer?.data?.pendingApplication}
        />
        <input type={"hidden"} id={"referrerCode"} name={"referrerCode"} value={referrerCode} />
        <input type={"hidden"} id={"refereeCode"} name={"refereeCode"} value={refereeCode} />
        <input type={"hidden"} id={"address-zip"} name={"address-zip"} value={customer?.data?.address?.zip} />
        <input type={"hidden"} id={"address-city"} name={"address-city"} value={customer?.data?.address?.city} />
        <input type={"hidden"} id={"address-street"} name={"address-street"} value={customer?.data?.address?.street} />
        <input
          type={"hidden"}
          id={"address-zip"}
          name={"address-houseNumber"}
          value={customer?.data?.address?.houseNumber}
        />
        <div className="tariff-header">
          <div className="header-background"></div>
          <div>
            <div className="header-title">{<T _str={"Welcome to Ostrom!"} />}</div>
          </div>
        </div>
        <div className={"referral-content"}>
          {/*Thank you Message content*/}
          {pageToDisplay === "ThankYouPage" ? (
            <div className={"default-container"}>
              <div className={"title"}>
                <T _str={"We’re glad you’re on board!"} />
                <br />
                <T _str={"The grid will process your application within a week."} />
              </div>
              <ThankYouMessage classStyle="description" />
            </div>
          ) : (
            <div className={"no-meter-id-message"}>
              <div className={"title"}>
                <T _str={"Just One More Step!"} />
              </div>
              <div className={"sub-title-no-meter-id"}>
                <T
                  _str={
                    "Don’t forget to send us your Zählernummer (Meter ID) or Marktlokations-ID within the next 14 days. Your application is on hold until we receive it."
                  }
                />
              </div>
              {/*Pending Application Description*/}
              <PendingApplicationDescription />
              <ThankYouMessage classStyle="description-no-meter-id" />
            </div>
          )}

          <Box
            className={"referral-code-card"}
            sx={{
              marginBottom: linkData ? "0 !important" : "-30px",
            }}
          >
            <div>
              <T _str={"Your referral code"} />
            </div>
            <div className={"referral-code"} onClick={handleCopy}>
              {referralCode}
              <div className={"copy-btn"}>
                <Button>
                  <SvgImage name={"copy"} />
                </Button>
              </div>
            </div>
            <div className={"referral-footer"}>
              <T _str={"Share referral code on"} />
              <div className={"social-links"}>
                <div
                  onClick={() => {
                    handleShareSocial("twitter")
                  }}
                >
                  <SvgImage name={"twitter"} />
                </div>
                <div
                  onClick={() => {
                    handleShareSocial("facebook")
                  }}
                >
                  <SvgImage name={"facebook"} />
                </div>
                <div
                  onClick={() => {
                    handleShareSocial("email")
                  }}
                >
                  <SvgImage name={"email-fill"} />
                </div>
                <div
                  onClick={() => {
                    handleShareSocial("phone")
                  }}
                >
                  <SvgImage name={"phone-contact"} />
                </div>
                <div
                  onClick={() => {
                    handleShareSocial("telegram")
                  }}
                >
                  <SvgImage name={"telegram"} />
                </div>
              </div>
            </div>
            <div className={"note-text"}>
              <div className={"description-note"}>
                <p>
                  {" "}
                  <span className={"sub-title-note"}>
                    <T _str={"Please note:"} />
                  </span>
                  <T
                    _str={
                      " Your referral code will be expired if shared in Ostrom assets such as Trustpilot and Google Reviews."
                    }
                  />
                </p>
                <div>
                  <a className={"terms-link"} target={"_blank"} rel="noreferrer" href={termsAndConditionsUrl}>
                    {" "}
                    <T _str={"View Terms & Conditions"} />
                  </a>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Container>
      {linkData ? (
        <Box
          textAlign={"center"}
          sx={{
            margin: "40px 0",
          }}
        >
          <Button href={linkData.redirectUrl} variant="contained" className={"linked-done-btn"}>
            <T _str={"Back to {partnerName}"} partnerName={linkData.partnerName} />
          </Button>
        </Box>
      ) : null}
      <div className="service-container">
        <div className={"background"}></div>
        <div className="title">
          <T _str={"Digitizing The Energy Market"} />
        </div>
        <div className="description">
          <T
            _str={
              'Because let’s be honest: it’s overdue. Simply sign up on our website and manage everything in our app. No need for "email templates" or translation apps!'
            }
          />
        </div>
        <div className={"service-item-container"}>
          <div className={"service-description-container"}>
            <div className={"sub_title"}>
              <T _str={"All In One Place"} />
            </div>
            <div className={"sub-description"}>
              <T
                _str={
                  "Access your annual invoices, submit and track your meter reading, and adapt your monthly payments all within the Ostrom app."
                }
              />
            </div>

            <div className={"sub_title"}>
              <T _str={"On-Demand Support"} />
            </div>
            <div className={"sub-description"}>
              <T
                _str={
                  "With our live customer support chat, get answers to your questions within seconds — not hours or days!"
                }
              />
            </div>

            <div className={"sub_title"}>
              <T _str={"English? German? You Choose!"} />
            </div>
            <div className={"sub-description"}>
              {langValue === ENGLISH ? (
                <p>
                  <span className={"sub-description-italic"}>Deutsche Sprache, schwere Sprache.</span>
                  {EnglishText}
                </p>
              ) : (
                <p>{GermanText}</p>
              )}
            </div>
            <div className={"phone-app-container"}>
              <a
                href="https://apps.apple.com/us/app/ostrom-energy-just-better/id1566216511"
                target={"_blank"}
                rel="noreferrer"
              >
                <SvgImage name={"AppStore"} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.ostrommobile"
                target={"_blank"}
                rel="noreferrer"
              >
                <SvgImage name={"Android"} />
              </a>
            </div>
          </div>
          <div className={"phone-img"}>
            {lang === "de_DE" ? (
              <img alt={"Phone"} src={deImg} width="306" />
            ) : (
              <img alt={"Phone"} src={enImg} width="306" />
            )}
          </div>
        </div>
      </div>
    </NavigationWrapper>
  )
}
