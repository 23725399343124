import { useEffect, useState } from "react"
import { useLocale } from "@transifex/react"
import "./PostcodeInput.scss"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { ENGLISH } from "../../constants/common"
import React from "react"

const PostcodeInput = ({ getPostCode, codeValue }) => {
  const [postCode, setPostCode] = useState("")
  const locale = useLocale()

  const onChangePostCode = e => {
    const code = e.target.value

    if (code.length > 5 || code === "") {
      const postNumber = () => {
        const previousValue = [...code]
        previousValue.splice(code.length - 1, 1)
        return previousValue.join("")
      }
      setPostCode(postNumber)
      getPostCode(postNumber)
      return
    }

    if (code.replace(/\s+/g, "") !== code) {
      setPostCode(code.replace(/\s+/g, ""))
      getPostCode(code.replace(/\s+/g, ""))
      return
    }

    const isValidPostalcode = [...code].every(c => "0123456789".includes(c))

    if (!isValidPostalcode) {
      return
    }

    setPostCode(code)
    getPostCode(code)
  }

  const onClear = () => {
    setPostCode("")
    getPostCode("")
  }

  useEffect(() => {
    setPostCode(codeValue)
  }, [codeValue])

  return (
    <div className={"postcode-input"}>
      <div className={"input-icon"}>
        <SvgImage name={"referral-location"} />
        <input
          value={postCode}
          placeholder={locale === ENGLISH ? "e.g. 12059" : "z. B. 12059"}
          onChange={onChangePostCode}
          data-test-id={"page-postcode-input"}
          className="postcode-input-referral"
        />
        <button onClick={onClear}>
          <SvgImage name={"referral-close"} />
        </button>
      </div>
    </div>
  )
}

export default PostcodeInput
