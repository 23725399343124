import React from "react"
import { T } from "@transifex/react"

import "./style.scss"
import { Link } from "@mui/material"

const NotFoundPage = () => {
  return (
    <div className="error-container">
      <div className="wrapper">
        <div className="text-error">
          <T _str={"Error"} />
        </div>
        <div className="text-404">
          <T _str={"404"} />
        </div>
        <div className="not-found">
          <T _str={"Page not found"} />
        </div>
        <div className="button">
          <Link href="/">
            <T _str="Go Back Home" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
