export type City = {
  id: number
  postcode: string
  name: string
}

export type Street = {
  id: number
  postcode: string
  name: string
}

export type Rate = {
  ostrom:
    | OstromRate[]
    | {
        monthly?: {
          savings?: number
        }
        yearly?: {
          savings?: number
        }
      }
  basicProvider?: string
  footprint: Footprint
  isPendingApplicationAllowed: boolean
  status: Status
  partnerName?: string
}

export enum Status {
  LIVE = "LIVE",
}

export type OstromRate = {
  productCode: ProductCode
  tariff: number
  basicFee: number
  networkFee: number
  unitPricePerkWH: number
  tariffWithStormPreisBremse: number
  stromPreisBremseUnitPrice: number
  accumulatedUnitPriceWithStromPreisBremse: number
  unitPrice: number
  energyConsumption: number
  basePriceBrutto: number
  workingPriceBrutto: number
  meterChargeBrutto: number
  averageHourlyPriceToday: number
  minHourlyPriceToday: number
  maxHourlyPriceToday: number
}

export type Footprint = {
  usage: number
  kgCO2Emissions: number
}

export enum ProductCode {
  basisProdukt = "basisProdukt",
  basisDynamic = "basisDynamic",
  basisPriceBrake35 = "basisPriceBrake35",
}

export type PartnerParams = {
  product: string
  clientApplicationId: string
  externalUserId: string
  externalTransactionId: string
}

export type PaymentMethod = "DIRECT_DEBIT" | "TRANSFER"

export type DeviceType = {
  mobile: boolean
  deskTop: boolean
}

export type Col1Type = {
  label: string
  tooltip?: string
  link?: undefined
  component?: undefined
  optionalText?: undefined
  showPriceCap?: undefined
  subtext?: undefined
}

export type ReferalValidate = {
  status: ReferralCodeStatus
  amount: number
}

export enum ReferralCodeStatus {
  ACTIVE = "ACTIVE",
  UNKNOWN = "UNKNOWN",
}

export type Supplier = {
  id: number
  name: string
  primary: boolean
  codeNumber: number
}

export type SignupStepsType = {
  [key: string]: FormPageStep
}

export type FormPageStep = {
  index: number
  name: string
  url: string
}

export type Contract = {
  id: string
  status: "ACTIVE" | "OPEN"
  productCode: ContractCode
  isConnectedToPartner: boolean
  address: {
    countryId: string
    zip: string
    city: string
    street: string
    houseNumber: string
  }
}

export enum ContractCode {
  SIMPLY_DYNAMIC = "SIMPLY_DYNAMIC",
  SIMPLY_FAIR = "SIMPLY_FAIR",
}

export type DynamicGraphData = {
  day: {
    prices: {
      taxesAndLevies: string
      price: number
      priceWithTaxesAndLevies: number
      date: string
      month: string
    }[]
    averagePrice: number
  }
  year: {
    prices: {
      taxesAndLevies: string
      price: number
      priceWithTaxesAndLevies: number
      date: string
      month: string
    }[]
    averagePrice: number
  }
}

export type SmartMeterGridData = {
  isEligible: boolean
  smartMeterPrice: number
  smartMeterPostalCodeId: number
  netBdewNumber: string
}
