export const styles = {
  victoryTooltipStyle: {
    stroke: "none",
    fill: "#fff",
    borderRadius: 12,
  },
  victoryLabelStyle: {
    fontFamily: "Inter",
    fontWeight: "800",
    fontSize: 10,
    fill: "#16295A",
  },
  customTooltipWrapper: {
    background: "#ffffff",
    borderRadius: 12,
    padding: 8,
    boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.04)",
    width: 152,
    height: 105,
  },
  tooltipBoldText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "700",
    lineHeight: "16px",
    color: "#16295A",
    padding: 0,
    margin: 0,
  },
  tooltipLightText: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "400",
    lineHeight: "16px",
    color: "#6D7381",
    whiteSpace: "nowrap",
  },
  tooltipRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 8,
  },
  victoryTickLabel: isMobile => {
    return {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: isMobile ? 10 : 18,
      fill: "#6D7381",
      lineHeight: isMobile ? 15 : 16,
    }
  },
  victoryAxisStyle: isMobile => {
    return {
      tickLabels: {
        fontFamily: "Inter",
        fontSize: isMobile ? 10 : 18,
        fill: "#6D7381",
        lineHeight: isMobile ? 15 : 27,
      },
      grid: {
        stroke: "#B6B9C0",
        strokeWidth: d => (d.tickValue === 0 ? 4 : 1),
      },
    }
  },
}
