import React, { useEffect, useState } from "react"
import SignupContainer from "../../components/SignupContainer/SignupContainer"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import { Button } from "@mui/material"
import { T } from "@transifex/react"
import { useNavigate } from "react-router-dom"
import { SvgImage } from "../../assets/svgs/SvgImage"
import { useShallow } from "zustand/react/shallow"

import "./forms.scss"
import PriceCapStep from "../../components/PersonalInfoStepper/PriceCapStep"
import { FREE_SMART_METER_OPT_OUT, FREE_SMART_METER_USAGE, PAYMENT_PAGE_PATH } from "../../constants/common"
import { useResponsive } from "../../hooks/useResponsive"
import { scrollToSection } from "../../utils"
import { useGeneralModel } from "../../models/useGeneralModel"
import { FormPageStep } from "../../types/generic"
import useCurrentStep from "../../hooks/useCurrentStep"
import SmartMeterStep from "../../components/PersonalInfoStepper/SmartMeterStep"
import { checkSmartMeterAvailability } from "../../service/api"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import useIsPriceCapAvailable from "../../hooks/useIsPriceCapAvailable"

function SignupPriceCapPage() {
  const navigate = useNavigate()

  const { currentStep } = useCurrentStep()
  const [smartMeterAvailable, setSmartMeterAvailable] = useState(false)

  const { deviceType } = useResponsive()

  const { priceCap, isDynamic, smartMeterOrder } = useGeneralModel(useShallow(state => state))
  const { usage, postalCode } = usePlanFormModel(useShallow(state => state))
  const isPriceCapAvailable = useIsPriceCapAvailable()
  const showPriceCap = !isDynamic && isPriceCapAvailable
  const handleNext = async (step?: FormPageStep) => {
    scrollToSection("stepper-container-section", deviceType.mobile)
    if (priceCap !== undefined || priceCap !== null) {
      useGeneralModel.setState({
        priceCap35: priceCap,
      })
      if (smartMeterAvailable) {
        useGeneralModel.setState({
          smartMeterOfferSeen: smartMeterAvailable,
        })
      }
      scrollToSection()
    }
    if (step) {
      const diff = step.index - currentStep.index
      if (diff > 1) return // This condition prevents the navigation to move more than one step ahead
    }
    if (step) {
      navigate(`${step.url}${window.location.search}`)
    } else {
      navigate(`${PAYMENT_PAGE_PATH}${window.location.search}`)
    }
  }

  useEffect(() => {
    const handleCityChange = async () => {
      setSmartMeterAvailable(false)
      if (postalCode && usage) {
        await checkSmartMeterAvailability(postalCode, usage)
          .then(res => {
            if (res?.data?.isEligible) {
              setSmartMeterAvailable(true)
              useGeneralModel.setState({
                smartMeterOfferAvailable: res.data.isEligible,
                smartMeterPostalCodeId: res.data.smartMeterPostalCodeId,
                smartMeterPrice: res.data.smartMeterPrice,
              })
              if (+usage >= FREE_SMART_METER_USAGE && +usage < FREE_SMART_METER_OPT_OUT && smartMeterOrder === null) {
                useGeneralModel.setState({ smartMeterOrder: true })
              }
            }
          })
          .catch(() => {})
      }
    }
    handleCityChange()
  }, [postalCode, usage])

  const disableNext = () => {
    if (
      (showPriceCap && (priceCap === undefined || priceCap === null)) ||
      (smartMeterAvailable && (smartMeterOrder === undefined || smartMeterOrder === null))
    ) {
      return true
    }
    return false
  }

  return (
    <NavigationWrapper>
      <SignupContainer handleNext={handleNext}>
        {showPriceCap && <PriceCapStep />}
        {smartMeterAvailable && <SmartMeterStep />}
        <div className={"form-action"}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={"btn-further"}
              style={{
                marginRight: 0,
              }}
              data-test-id={"stepper-next-btn"}
              disabled={disableNext()}
            >
              <>
                <T _str={"Next"} />
                <SvgImage className={"next-icon"} name={"icon-arrow-right"} />{" "}
              </>
            </Button>
          </div>
        </div>
      </SignupContainer>
    </NavigationWrapper>
  )
}

export default React.memo(SignupPriceCapPage)
