import React, { useMemo } from "react"
import { t } from "@transifex/native"

export default function useTextConstants() {
  const mobileListTwo = [
    {
      label: "Unit Price",
      tooltip: t(
        "The cost price per kWh of electricity, defined by the renewable energy plants generating your power."
      ),
    },
    {
      label: "Grid Fees",
      tooltip: t(
        "Fees for the usage and maintenance of the grid. They include the metering charges according to the T&C."
      ),
    },
    {
      label: "Basic Fee",
      tooltip: t(
        "This is Ostrom’s service and administration fee. Traditional providers often add this fee as part of the unit price, which means the more you use, the more they make. However, we don’t want to profit from additional energy consumption—if anything we want to reduce it. So, we’ve made this a flat monthly fee. Just enough to pay our bills, too."
      ),
    },
    {
      label: "Emission Savings",
      tooltip: t(
        `The CO₂ emissions you'll save by choosing Ostrom as your green energy supplier compared to the energy generated from the average energy blend in Germany. These calculations are based on information from the `
      ),
      link: (
        <a
          target="_blank"
          href="https://www.umweltbundesamt.de/sites/default/files/medien/1410/publikationen/2020-04-01_climate-change_13-2020_strommix_2020_fin.pdf"
          rel="noreferrer"
        >
          Umwelt Bundesamt.
        </a>
      ),
    },
  ]
  const links = [
    {
      name: t("Variable Tariff"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-tariff`,
      testID: "variable-tariff",
    },
    {
      name: t("Dynamic Pricing"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/dynamic-pricing`,
      testID: "dynamic-pricing",
    },
    {
      name: t("Smart EV Charging"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/smart-ev-charging`,
      testID: "smart-ev-charging",
    },
    {
      name: t("Smart HVAC"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/smart-hvac`,
      testID: "smart-hvac",
    },
    {
      name: t("Solar PV"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/solar-pv`,
      testID: "solar-pv",
    },
    { name: t("THG Quote"), path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/thg-quote`, testID: "thg-quote" },
    { name: t("Our Store"), path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/store`, testID: "our-store" },
    {
      name: t("Our Generators"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/our-generators`,
      testID: "our-generators",
    },
    {
      name: t("Developer Portal"),
      path: `https://docs.ostrom-api.io/reference/introduction`,
      testID: "developer-portal",
    },
    {
      name: t("Smart Meter"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/smart-meter`,
      testID: "smart-meter",
    },
    { name: t("FAQs"), path: `https://support.ostrom.de/en/`, testID: "faqs" },
  ]
  const aboutUs = [
    {
      name: t("About"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/about-us`,
      testID: "about-us",
    },
    {
      name: t("Careers"),
      path: `https://www.ostrom.de/careers`,
      testID: "careers",
    },
    {
      name: t("Blog"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/blog`,
      testID: "blog",
    },
    {
      name: t("Press"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/press`,
      testID: "press",
    },
    {
      name: t("Electricity Labeling"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/electricity-labeling`,
      testID: "electricity-labeling",
    },
    {
      name: t("Cancel Contract"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/kuendigung-cancellation`,
      testID: "cancel-contract",
    },
    {
      name: t("Contact Form"),
      path: "https://ostrom.typeform.com/to/Pchunu31?typeform-source=ostrom.webflow.io",
      testID: "contact-form",
    },
  ]
  const infomation = [
    {
      name: t("T&C"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/terms-and-conditions-overview`,
      testID: "t-and-c",
    },
    {
      name: t("Compliance"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/compliance`,
      testID: "compliance",
    },
    {
      name: t("Mako"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/market-partner`,
      testID: "mako",
    },
    {
      name: t("Sitemap"),
      path: `${process.env.REACT_APP_OSTROM_WEBSITE_URL}/html-sitemap`,
      testID: "sitemap",
    },
  ]

  return useMemo(() => ({ mobileListTwo, links, aboutUs, infomation }), [])
}
