import React, { useState } from "react"
import { SvgImage } from "../../assets/svgs/SvgImage"

import { T } from "@transifex/react"
import { t } from "@transifex/native"
import "./Dropdown.scss"

interface DropdownProps {
  style?: React.CSSProperties
  className?: string
  defaultValue?: number
  onChange?: (x: number) => void
  dataTestId?: string
  label?: string
  list: any[]
}

export default function Dropdown({
  style,
  className = "",
  defaultValue = 0,
  onChange = () => {},
  dataTestId = "",
  label = "",
  list = [],
}: DropdownProps) {
  const [value, setValue] = useState(list[defaultValue])
  const [anchorEl, setAnchorEl] = React.useState(false)
  const [selectedKey, setSelectedKey] = useState(defaultValue)

  const handleSelect = idx => {
    handleClose()
    if (idx > -1) {
      setValue(list[idx])
      setSelectedKey(idx)
      onChange(idx)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <div className={"drop-down-field-container" + className} style={style}>
      {label !== "" && (
        <div className={"label"}>
          <T _str={label} />
        </div>
      )}
      <div className={"drop-down-field"} onMouseLeave={handleClose}>
        <div onClick={handleClick} onKeyDown={handleClick} data-test-id={dataTestId}>
          {t(value)}
          <SvgImage name={"arrow-down"} className={"drop-down-icon"} />
        </div>
        <div style={{ display: anchorEl ? "block" : "none" }} className={"drop"} data-test-id={"dropdown"}>
          {list.map((item, index) => (
            <div
              key={item.replace(/\s/g, "-")}
              data-test-id={`${dataTestId}-${index}`}
              className={index === selectedKey ? "item selected" : "item"}
              onClick={() => {
                handleSelect(index)
              }}
              onKeyDown={() => {
                handleSelect(index)
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
