export default function Instagram({ width = 24, height = 24, color = "#16295A" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3191 7.61196C23.3062 6.64271 23.1248 5.68309 22.7829 4.77604C22.4865 4.01094 22.0337 3.3161 21.4535 2.7359C20.8733 2.15571 20.1784 1.70292 19.4133 1.40646C18.5179 1.07035 17.572 0.888603 16.6158 0.86897C15.3847 0.813941 14.9944 0.798584 11.8692 0.798584C8.74408 0.798584 8.34352 0.798584 7.12136 0.86897C6.16559 0.888747 5.2201 1.07049 4.32511 1.40646C3.5599 1.70271 2.86495 2.15543 2.28472 2.73565C1.7045 3.31587 1.25178 4.01082 0.955536 4.77604C0.618746 5.67075 0.437396 6.61646 0.419322 7.57229C0.364293 8.80468 0.347656 9.19501 0.347656 12.3202C0.347656 15.4453 0.347656 15.8446 0.419322 17.068C0.438518 18.0253 0.618963 18.9697 0.955536 19.8668C1.25228 20.6318 1.70534 21.3265 2.28575 21.9065C2.86617 22.4864 3.56118 22.939 4.32639 23.2351C5.21893 23.5847 6.16458 23.7795 7.12264 23.811C8.35504 23.866 8.74536 23.8827 11.8705 23.8827C14.9956 23.8827 15.3962 23.8827 16.6184 23.811C17.5745 23.7922 18.5205 23.6109 19.4159 23.2748C20.1808 22.978 20.8755 22.5251 21.4556 21.9449C22.0358 21.3648 22.4887 20.6701 22.7855 19.9052C23.122 19.0094 23.3025 18.0649 23.3217 17.1064C23.3767 15.8753 23.3933 15.485 23.3933 12.3585C23.3908 9.2334 23.3908 8.83668 23.3191 7.61196ZM11.8615 18.23C8.59307 18.23 5.94527 15.5822 5.94527 12.3138C5.94527 9.04528 8.59307 6.39748 11.8615 6.39748C13.4306 6.39748 14.9355 7.0208 16.045 8.13032C17.1545 9.23983 17.7778 10.7447 17.7778 12.3138C17.7778 13.8828 17.1545 15.3877 16.045 16.4972C14.9355 17.6067 13.4306 18.23 11.8615 18.23ZM18.0133 7.55821C17.2493 7.55821 16.6337 6.94137 16.6337 6.17864C16.6337 5.99756 16.6694 5.81825 16.7387 5.65095C16.808 5.48365 16.9096 5.33164 17.0376 5.20359C17.1656 5.07555 17.3177 4.97398 17.485 4.90468C17.6523 4.83538 17.8316 4.79971 18.0126 4.79971C18.1937 4.79971 18.373 4.83538 18.5403 4.90468C18.7076 4.97398 18.8597 5.07555 18.9877 5.20359C19.1157 5.33164 19.2173 5.48365 19.2866 5.65095C19.3559 5.81825 19.3916 5.99756 19.3916 6.17864C19.3916 6.94137 18.7747 7.55821 18.0133 7.55821Z"
        fill={color}
      />
      <path
        d="M11.8616 16.1569C13.9841 16.1569 15.7047 14.4363 15.7047 12.3138C15.7047 10.1913 13.9841 8.4707 11.8616 8.4707C9.73916 8.4707 8.01855 10.1913 8.01855 12.3138C8.01855 14.4363 9.73916 16.1569 11.8616 16.1569Z"
        fill={color}
      />
    </svg>
  )
}
