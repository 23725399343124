import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import { t } from "@transifex/native"
import { T } from "@transifex/react"
import React, { useEffect, useState } from "react"
import "./MarketLocationDlg.scss"
import Transition from "../../Transition/Transition"

export function MarketLocationDlg({ openStatus, onClose }) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(openStatus)
  }, [openStatus])
  useEffect(() => {
    if (open === false) {
      onClose()
    }
    // eslint-disable-next-line
  }, [open])
  // eslint-disable-next-line
  const [error] = useState("")
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="market-location-dialog"
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <IconButton
          className="dialog-close-icon"
          size="small"
          onClick={() => {
            setOpen(false)
          }}
        >
          <SvgImage name="clarity_window-close-line" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={"market-location-dialog-content"}>
          <div className={"dialog-content-title"}>
            <T _str={"Here is how to recognise your market location ID"} />
          </div>
          <div className={"dialog-content-bg"}>
            <div className={"dialog-content-description"}>
              <div>
                <div>Market Location ID</div>
                <div>(Marktlokations-ID)</div>
                <div>11 Digits</div>
              </div>
            </div>
          </div>
          {error !== "" && (
            <Alert className={"mt-20"} severity="warning">
              {error}
            </Alert>
          )}
        </div>
      </DialogContent>
      <DialogActions className={"dialog-action"}>
        <Button
          onClick={() => {
            onClose(false)
          }}
          className={"btn-primary secondary-color round-50"}
          autoFocus
        >
          {t("Ok, I understand")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
