import React, { useState } from "react"
import { useShallow } from "zustand/react/shallow"

import SignupContainer from "../../components/SignupContainer/SignupContainer"
import NavigationWrapper from "../../layouts/NavigationWrapper"
import { Button } from "@mui/material"
import { T } from "@transifex/react"
import { SvgImage } from "../../assets/svgs/SvgImage"

import "./forms.scss"
import DeliveryForm from "./forms/delivery-form"
import { validateMeterNumber } from "../../service/api"
import { useDeliveryFormModel } from "../../models/useDeliveryFormModel"
import { t } from "@transifex/native"
import { MeterNumberError } from "../../components/Dialog/MeterNumber/MeterNumberError"
import { MarketLocationDlg } from "../../components/Dialog/MarketeLocationDlg/MarketLocationDlg"
import { MeterNumber } from "../../components/Dialog/MeterNumber/MeterNumber"
import useDeliveryInfoValidation from "../../hooks/useDeliveryInfoValidation"

function SignupDeliveryPage() {
  const { meterNumber, noMeterId, meterNumberContinue } = useDeliveryFormModel(useShallow(state => state))

  const [openMeterNumberDlg, setOpenMeterNumberDlg] = useState(false)
  const [openMarketLocationDlg, setOpenMarketLocationDlg] = useState(false)
  const [openMeterIdErrorDlg, setOpenMeterIdErrorDlg] = useState(false)

  const checkMeterNumber = async () => {
    if (meterNumberContinue) {
      return 1
    }
    const res = await validateMeterNumber(meterNumber.value)
    if (res.data === false && !noMeterId.value) {
      useDeliveryFormModel.setState({
        meterNumber: {
          ...meterNumber,
          valid: false,
          error: t("Does not meet format requirements"),
        },
      })
      setOpenMeterIdErrorDlg(true)
      return 0
    } else if (res.data === true && !noMeterId.value) {
      useDeliveryFormModel.setState({
        meterNumber: {
          ...meterNumber,
          error: "",
          valid: true,
        },
      })
      return 1
    }
  }

  const handleMeterNumberContinue = (number: string) => {
    useDeliveryFormModel.setState({
      meterNumber: {
        value: number,
        error: "",
        valid: true,
      },
      meterNumberContinue: true,
    })
    setOpenMeterIdErrorDlg(false)
    handleNext()
  }

  const { handleNext } = useDeliveryInfoValidation({
    setOpenMeterIdErrorDlg,
    checkMeterNumber,
  })

  return (
    <NavigationWrapper>
      <SignupContainer handleNext={handleNext}>
        <DeliveryForm
          setOpenMarketLocationDlg={setOpenMarketLocationDlg}
          setOpenMeterNumberDlg={setOpenMeterNumberDlg}
        />
        <div className={"form-action"}>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={"btn-further"}
              style={{
                marginRight: 0,
              }}
              data-test-id={"stepper-next-btn"}
            >
              <>
                <T _str={"Next"} />
                <SvgImage className={"next-icon"} name={"icon-arrow-right"} />{" "}
              </>
            </Button>
          </div>
        </div>

        <MeterNumber
          openStatus={openMeterNumberDlg}
          onClose={() => {
            setOpenMeterNumberDlg(false)
          }}
        />

        <MeterNumberError
          openStatus={openMeterIdErrorDlg}
          onClose={() => {
            setOpenMeterIdErrorDlg(false)
          }}
          onContinue={number => {
            handleMeterNumberContinue(number)
          }}
          meterValue={meterNumber.value || "0"}
        />
        <MarketLocationDlg
          openStatus={openMarketLocationDlg}
          onClose={() => {
            setOpenMarketLocationDlg(false)
          }}
        />
      </SignupContainer>
    </NavigationWrapper>
  )
}

export default React.memo(SignupDeliveryPage)
