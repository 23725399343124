import React, { useCallback, useMemo } from "react"
import { useGeneralModel } from "../models/useGeneralModel"
import { useShallow } from "zustand/react/shallow"
import { LETTER_M, MONTH, YEAR } from "../constants/common"
import { getCommaSeparatedTwoDecimalsNumber } from "../utils/helpers"
import { STR_RATE_NULL, STR_RATE_TARIFF } from "../constants/global"

export default function useGetRate() {
  const { rate, currentPlan, isDynamic } = useGeneralModel(useShallow(state => state))

  const getRateData = useCallback(
    (key: string, provider: string, planKey = "") => {
      if (planKey === "") {
        planKey = currentPlan === LETTER_M ? MONTH : YEAR
      }
      if (rate && rate?.[provider] && rate?.[provider]?.[planKey] && rate?.[provider]?.[planKey]?.[key]) {
        if (key === STR_RATE_TARIFF) {
          if (isNaN(rate[provider][planKey][key])) {
            return 0
          }
          if (parseInt(rate[provider][planKey][key]) === 0) {
            return 0
          }
        }
        return parseInt(getCommaSeparatedTwoDecimalsNumber(rate[provider][planKey][key]))
      }
      return STR_RATE_NULL
    },
    [rate, currentPlan]
  )

  const selectedTariff = useMemo(() => {
    if (rate?.ostrom) {
      const productKey = !isDynamic ? 0 : 1
      const { tariff = 0 } = rate?.ostrom?.[productKey] ?? {}
      return tariff
    }
  }, [rate, isDynamic])

  return { getRateData, selectedTariff }
}
