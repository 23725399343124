import React, { useEffect, useState } from "react"
import { T } from "@transifex/react"
import { t } from "@transifex/native"
import "./ReferralTariffCard.scss"
import PostcodeInput from "../PostcodeInput/PostcodeInput"
import ConsumptionInput from "../ConsumptionInput/ConsumptionInput"
import ReferralInput from "../ReferralInput/ReferralInput"
import { getCitiesByPostalCode, getReferralType } from "../../service/api"
import { DEFAULT_KWH_CONSUMPTION, REFERRAL_CODE_LENGTH, REFERRAL_CODE_TYPE } from "../../constants/common"
import { getReferralInfo } from "../../utils"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { useFromPartner } from "../../hooks/useFromPartner"
import { City } from "../../types/generic"

const ReferralTariffCard = ({ refCode, refData, getRefCode }) => {
  const [code, setCode] = useState(null)
  const [postCode, setPostCode] = useState("")
  const [cities, setCities] = useState<City[] | null>(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [showDropdownLoading, setShowDropdownLoading] = useState(false)
  const [refFormData, setRefFormData] = useState({
    cityId: "",
    cityName: "",
    usage: DEFAULT_KWH_CONSUMPTION,
    postalCode: "",
    referralCode: "",
  })
  const [usageError, setUsageError] = useState("")
  const [postCodeError, setPostCodeError] = useState("")
  const [isValidRef, setIsValidRef] = useState(false)
  const [refError, setRefError] = useState("")
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const { partnerParams } = useFromPartner()

  const onPostCodeChange = code => {
    setPostCodeError("")
    setPostCode(code)
  }

  const getCities = async () => {
    const cities = await getCitiesByPostalCode(postCode).then(({ data }) => {
      if (data?.length === 0) {
        setPostCodeError(t("Postcode does not exist"))
        setCities([])
        setShowDropdown(false)
      } else {
        setShowDropdown(true)
      }
      return data
    })
    return cities
  }

  const setSelectedCity = city => () => {
    setPostCode(`${postCode} ${city?.name}`)
    setRefFormData({ ...refFormData, cityName: city?.name, cityId: city?.id, postalCode: postCode })
  }

  const getUsageError = error => {
    setUsageError(error)
  }

  const getUsageValue = usage => {
    setRefFormData({ ...refFormData, usage })
  }

  const onCalculate = () => {
    if (!refFormData.cityId || !refFormData.cityName || !refFormData.referralCode || !refFormData.postalCode) {
      enqueueSnackbar(`Please enter a valid postalcode`, {
        variant: "warning",
      })
      return
    }
    // remove empty properties from refFormData and partnerParams
    let newParams = { ...refFormData, ...partnerParams }
    // @ts-ignore
    Object.keys(newParams).reduce((prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev), {})
    if (Object.keys(newParams).length > 0) {
      // convert object to string
      // @ts-ignore
      const url = "/tariff-plan?" + new URLSearchParams(newParams).toString()
      navigate(url)
    }
  }

  const checkCode = async () => {
    const { data } = await getReferralType(code)
    const [valid, errMsg] = getReferralInfo(data?.status)
    setRefError(errMsg)

    valid
      ? setRefFormData({ ...refFormData, referralCode: code })
      : setRefFormData({ ...refFormData, referralCode: "" })

    if (!valid) setCode("")
    setIsValidRef(valid)
  }

  const getCode = async code => {
    if (code.length !== REFERRAL_CODE_LENGTH && isValidRef) {
      setIsValidRef(false)
    }
    setCode(code)
    getRefCode(code)
  }

  const getRefError = error => {
    setRefError(error)
  }

  const getIsValid = valid => {
    setIsValidRef(valid)
  }

  useEffect(() => {
    const handleRef = async () => {
      if (refCode?.length === REFERRAL_CODE_LENGTH) {
        const { data } = await getReferralType(refCode)
        if (data?.status === REFERRAL_CODE_TYPE.EXPIRED || data?.status === REFERRAL_CODE_TYPE.INACTIVE) {
          setCode("")
        } else {
          setCode(refCode)
        }
      }
    }
    handleRef()
  }, [refCode])

  useEffect(() => {
    if (refData) {
      const [valid, errMsg] = getReferralInfo(refData?.status)
      setRefError(errMsg)
      setRefFormData({ ...refFormData, referralCode: refCode })
      setIsValidRef(valid)
    }
  }, [refData])

  useEffect(() => {
    const handlePostCode = async () => {
      if (postCode === "") {
        setRefFormData({ ...refFormData, cityId: "", cityName: "", postalCode: "" })
      }
      if (postCode.length > 0) {
        if (postCode.length < 5 || postCode.length > 5) {
          setCities(null)
          setShowDropdown(false)
        } else {
          setPostCodeError("")
          setPostCodeError("")
          setShowDropdownLoading(true)
          const citiesList = await getCities()
          setCities(citiesList)
          setShowDropdownLoading(false)
        }
      }
    }
    handlePostCode()
  }, [postCode])

  return (
    <div className={"referral-tariff-wrapper"}>
      <div className={"ref-container-double"}>
        <div className={"referral-input-container"}>
          <p className={"referral-input-label"}>
            <T _str={"Your Postcode"} />
          </p>
          <div className={"refer-input postcode-desktop"}>
            <PostcodeInput getPostCode={onPostCodeChange} codeValue={postCode} />
            {showDropdownLoading && <p>loading...</p>}
            {showDropdown && (
              <div className={"dropdown-container"}>
                {cities?.length !== 0 && (
                  <div className={"dropdown-cities-list"}>
                    {cities?.map(city => (
                      <p key={city.name} onClick={setSelectedCity(city)}>{`${postCode} ${city.name}`}</p>
                    ))}
                  </div>
                )}
              </div>
            )}
            <p className={"error-description"}>{postCodeError}</p>
          </div>
        </div>
        <div className={"referral-input-container ref-desktop"}>
          <p className={"referral-input-label"}>
            <T _str={"Referral Code"} />
          </p>
          <div className={"refer-input ref-input-desktop"} style={{ display: "flex", justifyContent: "center" }}>
            <ReferralInput
              value={code}
              valid={isValidRef}
              calculate={checkCode}
              getCode={getCode}
              setError={getRefError}
              setIsValid={getIsValid}
            />
          </div>
          <p className={"error-description"}>{refError}</p>
        </div>
      </div>

      <div className={"referral-input-container consumption-desktop"} style={{ paddingTop: 20 }}>
        <p className={"referral-input-label"}>
          <T _str={"Yearly Consumption"} />
        </p>
        <div className={"refer-input"}>
          <ConsumptionInput usageError={getUsageError} usageValue={getUsageValue} />
        </div>
        <p className={"error-description"}>{usageError}</p>
      </div>
      <div className={"referral-input-container ref-mobile"} style={{ paddingTop: 20 }}>
        <p className={"referral-input-label"}>
          <T _str={"Referral Code"} />
        </p>
        <div
          className={"refer-input"}
          style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
        >
          <ReferralInput
            value={code}
            valid={isValidRef}
            calculate={checkCode}
            getCode={getCode}
            setError={getRefError}
            setIsValid={getIsValid}
          />
          <div style={{ maxWidth: 199, width: "100%" }}>
            <p className={"error-description"}>{refError}</p>
          </div>
        </div>
      </div>
      <div className={"ref-button-container"}>
        <button data-test-id={"calc-tariff-btn"} className={"ref-button"} onClick={onCalculate}>
          <T _str={"Calculate Your Tariff"} />
        </button>
      </div>
    </div>
  )
}

export default ReferralTariffCard
