import React from "react"
import "./customToggleButton.scss"
import { Paper, ToggleButton, ToggleButtonGroup, styled } from "@mui/material"

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    "margin": 2.98,
    "border": 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&.Mui-selected": {
      "borderRadius": 83.359,
      "background": "#FFF",
      "height": 38.04,
      "boxShadow":
        "0px 0px 1.48855px 0px rgba(0, 0, 0, 0.04), 0px 2.9771px 8.93131px 0px rgba(0, 0, 0, 0.04), 0px 14.88551px 29.77102px 0px rgba(0, 0, 0, 0.04)",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px !important",
      margin: "2px !important",
    },
  },
}))

const CustomToggleButton = ({ toggleButtonStyle = {}, buttonData, onChange, value }) => {
  return (
    <div className={"toggle-button-wrapper"}>
      <Paper
        elevation={0}
        style={{
          display: "flex",
          border: theme => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
          width: "fit-content",
        }}
        className={"toggle-button"}
      >
        <StyledToggleButtonGroup size="small" value={value} exclusive onChange={onChange} aria-label="text alignment">
          {buttonData.length > 1 ? (
            buttonData.map((item, index) => {
              return (
                <ToggleButton
                  key={item}
                  value={index}
                  aria-label="left aligned"
                  className={"toggle-button-item"}
                  style={{ ...toggleButtonStyle, opacity: value === index ? 1 : 0.5 }}
                  data-test-id={`toggle-button-${index}`}
                >
                  {item}
                </ToggleButton>
              )
            })
          ) : (
            <div></div>
          )}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  )
}

export default CustomToggleButton
