import * as React from "react"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Menu from "@mui/material/Menu"
import { ListItemIcon } from "@mui/material"
import { usePartnerModel } from "../../../models/usePartnerModel"
import { useShallow } from "zustand/react/shallow"
import { t } from "@transifex/native"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import usePartnerContractSelection from "../../../hooks/usePartnerContractSelection"

export default function ContractPicker() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const { contracts, selectedContract } = usePartnerModel(
    useShallow(state => ({
      selectedContract: state.selectedContract,
      contracts: state?.user?.contracts ?? [],
    }))
  )

  const { hasError, addressLabel } = usePartnerContractSelection()

  React.useEffect(() => {
    if (contracts?.length > 0) {
      usePartnerModel.setState({ selectedContract: contracts[0] })
    }
  }, [contracts])

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = index => {
    usePartnerModel.setState({ selectedContract: contracts[index] })
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const multipleContracts = contracts.length > 1

  return (
    <div>
      {multipleContracts ? (
        <h3
          style={{
            textAlign: "center",
            fontSize: 18,
            fontWeight: 800,
            color: "#16295A",
            fontFamily: "Inter, sans-serif",
            marginTop: 0,
          }}
        >
          {t("Select Contract")}
        </h3>
      ) : null}
      <List
        component="div"
        aria-label="Device settings"
        sx={{
          bgcolor: hasError ? "#fff" : "#F0F7F7",
          borderRadius: "20px",
          border: hasError ? "2px solid #E91B1B" : "none",
          width: "100%",
          padding: 0,
        }}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="contract-menu"
          aria-label={addressLabel}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <ListItemText
            primary={addressLabel}
            primaryTypographyProps={{
              fontSize: 20,
              fontWeight: "800 !important",
              letterSpacing: 0,
              color: hasError ? "#E91B1B !important" : "#0B152D !important",
              textAlign: "left",
            }}
          />
          {multipleContracts ? (
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <SvgImage name="language-arrow" />
            </ListItemIcon>
          ) : null}
        </ListItemButton>
      </List>
      {multipleContracts ? (
        <Menu
          id="contract-menu"
          anchorEl={anchorEl}
          open={open}
          sx={{
            "& .MuiMenu-paper": {
              "width": 552,
              "borderRadius": "20px",
              "border": "2px solid #DEEFEF",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent !important",
            },
          }}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            "role": "listbox",
          }}
        >
          {contracts?.map((contract, index) => (
            <MenuItem
              key={contract.id}
              selected={contract.id === selectedContract?.id}
              onClick={() => handleMenuItemClick(index)}
              sx={{
                "fontSize": 16,
                "color": "#0B152D !important",
                "fontFamily": "Inter, sans-serif",
                "lineHeight": "24px",
                "&.Mui-selected": {
                  backgroundColor: "#fff !important",
                  fontWeight: 800,
                },
              }}
            >
              {contract.address.street} {contract.address.houseNumber}, {contract.address.zip} {contract.address.city}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </div>
  )
}
