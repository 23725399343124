import { t } from "@transifex/native"
import {
  City,
  DynamicGraphData,
  Rate,
  ReferralCodeStatus,
  SmartMeterGridData,
  Street,
  Supplier,
} from "../types/generic"
import { sendErrorToSentry } from "../utils/helpers"

import Axios from "axios"
import { setupCache, buildWebStorage } from "axios-cache-interceptor"
import { SignUpFormType } from "../types/signup"
import { DEFAULT_KWH_CONSUMPTION, ENGLISH } from "../constants/common"

const instance = Axios.create()
const axios = setupCache(instance, {
  // As sessionStorage is a public storage, you can add a prefix
  // to all keys to avoid collisions with other code.
  storage: buildWebStorage(sessionStorage, "ostrom-axios-cache:"),
  cacheTakeover: false,
})

const baseUrl = process.env.REACT_APP_OSTROM_API_URL

export default async function getRate({
  cityId,
  usage = DEFAULT_KWH_CONSUMPTION,
  clientApplicationId,
}: {
  cityId: string | number
  usage?: number
  clientApplicationId?: string
}): Promise<
  Partial<{
    error: string
    message: string
    data: Rate
  }>
> {
  return axios
    .get(baseUrl + "tariffs/city-id", {
      params: {
        cityId,
        usage,
        ...(clientApplicationId ? { clientApplicationId } : {}),
      },
    })
    .then(({ data }) => {
      if (data.statusCode === 400) {
        sendErrorToSentry(data.error, {
          message: data.message,
          statusCode: data.statusCode,
          url: baseUrl + "tariffs/city-id",
        })
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(err => {
      sendErrorToSentry(err, {
        name: err.name,
        message: err.message,
        error: err.config.toString(),
        url: baseUrl + "tariffs/city-id",
      })
      return {
        error: "Network Error",
      }
    })
}
export async function validateReferralCode({ referralCode = "" } = {}): Promise<
  Partial<{
    error: string
    message: string
    data: {
      status: ReferralCodeStatus
      amount: number
    }
  }>
> {
  return axios
    .get(baseUrl + "users/validate-referral-code", {
      params: {
        referralCode,
      },
    })
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function checkIfEmailExists({ email }: { email?: string }): Promise<
  Partial<{
    error: string
    message: string
    data: boolean
  }>
> {
  return axios
    .get(baseUrl + "users/check-if-email-exists", {
      params: {
        email,
      },
    })
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function validateIban(iban: string): Promise<
  Partial<{
    error: string
    message: string
    data: boolean
  }>
> {
  return axios
    .get(baseUrl + "users/validate-iban", {
      params: {
        iban,
      },
    })
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function validateMarketLocationId(id: string): Promise<
  Partial<{
    error: string
    message: string
    data: boolean
  }>
> {
  return axios
    .get(baseUrl + "users/validate-market-location-id?marketLocationId=" + id, {})
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function sendQuote(
  {
    email,
    zip,
    cityId,
    cityName,
    usage,
  }: { email: string; zip: string; cityId: string; cityName: string; usage: number },
  locale: string
) {
  let language = locale === ENGLISH ? "ENGLISH" : "GERMAN"
  return axios
    .post(baseUrl + "users/send-quote", {
      email,
      zip,
      cityId,
      cityName,
      usage,
      language,
    })
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function signUp(req: SignUpFormType) {
  return axios
    .post(baseUrl + "users/signup", req)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        sendErrorToSentry(new Error("users/signUp 400"), {
          api: "users/signUp",
          message: data?.message,
        })
        throw Error(
          t("Your request is being processed! If you have any questions please contact us in our support chat.")
        )

        // eslint-disable-next-line
        // I am not sure why we have this block , but it is unreachable , so I would comment it for now
        // throw {
        //   error: data.error,
        //   message: data.message,
        // }
      } else {
        return {
          data: data,
        }
      }
    })
    .catch(e => {
      sendErrorToSentry(e, {
        api: "users/signUp",
        message: e?.response?.data?.message,
      })
      throw (
        e?.response?.data ||
        Error(t("Your request is being processed! If you have any questions please contact us in our support chat."))
      )
    })
}
export async function getOldSupplier(): Promise<{
  data: Supplier[]
}> {
  return axios
    .get(baseUrl + "tariffs/providers")
    .then(({ data }) => {
      if (data.statusCode === 400) {
        sendErrorToSentry(new Error("tariffs/providers 400"), {
          api: "tariffs/providers",
          message: data?.message,
        })
        return {
          data: [],
        }
      } else {
        return {
          data: data,
        }
      }
    })
    .catch(e => {
      sendErrorToSentry(e, {
        api: "getOldSupplier",
        message: e?.response?.data?.message,
      })
      return {
        data: [],
      }
    })
}

export async function getCitiesByPostalCode(postalCode: string): Promise<
  Partial<{
    error: string
    message: string
    data: City[]
  }>
> {
  return axios
    .get(baseUrl + `addresses/cities?zip=${postalCode}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}

export async function getStreetsByCityId(cityId: string): Promise<
  Partial<{
    error: string
    message: string
    data: Street[]
  }>
> {
  return axios
    .get(baseUrl + `addresses/streets?cityId=${cityId}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}

export async function getReferralType(code: string): Promise<
  Partial<{
    error: string
    message: string
    data: {
      status: ReferralCodeStatus
    }
  }>
> {
  return axios
    .get(baseUrl + `users/referral-code-info?referralCode=${code}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}

export async function validateMeterNumber(meterNumberId: string): Promise<
  Partial<{
    error: string
    message: string
    data: boolean
  }>
> {
  return axios
    .get(baseUrl + `users/validate-meter-number-id?meterNumberId=${meterNumberId}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
export async function getSpotPrices(
  cityId,
  postalCode
): Promise<
  Partial<{
    error: string
    message: string
    data: DynamicGraphData
  }>
> {
  return axios
    .get(baseUrl + `spot-prices/ostrom-product-tariff?cityId=${cityId}&postalCode=${postalCode}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}

export async function checkSmartMeterAvailability(
  postalCode,
  usage
): Promise<
  Partial<{
    error: string
    message: string
    data: SmartMeterGridData
  }>
> {
  return axios
    .get(baseUrl + `users/smart-meter-grid?postalCode=${postalCode}&usage=${usage}`)
    .then(({ data }) => {
      if (data.statusCode === 400) {
        return {
          error: data.error,
          message: data.message,
        }
      }
      return {
        data: data,
      }
    })
    .catch(() => {
      return {
        error: "Network Error",
      }
    })
}
