import React from "react"
import Footer from "./Footer"
import Header from "./Header"

export default function NavigationWrapper({ children }: { children: any }) {
  return (
    <>
      <Header />
      <div style={{ minHeight: "80vh" }}>{children}</div>
      <Footer />
    </>
  )
}
