import { PartnerLinkType } from "./../types/partner"
import moment from "moment"
import dayjs from "dayjs"
import { DEFAULT_VALUE_0, DEFAULT_VALUE_1, HOURLY, REFERRAL_CODE_TYPE } from "../constants/common"
import {
  FormKeysValueTypeBoolean,
  FormKeysValueTypeString,
  InitFormValueTypeBoolean,
  InitFormValueTypeString,
} from "../types/form"
import { languages } from "../constants/global"
import { PartnerParams } from "../types/generic"

export const scrollToSection = (sectionId?: string, isMobile?: boolean) => {
  const options = { behavior: "smooth" } as ScrollToOptions
  setTimeout(() => {
    if (sectionId) {
      const yOffset = isMobile ? -200 : -300
      const element = document?.getElementById(sectionId)
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, ...options })
    }
  }, 200)
}

export const handleGoSocial = icon => {
  switch (icon) {
    case "facebook":
      window.open("https://www.facebook.com/ostrom.de", "_blank", "noopener")
      break
    case "twitter":
      window.open("https://twitter.com/ostrom_de", "_blank", "noopener")
      break
    case "instagram":
      window.open("https://www.instagram.com/ostrom.de/", "_blank", "noopener")
      break
    case "linkedin":
      window.open("https://www.linkedin.com/company/ostrom/", "_blank", "noopener")
      break
    case "pinterest":
      window.open("https://www.pinterest.de/ostrom_de/", "_blank", "noopener")
      break
    case "snapchat":
      window.open("https://www.snapchat.com/add/ostrom_de", "_blank", "noopener")
      break
    case "youtube":
      window.open("https://www.youtube.com/channel/UClwtTJIz3Eu2pK9rfjfXl7Q", "_blank", "noopener")
      break
    case "tiktok":
      window.open("https://www.tiktok.com/@ostrom_de", "_blank", "noopener")
      break
    default:
      break
  }
}

export const formKeys = [
  "email",
  "firstName",
  "street",
  "houseNumber",
  "surname",
  "dob",
  "companyName",
  // "areaCode",
  "phone",
  "marketLocationId",
  "meterNumber",
  "movingDate",
  "previousSupplierId",
  "terminatedFromDate",
  "iban",
  "accountHolderName",
  "billingFirstName",
  "billingSurname",
  "billingStreet",
  "billingHouseNumber",
  "billingZip",
  "billingCity",
] as FormKeysValueTypeString

export const formKeys2 = [
  "cancelAsSoonAsPossible",
  "sepaMandate",
  "termsAndConditions",
  "termsAndConditionsPriceCap",
  "noMarketLocationId",
  "interestedInThgQuota",
  "hasHeatPump",
  "hasSmartMeter",
  "hasElectricVehicle",
  "acceptPartnership",
] as FormKeysValueTypeBoolean

export const initFormValue = {
  value: "",
  valid: null,
  error: "",
} as InitFormValueTypeString

export const initFormValue2 = {
  value: false,
  valid: null,
  error: "",
} as InitFormValueTypeBoolean

export const signUpData = {
  customer: {},
  contract: {},
  payment: {},
  agreement: {},
  addOn: {},
  campaign: "",
  productCode: "",
}

export const clearFormCache = () => {
  localStorage["signUpData"] = JSON.stringify(signUpData)
  localStorage["activeStep"] = DEFAULT_VALUE_0
  localStorage["personalTab"] = JSON.stringify("home")
  localStorage["deliveryTab1"] = DEFAULT_VALUE_0
  localStorage["deliveryTab2"] = DEFAULT_VALUE_1
  localStorage["priceCap"] = JSON.stringify(undefined)
  localStorage["electricityContractType"] = JSON.stringify("CHANGE_OF_SUPPLIER")
  localStorage["electricityIdentifierType"] = JSON.stringify("MARKET_LOCATION_ID")
  localStorage["paymentType"] = DEFAULT_VALUE_0
  localStorage["electricityContract"] = JSON.stringify("no")
  localStorage["oldSupplier"] = JSON.stringify([])
  localStorage["cityStreets"] = JSON.stringify([])
  localStorage["cities"] = JSON.stringify([])
  localStorage["postalCode"] = JSON.stringify("")
  formKeys.forEach(item => {
    localStorage[item] = JSON.stringify(initFormValue)
  })
  formKeys2.forEach(item => {
    localStorage[item] = JSON.stringify(initFormValue2)
  })
}

export const convertToMoment = dateString => {
  const formatted = dateString.split(".").join("-")
  let momentDate = moment(formatted, "DD-MM-YYYY")
  if (momentDate.isValid()) {
    return momentDate
  }
  return dateString
}

export const convertToDayJs = dateValue => {
  const formatted = dateValue.split(".")

  //day js doesnt accept DD-MM-YYYY format
  // so we reformat to YYYY-MM-DD format to validate the date
  let newFormatted = []
  newFormatted[0] = formatted[1]
  newFormatted[1] = formatted[0]
  newFormatted[2] = formatted[2]
  const formattedDate = newFormatted.join("-")
  let newDate = dayjs(formattedDate, "MM-DD-YYYY")
  if (newDate.isValid()) {
    return newDate
  }
  return dateValue
}

export const getReferralInfo = (status: string): [boolean, string] => {
  const isValid = status === REFERRAL_CODE_TYPE.ACTIVE ? true : false
  const errMsg = isValid ? "" : status === REFERRAL_CODE_TYPE.EXPIRED ? "Invalid: expired code" : "Invalid Code"
  return [isValid, errMsg]
}

// This removes the dots from the IBAN format displayed in the IBAN input field
export const formatIban = iban => {
  return iban.split(" ").join("")
}

//pad zeros for Postalcode in cases where the postal code is less than 5
// eg 6258 => 06258
export const padPostalCodeZeros = (postalCode, postalCodeLength) => {
  return postalCode.toString().padStart(postalCodeLength, "0")
}

// we need this function to remove the country code and plus sign from the phoneNumber
export const formatPhoneNumber = (phoneNumber, phoneAreaCode) => {
  return phoneNumber
    .slice(phoneAreaCode.length + 1)
    .split(" ")
    .join("")
}

// return a valid Language no matter what the cookie info is
export const getLanguage = (locale = "en") => {
  const index = languages.findIndex(x => x.code === locale)
  let language = index > -1 ? languages[index].name.toUpperCase() : "ENGLISH"
  if (language !== "ENGLISH" && language !== "GERMAN") {
    language = "ENGLISH"
  }
  return language
}

//Get tick count
export const getTickCount = (toggleValue, dataLength, isMobile = false) => {
  if (toggleValue === HOURLY) {
    return isMobile ? Math.round(dataLength) / 6 : Math.round(dataLength) / 2
  }
  return dataLength < 20 ? Math.round(dataLength) : Math.round(dataLength) / 4
}

export function validateMeterNumberMininumRequirements(input) {
  // Rule 1: Minimum of 2 characters, first character is a letter, second character is a number
  const firstLetterAndSecondNumberRegex = /^[a-zA-Z][0-9]([a-zA-Z0-9]*)?$/
  if (input.length === 2 && firstLetterAndSecondNumberRegex.test(input)) {
    return true
  }
  // Rule 2: Only numbers and minimum of three digits
  const numericRegex = /^\d+$/
  const digitRegex = /\d/g
  const digitCount = (input.match(digitRegex) || []).length
  if (input.length > 2 && numericRegex.test(input) && digitCount >= 3) {
    return true
  }
  // Rule: Only numbers and minimum of two digits with hyphen in between
  const numericWithHyphenRegex = /^\d+-\d+$/
  if (input.length > 2 && numericWithHyphenRegex.test(input) && digitCount >= 2) {
    return true
  }
  return input.length > 2
}

export const removeSpecialCharsExceptDash = input => {
  return input.replace(/[^a-zA-Z0-9-]/g, "")
}

export function isEmptyOrSpaces(str: any) {
  return !str || ((typeof str === "string" || str instanceof String) && str.trim() === "")
}

export function getDateBackendFormat(date: string) {
  return date.split(".").reverse().join("-")
}

export const getLinkDataParams = (linkData: PartnerLinkType) => {
  if (!linkData) return ""
  let data = {
    referralCode: linkData.referralCode,
    clientApplicationId: linkData.clientApplicationId,
    externalUserId: linkData.externalUserId,
    externalTransactionId: linkData.externalTransactionId,
    ...(linkData?.language?.includes("DE")
      ? {
          language: "de_DE",
        }
      : {
          language: "en",
        }),
  } as Partial<PartnerParams>
  // remove values that are not defined
  data = Object.entries(data).reduce((prev, [key, val]) => (val ? ((prev[key] = val), prev) : prev), {})
  return new URLSearchParams(data).toString()
}
