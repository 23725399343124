import { T } from "@transifex/react"
import { SvgImage } from "../../assets/svgs/SvgImage"
import React from "react"
import { t } from "@transifex/native"
import "./ServiceSection.scss"

const ServiceSection = () => {
  const services = [
    {
      icon: "technician",
      title: t("Seamless & Carefree"),
      description: t("We manage the cancellation and handover process so you can stay lit at all times!"),
    },
    {
      icon: "power",
      title: t("Uninterrupted Service"),
      description: t(
        "We'll sort everything right away! Even after the technical setup, we’re here to make sure everything works smoothly."
      ),
    },
    {
      icon: "resting",
      title: t("We Deal With Your Ex"),
      description: t("Let Ostrom do it for you: We let your old provider know you’ve seen the light."),
    },
  ]
  return (
    <div className="service-container">
      <div className={"background"}></div>
      <div className="title">
        <T _str={"Breakups are Never Easy… "} />
        <br />
        <T _str={"Oh Wait, With Ostrom They Are!"} />
      </div>
      <div className="description">
        <T _str="Lean back and enjoy: When switching to us, we handle all the details with your old provider." />
      </div>
      <div className="service-items">
        {services.map((item, idx) => (
          <div key={idx} className={"service-item"}>
            <div className="icon">
              <SvgImage name={item.icon} />{" "}
            </div>
            <div className="title">
              <T _str={item.title} />
            </div>
            <div className="description">
              <T _str={item.description} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ServiceSection
