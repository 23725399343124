import React, { useEffect, useMemo, useState } from "react"
import { T, useLocale } from "@transifex/react"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import { t } from "@transifex/native"
import { useShallow } from "zustand/react/shallow"

import { InitFormValueTypeString } from "../../../types/form"
import { DELAYED_EMAIL_PROVIDERS, ENGLISH, MOBILE_WIDTH } from "../../../constants/common"
import { getStreetsByCityId } from "../../../service/api"
import TextField from "../../../components/TextField/TextField"
import CountryCodeInput from "../../../components/CountryCodeInput/CountryCodeInput"
import AutoComplete from "../../../components/SupplierInput/AutoComplete"
import { useUserInfoModel } from "../../../models/userInfoModel"
import { useResponsive } from "../../../hooks/useResponsive"
import { usePlanFormModel } from "../../../models/usePlanFormModel"
import { isEmptyOrSpaces } from "../../../utils"
import { useGeneralModel } from "../../../models/useGeneralModel"
import useCheckIfEmailExists from "../../../hooks/useCheckIfEmailExists"
import { ValidateEmail } from "../../../utils/helpers"

export default function UserInfoForm() {
  const [warningText, setWarningText] = useState("")

  const { firstName, email, dob, street, surname, phone, companyName, houseNumber } = useUserInfoModel(
    useShallow(state => state)
  )
  const { personalTab, cityStreets } = useGeneralModel(useShallow(state => state))

  const planForm = usePlanFormModel(useShallow(state => state))

  const { deviceType } = useResponsive()

  const emailProviderIsDelayed = (email: InitFormValueTypeString) => {
    return DELAYED_EMAIL_PROVIDERS.some(e => email.value.endsWith(e))
  }

  const isMobile = useMemo(() => window.innerWidth <= MOBILE_WIDTH, [window.innerWidth])
  const locale = useLocale()

  const { checkEmail } = useCheckIfEmailExists()

  const handlePhoneNumber = (phoneNumber, phoneAreaCode) => {
    // @ts-ignore
    useUserInfoModel.setState({
      phone: {
        ...phone,
        value: phoneNumber,
        valid: true,
        error: "",
        // @ts-ignore
        phoneAreaCode,
      },
    })
  }

  useEffect(() => {
    if (planForm?.cityId) {
      getStreetsByCityId(`${planForm?.cityId}`).then(({ data }) => {
        if (data) {
          useGeneralModel.setState({ cityStreets: data })
        }
      })
    }
  }, [planForm.cityId])

  useEffect(() => {
    useGeneralModel.setState({ isEmailInUse: false })
    if (ValidateEmail(email.value) === false) {
      return () => {}
    }
    if (email.valid === true) {
      if (isEmptyOrSpaces(email.value)) {
        return () => {}
      }
      checkEmail(email.value)
    } else {
      useUserInfoModel.setState({ email: { ...email, error: "" } })
    }
    // eslint-disable-next-line
  }, [email.value])

  useEffect(() => {
    if (emailProviderIsDelayed(email)) {
      setWarningText(t("Our recommendation: choose another email provider to avoid delays."))
    } else {
      setWarningText("")
    }
  }, [email])

  return (
    <>
      <div className={"form-container bg-white-color"}>
        <div className={"form-title"} autoFocus={true}>
          <div className={"title bg-white-color"}>
            <T _str="Personal Info" />
          </div>
          <div className={"line-container"}>
            <div className={"line"} />
          </div>
        </div>
        <div className={"form-content"} style={{ paddingTop: 0 }}>
          <div className={"form-tab-container"}>
            <div
              className={"form-tab" + (personalTab === "home" ? " active" : "")}
              onClick={() => useGeneralModel.setState({ personalTab: "home" })}
            >
              <div className={"tab-title"}>
                {personalTab === "home" ? <SvgImage name={"home-active"} /> : <SvgImage name={"home-inactive"} />}
                <T _str={"HOME"} />
                <p style={{ margin: 0 }}>
                  <input type="radio" readOnly checked={personalTab === "home"} />
                </p>
              </div>
            </div>
            <div
              className={"form-tab" + (personalTab === "business" ? " active" : "")}
              onClick={() => useGeneralModel.setState({ personalTab: "business" })}
            >
              <div className={"tab-title"}>
                {personalTab === "business" ? (
                  <SvgImage name={"business-active"} />
                ) : (
                  <SvgImage name={"business-inactive"} />
                )}
                <T _str={"BUSINESS"} />
                <p style={{ margin: 0 }}>
                  <input type="radio" readOnly checked={personalTab === "business"} />
                </p>
              </div>
            </div>
          </div>
          {personalTab === "home" && (
            <>
              <div className={"personal-input-controller"} style={{ marginTop: 28 }}>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    defaultValue={firstName.value}
                    showError={firstName.error !== ""}
                    helpText={firstName.error}
                    onChange={e => {
                      useUserInfoModel.setState({
                        firstName: {
                          ...firstName,
                          value: e.value,
                          error: "",
                        },
                      })
                    }}
                    label={"FIRST NAME"}
                    id="firstname"
                    autoComplete={"given_name"}
                    data-test-id={"first-name"}
                  />
                </div>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    defaultValue={surname.value}
                    showError={surname.error !== ""}
                    helpText={surname.error}
                    onChange={e => {
                      useUserInfoModel.setState({
                        surname: {
                          ...surname,
                          value: e.value,
                          error: "",
                        },
                      })
                    }}
                    label={"SURNAME"}
                    id="lastname"
                    autoComplete={"family-name"}
                    data-test-id={"surname"}
                  />
                </div>
              </div>
              <div className={"personal-input-controller"}>
                <div className={"personal-text-field dob-mobile"}>
                  <div className={"personal-text-field dob"} style={{ flex: 0.2 }}>
                    <TextField
                      active={true}
                      defaultValue={dob.value}
                      showError={dob.error !== ""}
                      helpText={dob.error}
                      onChange={e => {
                        useUserInfoModel.setState({
                          dob: {
                            ...dob,
                            value: e.value,
                            valid: e.valid,
                            error: "",
                          },
                        })
                      }}
                      label={"DATE OF BIRTH"}
                      type={"date"}
                      placeholder={"DD.MM.YYYY"}
                      id="bday"
                      autoComplete="bday"
                      {...(isMobile ? { inputMode: "numeric" } : {})}
                    />
                  </div>
                  <div className={"personal-text-field"} style={{ flex: 0.4 }}>
                    <CountryCodeInput
                      showError={phone.error !== ""}
                      helpText={phone.error}
                      label={"PHONE NUMBER"}
                      onChange={handlePhoneNumber}
                      value={phone.value}
                      inputProps={{ "data-test-id": "phone-number" }}
                    />
                  </div>
                  <div className={"personal-text-field"} style={{ flex: 0.4 }}>
                    <TextField
                      active={true}
                      label={"EMAIL"}
                      defaultValue={email.value}
                      type={"email"}
                      helpText={email.error}
                      showError={email.error !== ""}
                      onChange={e => {
                        useUserInfoModel.setState({
                          email: {
                            ...email,
                            valid: e.valid,
                            error: "",
                            value: e.value,
                          },
                        })
                      }}
                      data-test-id={"email"}
                      warningText={warningText}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {personalTab === "business" && (
            <>
              <div className={"personal-input-controller"} style={{ marginTop: 28 }}>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    defaultValue={firstName.value}
                    helpText={firstName.error}
                    showError={firstName.error !== ""}
                    onChange={e => {
                      useUserInfoModel.setState({
                        firstName: {
                          ...firstName,
                          value: e.value,
                          error: "",
                        },
                      })
                    }}
                    label={"FIRST NAME"}
                  />
                </div>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    defaultValue={surname.value}
                    helpText={surname.error}
                    showError={surname.error !== ""}
                    onChange={e => {
                      useUserInfoModel.setState({
                        surname: {
                          ...surname,
                          value: e.value,
                          error: "",
                        },
                      })
                    }}
                    label={"SURNAME"}
                  />
                </div>
              </div>
              <div className={"personal-input-controller"}>
                <div className={"personal-text-field country-code-wrapper"}>
                  <div className={"personal-text-field"}>
                    <CountryCodeInput
                      showError={phone.error !== ""}
                      helpText={phone.error}
                      label={"PHONE NUMBER"}
                      onChange={handlePhoneNumber}
                      value={phone.value}
                    />
                  </div>
                </div>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    label={"EMAIL"}
                    defaultValue={email.value}
                    type={"email"}
                    helpText={email.error}
                    showError={email.error !== ""}
                    onChange={e => {
                      useUserInfoModel.setState({
                        email: {
                          ...email,
                          valid: e.valid,
                          error: "",
                          value: e.value,
                        },
                      })
                    }}
                    data-test-id={"business-email"}
                    warningText={t(warningText)}
                  />
                </div>
              </div>
              <div className={"personal-input-controller"}>
                <div className={"personal-text-field"}>
                  <TextField
                    active={true}
                    onChange={e => {
                      useUserInfoModel.setState({
                        companyName: {
                          ...companyName,
                          value: e.value,
                          error: "",
                        },
                      })
                    }}
                    defaultValue={companyName.value}
                    helpText={companyName.error}
                    showError={companyName.error !== ""}
                    label={t("COMPANY NAME")}
                  />
                </div>
                <div className={"personal-text-field"}></div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={"form-container bg-white-color mt-20"}>
        <div className={"form-title"}>
          <div className={"title bg-white-color"}>
            <T _str="Address" />
          </div>
          <div className={"line-container"}>
            <div className={"line"} />
          </div>
        </div>
        {deviceType?.deskTop && (
          <div className={"form-content"} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div className={"personal-input-controller"} style={{ marginTop: 20 }}>
              <div className={"personal-text-field"} style={{ flex: 0.25 }}>
                <TextField
                  active={true}
                  className={"mt-0"}
                  defaultValue={planForm?.postalCode}
                  disabled={true}
                  placeholder={"00000"}
                  label={"POSTCODE"}
                  data-test-id={"address-postcode"}
                />
              </div>
              <div className={"personal-text-field"} style={{ flex: 0.75 }}>
                <TextField
                  active={true}
                  className={"mt-0"}
                  defaultValue={planForm?.cityName}
                  disabled={true}
                  placeholder={"00000"}
                  label={"CITY"}
                  data-test-id={"address-city"}
                />
              </div>
            </div>
            <div className={"personal-input-controller"}>
              <div className={"personal-text-field select-trailing-icon"} style={{ flex: 0.75, height: 72 }}>
                <AutoComplete
                  active={true}
                  defaultValue={street.value}
                  showError={street.error !== ""}
                  helpText={street.error}
                  label={"STREET"}
                  className={"mt-0"}
                  labelKey={"name"}
                  indexKey={"name"}
                  onChange={e => {
                    useUserInfoModel.setState({
                      street: {
                        ...street,
                        value: e.value,
                        valid: e.valid,
                        error: "",
                      },
                    })
                  }}
                  placeholder={t("Select")}
                  list={cityStreets}
                  trailingIcon={"arrow-down"}
                  data-test-id={"address-street"}
                />
              </div>
              <div
                className={"personal-text-field personal-number-field"}
                style={{ flex: 0.25, height: 72, marginTop: 0 }}
              >
                <TextField
                  active={true}
                  defaultValue={houseNumber.value}
                  showError={houseNumber.error !== ""}
                  label={"NUMBER"}
                  onChange={e => {
                    useUserInfoModel.setState({
                      houseNumber: {
                        ...houseNumber,
                        value: e.value,
                        valid: e.valid,
                        error: "",
                      },
                    })
                  }}
                  data-test-id={"address-house-number"}
                />
              </div>
            </div>
          </div>
        )}
        {!deviceType?.deskTop && (
          <div className={"form-content"} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <div className={"personal-input-controller personal-input-controller-mobile"} style={{ marginTop: 20 }}>
              <div className={"personal-text-field address-text-field-mobile"} style={{ flex: 0.25 }}>
                <TextField
                  active={true}
                  className={"mt-0"}
                  defaultValue={planForm?.postalCode}
                  disabled={true}
                  placeholder={"00000"}
                  label={locale === ENGLISH ? "POSTCODE" : "PLZ"}
                  data-test-id={"address-postcode-mobile"}
                />
              </div>
              <div className={"personal-text-field address-text-field-mobile"} style={{ flex: 0.75 }}>
                <TextField
                  active={true}
                  className={"mt-0"}
                  defaultValue={planForm?.cityName}
                  disabled={true}
                  placeholder={"00000"}
                  label={"CITY"}
                  data-test-id={"address-city-mobile"}
                />
              </div>
            </div>
            <div className={"personal-input-controller personal-input-controller-mobile"}>
              <div className={"personal-text-field select-trailing-icon"} style={{ flex: 0.75 }}>
                <AutoComplete
                  active={true}
                  defaultValue={street.value}
                  showError={street.error !== ""}
                  helpText={street.error}
                  label={"STREET"}
                  className={"mt-0"}
                  labelKey={"name"}
                  indexKey={"name"}
                  onChange={(e: InitFormValueTypeString) => {
                    useUserInfoModel.setState({
                      street: {
                        ...street,
                        value: e.value,
                        valid: e.valid,
                        error: "",
                      },
                    })
                  }}
                  placeholder={t("Select")}
                  list={cityStreets}
                  trailingIcon={"arrow-down"}
                  data-test-id={"address-street-mobile"}
                />
              </div>
              <div
                className={"personal-text-field address-text-field-mobile personal-number-field"}
                style={{ flex: 0.25 }}
              >
                <TextField
                  active={true}
                  defaultValue={houseNumber.value}
                  showError={houseNumber.error !== ""}
                  label={"NUMBER"}
                  onChange={e => {
                    useUserInfoModel.setState({
                      houseNumber: {
                        ...houseNumber,
                        value: e.value,
                        valid: e.valid,
                        error: "",
                      },
                    })
                  }}
                  data-test-id={"address-house-number-mobile"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
