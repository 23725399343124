import { T } from "@transifex/react"
import "./CalculateTariff.scss"
import ReferralTariffCard from "../ReferralTariffCard/ReferralTariffCard"

const CalculateTariff = ({ refCode, refData, getRefCode }) => {
  return (
    <div className={"calculate-wrapper"}>
      <div className={"calculate-header"}>
        <h2>
          <T _str={"Green Energy Made Easy"} />{" "}
        </h2>
        <p className={"mobile"}>
          <T _str={"It only takes 5 minutes to switch and save with Ostrom!"} />{" "}
        </p>
        <p className={"desktop"}>
          <T _str={"Switch and save in only 5 minutes!"} />
        </p>
      </div>
      <ReferralTariffCard refCode={refCode} refData={refData} getRefCode={getRefCode} />
    </div>
  )
}

export default CalculateTariff
