import { Alert, Dialog, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material"
import { SvgImage } from "../../../assets/svgs/SvgImage"
import { T } from "@transifex/react"
import React, { useEffect, useState } from "react"
import "./MeterNumber.scss"
import Transition from "../../Transition/Transition"

export function MeterNumber({ openStatus, onClose }) {
  const [open, setOpen] = useState(false)
  const [currentMeterExample, setCurrentMeterExample] = useState(0)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const MIN_SWIPE_DISTANCE = 50

  const onTouchStart = e => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0]?.clientX)
  }

  const onTouchMove = e => {
    setTouchEnd(e.targetTouches[0]?.clientX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const leftSwipe = distance > MIN_SWIPE_DISTANCE
    const rightSwipe = distance < -MIN_SWIPE_DISTANCE
    if (leftSwipe || rightSwipe) {
      rightSwipe ? handleBack() : handleNext()
    }
  }

  useEffect(() => {
    setOpen(openStatus)
  }, [openStatus])
  useEffect(() => {
    if (open === false) {
      onClose()
    }
    // eslint-disable-next-line
  }, [open])
  // eslint-disable-next-line
  const [error] = useState("")

  const handleNext = () => {
    if (currentMeterExample > 4) {
      setCurrentMeterExample(0)
      return
    }
    setCurrentMeterExample(prev => prev + 1)
  }

  const handleBack = () => {
    if (currentMeterExample < 1) {
      setCurrentMeterExample(5)
      return
    }
    setCurrentMeterExample(prev => prev - 1)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="meter-number-dialog"
      maxWidth={"md"}
      data-test-id="meter-number-dialog"
    >
      <DialogTitle id="alert-dialog-title">
        <div
          className={"dialog-close-icon"}
          style={{ width: 20, height: 40 }}
          onClick={() => {
            setOpen(false)
          }}
        >
          <IconButton size="small" data-test-id="dialog-close-button">
            <SvgImage name="dialog-close" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={"meter-number-dialog-content"}>
          <div className={"dialog-content-title"}>
            <T _str={"Where do I find my "} />
            <span style={{ fontStyle: "italic" }}>Zählernummer</span>?
          </div>
          <div className={"dialog-content-subtitle"}>
            <T _str={"Your "} />
            <span style={{ fontStyle: "italic" }}>Zählernummer</span>
            <T
              _str={" (Meter ID) can be found on your electricity bill or on your electricity meter, as seen below."}
            />
          </div>
          <div
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            className={"dialog-content-bg"}
          >
            <img src={require(`../../../assets/images/meterImage-${currentMeterExample}.png`)} alt="meter-number" />
          </div>
          <div className={"dialog-carousel-button"}>
            <IconButton onClick={handleBack} data-test-id="dialog-back-button">
              <SvgImage name={"dialog-back-btn"} />
            </IconButton>
            <p className={"desktop-mobile-text"}>
              <T _str={"Click to see other meter examples "} /> <span>{`(${currentMeterExample + 1}/6)`}</span>
            </p>
            <p className={"mobile-text"}>
              <T _str={"Swipe to see other meter examples "} /> <span>{`(${currentMeterExample + 1}/6)`}</span>
            </p>
            <IconButton onClick={handleNext} data-test-id="dialog-next-button">
              <SvgImage name={"dialog-forward-btn"} />
            </IconButton>
          </div>
          {error !== "" && (
            <Alert className={"mt-20"} severity="warning">
              {error}
            </Alert>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
