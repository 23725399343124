import React from "react"
import { T } from "@transifex/react"
import { useShallow } from "zustand/react/shallow"

import smartMeter from "../../assets/images/smart-meter.png"
import { useGeneralModel } from "../../models/useGeneralModel"
import { usePlanFormModel } from "../../models/usePlanFormModel"
import { FREE_SMART_METER_OPT_OUT, FREE_SMART_METER_USAGE } from "../../constants/common"

const priceContent = {
  below4k: (
    <>
      <T
        _str={
          "Your smart meter will only cost a one-time payment of 30€. You can select your payment method for this and your Ostrom contract on the next screen."
        }
      />
    </>
  ),
  above4k: (
    <>
      <T
        _str={
          "You don’t have to pay anything for your smart meter installation, it's completely free with no hidden fees."
        }
      />
    </>
  ),
  above10k: (
    <>
      <T
        _str={
          "Your smart meter installation is completely free! However, because your consumption is over 10,000 kWh, you are part of the mandatory smart meter rollout, which means your metering fees will increase by 2,50 € per month. Ostrom has no control over this fee, which applies whether you order your smart meter through us, or receive it from your local grid operator."
        }
      />
    </>
  ),
}

export default function SmartMeterStep() {
  const { smartMeterOrder, smartMeterPrice } = useGeneralModel(useShallow(state => state))
  const { usage } = usePlanFormModel(useShallow(state => state))

  const getPriceContentBasedOnUsage = (usage: number) => {
    if (usage < FREE_SMART_METER_USAGE) {
      return priceContent.below4k
    } else if (usage >= FREE_SMART_METER_USAGE && usage < FREE_SMART_METER_OPT_OUT) {
      return priceContent.above4k
    } else {
      return priceContent.above10k
    }
  }

  const getAmountContentBasedOnUsage = (usage: number) => {
    if (usage < FREE_SMART_METER_USAGE) {
      return <T _str="Order a smart meter with Ostrom for only {price}€!" price={smartMeterPrice} />
    } else {
      return <T _str="Order your free smart meter with Ostrom!" />
    }
  }

  return (
    <>
      <div className={"form-container bg-white-color"}>
        <div className={"form-title"}>
          <div className={"title bg-white-color mb-0"} data-test-id={"smart-meter-title"}>
            {getAmountContentBasedOnUsage(Number(usage))}
          </div>
          <div className={"line-container"}>
            <div className={"line add-on-line"} />
          </div>
        </div>
        <div className={"form-container form-container-border mb-20"}>
          <div className={"form-container-border-line"} />
        </div>
        <div className="text-content">
          <div className="text-block-container mb-0">
            <div className="text-block mobile">
              <div className={"text-sub"}>
                <T
                  _str={
                    "Select yes below, and Ostrom will forward your smart meter order to your local grid operator. Once installed, you can track your real-time consumption data and switch to our SimplyDynamic tariff."
                  }
                />
              </div>
              <div className={"text-sub"}>
                <T
                  _str={
                    "SimplyDynamic allows you to take advantage of price fluctuations in the energy market, potentially saving you up to 35% on your energy costs, and supporting the fight against climate change—Smart meters are key to Germany's energy transition!"
                  }
                />
              </div>
              <div className={"text-sub"}>{getPriceContentBasedOnUsage(Number(usage))}</div>
            </div>
            <div className="bg-meter-img">
              <img src={smartMeter} alt={"Smart Meter"} width={"190px"} data-test-id={"smart-meter-img"} />
            </div>
          </div>
          <div className="text-flex">
            <div className="text-note">
              <T
                _str={
                  "Please note: Your grid operator will reach out to you to set up an installation appointment, which could take up to 4 months."
                }
              />
            </div>
            <div className="sm-meter-img">
              <img src={smartMeter} alt={"Smart Meter"} width={"165px"} data-test-id={"smart-meter-img"} />
            </div>
          </div>
        </div>
      </div>
      <div className={"form-container form-container-border bg-white-color"}>
        <div className={"form-container-border-line"} />
      </div>
      <div className={"form-container bg-white-color"}>
        <div className={"form-title"}>
          <div className={"title md-title bg-white-color"}>
            <span>
              <T _str="Would you like to receive a smart meter?" />
            </span>
          </div>
        </div>
        <div className={"form-content pb-0"}>
          <div className={"form-tab-container "}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => useGeneralModel.setState({ smartMeterOrder: true })}
              className={" form-tab-price-cap form-tab" + (smartMeterOrder === true ? " active" : "")}
              onClick={() => useGeneralModel.setState({ smartMeterOrder: true })}
            >
              <div className={"tab-title"}>
                <T _str={"YES"} />
              </div>
              <div className="text-radio pad-5">
                <T
                  _str={
                    "I want Ostrom to order a smart meter for me so I can profit from real-time usage insights and SimplyDynamic"
                  }
                />
              </div>
              <p style={{ margin: 0 }}>
                <input
                  type="radio"
                  name="radi-yes"
                  data-test-id={"smart-meter-yes"}
                  onChange={() => {}}
                  checked={smartMeterOrder === true}
                />
              </p>
            </div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => useGeneralModel.setState({ smartMeterOrder: false })}
              className={" form-tab-price-cap form-tab" + (smartMeterOrder === false ? " active" : "")}
              onClick={() => useGeneralModel.setState({ smartMeterOrder: false })}
            >
              <div className={"tab-title"}>
                <T _str={"NO"} />
              </div>
              <div className="text-radio">
                <T _str={"I don’t want to gain insights into my energy usage"} />
              </div>
              <p style={{ margin: 0 }}>
                <input
                  type="radio"
                  name="radi-no"
                  data-test-id={"smart-meter-no"}
                  onChange={() => {}}
                  checked={smartMeterOrder === false}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
