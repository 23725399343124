export default function Facebook({ color = "#16295A" }) {
  return (
    <svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4582 14.8533L14.0835 10.7439H10.1082V8.06388C10.1082 6.90253 10.6442 5.83052 12.4309 5.83052H14.2622V2.30182C14.2622 2.30182 12.6095 1.98915 11.0462 1.98915C7.78549 1.98915 5.64147 3.99917 5.64147 7.57254V10.7439H1.97877V14.8533H5.64147V24.8587H10.1082V14.8533H13.4582Z"
        fill={color}
      />
    </svg>
  )
}
