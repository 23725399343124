import React, { useEffect } from "react"
import "./PageLoader.scss"

export default function PageLoader({ loading = false }) {
  const [open, setOpen] = React.useState(loading)
  useEffect(() => {
    setOpen(loading)
  }, [loading])
  return (
    <>
      {open === true && (
        <div className={"page-loader"}>
          <img
            src={require("../../assets/images/ostrom-loader.gif")}
            alt={"Loader"}
            style={{ width: "200px", margin: "auto", display: "block" }}
          />
        </div>
      )}
    </>
  )
}
